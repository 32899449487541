import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-stepper",
  templateUrl: "./login-stepper.component.html",
  styleUrls: ["./login-stepper.component.scss"],
})
export class LoginStepperComponent {
  @Input() fromResetPassword: boolean;
  @Input() siteName: string;

  constructor(private router: Router) {}

  stepper = 1;

  onChangeStepper(step: number) {
    this.stepper = step;
  }

  navigateToRentUnit() {
    this.router.navigate(["/dashboard/rent-unit"]);
  }
}
