import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { HelperService } from "./helper.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../authentication/auth.service";
import { SiteNoService } from "./siteno.service";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    private siteNoService: SiteNoService,
    private snackBar: MatSnackBar
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      ...((!request?.url.includes('importIIFFile') && !request.headers.has('Skip-Authorization') && !request?.url.includes('pictures') && (!request?.url.includes('GuestUser') && !request.params.get('isGuestUser'))) && {
        setHeaders: {
          Authorization: `Bearer ${this.helperService.getItemFromLocalStorage('token')}`,
          'Content-Type': 'application/json'
        },
      }),
      ...((!request?.url.includes('?SiteNo') && !request?.url.includes('&SiteNo')) && {
        setParams: {
          SiteNo: this.siteNoService.getSiteNo(),
        }
      }),
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          error?.error?.ErrorMessage === "Token Expired"
        ) {
          this.helperService.clearLocalStorage();
          this.authService.redirectToLogin();
          return throwError(error);
        }

        if (
          error.status === 401 &&
          error?.error?.ErrorMessage.includes("Access denied to SiteNo")
        ) {
          this.snackBar.open(error?.error?.ErrorMessage, "Close");
          return throwError(error);
        }

        if (error.status === 404) {
          return throwError(error);
        }

        return throwError(error);
      })
    );
  }
}