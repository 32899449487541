import { Component, OnInit, Inject } from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { RentUnitService } from "src/app/services/rent-unit.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "src/app/authentication/auth.service";
import { HttpErrorResponse } from "@angular/common/http";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ForgotPasswordModalComponent } from "../forgot-password-modal/forgot-password-modal.component";

@Component({
  selector: "app-tenant-login-modal",
  templateUrl: "./tenant-login-modal.component.html",
  styleUrls: ["./tenant-login-modal.component.scss"],
})
export class TenantLoginModalComponent {
  loginForm: FormGroup ;
  loadingTenantLogin = false;
  isSmallScreen: boolean;
  errorMessage = "";
  submitAttempt = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TenantLoginModalComponent>,
    private fb: FormBuilder,
    private rentUnitService: RentUnitService,
    private snackBar: MatSnackBar
  ) {
    this.isSmallScreen = breakpointObserver.isMatched("(max-width: 768px)");
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      Email: ["", [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      OnlinePassword: ["", Validators.required],
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onForgotPassword() {
    const position = this.isSmallScreen
      ? {
          bottom: "0px",
        }
      : {
          bottom: "0px",
          right: "0px",
        };

    const modal = this.dialog.open(ForgotPasswordModalComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: "forgot-password-modal",
      position: position,
    });
  }

  onSave() {
    this.submitAttempt = true;

    if (!this.loginForm.valid) {
      if (!this.loginForm?.get('Email')?.valid && !this.loginForm?.get('OnlinePassword')?.valid) {
        this.errorMessage = this.checkFormValidation('Form');
      } else if (!this.loginForm?.get('OnlinePassword')?.valid) {
        this.errorMessage = this.checkFormValidation('OnlinePassword');
      } else if (!this.loginForm?.get('Email')?.valid) {
        this.errorMessage = this.checkFormValidation('Email');
      }
      return;
    }

    this.loadingTenantLogin = true;
    this.errorMessage = "";

    const payload = {
      Email: this.loginForm.value.Email,
      Password: this.loginForm.value.OnlinePassword
    }
    this.authService.signIn(payload).subscribe(
      (data: any) => {
        if (data.Result) {
          this.loadingTenantLogin = false;
          this.dialogRef.close({
            customerId: data.Result.UserID,
          });
        }

        if (data.ErrorMessage) {
          this.errorMessage = data.ErrorMessage;
        }
      },
      (error: HttpErrorResponse) => {
        this.loadingTenantLogin = false;
        this.errorMessage = error.error.ErrorMessage;
        if (this.errorMessage === 'Invalid login') {
          this.errorMessage = 'Email or Password incorrect. Please try again or reset your password!'
        }
        this.loadingTenantLogin = false;
      }
    );
  }

  checkFormValidation(form: any) {
    const errors: any = {
      Email: 'Email is required!',
      OnlinePassword: 'Password is required!',
      Form: 'Email and password are required!'
    }
    return errors[form];
  }
}
