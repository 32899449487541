import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ThemeSettingsService } from "src/app/services/theme-settings.service";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public themeService: ThemeSettingsService,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ) {}

  ngOnInit() {}

  close(data: any) {
    if (data?.title == "Open gate?") {
      this.dialogRef.close({ openGate: true });
    } else {
      this.dialogRef.close(data);
    }
  }
}
