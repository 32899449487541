<div class="transaction-details-container box">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex align-items-center">
      <img class="card-icon" src="../../../assets/icons/rent-unit/card-info.svg" alt="" />
      <span class="title">Transaction details</span>
    </div>

    <img src="../../../assets/icons/rent-unit/close-rental-popup.svg" alt="" (click)="onCancel()" class="clickable" />
  </div>

  <div class="body-wrapper">
    <div class="box small-box">
      <span class="title mb-3">Billing info</span>

      <span class="subtitle" *ngIf="data?.customerData?.FirstName">
        {{ data?.customerData?.LastName + ", " + data?.customerData?.FirstName }}
      </span>

      <span class="subtitle" *ngIf="data?.customerData?.EmailAddress">
        {{ data?.customerData?.EmailAddress }}
      </span>

      <span class="subtitle" *ngIf="data?.customerData?.HomePhone">
        {{ data?.customerData?.HomePhone }}
      </span>

      <span class="subtitle" *ngIf="data?.customerData?.Address1">
        {{ data?.customerData?.Address1 }}
      </span>
    </div>

    <div class="box small-summary-box mb-4">
      <span class="title mb-3 title-summary">Transaction Summary</span>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <span *ngFor="let item of accountData" class="subtitle description">
            <span *ngIf="item?.Amount && item?.Description">
              {{item?.Description || ''}}
            </span>  
          </span>
          <span class="subtitle mt-3 fw-bold">Total:</span>
        </div>
        <div>
          <div class="d-flex flex-column" *ngFor="let item of accountData; ">
            <span class="subtitle description text-end right">
              <span *ngIf="item?.Amount && item?.Description">
                {{item?.Amount | currency }}
              </span>  
            </span>

          </div>
          <div class="subtitle right mt-3 text-end fw-bold">{{totalDue | currency }}</div>
        </div>
      </div>
    </div>

    <div class="box small-box">
      <span class="title mb-3">Payment</span>
      <div class="row">
        <div class="card-box" style=" height: fit-content; flex-direction: column;">
          <div class="d-flex">
            <div class="card-box__info" style=" margin-left: 0;">
              <span class="subtitle">Paid by
              </span>
              <span class="subtitle" *ngIf="paymentMethod">{{ paymentMethod }} - {{ (totalDue | currency) || '$0.00'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="head-wrapper border-btm justify-content-end">
    <div class="d-flex align-items-end">
      <div class="action-btns d-flex align-items-end">
        <button class="sentinel-btn sentinel-btn-transparent mrr-16" (click)="onCancel()">
          Close
        </button>
        <button *ngIf="pdfSrc" class="sentinel-btn sentinel-btn-primary" (click)="downloadPdf(pdfSrc)">
          <img src="../../../assets/icons/bookmark.svg" alt="">
          View Receipt
        </button>
        <img class="loading-wrapper m-1" style="width: 20px; height: 20px; margin-top: 10px !important;"
          src="../../../assets/loaders/loader.gif" alt="loading" *ngIf="loadingPdf" />
      </div>
    </div>
  </div>
</div>
