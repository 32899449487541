import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HomeService } from 'src/app/services/home.service';
import { RentUnitService } from 'src/app/services/rent-unit.service';

@Component({
  selector: 'app-add-secondary-tenant',
  templateUrl: './add-secondary-tenant.component.html',
  styleUrls: ['./add-secondary-tenant.component.scss']
})
export class AddSecondaryTenantComponent {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  secondaryTenantForm: FormGroup;
  secondaryTenantControl = 0

  // option list
  countryList: any = [];
  stateList: any = [];
  smsCarrierList: any = [];
  secondaryTenantList: any[] = [];

  // accordion
  panelOpenState = false;

  // loaders
  requesting = false;
  loading = false;

  submitAttempt = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddSecondaryTenantComponent>,
    private formBuilder: FormBuilder,
    public homeService: HomeService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.buildSecondaryTenantForm();
    this.getCountries();

    this.getSecondaryTenant();
  }

  getSecondaryTenant() {
    this.loading = true;
    this.homeService.getSecondaryTenants(this.data?.primaryCustomerId).subscribe(
      data => {
        this.secondaryTenantList = data?.Result;
        this.loading = false;
      }, error => {
        this.loading = false;
      }
    );
  }

  ngAfterViewInit(): void {
    this.accordion.openAll();
  }

  buildSecondaryTenantForm() {
    this.secondaryTenantForm = this.formBuilder.group({
      secondaryTenantControl: [0],
      CustomerNo: [''],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      BusinessName: [''],
      Address1: ['', Validators.required],
      Address2: [''],
      City: ['', Validators.required],
      StateID: [0, Validators.required],
      ZipCode: ['', Validators.required],
      CountryID: [0, Validators.required],
      HomePhone: ['', Validators.required],
      MobilePhone: [''],
      FaxPhone: [''],
      EMailAddress: ['', [Validators.required]],
      SMSCarrierID: [0],
    });
  }

  getCountries() {
    this.homeService.getCountries().subscribe(
      data => {
        this.countryList = data.Result;
      }
    );
  }

  getStatesByCountry(event: any, employer?: boolean) {
    const countryId = +event.target.value;
    this.homeService.getStatesByCountry(countryId).subscribe(
      data => {
        if (!employer) {
          this.stateList = data?.Result;
          this.secondaryTenantForm.patchValue({
            StateID: this.data?.itemToEdit?.StateID || this.stateList[0]?.StateID,
          });
        }
      }, error => {
        if (!employer) {
          this.stateList = [];
          this.secondaryTenantForm.patchValue({
            StateID: '',
          });
        }
      }
    );
  }

  getSmsCarriers(event: any) {
    const countryId = +event.target.value;
    this.homeService.getSmsCarriers(countryId).subscribe(
      data => {
        this.smsCarrierList = data.Result;
      }
    );
  }

  addSecondaryTenant() {
    if (this.secondaryTenantForm.value.secondaryTenantControl) {
      const secondaryTenant = this.secondaryTenantList.find((item: any) => item?.CustomerID == this.secondaryTenantForm.value.secondaryTenantControl);
      this.dialogRef.close(secondaryTenant);
    }

    this.submitAttempt = true;
    if (!this.secondaryTenantForm.valid) {
      return;
    }

    const payload = {
      ...this.secondaryTenantForm.value,
      PrimaryCustomerID: this.data?.primaryCustomerId,
      CustomerID: 0,
      CustomerNo: 0,
      SocialSecurityNo: '',
      DriversLicenseNo: '',
      SMSCarrierID: 0
    }

    delete payload.secondaryTenantControl;

    this.requesting = true;
    this.homeService.secondaryTenantSave(payload).subscribe(
      data => {
        const customerID = data?.Result?.CustomerID
        this.snackBar.open('Secondary tenant successfully created!', 'Close', { duration: 3000 });
        this.requesting = false;
        this.dialogRef.close(data?.Result);
      },
      error => {
        this.requesting = false;
        this.snackBar.open(`Error: ${error?.error?.ErrorMessage}`, 'Close', { duration: 3000 });
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }

}
