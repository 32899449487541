import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent {
  @Input() totalCount: number;
  @Input() totalPages: number;
  @Input() hasNextPage: boolean;
  @Input() hasPreviousPage: boolean;
  @Input() pageNumber: number;

  @Output() pageEmitter = new EventEmitter();

  pageSizes = [5, 10, 15, 20];
  pageSize = this.pageSizes[0];
  isSmallScreen: boolean
  pages: number[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,

  ) {
    this.isSmallScreen = breakpointObserver.isMatched("(max-width: 768px)");
  }

  ngOnInit() {
    this.pages = this.getVisiblePages();
  }

  previousPage() {
    if (this.hasPreviousPage) {
      this.goToPage(this.pageNumber - 1);
    }
  }

  nextPage() {
    if (this.hasNextPage) {
      this.goToPage(this.pageNumber + 1);
    }
  }

  goToPage(page: number) {
    this.pageNumber = page > 0 ? page : this.pageNumber;

    this.pages = this.getVisiblePages();

    const model = {
      page: this.pageNumber,
      pageSize: this.pageSize,
    };

    this.pageEmitter.emit(model);
  }

  pageSizeChange() {
    this.goToPage(0);
  }

  getVisiblePages(): number[] {
    const visiblePages: number[] = [];
    const start = Math.max(1, this.pageNumber - 2);
    const end = Math.min(this.totalPages, this.pageNumber + 2);
    for (let i = start; i <= end; i++) {
      visiblePages.push(i);
    }
    return visiblePages;
  }
}
