<div class="e-sign-agreement box">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex">
      <img
        src="../../../assets/icons/rent-unit/sign-rental-agreement.svg"
        alt=""
      />
      <span class="title d-flex align-items-center">Sign rental agreement</span>
    </div>

    <img
      src="../../../assets/icons/rent-unit/close-rental-popup.svg"
      alt=""
      (click)="onCancel()"
      class="clickable"
    />
  </div>

  <form class="body-wrapper" [formGroup]="agreementForm">
    <div class="input-wrapper">
      <label for="firstName">First name</label>
      <input
        type="text"
        formControlName="firstName"
        placeholder="Type your first name"
        [ngClass]="{'error-message': agreementForm.get('firstName')?.errors?.incorrectValue}"
      />
    </div>
    <div class="input-wrapper">
      <label for="lastName">Last name</label>
      <input
        type="text"
        formControlName="lastName"
        placeholder="Type your last name"
        [ngClass]="{'error-message': agreementForm.get('lastName')?.errors?.incorrectValue}"
      />
    </div>
    <div class="input-wrapper">
      <label for="initials">Initials</label>
      <input
        type="text"
        formControlName="initials"
        placeholder="Type your initials"
        [ngClass]="{'error-message': agreementForm.get('initials')?.errors?.incorrectValue}"
      />
    </div>
    <div class="agreement-input-wrapper">
      <input
        class="agreement-input"
        type="checkbox"
        formControlName="acceptTerms"
      />
      <span>I accept the Terms and Conditions of the Rental Agreement</span>
    </div>

    <div>
      <span
        >Agreement: By filling out this form and checking the box, I acknowledge
        that I have read the Rental Agreement, and accept all of the Terms and
        Conditions therein. I also agree that the signature and initials will be
        electric representation of my signature and initials for all purposes
        when I use them on documents, including legally binding contracts, just
        the same as pen and paper signature or initials.</span
      >
    </div>
    <div class="agreement-btns">
      <button
        class="sentinel-btn sentinel-btn-transparent mrr-16"
        (click)="onCancel()"
      >
        Cancel
      </button>
      <button class="sentinel-btn sentinel-btn-primary" (click)="onSave()">
        Save signature
      </button>
    </div>
    <span *ngIf="showErrorMessage" class="error">{{ showErrorMessage }}</span>
  </form>
</div>
