import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { HoursModalComponent } from "src/@sentinel/shared/hours-modal/hours-modal.component";
import { AuthService } from "src/app/authentication/auth.service";
import { HelperService } from "src/app/services/helper.service";
import { HomeService } from "src/app/services/home.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  expandedSideNav = true;
  siteDetails: any;

  imageSrc: string;
  userInitials: string = "";
  userFullName: string = "";

  siteConfigId: string = "";

  isSmallScreen: any;

  constructor(
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private helperService: HelperService,
    private homeService: HomeService,
    private route: ActivatedRoute,
  ) {
    this.isSmallScreen = breakpointObserver.isMatched("(max-width: 768px)");
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: {[key: string]: string}) => {
      params?.SiteConfigId && (this.siteConfigId = params.SiteConfigId);
    });
    this.getSiteDetails();
  }

  isLoggedIn() {
    return this.authService.isAuthenticated();
  }

  handleSideNavEvent(event: boolean) {
    this.expandedSideNav = event;
  }

  handleUserAvatarEvent(imgSrc: any) {
    this.imageSrc = imgSrc;
  }

  handleUserInitialsEvent(userInitials: any) {
    this.userInitials = userInitials;
  }

  getMarginLeft(): string {
    if (this.expandedSideNav && this.isLoggedIn()) {
      return "300px";
    } else if (!this.isLoggedIn()) {
      return "0px";
    } else if (!this.expandedSideNav) {
      return "72px";
    } else {
      return "";
    }
  }

  getSiteDetails() {
    const siteConfigId = this.siteConfigId || localStorage.getItem('SiteConfigId') || '';
    this.homeService
      .getSiteDetail(true, siteConfigId)
      .subscribe((data: any) => {
        this.siteDetails = data.Result;
        this.helperService.loadClearentScript(this.siteDetails?.ClearentBaseURL);
      });
  }

  onHoursModal() {
    const position = this.isSmallScreen
      ? {
        bottom: "0px",
      }
      : {
        bottom: "0px",
        right: "0px",
      };

    const modal = this.dialog.open(HoursModalComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: "forgot-password-modal",
      position: position,
      width: this.isSmallScreen ? "100%" : "unset",
      height: this.isSmallScreen ? "100%" : "unset",
      data: {
        siteDetails: this.siteDetails,
      },
    });
  }

  siteLogoImageUrl() {
    if (this.siteDetails && this.siteDetails.LogoImage) {
      return `${environment.apiUrl}${this.siteDetails.LogoImage}`;
    }

    return null;
  }
}
