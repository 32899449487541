<div class="open-gate box">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex align-items-center">
      <span class="title">Open Gate</span>
    </div>

    <img
      src="../../../assets/icons/rent-unit/close-rental-popup.svg"
      alt=""
      class="clickable"
      (click)="onCancel()"
    />
  </div>

  <div class="body-wrapper">
    <div class="open-gate-wrapper">
      <div class="d-flex col-xs-12 open-gate-item">
        <img src="../../../assets/icons/open-gate/name.svg" alt="" />
        <div class="d-flex flex-column">
          <span class="label">Name</span>
          <span class="value">{{ signalrService.getNameUI() || '-' }}</span>
        </div>
      </div>

      <div class="d-flex col-xs-12 open-gate-item">
        <img src="../../../assets/icons/open-gate/description.svg" alt="" />
        <div class="d-flex flex-column">
          <span class="label">Description</span>
          <span class="value">{{ signalrService.getDescriptionUI() || '-' }}</span>
        </div>
      </div>

      <div class="d-flex col-xs-12 open-gate-item">
        <img src="../../../assets/icons/open-gate/location.svg" alt="" />
        <div class="d-flex flex-column">
          <span class="label">Location</span>
          <span class="value">{{ signalrService.getLocationUI() || '-' }}</span>
        </div>
      </div>
      
      <div *ngIf="gateStatus === GateStatus.Closed" class="d-flex col-xs-12 open-gate-item">
        <img src="../../../assets/icons/open-gate/closed-status.svg" alt="" />
        <div class="d-flex flex-column">
          <span class="label">Status</span>
          <span class="value">{{ "Closed" }}</span>
        </div>
      </div>

      <div *ngIf="gateStatus === GateStatus.Opening" class="d-flex col-xs-12 open-gate-item">
        <img src="../../../assets/icons/open-gate/opening-status.svg" alt="" />
        <div class="d-flex flex-column">
          <span class="label">Status</span>
          <span class="value opening">{{ "Opening" }}</span>
        </div>
      </div>

      <div *ngIf="gateStatus === GateStatus.Opened" class="d-flex col-xs-12 open-gate-item">
        <img src="../../../assets/icons/open-gate/opened-status.svg" alt="" />
        <div class="d-flex flex-column">
          <span class="label">Status</span>
          <span class="value opened">{{ "Opened" }}</span>
        </div>
      </div>
    </div>

    <div class="main-btn">
      <button
        [disabled]="gateStatus !== GateStatus.Closed || signalrService.isRequesting || !signalrService.isGeolocationGranted"
        class="sentinel-btn sentinel-btn-primary sentinel-btn-big"
        (click)="openGate()"
      >
        Open Gate
        <img class="loading-wrapper m-1" style="width: 10px; height: 10px" src="../../../assets/loaders/loader.gif"
          alt="loading" *ngIf="signalrService.isRequesting" />
      </button>
    </div>
  </div>
</div>
