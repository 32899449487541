import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {canActivate, canActivateChild} from "../guards/dashboard.guard";
import { LayoutComponent } from "./layout/layout.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "home",
        canActivate: [canActivate],
        canActivateChild: [canActivateChild],
        loadChildren: () =>
          import("../pages/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "rent-unit",
        loadChildren: () =>
          import("../pages/rent-unit/rent-unit.module").then(
            (m) => m.RentUnitModule
          ),
      },
      {
        path: "rent-unit/:id",
        loadChildren: () =>
          import("../pages/rent-unit-view/rent-unit-view.module").then(
            (m) => m.RentUnitViewModule
          ),
      },
      {
        path: "rent-unit-view",
        loadChildren: () =>
          import("../pages/rent-unit-view/rent-unit-view.module").then(
            (m) => m.RentUnitViewModule
          ),
      },
      {
        path: "reserve-unit/:id",
        loadChildren: () =>
          import("../pages/reserve-unit-view/reserve-unit-view.module").then(
            (m) => m.ReserveUnitViewModule
          ),
      },
      {
        path: "reserve-unit-view",
        loadChildren: () =>
          import("../pages/reserve-unit-view/reserve-unit-view.module").then(
            (m) => m.ReserveUnitViewModule
          ),
      },

      {
        path: "payments",
        canActivate: [canActivate],
        canActivateChild: [canActivateChild],
        loadChildren: () =>
          import("../pages/make-a-payment/make-a-payment.module").then(
            (m) => m.MakeAPaymentModule
          ),
      },
      {
        path: "settings",
        canActivate: [canActivate],
        canActivateChild: [canActivateChild],
        loadChildren: () =>
          import("../pages/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: "support",
        canActivate: [canActivate],
        canActivateChild: [canActivateChild],
        loadChildren: () =>
          import("../pages/support/support.module").then(
            (m) => m.SupportModule
          ),
      },

      {
        path: "history",
        canActivate: [canActivate],
        canActivateChild: [canActivateChild],
        loadChildren: () =>
          import("../pages/history/history.module").then(
            (m) => m.HistoryModule
          ),
      },
      {
        path: "",
        redirectTo: "/dashboard/home",
        pathMatch: "full",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
