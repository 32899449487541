import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-rental-agreement-modal",
  templateUrl: "./rental-agreement-modal.component.html",
  styleUrls: ["./rental-agreement-modal.component.scss"],
})
export class RentalAgreementModalComponent implements OnInit {
  agreementForm: FormGroup | any;
  showErrorMessage: string;

  userInitials: string = '';  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RentalAgreementModalComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.agreementForm = this.fb.group({
      initials: ["", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      acceptTerms: [false, Validators.required],
    });
    
    this.getUserInitials();
  }

  getUserInitials() {
    let userFullName = `${this.data?.customerFirstName} ${this.data?.customerLastName}`;

    const words = userFullName.split(' ');
    userFullName = `${words[0]} ${words[1]}`;

    this.userInitials = '';
    for (let item of words) {
      this.userInitials += item.charAt(0);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.data.customerLastName !== this.agreementForm.get("lastName").value) {
      this.agreementForm.controls.lastName.setErrors({ incorrectValue: true });
    } else {
      this.agreementForm.controls.lastName.setErrors(null);
    }

    if (this.data.customerFirstName !== this.agreementForm.get("firstName").value) {
      this.agreementForm.controls.firstName.setErrors({ incorrectValue: true });
    } else {
      this.agreementForm.controls.firstName.setErrors(null);
    }

    if (this.userInitials.toLocaleLowerCase() !== this.agreementForm.get("initials").value.toLocaleLowerCase()) {
      this.agreementForm.controls.initials.setErrors({ incorrectValue: true });
    } else {
      this.agreementForm.controls.initials.setErrors(null);
    }

    if (this.agreementForm.valid) {
      this.dialogRef.close({ agreementForm: this.agreementForm.value });
    } else {
      this.snackBar.open(
        "You need to fill out the inputs and accept terms in order to proceed",
        "Close",
        { duration: 3000 }
      );
    }
  }
}
