import { Directive, ElementRef, EventEmitter, Output } from "@angular/core";

@Directive({
  selector: "[appOutside]",
  host: {
    "(document:click)": "onClick($event)",
  },
})
export class OutsideDirective {
  @Output()
  clickedOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  onClick(event: any) {
    let path = event.path || (event.composedPath && event.composedPath());

    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (
        path[0].classList[0] !== "sort-item" &&
        path[0].classList[0] !== "popup-main-title" &&
        path[0].classList[0] !== "popup-header" &&
        path[0].classList[0] !== "popup-container"
      ) {
        this.clickedOutside.emit();
      }

      if (path[0].classList[0] == "popup-close-btn") {
        this.clickedOutside.emit();
      }
    }
  }
}
