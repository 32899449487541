<div class="box">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex align-items-center">
      <img
        src="../../../assets/icons/home/unit-main-icon.svg"
        alt=""
        class="clickable"
      />
      <span class="title">Unit #{{ unitDetails?.UnitNo }}</span>
    </div>

    <img
      src="../../../assets/icons/rent-unit/close-rental-popup.svg"
      alt=""
      (click)="onCancel()"
      class="clickable"
    />
  </div>

  <div *ngIf="loading" class="loading-wrapper">
    <img src="../../../assets/loaders/loader.gif" alt="" />
  </div>

  <div class="body-wrapper" [ngClass]="{ 'disable-section': loading }">
    <div class="row mx-0 mt-0 mrb-24">
      <div class="tabs col-sm-12 col-md-8">
        <span
          *ngFor="let tab of tabs"
          class="col-4 tabs-icon"
          [ngClass]="{ active: selectedTab === tab }"
          (click)="onSelectTab(tab)"
          >{{ tab }}</span
        >
      </div>
    </div>


    <div *ngIf="selectedTab == UnitDetailsTabs.UNIT_DETAILS" class="unit-details">
      <div class="unit row">
        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/status.svg" alt="" *ngIf="!(themeService.isDarkModeSubject | async)" />
          <img src="../../../assets/icons/home/status-dark.svg" alt="" *ngIf="themeService.isDarkModeSubject | async" />
          <div class="d-flex flex-column">
            <span class="label">Status</span>
            <span class="value">{{ unitDetails?.UnitStatus || "-" }}</span>
          </div>
        </div>

        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/schedule.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Late cycle schedule </span>
            <span class="value">{{
              unitDetails?.LateCycleSchedule || "-"
            }}</span>
          </div>
        </div>

        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/unit-no.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Unit no</span>
            <span class="value">{{ unitDetails?.UnitNo || "-" }}</span>
          </div>
        </div>


        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/paid-to-date.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Pro rate days </span>
            <span class="value">{{ unitDetails?.ProrateDays || "-" }}</span>
          </div>
        </div>

        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/unit-size.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Unit Size</span>
            <span class="value"
              >{{ unitDetails?.SizeDescription || "-" }}
            </span>
          </div>
        </div>

        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/unit-rate.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Unit Rate</span>
            <span class="value">{{
              (unitDetails?.UnitRate | currency) || "-"
            }}</span>
          </div>
        </div>

        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/paid-to-date.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Move in date</span>
            <span class="value"
              >{{ (unitDetails?.MoveinDate | date) || "-" }}
            </span>
          </div>
        </div>


        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/insurance.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Insurance Premium </span>
            <span class="value">{{
              (unitDetails?.InsurancePremium | currency) || "-"
            }}</span>
          </div>
        </div>

        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/paid-to-date.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Paid to date</span>
            <span class="value"
              >{{ (unitDetails?.PaidtoDate | date) || "-" }}
            </span>
          </div>
        </div>

        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/unit-rate.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Monthly charges</span>
            <span class="value">{{
              (unitDetails?.MonthlyCharges | currency) || "-"
            }}</span>
          </div>
        </div>


        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/unit-rate.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Taxable lease </span>
            <span class="value">{{
              unitDetails?.LeaseTaxable ? "Yes" : "No"
            }}</span>
          </div>
        </div>


        <div class="d-flex col-xs-12 col-md-6">
          <img src="../../../assets/icons/home/balance-due.svg" alt="" />
          <div class="d-flex flex-column">
            <span class="label">Balance Due</span>
            <span class="value"
              >{{ (unitDetails?.BalanceDue | currency) || "-" }}
            </span>
          </div>
        </div>
        
      </div>
    </div>

    <div *ngIf="selectedTab == UnitDetailsTabs.SECONDARY_TENANTS">
      <app-secondary-tenants [customers]="customers"></app-secondary-tenants>
    </div>
    <div *ngIf="selectedTab == UnitDetailsTabs.INSURANCE">
      <app-insurance [insuranceDetails]="insuranceDetails"></app-insurance>
    </div>
    <div *ngIf="selectedTab == UnitDetailsTabs.AGREEMENT">
      <app-agreement-modal [unitId]="unitDetails?.UnitID" [leaseId]="unitDetails?.LeaseID"></app-agreement-modal>
    </div>
  </div>
</div>
