import { Component, Input } from "@angular/core";
import { ThemeSettingsService } from "src/app/services/theme-settings.service";

@Component({
  selector: "app-secondary-tenants",
  templateUrl: "./secondary-tenants.component.html",
  styleUrls: ["./secondary-tenants.component.scss"],
})
export class SecondaryTenantsComponent {
  @Input() customers: any;

  customerExpanded = false;
  customerDetails: any;

  constructor(public themeService: ThemeSettingsService) {}

  onViewMore(customer: any) {
    console.log(customer);
    this.customerDetails = customer;
    this.customerExpanded = true;
  }

  goBack() {
    this.customerDetails = "";
    this.customerExpanded = false;
  }
}
