<div class="forgot-password box">
    <div class="head-wrapper border-btm justify-content-between">
        <div class="d-flex align-items-center">
            <img src="../../../assets/icons/hours-active-icon.svg" alt="">
            <span class="title">Hours</span>
        </div>

        <img src="../../../assets/icons/rent-unit/close-rental-popup.svg" alt="" (click)="onCancel()"
            class="clickable" />
    </div>

    <div class="modal-body">

        <div class="card">
            <div class="item" *ngFor="let item of workingDays">
                <span>{{ item }}</span>
                <span class="hours-label">{{ this.data.siteDetails.TimeZones.WeekDayStart }} -
                    {{ this.data.siteDetails.TimeZones.WeekDayStop }}
                </span>
            </div>

            <div class="item">
                <span>{{ Weekends.SATURDAY }}</span>
                <span class="hours-label">{{ this.data.siteDetails.TimeZones.SatStart }} -
                    {{ this.data.siteDetails.TimeZones.SatStop }}
                </span>
            </div>

            <div class="item">
                <span>{{ Weekends.SUNDAY }}</span>
                <span class="hours-label">{{ this.data.siteDetails.TimeZones.SunStart }} -
                    {{ this.data.siteDetails.TimeZones.SunStop }}
                </span>
            </div>
        </div>
    </div>
</div>