import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout/layout.component";
import { LayoutRoutingModule } from "./layout-routing.module";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { SentinelSharedModule } from "src/@sentinel/sentinel-shared.module";
import { MobileSideNavComponent } from "./mobile-side-nav/mobile-side-nav.component";
import { MobileFooterComponent } from "./mobile-footer/mobile-footer.component";

@NgModule({
  declarations: [
    LayoutComponent,
    SideNavComponent,
    MobileSideNavComponent,
    MobileFooterComponent,
  ],
  imports: [CommonModule, LayoutRoutingModule, SentinelSharedModule],
  exports: [],
})
export class LayoutModule {}
