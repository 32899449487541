<div class="box" [ngClass]="{'disable-section': loading}">
    <div class="row" *ngIf="loading" class="loading-wrapper">
        <img src="../../../assets/loaders/loader.gif" alt="" />
    </div>

    <ng-container *ngIf="agreementDocuments?.length">
        <div class="row table-header">
            <span class="col-4 table-title">Unit No</span>
            <span class="col-3 table-title">Tenant</span>
            <span class="col-2 table-title">Is Signed</span>
            <span class="col-3 table-title ps-5">Action</span>
        </div>

        <div class="row table-body" *ngFor="let item of agreementDocuments">
            <span class="col-4 fw-bold"> {{ item?.UnitNo}}</span>
            <span class="col-3">
                <span>{{ customerFullName }}</span>
            </span>
            <span class="col-2" [ngClass]="{'locked-status': !item?.IsSigned, 'signed-status': item?.IsSigned}">{{
                item?.IsSigned ? 'Yes' : 'No' }}
            </span>
            <span class="col-3 ps-5">
                <button class="sentinel-btn sentinel-btn-primary" (click)="getTheAgreement(item)">{{ item?.IsSigned ?
                    'View' : 'Sign document' }}</button>
            </span>
        </div>
    </ng-container>

    <div class="col-12" *ngIf="!agreementDocuments?.length && !loading">
        <div class="box d-flex flex-column align-items-center justify-content-center" style="padding: 80px 0;">
            <img width="30%" height="auto" src="../../../assets/icons/home/no-items-icon.svg" alt="">
            <h3 class="no-agreements-message">No documents.</h3>
        </div>
    </div>
</div>