export enum SortDirection {
    ASC = 'Acs',
    DESC = 'Desc'
}

export enum HistoryTabs {
    ACTIVITY = 'Activity',
    TRANSACTTIONS = 'Transactions', 
    BALANCE = 'Balance'
}

export enum LeaseStatuses {
    COMPLIMENTARY = 4,
    RENT = 5,
    DELINQUENT = 11,
    IN_LIEN_PROCESS = 12,
    LIEN_COMPLETED = 13,
}

export enum UnitDetailsTabs {
    UNIT_DETAILS = 'Unit details',
    SECONDARY_TENANTS = 'Secondary Tenants',
    SECONDARY_TENANTS_SHORT = 'Sec. Tenants',
    INSURANCE = 'Insurance',
    AGREEMENT = 'Agreement'
}

export enum NameOfSize {
    ALL = 'All sizes',
    SMALL = 'Small',
    MEDIUM = 'Medium',
    LARGE = 'Large'
}

export enum XsNameOfSize {
    ALL = 'All',
    SMALL = 'S',
    MEDIUM = 'M',
    LARGE = 'L'
}

export enum WorkingDays {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday'
}

export enum Weekends {
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday'
}

export enum DarkOrLight {
    DARK = 'dark', 
    LIGHT = 'light'
}

export enum DarkOrLightMode {
    DARK_MODE = 'darkMode', 
    LIGHT_MODE = 'lightMode'
}

export enum BooleanString {
    TRUE = 'true', 
    FALSE = 'false'
}