import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HelperService } from './helper.service';
import { BehaviorSubject } from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { DarkOrLight, DarkOrLightMode } from 'shared/enums';

@Injectable({
  providedIn: 'root'
})
export class ThemeSettingsService {
  isDarkModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  private renderer: Renderer2;
  private currentTheme: string;

  constructor(private rendererFactory: RendererFactory2, 
    private helperService: HelperService
    ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setTheme(theme: string = this.currentTheme) {
    this.renderer.removeClass(document.body, DarkOrLight.LIGHT);
    this.renderer.removeClass(document.body, DarkOrLight.DARK);
    this.renderer.addClass(document.body, theme);
    this.currentTheme = theme;
    this.helperService.setItemToLocalStorage(DarkOrLightMode.DARK_MODE, theme === DarkOrLight.LIGHT ? false : true);
    this.isDarkModeSubject.next(theme === DarkOrLight.LIGHT ? false : true);

    if (!localStorage.getItem("CustomerId")) {
      this.renderer.removeClass(document.body, this.currentTheme);
      this.renderer.addClass(document.body, DarkOrLight.LIGHT);
    }
  }

  toggleTheme(newThemeValue: any) {
    this.setTheme(newThemeValue);
  }
}
