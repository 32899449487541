<div class="row" *ngIf="!customerExpanded">
  <div class="col-xs-12 col-lg-6 col-xl-4" *ngFor="let customer of customers">
    <div class="secondary-tenant-box">
      <h4>{{ customer?.FirstName + " " + customer?.LastName }}</h4>
      <span>{{ customer?.Address1 }}</span>

      <span>{{ customer?.EMailAddress }}</span>
      <span>{{ customer?.HomePhone }}</span>

      <div class="buttons">
        <div class="icons">
          <a href="tel:+1-{{customer?.HomePhone}}">
            <img src="../../../../assets/icons/home/phone.svg" alt="" class="clickable" />
          </a>
          <a href="sms:+1-{{customer?.HomePhone}}?body=Type!">
            <img src="../../../../assets/icons/home/message.svg" alt="" class="clickable"/>
          </a>
        </div>
        <button
          class="sentinel-btn sentinel-btn-secondary"
          (click)="onViewMore(customer)"
        >
          View more
        </button>
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="customers.length == 0">
    <div class="secondary-tenant-box d-flex align-items-center justify-content-center" style="padding: 80px 0;">
      <img width="30%" height="auto" src="../../../assets/icons/home/no-items-icon.svg" alt="">
      <h3>No secondary tenants at this time</h3>
    </div>
  </div>
</div>

<div class="customer-details box" *ngIf="customerExpanded">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex align-items-center">
      <img
        (click)="goBack()"
        src="../../../../assets/icons/home/back.svg"
        alt=""
        class="clickable"
      />
      <span class="title">Tenant details</span>
    </div>
  </div>

  <div class="body-wrapper">
    <div class="unit">
      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/fullname.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Full name</span>
          <span class="value">{{
            customerDetails?.FirstName + " " + customerDetails?.LastName || "-"
          }}</span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/customerno.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Customer No</span>
          <span class="value">{{ customerDetails?.CustomerNo || "-" }} </span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/status.svg"
          alt=""
          *ngIf="!(themeService.isDarkModeSubject | async)"
        />
        <img
          src="../../../../assets/icons/home/tenant-details/status-dark.svg"
          alt=""
          *ngIf="themeService.isDarkModeSubject | async"
        />
        <div class="d-flex flex-column">
          <span class="label">Status</span>
          <span class="value" [ngStyle]="{'color': (themeService.isDarkModeSubject | async) ? '#30D158' : '#34C759'}">{{ "Active" || "-" }} </span>
        </div>
      </div>
    </div>

    <div class="unit">
      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/accesslevel.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Access level</span>
          <span class="value">{{ customerDetails?.AccessLevel || "-" }}</span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/homephone.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Home phone</span>
          <span class="value">{{ customerDetails?.HomePhone || "-" }} </span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/mobilephone.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Mobile phone</span>
          <span class="value">{{ customerDetails?.MobilePhone || "-" }} </span>
        </div>
      </div>
    </div>

    <div class="unit">
      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/faxphone.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Fax phone</span>
          <span class="value">{{ customerDetails?.FaxPhone || "-" }}</span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/emailsms.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Email</span>
          <span class="value value-email clickable" matTooltip="{{ customerDetails?.EMailAddress || '-' }}">
            {{ customerDetails?.EMailAddress || '-' }}
          </span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/emailsms.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">SMS carrier </span>
          <span class="value">{{ customerDetails?.SMSCarrier || "-" }} </span>
        </div>
      </div>
    </div>

    <div class="unit">
      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/country-state-city.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Country</span>
          <span class="value">{{ customerDetails?.Country || "-" }}</span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/country-state-city.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">City</span>
          <span class="value">{{ customerDetails?.City || "-" }} </span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/country-state-city.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">State</span>
          <span class="value">{{ customerDetails?.State || "-" }} </span>
        </div>
      </div>
    </div>

    <div class="unit">
      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/zipcode.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Zip Code</span>
          <span class="value">{{ customerDetails?.ZipCode || "-" }}</span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4">
        <img
          src="../../../../assets/icons/home/tenant-details/address.svg"
          alt=""
        />
        <div class="d-flex flex-column">
          <span class="label">Address</span>
          <span class="value">{{ customerDetails?.Address1 || "-" }} </span>
        </div>
      </div>

      <div class="d-flex col-xs-12 col-md-4"></div>
    </div>
  </div>
</div>
