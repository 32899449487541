<div *ngIf="isLoggedIn()" class="fixed-mobile-footer">
  <img
    class="icon"
    src="../../../assets/images/mobile-footer/{{
      rla.isActive ? 'home-icon-active' : 'home-icon'
    }}.svg"
    alt=""
    routerLink="/dashboard/home"
    #rla="routerLinkActive"
    routerLinkActive="active"
  />
  <img
    class="icon"
    src="../../../assets/images/mobile-footer/{{
      rla1.isActive && !this.isSentinelOnly ? 'payment-icon-active' : 'payment-icon'
    }}.svg"
    alt=""
    [routerLink]="isSentinelOnly ? null : '/dashboard/payments'"
    #rla1="routerLinkActive"
    routerLinkActive="active"
    
  />
  <img src="" alt="" />
  <img
    class="icon"
    src="../../../assets/images/mobile-footer/{{
      rla2.isActive && !this.isSentinelOnly ? 'rent-icon-active' : 'rent-icon'
    }}.svg"
    alt=""
    #rla2="routerLinkActive"
    [routerLink]="isSentinelOnly ? null : '/dashboard/rent-unit'"
    routerLinkActive="active"
  />
  <img
    (click)="openLeftSideNav()"
    class="icon"
    src="../../../assets/images/mobile-footer/sidenav-icon.svg"
    alt=""
  />
</div>

<div class="main-icon" *ngIf="isLoggedIn()">
  <img
    class="main-icon"
    src="../../../assets/images/mobile-footer/open-gate-icon-active.svg"
    alt=""
    #rla3="routerLinkActive"
    routerLinkActive="active"
    (click)="openGate()"
  />
</div>
