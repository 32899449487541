<div class="mobile-side-nav box">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex align-items-center">

      <img *ngIf="!(themeService.isDarkModeSubject | async) && !!siteDetails" width="200px" class="sentinel-icon"
        src="{{ siteLogoImageUrl() || '../../../assets/icons/sentinel-logo.svg' }}" alt="" />
      <img *ngIf="(themeService.isDarkModeSubject | async) && !!siteDetails" width="200px" class="sentinel-icon"
        src="{{ siteLogoImageUrl() || '../../../assets/icons/sentinel-logo-dark.svg' }}" alt="" />
    </div>

    <img src="../../../assets/icons/rent-unit/close-rental-popup.svg" alt="" (click)="onCancel()" class="clickable" />
  </div>

  <div class="body-wrapper">
    <div class="link-wrapper" *ngFor="let item of listOfItems" [routerLink]="item.path" routerLinkActive="active"
      #rla="routerLinkActive" (click)="onNavigate(item)">
      <div class="link-wrapper__text">
        <div>
          <img *ngIf="!rla.isActive" src="../../../assets/icons/mobile-side-nav/{{ item.icon }}" alt="" />

          <img *ngIf="rla.isActive" src="../../../assets/icons/mobile-side-nav/{{ item.iconActive }}" alt="" />
        </div>

        <span [ngClass]="{ warning: item.name == 'Log out' }">{{
          item.name
          }}</span>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="d-flex flex-column">
      <span>Logged in as</span>
      <span class="user-name">{{ userFullName }}</span>
    </div>

    <div>
      <img *ngIf="!(themeService.isDarkModeSubject | async)"
        src="../../../assets/icons/mobile-side-nav/background-changer-icon.svg" alt="" (click)="changeDarkMode()" />

      <img *ngIf="themeService.isDarkModeSubject | async"
        src="../../../assets/icons/mobile-side-nav/background-changer-icon-dark.svg" alt=""
        (click)="changeDarkMode()" />
    </div>
  </div>
</div>