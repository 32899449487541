<div class="tenant-login box">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex align-items-center">
      <img src="../../../assets/icons/login-icon.svg" alt="" />
      <span class="title">Tenant Login</span>
    </div>

    <img
      src="../../../assets/icons/rent-unit/close-rental-popup.svg"
      alt=""
      (click)="onCancel()"
      class="clickable"
    />
  </div>

  <form class="body-wrapper" [formGroup]="loginForm">

    <div class="d-flex flex-column mrb-24">
      <label for="firstName">Email</label>
      <span style="position: relative;">
        <img src="../../../assets/icons/input-field-icons/mail-search-icon.svg" alt="" class="input-image">
        <input type="text" formControlName="Email" class="email-field" placeholder="Email"
          [ngClass]="{'error-message': !loginForm.controls.Email.valid && submitAttempt}"/>
      </span>
    </div>

    <div class="d-flex flex-column mrb-24">
      <label for="lastName">Password</label>
      <span style="position: relative;">
        <img src="../../../assets/icons/input-field-icons/lock-search-icon.svg" alt="" class="input-image">
        <input type="password" formControlName="OnlinePassword" class="password-field" placeholder="Password"
          [ngClass]="{'error-message': !loginForm.controls.OnlinePassword.valid && submitAttempt}"/>
      </span>
    </div>

    <div class="d-flex mrb-24">
      <input class="login-input" type="checkbox" />
      <span>Remember me</span>
    </div>
    <div class="login-btns">
      <button
        [disabled]="loadingTenantLogin"
        class="sentinel-btn sentinel-btn-primary"
        (click)="onSave()"
      >
        Sign In
      </button>

      <button
        class="sentinel-btn sentinel-btn-transparent"
        (click)="onForgotPassword()"
      >
        Forgot Password
      </button>

      <span class="error" *ngIf="errorMessage">{{ errorMessage }}</span>
    </div>

    <div class="loader" *ngIf="loadingTenantLogin">
      <img src="../../../assets/loaders/loader.gif" alt="" />
    </div>
  </form>
</div>
