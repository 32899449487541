import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HomeService } from "src/app/services/home.service";
import { HttpErrorResponse } from "@angular/common/http";


interface AccountDetails {
  AccountNo: string;
  Description: string;
  Amount: number;
}

@Component({
  selector: "app-transaction-details",
  templateUrl: "./transaction-details.component.html",
  styleUrls: ["./transaction-details.component.scss"],
})
export class TransactionDetailsComponent implements OnInit, OnDestroy {
  pdfSrc: any;
  loadingPdf = false;

  totalDue = 0;
  paymentMethod: string = '';
  accountData: AccountDetails[] = [];

  style: HTMLStyleElement

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TransactionDetailsComponent>,
    private fb: FormBuilder,
    private homeService: HomeService
  ) {}

  ngOnInit() {
    this.getTransactionDetails();
    this.loadingPdf = true;
    this.homeService
      .getTransactionInvoice(this.data.transId)
      .subscribe((data: any) => {
        this.loadingPdf = false;
        this.pdfSrc = data.Result.Base64String;
      }, (error: HttpErrorResponse) => {
        this.loadingPdf = false;

      });

      this.style = this.createStyle();
  }

  getTransactionDetails() {
    this.totalDue = 0;
    this.accountData = this.data?.transactionsData?.reduce((acc: any, cur: any) => {
      let amount = 0;

      const accountNo = cur.AccountNo;
      const description = cur.Description;
      const accCategory = cur.AccountCategory; // 1, 5, 10 = paymens

      if (accountNo == 1000 || accountNo == 1020 || accCategory === 1 || accCategory === 5 || accCategory === 10) {
        amount = 0;
        this.paymentMethod = description;
      } else {
        amount = cur?.Amount;
      }

      const existingItem = acc.find(
        (item: any) => (item.AccountNo === accountNo));
      if (existingItem) {
        if (existingItem?.Amount) {
          existingItem.Amount += amount;
        } 
      } else {
        if (accountNo !== 1020 && accountNo !== 0 && cur?.Description !== '' && cur.Description !== null) {
          acc.push({
            AccountNo: accountNo,
            Description: cur.Description,
            Amount: amount,
          });
        }
      }

      return acc;
    }, []);

    for (let i = 0; i < this.accountData.length; i++) {
      this.totalDue += this.accountData[i].Amount;
    }
  }
  
  onSaveCard() {}

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {}

  downloadPdf(pdfData: any) {
    const byteCharacters = atob(pdfData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);

    window.open(url);
  }

  private createStyle(): HTMLStyleElement {
    const style = document.createElement('style');
    style.textContent = `::-webkit-scrollbar {
        width: 0px !important;
    }`;
    document.head.append(style);
    return style;
  }

  ngOnDestroy(): void {
    document.head.removeChild(this.style);
  }
}
