import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { GetCustomerContractResponse, FileResponse } from "api/models";
import { APIClient, HttpOptions } from "api";
import { RentalAgreementModalComponent } from "src/@sentinel/shared/rental-agreement-modal/rental-agreement-modal.component";
import { HelperService } from "src/app/services/helper.service";
import { HomeService } from "src/app/services/home.service";

@Component({
  selector: 'app-agreement-modal',
  templateUrl: './agreement-modal.component.html',
  styleUrls: ['./agreement-modal.component.scss']
})
export class AgreementModalComponent implements OnInit {
  @Input() unitId: number;
  @Input() leaseId: number;
  
  selectedDocumentToSign: GetCustomerContractResponse;
  customerId: string;
  dataUri: string;
  rentalAgreementPdf: FileResponse | undefined;
  signedAgreement = false;

  // loaders
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiClient: APIClient,
    private helperService: HelperService,
    private homeService: HomeService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AgreementModalComponent>,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.getCustomerDocuments();
  }

  getCustomerDocuments() {
    this.loading = true;

    this.homeService.getCustomerDocuments(this.leaseId).subscribe(
      (data) => {
        this.loading = false;
        const customerAgreementDocuments = data?.Result;
        const selectedDocument = customerAgreementDocuments.find((item: any) => item?.UnitId === this.unitId);
        this.getTheAgreement(selectedDocument);
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.snackBar.open(`Error: ${error?.error?.ErrorMessage}`, 'Close', { duration: 4000 })
      }
    );
  }

  getTheAgreement(item: GetCustomerContractResponse) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    }).set(
      'Authorization', `Bearer ${localStorage.getItem("token")}`
    );

    let params = new HttpParams()
      .set('SiteNo', this.helperService.getItemFromLocalStorage('SiteNo'))

    const httpOptions: HttpOptions = {
      headers,
      params,
    };
    
    this.selectedDocumentToSign = item;

    const args = {
      document: {
        TransId: item?.TransactionId,
        PDFFile: item?.FileName
      }
    };

    this.loading = true;
    this.apiClient.documentsRentalAgreement(args, httpOptions).subscribe(
      data => {
        this.loading = false;

        if (data.Result) {
          this.dataUri =
            "data:application/pdf;base64," + data.Result.Base64String;

          this.rentalAgreementPdf = data.Result;
        }
      }, error => {
        this.loading = false;
        this.snackBar.open(`Error: ${error?.error?.ErrorMessage}`, 'Close', { duration: 4000 })
      }
    );
  }

  viewDocument(item: GetCustomerContractResponse) {
    const args = {
      transId: item?.TransactionId || 0
    }
    this.viewReserveAgreement(args);
  }

  viewReserveAgreement(args: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    }).set(
      'Authorization', `Bearer ${localStorage.getItem("token")}`
    );

    let params = new HttpParams()
      .set('SiteNo', this.helperService.getItemFromLocalStorage('SiteNo'))

    const httpOptions: HttpOptions = {
      headers,
      params,
    };

    this.loading = true;
    this.apiClient.documentsReservationContract(args, httpOptions).subscribe(
      data => {
        this.dataUri =
          "data:application/pdf;base64," + data.Result?.Base64String;

        this.rentalAgreementPdf = data?.Result;
        this.loading = false;
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.snackBar.open(error.error.ErrorMessage, "Close", { duration: 3000 });
      }
    );
  }

  eSignAgreement() {
    const position = {
      bottom: "0px",
      right: "0px",
    };

    let customerName = '';
    this.homeService.userName.subscribe(
      data => customerName = data
    );

    const modal = this.dialog.open(RentalAgreementModalComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: "common-modal",
      position: position,
      height: "100vh",
      width: "unset",
      data: {
        customerLastName: customerName.split(',')[0].trim(),
        customerFirstName: customerName.split(',')[1].trim(),
      },
    });

    modal.afterClosed().subscribe((res: any) => {
      if (res && res.agreementForm) {
        this.loading = true;

        const headers = new HttpHeaders({
          'Content-Type': 'application/json'
        }).set(
          'Authorization', `Bearer ${localStorage.getItem("token")}`
        );
    
        let params = new HttpParams()
          .set('SiteNo', this.helperService.getItemFromLocalStorage('SiteNo'))
    
        const httpOptions: HttpOptions = {
          headers,
          params,
        };

        const args = {
          customerSig: {
            AcceptTerms: res.agreementForm.acceptTerms,
            Initials: res.agreementForm.initials,
            FirstName: res.agreementForm.firstName,
            LastName: res.agreementForm.lastName,
            TransId: this.selectedDocumentToSign?.TransactionId,
            PDFFile: this.rentalAgreementPdf?.FileName,
            CustomerID: +this.helperService.getItemFromLocalStorage("CustomerId")
          }
        }

        this.apiClient.documentsPostSignature(args, httpOptions).subscribe(
          (data: any) => {
            if (data.Result) {
              this.signedAgreement = true;

              this.loading = false;
              this.dataUri =
                "data:application/pdf;base64," + data.Result.Base64String;

              this.rentalAgreementPdf = data.Result;
              this.snackBar.open('Agreement was signed successfully', 'Close', { duration: 3000 });
            }

            if (!data.IsSucceed) {
              this.loading = false;
              this.snackBar.open(data.ErrorMessage, "Close", { duration: 3000 });
            }
          }, (error: HttpErrorResponse) => {
            this.loading = false;
            this.snackBar.open(error.error.ErrorMessage, "Close", { duration: 3000 });
          }
        );
      }
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
