<div class="preview-items box" [ngClass]="{'disable-section': requesting || loading}">
    <div class="head-wrapper border-btm justify-content-between">
        <div class="d-flex align-items-center">
            <span class="title">Add Credit Card Profile</span>
        </div>

        <img src="../../../assets/icons/rent-unit/close-rental-popup.svg" alt="" (click)="onCancel()"
            class="clickable" />
    </div>
    <div class="body-wrapper" style="position: relative;" [ngClass]="{'disable-section': requesting}">
        <div class="row" *ngIf="loading" class="loading-wrapper">
            <img src="../../../assets/loaders/loader.gif" alt="" />
        </div>
        <form [formGroup]="secondaryTenantForm">
            <div class="heading-title">
                Select a secondary tenant
            </div>

            <div class="heading-subtitle py-2">
                In order to add additional credit card to your account it must be attached to a separate contact listed
                under your account. Please select an existing contact below or create a new secondary tenant.
            </div>

            <div class="box box-shadow p-4" style="row-gap: 20px;">
                <span class="d-flex col-md-6">
                    <span class="d-flex flex-column w-100">
                        <select formControlName="secondaryTenantControl">
                            <option [value]="0">Select Existing Secondary Tenant</option>
                            <option *ngFor="let item of secondaryTenantList" [value]="item?.CustomerID">
                                {{ item?.FullName }}
                            </option>
                        </select>
                    </span>
                </span>
                <div class="divider"></div>
            </div>

            <div class="my-3">
                <span class="heading-title my-3">
                    OR create a new one
                </span>
            </div>

            <div class="box p-4 box-shadow"
                [ngClass]="{'disable-section': secondaryTenantForm.controls.secondaryTenantControl.value !== 0}">
                <span class="section-title mb-3">
                    Tenant details
                </span>

                <div class="row my-3" style="row-gap: 20px;">
                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">First name</span>
                            <input formControlName="FirstName" type="text" placeholder="First name"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.FirstName.valid && submitAttempt}">
                        </span>
                    </span>

                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">Last name</span>
                            <input formControlName="LastName" type="text" placeholder="Last name"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.LastName.valid && submitAttempt}">
                        </span>
                    </span>

                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">Business name</span>
                            <input formControlName="BusinessName" type="text" placeholder="Business name"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.BusinessName.valid && submitAttempt}">
                        </span>
                    </span>
                    <div class="divider"></div>
                </div>

                <span class="section-title mb-3">
                    Address info
                </span>
                <div class="row my-3" style="row-gap: 20px;">
                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">Address line 1</span>
                            <input formControlName="Address1" type="text" placeholder="Address line 1"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.Address1.valid && submitAttempt}">
                        </span>
                    </span>

                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">Address line 2</span>
                            <input formControlName="Address2" type="text" placeholder="Address line 2"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.Address2.valid && submitAttempt}">
                        </span>
                    </span>

                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">City</span>
                            <input formControlName="City" type="text" placeholder="City"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.City.valid && submitAttempt}">
                        </span>
                    </span>

                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">Country</span>
                            <select formControlName="CountryID"
                                (change)="getStatesByCountry($event); getSmsCarriers($event)"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.CountryID.valid && submitAttempt}">
                                <option [value]="0" [disabled]="true">Select</option>
                                <option *ngFor="let item of countryList" [value]="item?.CounrtyID">
                                    {{ item?.Name }}
                                </option>
                            </select>
                        </span>
                    </span>

                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">Zip Code</span>
                            <input formControlName="ZipCode" type="text" placeholder="Zip Code"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.ZipCode.valid && submitAttempt}">
                        </span>
                    </span>

                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">State</span>
                            <select formControlName="StateID"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.StateID.valid && submitAttempt}">
                                <option [value]="0" [disabled]="true">Select</option>
                                <option *ngFor="let item of stateList" [value]="item?.StateID">
                                    {{ item?.Name }}
                                </option>
                            </select>
                        </span>
                    </span>
                    <div class="divider"></div>
                </div>

                <span class="section-title mb-3">
                    Phone info
                </span>
                <div class="row my-3" style="row-gap: 20px;">
                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">Home phone</span>
                            <input formControlName="HomePhone" type="text" placeholder="Home phone"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.HomePhone.valid && submitAttempt}">
                        </span>
                    </span>

                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">Mobile phone</span>
                            <input formControlName="MobilePhone" type="text" placeholder="Mobile phone"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.MobilePhone.valid && submitAttempt}">
                        </span>
                    </span>
                </div>

                <span class="section-title mb-3">
                    Email and SMS info
                </span>
                <div class="row my-3" style="row-gap: 20px;">
                    <span class="d-flex col-md-6">
                        <span class="d-flex flex-column w-100">
                            <span class="label">Email</span>
                            <input formControlName="EMailAddress" type="text" placeholder="Email"
                                [ngClass]="{'error-message': !secondaryTenantForm.controls.EMailAddress.valid && submitAttempt}">
                        </span>
                    </span>
                </div>
            </div>
        </form>
    </div>

    <div class="d-flex justify-content-end p-3 footer-wrapper">
        <button (click)="onCancel()" class="sentinel-btn sentinel-btn-transparent me-2"
            [disabled]="requesting">Cancel</button>
        <button (click)="addSecondaryTenant()" class="sentinel-btn sentinel-btn-primary" [disabled]="requesting">OK
            <img class="m-1" style="width: 10px; height: 10px" src="../../../assets/loaders/loader.gif" alt="loading"
                *ngIf="requesting" />
        </button>
    </div>
</div>