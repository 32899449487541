<div class="stepper-wrapper">
  <h4>{{siteName}}</h4>
  <span>Tenant Portal</span>

  <ul>
    <li>- Open gate</li>
    <li>- Make a payment</li>
    <li>- Account history</li>
    <li>- Rent a unit</li>
    <li>- Contact Support</li>
  </ul>
</div>
