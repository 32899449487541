import { Component, Inject } from "@angular/core";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { SignalRService } from "src/app/services/signalr.service";

export enum GateStatus {
  Closed,
  Opening,
  Opened,
}

@Component({
  selector: "app-open-gate-modal",
  templateUrl: "./open-gate-modal.component.html",
  styleUrls: ["./open-gate-modal.component.scss"],
})
export class OpenGateModalComponent {
  // Expose the enum in the component for template use
  GateStatus = GateStatus;

  gateStatus: GateStatus = GateStatus.Closed;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<OpenGateModalComponent>,
    private dialog: MatDialog,
    public signalrService: SignalRService
  ) {}

  onCancel() {
    this.dialogRef.close();
  }

  openGate() {
    this.gateStatus = GateStatus.Opening;

    this.signalrService.onOpenGate(
      () => {
        this.gateStatus = GateStatus.Closed;
      }
    );

    this.signalrService.gateOpened$.subscribe(() => {
      this.gateStatus = GateStatus.Opened;

      setTimeout(() => {
        this.resetStatus();
      }, 30000);
    });
  }

  resetStatus() {
    this.gateStatus = GateStatus.Closed;
  }

  onModalClosed(): void {
    console.log("geoLocationIntervalSubscription unsubscribing");
    this.signalrService.geoLocationIntervalSubscription?.unsubscribe();
  }
}
