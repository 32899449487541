<div class="preview-items box">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex align-items-center">
      <img
        src="../../../assets/icons/rent-unit/transaction-details.svg"
        alt=""
      />
      <span class="title">Transaction Details</span>
    </div>

    <img
      src="../../../assets/icons/rent-unit/close-rental-popup.svg"
      alt=""
      (click)="onCancel()"
      class="clickable"
    />
  </div>

  <div class="body-wrapper">
    <div class="search-input">
      <div class="input-container">
        <input
          (input)="onSearchInput($event)"
          type="text"
          placeholder="Search..."
        />
      </div>

      <mat-menu
        #menuItem="matMenu"
        yPosition="below"
        xPosition="before"
        class="popup-menu-panel"
      >
      </mat-menu>
    </div>

    <div class="unit-details">
      <div class="unit-details__head col-12">
        <span class="col-3">DESCRIPTION </span>
        <span class="col-2">UNIT NO </span>
        <span class="col-3">ACCOUNT NO </span>
        <span class="col-2">AMOUNT </span>
        <span class="col-2">TOTAL </span>
      </div>

      <div
        class="unit-details__body col-12"
        *ngFor="let item of mainTransactionData"
      >
        <span class="col-3 first-item">{{ item?.Description }}</span>
        <span class="col-2">{{ item?.UnitNo }}</span>
        <span class="col-3">{{ item?.AccountNo }}</span>
        <span class="col-2">{{ (item?.Amount | currency) }}</span>
        <span class="col-2">{{ (item?.Amount | currency) }}</span>
      </div>

      <div class="unit-details__body col-12" [ngClass]="{'dark-bg': (themeService.isDarkModeSubject | async),
              'light-bg': !(themeService.isDarkModeSubject | async)}">
        <span class="col-10" style="padding-left: 24px">Total: </span>
        <span class="col-2 fw-bold">{{ totalAmount | currency }}</span>
      </div>
    </div>

    <div class="unit-details-mobile">
      <div class="unit-details__head col-12">
        <span class="col-4">DESCRIPTION </span>
        <span class="col-2">UNIT NO </span>
        <span class="col-3">AMOUNT </span>
        <span class="col-3">TOTAL </span>
      </div>

      <div
        class="unit-details__body col-12"
        *ngFor="let item of mainTransactionData"
      >
        <span class="col-4 first-item">{{ item?.Description }}</span>
        <span class="col-2">{{ item?.UnitNo }}</span>
        <span class="col-3">{{ (item?.Amount | currency) }}</span>
        <span class="col-3">{{ (item?.Amount | currency) }}</span>
      </div>
      
      <div class="unit-details__body col-12" [ngClass]="{'dark-bg': (themeService.isDarkModeSubject | async),
            'light-bg': !(themeService.isDarkModeSubject | async)}">
        <span class="col-10" style="padding-left: 10px">Total: </span>
        <span class="col-2 fw-bold">{{ totalAmount | currency }}</span>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-end modal-footer p-3"  [align]="'end'">
  <button class="sentinel-btn sentinel-btn-transparent me-2 mt-2"  (click)="onCancel()">Close</button>
</div>
