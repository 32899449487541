import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ApiVersionInterceptor implements HttpInterceptor {

    private defaultApiVersion: string = '1.0'

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        request = request.clone({
            setParams: {
                apiVersion: this.defaultApiVersion,
            }
        });

        return next.handle(request);
    }
}