import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DarkOrLightMode } from "shared/enums";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  constructor(private router: Router) {}

  readonly statusClassMap: { [key: string]: string }  = {
    'Reserved': 'reserved',
    'Rented': 'rented',
    'Complimentary': 'complimentary',
    'Access Only': 'complimentary',
    'Delinquent': 'locked',
    'Delinquent - 1': 'locked',
    'Delinquent - 2': 'locked',
    'In Lien Process': 'locked',
    'In Lien Process - 0': 'locked',
    'In Lien Process - 1': 'locked',
    'In Lien Process - 2': 'locked',
    'Reservation Forfeited' : 'locked'
  };

  loadClearentScript(scriptUrl: string) {
    const scriptId = 'clearent-script';
    
    if (!document.getElementById(scriptId)) {
      let node = document.createElement('script');
      node.id = scriptId;
      node.src = `${scriptUrl}/js-sdk/js/clearent-host.js`;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  getItemFromLocalStorage(key: any): any {
    if (this.isLocalStorageAvailable()) {
      return localStorage.getItem(key);
    }
    return null;
  }

  setItemToLocalStorage(key: string, value: any): void {
    if (this.isLocalStorageAvailable()) {
      localStorage.setItem(key, value);
    }
  }

  isLocalStorageAvailable(): boolean {
    const randomKey = "test_random_key";
    try {
      localStorage.setItem(randomKey, randomKey);
      localStorage.removeItem(randomKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  clearLocalStorage() {
    this.setItemToLocalStorage("CustomerId", "");
    this.setItemToLocalStorage("token", "");
    this.setItemToLocalStorage("fullName", "");
    this.setItemToLocalStorage("isSentinelOnly", "");
  }
  
  generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getStatusClass(status: string): string {
    let isDardMode = localStorage.getItem(DarkOrLightMode.DARK_MODE);

    if (status.indexOf(' ') !== -1) {
    } else {
      status = status.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    if (isDardMode == 'true') {
      return this.statusClassMap[status] + '-status' + '-dark' || '';
    } else {
      return this.statusClassMap[status] + '-status' || '';
    }
  }
}
