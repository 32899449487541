/* tslint:disable */

import * as models from '../models';

/* pre-prepared guards for build in complex types */

function _isBlob(arg: any): arg is Blob {
  return arg != null && typeof arg.size === 'number' && typeof arg.type === 'string' && typeof arg.slice === 'function';
}

export function isFile(arg: any): arg is File {
return arg != null && typeof arg.lastModified === 'number' && typeof arg.name === 'string' && _isBlob(arg);
}

/* generated type guards */

export function isAccessControlModel(arg: any): arg is models.AccessControlModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessCardNo?: number
    ( typeof arg.AccessCardNo === 'undefined' || typeof arg.AccessCardNo === 'number' ) &&
    // AlertFlag?: boolean
    ( typeof arg.AlertFlag === 'undefined' || typeof arg.AlertFlag === 'boolean' ) &&
    // LastOffSite?: string
    ( typeof arg.LastOffSite === 'undefined' || typeof arg.LastOffSite === 'string' ) &&
    // LastOnSite?: string
    ( typeof arg.LastOnSite === 'undefined' || typeof arg.LastOnSite === 'string' ) &&
    // LeaseLocked?: boolean
    ( typeof arg.LeaseLocked === 'undefined' || typeof arg.LeaseLocked === 'boolean' ) &&
    // LockedDate?: string
    ( typeof arg.LockedDate === 'undefined' || typeof arg.LockedDate === 'string' ) &&
    // OnSite?: boolean
    ( typeof arg.OnSite === 'undefined' || typeof arg.OnSite === 'boolean' ) &&
    // PassCode?: number
    ( typeof arg.PassCode === 'undefined' || typeof arg.PassCode === 'number' ) &&
    // PrimaryAccessLevels?: AccessLevelsItem[]
    ( typeof arg.PrimaryAccessLevels === 'undefined' || (Array.isArray(arg.PrimaryAccessLevels) && arg.PrimaryAccessLevels.every((item: unknown) => isAccessLevelsItem(item))) ) &&
    // TimeZoneId?: number
    ( typeof arg.TimeZoneId === 'undefined' || typeof arg.TimeZoneId === 'number' ) &&
    // UnlockedDate?: string
    ( typeof arg.UnlockedDate === 'undefined' || typeof arg.UnlockedDate === 'string' ) &&

  true
  );
  }

export function isAccessControlPostModel(arg: any): arg is models.AccessControlPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessCardNo?: number
    ( typeof arg.AccessCardNo === 'undefined' || typeof arg.AccessCardNo === 'number' ) &&
    // AlertFlag?: boolean
    ( typeof arg.AlertFlag === 'undefined' || typeof arg.AlertFlag === 'boolean' ) &&
    // LeaseLocked?: boolean
    ( typeof arg.LeaseLocked === 'undefined' || typeof arg.LeaseLocked === 'boolean' ) &&
    // PassCode?: number
    ( typeof arg.PassCode === 'undefined' || typeof arg.PassCode === 'number' ) &&
    // TimeZoneId?: number
    ( typeof arg.TimeZoneId === 'undefined' || typeof arg.TimeZoneId === 'number' ) &&

  true
  );
  }

export function isAccessDeviceModel(arg: any): arg is models.AccessDeviceModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address?: number
    ( typeof arg.Address === 'undefined' || typeof arg.Address === 'number' ) &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // CommDeviceID?: number
    ( typeof arg.CommDeviceID === 'undefined' || typeof arg.CommDeviceID === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DeviceNo?: number
    ( typeof arg.DeviceNo === 'undefined' || typeof arg.DeviceNo === 'number' ) &&
    // DeviceType?: string
    ( typeof arg.DeviceType === 'undefined' || typeof arg.DeviceType === 'string' ) &&
    // DeviceTypeId?: number
    ( typeof arg.DeviceTypeId === 'undefined' || typeof arg.DeviceTypeId === 'number' ) &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&

  true
  );
  }

export function isAccessLevelPostModel(arg: any): arg is models.AccessLevelPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccLevelId?: number
    ( typeof arg.AccLevelId === 'undefined' || typeof arg.AccLevelId === 'number' ) &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&

  true
  );
  }

export function isAccessLevelsItem(arg: any): arg is models.AccessLevelsItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccLevelID?: number
    ( typeof arg.AccLevelID === 'undefined' || typeof arg.AccLevelID === 'number' ) &&
    // AccLevelNo?: number
    ( typeof arg.AccLevelNo === 'undefined' || typeof arg.AccLevelNo === 'number' ) &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&

  true
  );
  }

export function isAccLevelDeviceModel(arg: any): arg is models.AccLevelDeviceModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessDeviceID?: number
    ( typeof arg.AccessDeviceID === 'undefined' || typeof arg.AccessDeviceID === 'number' ) &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&

  true
  );
  }

export function isAccLevelModel(arg: any): arg is models.AccLevelModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessLevelNo?: number
    ( typeof arg.AccessLevelNo === 'undefined' || typeof arg.AccessLevelNo === 'number' ) &&
    // Allow?: boolean
    ( typeof arg.Allow === 'undefined' || typeof arg.Allow === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&

  true
  );
  }

export function isAccLevelPostModel(arg: any): arg is models.AccLevelPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccLevelDevices?: AccLevelDeviceModel[]
    ( typeof arg.AccLevelDevices === 'undefined' || (Array.isArray(arg.AccLevelDevices) && arg.AccLevelDevices.every((item: unknown) => isAccLevelDeviceModel(item))) ) &&
    // AccLevelID?: number
    ( typeof arg.AccLevelID === 'undefined' || typeof arg.AccLevelID === 'number' ) &&
    // AccLevelNo?: number
    ( typeof arg.AccLevelNo === 'undefined' || typeof arg.AccLevelNo === 'number' ) &&
    // AccLevelRelays?: AccLevelRelayModel[]
    ( typeof arg.AccLevelRelays === 'undefined' || (Array.isArray(arg.AccLevelRelays) && arg.AccLevelRelays.every((item: unknown) => isAccLevelRelayModel(item))) ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // LightingZoneID?: number
    ( typeof arg.LightingZoneID === 'undefined' || typeof arg.LightingZoneID === 'number' ) &&

  true
  );
  }

export function isAccLevelRelayModel(arg: any): arg is models.AccLevelRelayModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // RelayID?: number
    ( typeof arg.RelayID === 'undefined' || typeof arg.RelayID === 'number' ) &&

  true
  );
  }

export function isAccLevelViewModel(arg: any): arg is models.AccLevelViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccLevelID?: number
    ( typeof arg.AccLevelID === 'undefined' || typeof arg.AccLevelID === 'number' ) &&
    // AccLevelNo?: number
    ( typeof arg.AccLevelNo === 'undefined' || typeof arg.AccLevelNo === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // Devices?: AccessDeviceModel[]
    ( typeof arg.Devices === 'undefined' || (Array.isArray(arg.Devices) && arg.Devices.every((item: unknown) => isAccessDeviceModel(item))) ) &&
    // LightingZone?: string
    ( typeof arg.LightingZone === 'undefined' || typeof arg.LightingZone === 'string' ) &&
    // LightingZoneID?: number
    ( typeof arg.LightingZoneID === 'undefined' || typeof arg.LightingZoneID === 'number' ) &&
    // Relays?: RelayModel[]
    ( typeof arg.Relays === 'undefined' || (Array.isArray(arg.Relays) && arg.Relays.every((item: unknown) => isRelayModel(item))) ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&

  true
  );
  }

export function isAccountInfo(arg: any): arg is models.AccountInfo {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BalanceDetails?: TransactionDetail[]
    ( typeof arg.BalanceDetails === 'undefined' || (Array.isArray(arg.BalanceDetails) && arg.BalanceDetails.every((item: unknown) => isTransactionDetail(item))) ) &&
    // Customers?: CustomerModel[]
    ( typeof arg.Customers === 'undefined' || (Array.isArray(arg.Customers) && arg.Customers.every((item: unknown) => isCustomerModel(item))) ) &&
    // TotalDue?: number
    ( typeof arg.TotalDue === 'undefined' || typeof arg.TotalDue === 'number' ) &&
    // Units?: UnitSummaryModel[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => isUnitSummaryModel(item))) ) &&

  true
  );
  }

export function isAccountInfoByPhoneRequest(arg: any): arg is models.AccountInfoByPhoneRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // PhoneNumber: string
    ( typeof arg.PhoneNumber === 'string' ) &&

  true
  );
  }

export function isAccountInfoRequest(arg: any): arg is models.AccountInfoRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Email?: string
    ( typeof arg.Email === 'undefined' || typeof arg.Email === 'string' ) &&
    // GateCode?: number
    ( typeof arg.GateCode === 'undefined' || typeof arg.GateCode === 'number' ) &&
    // OnlinePassword?: string
    ( typeof arg.OnlinePassword === 'undefined' || typeof arg.OnlinePassword === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isAccountPostModel(arg: any): arg is models.AccountPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountCategory?: number
    ( typeof arg.AccountCategory === 'undefined' || typeof arg.AccountCategory === 'number' ) &&
    // AccountID?: number
    ( typeof arg.AccountID === 'undefined' || typeof arg.AccountID === 'number' ) &&
    // AccountNo?: number
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'number' ) &&
    // AccountType?: number
    ( typeof arg.AccountType === 'undefined' || typeof arg.AccountType === 'number' ) &&
    // Debit?: boolean
    ( typeof arg.Debit === 'undefined' || typeof arg.Debit === 'boolean' ) &&
    // DepositGroupNo?: number
    ( typeof arg.DepositGroupNo === 'undefined' || typeof arg.DepositGroupNo === 'number' ) &&
    // DiscountRate?: number
    ( typeof arg.DiscountRate === 'undefined' || typeof arg.DiscountRate === 'number' ) &&
    // ExportAccountNo?: number
    ( typeof arg.ExportAccountNo === 'undefined' || typeof arg.ExportAccountNo === 'number' ) &&
    // ExportAccountTitle?: string
    ( typeof arg.ExportAccountTitle === 'undefined' || typeof arg.ExportAccountTitle === 'string' ) &&
    // ExportVendor?: string
    ( typeof arg.ExportVendor === 'undefined' || typeof arg.ExportVendor === 'string' ) &&
    // PaymentOrder?: number
    ( typeof arg.PaymentOrder === 'undefined' || typeof arg.PaymentOrder === 'number' ) &&
    // ReceiptOrder?: number
    ( typeof arg.ReceiptOrder === 'undefined' || typeof arg.ReceiptOrder === 'number' ) &&
    // ReceiptTitle?: string
    ( typeof arg.ReceiptTitle === 'undefined' || typeof arg.ReceiptTitle === 'string' ) &&
    // RevenueType?: number
    ( typeof arg.RevenueType === 'undefined' || typeof arg.RevenueType === 'number' ) &&
    // SystemAccount?: boolean
    ( typeof arg.SystemAccount === 'undefined' || typeof arg.SystemAccount === 'boolean' ) &&
    // Taxes?: AccountTax[]
    ( typeof arg.Taxes === 'undefined' || (Array.isArray(arg.Taxes) && arg.Taxes.every((item: unknown) => isAccountTax(item))) ) &&
    // Title?: string
    ( typeof arg.Title === 'undefined' || typeof arg.Title === 'string' ) &&

  true
  );
  }

export function isAccountTax(arg: any): arg is models.AccountTax {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsApply?: boolean
    ( typeof arg.IsApply === 'undefined' || typeof arg.IsApply === 'boolean' ) &&
    // TaxAuthorityID?: number
    ( typeof arg.TaxAuthorityID === 'undefined' || typeof arg.TaxAuthorityID === 'number' ) &&

  true
  );
  }

export function isAccountViewModel(arg: any): arg is models.AccountViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountCategory?: number
    ( typeof arg.AccountCategory === 'undefined' || typeof arg.AccountCategory === 'number' ) &&
    // AccountCategoryName?: string
    ( typeof arg.AccountCategoryName === 'undefined' || typeof arg.AccountCategoryName === 'string' ) &&
    // AccountID?: number
    ( typeof arg.AccountID === 'undefined' || typeof arg.AccountID === 'number' ) &&
    // AccountNo?: number
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'number' ) &&
    // AccountType?: number
    ( typeof arg.AccountType === 'undefined' || typeof arg.AccountType === 'number' ) &&
    // AccountTypeName?: string
    ( typeof arg.AccountTypeName === 'undefined' || typeof arg.AccountTypeName === 'string' ) &&
    // AccountTypeReadOnly?: boolean
    ( typeof arg.AccountTypeReadOnly === 'undefined' || typeof arg.AccountTypeReadOnly === 'boolean' ) &&
    // CanDelete?: boolean
    ( typeof arg.CanDelete === 'undefined' || typeof arg.CanDelete === 'boolean' ) &&
    // Debit?: boolean
    ( typeof arg.Debit === 'undefined' || typeof arg.Debit === 'boolean' ) &&
    // DepositGroupNo?: number
    ( typeof arg.DepositGroupNo === 'undefined' || typeof arg.DepositGroupNo === 'number' ) &&
    // DiscountRate?: number
    ( typeof arg.DiscountRate === 'undefined' || typeof arg.DiscountRate === 'number' ) &&
    // ExportAccountNo?: number
    ( typeof arg.ExportAccountNo === 'undefined' || typeof arg.ExportAccountNo === 'number' ) &&
    // ExportAccountTitle?: string
    ( typeof arg.ExportAccountTitle === 'undefined' || typeof arg.ExportAccountTitle === 'string' ) &&
    // ExportVendor?: string
    ( typeof arg.ExportVendor === 'undefined' || typeof arg.ExportVendor === 'string' ) &&
    // ModifiedDate?: string
    ( typeof arg.ModifiedDate === 'undefined' || typeof arg.ModifiedDate === 'string' ) &&
    // PaymentOrder?: number
    ( typeof arg.PaymentOrder === 'undefined' || typeof arg.PaymentOrder === 'number' ) &&
    // ReceiptOrder?: number
    ( typeof arg.ReceiptOrder === 'undefined' || typeof arg.ReceiptOrder === 'number' ) &&
    // ReceiptTitle?: string
    ( typeof arg.ReceiptTitle === 'undefined' || typeof arg.ReceiptTitle === 'string' ) &&
    // RevenueReadOnly?: boolean
    ( typeof arg.RevenueReadOnly === 'undefined' || typeof arg.RevenueReadOnly === 'boolean' ) &&
    // RevenueType?: number
    ( typeof arg.RevenueType === 'undefined' || typeof arg.RevenueType === 'number' ) &&
    // RevenueTypeName?: string
    ( typeof arg.RevenueTypeName === 'undefined' || typeof arg.RevenueTypeName === 'string' ) &&
    // SystemAccount?: boolean
    ( typeof arg.SystemAccount === 'undefined' || typeof arg.SystemAccount === 'boolean' ) &&
    // Taxes?: TaxViewAccount[]
    ( typeof arg.Taxes === 'undefined' || (Array.isArray(arg.Taxes) && arg.Taxes.every((item: unknown) => isTaxViewAccount(item))) ) &&
    // TaxReadOnly?: boolean
    ( typeof arg.TaxReadOnly === 'undefined' || typeof arg.TaxReadOnly === 'boolean' ) &&
    // Title?: string
    ( typeof arg.Title === 'undefined' || typeof arg.Title === 'string' ) &&
    // UserAccountNo?: number
    ( typeof arg.UserAccountNo === 'undefined' || typeof arg.UserAccountNo === 'number' ) &&

  true
  );
  }

export function isACHProcessModel(arg: any): arg is models.ACHProcessModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // LastACHOrderId?: number
    ( typeof arg.LastACHOrderId === 'undefined' || typeof arg.LastACHOrderId === 'number' ) &&

  true
  );
  }

export function isAdditionalAccountModel(arg: any): arg is models.AdditionalAccountModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // MerchantAccount?: string
    ( typeof arg.MerchantAccount === 'undefined' || typeof arg.MerchantAccount === 'string' ) &&
    // SecondaryMerchantAccount?: string
    ( typeof arg.SecondaryMerchantAccount === 'undefined' || typeof arg.SecondaryMerchantAccount === 'string' ) &&
    // SiteName?: string
    ( typeof arg.SiteName === 'undefined' || typeof arg.SiteName === 'string' ) &&
    // SiteNo?: number
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'number' ) &&

  true
  );
  }

export function isAdditionalCharge(arg: any): arg is models.AdditionalCharge {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // ChargeType?: '1' | '2' | '3' | '4' | '5'
    ( typeof arg.ChargeType === 'undefined' || ['1', '2', '3', '4', '5'].includes(arg.ChargeType) ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&
    // Price?: number
    ( typeof arg.Price === 'undefined' || typeof arg.Price === 'number' ) &&
    // Quantity?: number
    ( typeof arg.Quantity === 'undefined' || typeof arg.Quantity === 'number' ) &&

  true
  );
  }

export function isAdditionalChargeAccountItem(arg: any): arg is models.AdditionalChargeAccountItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&
    // Price?: number
    ( typeof arg.Price === 'undefined' || typeof arg.Price === 'number' ) &&

  true
  );
  }

export function isAdditionalChargeTypesResponse(arg: any): arg is models.AdditionalChargeTypesResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Accounts?: AdditionalChargeAccountItem[]
    ( typeof arg.Accounts === 'undefined' || (Array.isArray(arg.Accounts) && arg.Accounts.every((item: unknown) => isAdditionalChargeAccountItem(item))) ) &&
    // ChargeType?: '1' | '2' | '3' | '4' | '5'
    ( typeof arg.ChargeType === 'undefined' || ['1', '2', '3', '4', '5'].includes(arg.ChargeType) ) &&
    // Title?: string
    ( typeof arg.Title === 'undefined' || typeof arg.Title === 'string' ) &&

  true
  );
  }

export function isAddressVerificationOption(arg: any): arg is models.AddressVerificationOption {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AddrMatch0?: boolean
    ( typeof arg.AddrMatch0 === 'undefined' || typeof arg.AddrMatch0 === 'boolean' ) &&
    // AddrMatch1?: boolean
    ( typeof arg.AddrMatch1 === 'undefined' || typeof arg.AddrMatch1 === 'boolean' ) &&
    // AddrMatch2?: boolean
    ( typeof arg.AddrMatch2 === 'undefined' || typeof arg.AddrMatch2 === 'boolean' ) &&
    // AddrMatch3?: boolean
    ( typeof arg.AddrMatch3 === 'undefined' || typeof arg.AddrMatch3 === 'boolean' ) &&
    // AddrMatch4?: boolean
    ( typeof arg.AddrMatch4 === 'undefined' || typeof arg.AddrMatch4 === 'boolean' ) &&
    // AddrMatch5?: boolean
    ( typeof arg.AddrMatch5 === 'undefined' || typeof arg.AddrMatch5 === 'boolean' ) &&
    // AddrMatch6?: boolean
    ( typeof arg.AddrMatch6 === 'undefined' || typeof arg.AddrMatch6 === 'boolean' ) &&
    // AddrMatch7?: boolean
    ( typeof arg.AddrMatch7 === 'undefined' || typeof arg.AddrMatch7 === 'boolean' ) &&
    // AddrMatch8?: boolean
    ( typeof arg.AddrMatch8 === 'undefined' || typeof arg.AddrMatch8 === 'boolean' ) &&
    // AddrMatch9?: boolean
    ( typeof arg.AddrMatch9 === 'undefined' || typeof arg.AddrMatch9 === 'boolean' ) &&

  true
  );
  }

export function isAdvertisingConfigModel(arg: any): arg is models.AdvertisingConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AdvConfigID?: number
    ( typeof arg.AdvConfigID === 'undefined' || typeof arg.AdvConfigID === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // Selected?: boolean
    ( typeof arg.Selected === 'undefined' || typeof arg.Selected === 'boolean' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&

  true
  );
  }

export function isAlarmPointResponse(arg: any): arg is models.AlarmPointResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Alarmed?: boolean
    ( typeof arg.Alarmed === 'undefined' || typeof arg.Alarmed === 'boolean' ) &&
    // AlarmStatus?: boolean
    ( typeof arg.AlarmStatus === 'undefined' || typeof arg.AlarmStatus === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DoorID?: number
    ( typeof arg.DoorID === 'undefined' || typeof arg.DoorID === 'number' ) &&
    // DoorNo?: string
    ( typeof arg.DoorNo === 'undefined' || typeof arg.DoorNo === 'string' ) &&
    // DoorStatus?: boolean
    ( typeof arg.DoorStatus === 'undefined' || typeof arg.DoorStatus === 'boolean' ) &&
    // SiteConfigName?: string
    ( typeof arg.SiteConfigName === 'undefined' || typeof arg.SiteConfigName === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isAlarmRelay(arg: any): arg is models.AlarmRelay {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AlarmRelayID?: number
    ( typeof arg.AlarmRelayID === 'undefined' || typeof arg.AlarmRelayID === 'number' ) &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // RelayBox?: string
    ( typeof arg.RelayBox === 'undefined' || typeof arg.RelayBox === 'string' ) &&
    // RelayBoxID?: number
    ( typeof arg.RelayBoxID === 'undefined' || typeof arg.RelayBoxID === 'number' ) &&
    // RelayNo?: number
    ( typeof arg.RelayNo === 'undefined' || typeof arg.RelayNo === 'number' ) &&
    // RelayOn?: boolean
    ( typeof arg.RelayOn === 'undefined' || typeof arg.RelayOn === 'boolean' ) &&
    // Timeout?: boolean
    ( typeof arg.Timeout === 'undefined' || typeof arg.Timeout === 'boolean' ) &&

  true
  );
  }

export function isAllContactRequest(arg: any): arg is models.AllContactRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Category?: string
    ( typeof arg.Category === 'undefined' || typeof arg.Category === 'string' ) &&
    // CustomerName?: string
    ( typeof arg.CustomerName === 'undefined' || typeof arg.CustomerName === 'string' ) &&
    // EmailAddress?: string
    ( typeof arg.EmailAddress === 'undefined' || typeof arg.EmailAddress === 'string' ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // SiteGroupId?: number
    ( typeof arg.SiteGroupId === 'undefined' || typeof arg.SiteGroupId === 'number' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isAllContactResponse(arg: any): arg is models.AllContactResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // EmailAddress?: string
    ( typeof arg.EmailAddress === 'undefined' || typeof arg.EmailAddress === 'string' ) &&
    // FullName?: string
    ( typeof arg.FullName === 'undefined' || typeof arg.FullName === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // Type?: string
    ( typeof arg.Type === 'undefined' || typeof arg.Type === 'string' ) &&
    // UnitCustomerID?: string
    ( typeof arg.UnitCustomerID === 'undefined' || typeof arg.UnitCustomerID === 'string' ) &&

  true
  );
  }

export function isAuctionLocation(arg: any): arg is models.AuctionLocation {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AuctionLocationID?: number
    ( typeof arg.AuctionLocationID === 'undefined' || typeof arg.AuctionLocationID === 'number' ) &&
    // AuctionLocationNo?: number
    ( typeof arg.AuctionLocationNo === 'undefined' || typeof arg.AuctionLocationNo === 'number' ) &&
    // AuctionSiteName?: string
    ( typeof arg.AuctionSiteName === 'undefined' || typeof arg.AuctionSiteName === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // Country?: string
    ( typeof arg.Country === 'undefined' || typeof arg.Country === 'string' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isAuctionLocationModel(arg: any): arg is models.AuctionLocationModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AuctionLocationID?: number
    ( typeof arg.AuctionLocationID === 'undefined' || typeof arg.AuctionLocationID === 'number' ) &&
    // AuctionLocationNo?: number
    ( typeof arg.AuctionLocationNo === 'undefined' || typeof arg.AuctionLocationNo === 'number' ) &&
    // AuctionSiteName?: string
    ( typeof arg.AuctionSiteName === 'undefined' || typeof arg.AuctionSiteName === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isAuctionResponse(arg: any): arg is models.AuctionResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AuctionDate?: string
    ( typeof arg.AuctionDate === 'undefined' || typeof arg.AuctionDate === 'string' ) &&
    // AuctionLocationId?: number
    ( typeof arg.AuctionLocationId === 'undefined' || typeof arg.AuctionLocationId === 'number' ) &&
    // AuctionLocationName?: string
    ( typeof arg.AuctionLocationName === 'undefined' || typeof arg.AuctionLocationName === 'string' ) &&
    // AuctionStatus?: '0' | '1' | '2' | '3'
    ( typeof arg.AuctionStatus === 'undefined' || ['0', '1', '2', '3'].includes(arg.AuctionStatus) ) &&
    // AuctionStatusDescription?: string
    ( typeof arg.AuctionStatusDescription === 'undefined' || typeof arg.AuctionStatusDescription === 'string' ) &&
    // Business?: string
    ( typeof arg.Business === 'undefined' || typeof arg.Business === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // ContentsOfUnit?: AuctionUnitItem[]
    ( typeof arg.ContentsOfUnit === 'undefined' || (Array.isArray(arg.ContentsOfUnit) && arg.ContentsOfUnit.every((item: unknown) => isAuctionUnitItem(item))) ) &&
    // EmailAddress?: string
    ( typeof arg.EmailAddress === 'undefined' || typeof arg.EmailAddress === 'string' ) &&
    // FacilityId?: number
    ( typeof arg.FacilityId === 'undefined' || typeof arg.FacilityId === 'number' ) &&
    // FacilityName?: string
    ( typeof arg.FacilityName === 'undefined' || typeof arg.FacilityName === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // LastPaidDate?: string
    ( typeof arg.LastPaidDate === 'undefined' || typeof arg.LastPaidDate === 'string' ) &&
    // LienId?: number
    ( typeof arg.LienId === 'undefined' || typeof arg.LienId === 'number' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // MoveInDate?: string
    ( typeof arg.MoveInDate === 'undefined' || typeof arg.MoveInDate === 'string' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // TenantId?: number
    ( typeof arg.TenantId === 'undefined' || typeof arg.TenantId === 'number' ) &&
    // TotalDue?: number
    ( typeof arg.TotalDue === 'undefined' || typeof arg.TotalDue === 'number' ) &&
    // TransactionsDetails?: TransactionDetail[]
    ( typeof arg.TransactionsDetails === 'undefined' || (Array.isArray(arg.TransactionsDetails) && arg.TransactionsDetails.every((item: unknown) => isTransactionDetail(item))) ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitType?: string
    ( typeof arg.UnitType === 'undefined' || typeof arg.UnitType === 'string' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isAuctionUnitItem(arg: any): arg is models.AuctionUnitItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&

  true
  );
  }

export function isAutoBillItem(arg: any): arg is models.AutoBillItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isAutoBillResponse(arg: any): arg is models.AutoBillResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CardExpiration?: string
    ( typeof arg.CardExpiration === 'undefined' || typeof arg.CardExpiration === 'string' ) &&
    // CustomerCardLast4Digit?: string
    ( typeof arg.CustomerCardLast4Digit === 'undefined' || typeof arg.CustomerCardLast4Digit === 'string' ) &&
    // CustomerFullName?: string
    ( typeof arg.CustomerFullName === 'undefined' || typeof arg.CustomerFullName === 'string' ) &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // ErrorDescription?: string
    ( typeof arg.ErrorDescription === 'undefined' || typeof arg.ErrorDescription === 'string' ) &&
    // ErrorDescriptionList?: string[]
    ( typeof arg.ErrorDescriptionList === 'undefined' || (Array.isArray(arg.ErrorDescriptionList) && arg.ErrorDescriptionList.every((item: unknown) => typeof item === 'string')) ) &&
    // UnitCustomerId?: string
    ( typeof arg.UnitCustomerId === 'undefined' || typeof arg.UnitCustomerId === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // ValidCreditCard?: boolean
    ( typeof arg.ValidCreditCard === 'undefined' || typeof arg.ValidCreditCard === 'boolean' ) &&

  true
  );
  }

export function isBalanceItem(arg: any): arg is models.BalanceItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountNo?: string
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'string' ) &&
    // BalanceDue?: number
    ( typeof arg.BalanceDue === 'undefined' || typeof arg.BalanceDue === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // PostDate?: string
    ( typeof arg.PostDate === 'undefined' || typeof arg.PostDate === 'string' ) &&
    // TransNo?: string
    ( typeof arg.TransNo === 'undefined' || typeof arg.TransNo === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isBalanceRequest(arg: any): arg is models.BalanceRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // SelectedLeases?: MoveOutLeases[]
    ( typeof arg.SelectedLeases === 'undefined' || (Array.isArray(arg.SelectedLeases) && arg.SelectedLeases.every((item: unknown) => isMoveOutLeases(item))) ) &&

  true
  );
  }

export function isBalanceResponse(arg: any): arg is models.BalanceResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BalanceItems?: BalanceItem[]
    ( typeof arg.BalanceItems === 'undefined' || (Array.isArray(arg.BalanceItems) && arg.BalanceItems.every((item: unknown) => isBalanceItem(item))) ) &&
    // TotalAmount?: number
    ( typeof arg.TotalAmount === 'undefined' || typeof arg.TotalAmount === 'number' ) &&

  true
  );
  }

export function isBaseReferenceTable(arg: any): arg is models.BaseReferenceTable {
  return (
  arg != null &&
  typeof arg === 'object' &&

  true
  );
  }

export function isBillingInfoModel(arg: any): arg is models.BillingInfoModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountNumber?: string
    ( typeof arg.AccountNumber === 'undefined' || typeof arg.AccountNumber === 'string' ) &&
    // AutoBill?: boolean
    ( typeof arg.AutoBill === 'undefined' || typeof arg.AutoBill === 'boolean' ) &&
    // AutoBillPeriods?: number
    ( typeof arg.AutoBillPeriods === 'undefined' || typeof arg.AutoBillPeriods === 'number' ) &&
    // BankAccountClass?: number
    ( typeof arg.BankAccountClass === 'undefined' || typeof arg.BankAccountClass === 'number' ) &&
    // BankAccountType?: number
    ( typeof arg.BankAccountType === 'undefined' || typeof arg.BankAccountType === 'number' ) &&
    // BillingDays?: number
    ( typeof arg.BillingDays === 'undefined' || typeof arg.BillingDays === 'number' ) &&
    // BillingType?: number
    ( typeof arg.BillingType === 'undefined' || typeof arg.BillingType === 'number' ) &&
    // CardExp?: string
    ( typeof arg.CardExp === 'undefined' || typeof arg.CardExp === 'string' ) &&
    // CardNo?: string
    ( typeof arg.CardNo === 'undefined' || typeof arg.CardNo === 'string' ) &&
    // CreditCardBrand?: string
    ( typeof arg.CreditCardBrand === 'undefined' || typeof arg.CreditCardBrand === 'string' ) &&
    // CreditCardBrandId?: number
    ( typeof arg.CreditCardBrandId === 'undefined' || typeof arg.CreditCardBrandId === 'number' ) &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // CustomerTaxable?: boolean
    ( typeof arg.CustomerTaxable === 'undefined' || typeof arg.CustomerTaxable === 'boolean' ) &&
    // PayerId?: string
    ( typeof arg.PayerId === 'undefined' || typeof arg.PayerId === 'string' ) &&
    // PayerIdExp?: string
    ( typeof arg.PayerIdExp === 'undefined' || typeof arg.PayerIdExp === 'string' ) &&
    // RoutingNumber?: string
    ( typeof arg.RoutingNumber === 'undefined' || typeof arg.RoutingNumber === 'string' ) &&

  true
  );
  }

export function isBillingInfoPostModel(arg: any): arg is models.BillingInfoPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountNumber?: string
    ( typeof arg.AccountNumber === 'undefined' || typeof arg.AccountNumber === 'string' ) &&
    // AutoBill?: boolean
    ( typeof arg.AutoBill === 'undefined' || typeof arg.AutoBill === 'boolean' ) &&
    // AutoBillPeriods?: number
    ( typeof arg.AutoBillPeriods === 'undefined' || typeof arg.AutoBillPeriods === 'number' ) &&
    // BankAccountClass?: number
    ( typeof arg.BankAccountClass === 'undefined' || typeof arg.BankAccountClass === 'number' ) &&
    // BankAccountType?: number
    ( typeof arg.BankAccountType === 'undefined' || typeof arg.BankAccountType === 'number' ) &&
    // BillingDays?: number
    ( typeof arg.BillingDays === 'undefined' || typeof arg.BillingDays === 'number' ) &&
    // BillingType?: number
    ( typeof arg.BillingType === 'undefined' || typeof arg.BillingType === 'number' ) &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // CustomerTaxable?: boolean
    ( typeof arg.CustomerTaxable === 'undefined' || typeof arg.CustomerTaxable === 'boolean' ) &&
    // RoutingNumber?: string
    ( typeof arg.RoutingNumber === 'undefined' || typeof arg.RoutingNumber === 'string' ) &&
    // SelectedPayerID?: string
    ( typeof arg.SelectedPayerID === 'undefined' || typeof arg.SelectedPayerID === 'string' ) &&

  true
  );
  }

export function isBlueMoonSettingModel(arg: any): arg is models.BlueMoonSettingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ExportEnabled?: boolean
    ( typeof arg.ExportEnabled === 'undefined' || typeof arg.ExportEnabled === 'boolean' ) &&
    // Password?: string
    ( typeof arg.Password === 'undefined' || typeof arg.Password === 'string' ) &&
    // PostUrl?: string
    ( typeof arg.PostUrl === 'undefined' || typeof arg.PostUrl === 'string' ) &&
    // PropertyNumber?: string
    ( typeof arg.PropertyNumber === 'undefined' || typeof arg.PropertyNumber === 'string' ) &&
    // SerialNumber?: string
    ( typeof arg.SerialNumber === 'undefined' || typeof arg.SerialNumber === 'string' ) &&
    // UserId?: string
    ( typeof arg.UserId === 'undefined' || typeof arg.UserId === 'string' ) &&

  true
  );
  }

export function isCancelTransactionRequest(arg: any): arg is models.CancelTransactionRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AchPaymentAmount?: number
    ( typeof arg.AchPaymentAmount === 'undefined' || typeof arg.AchPaymentAmount === 'number' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankNo?: string
    ( typeof arg.BankNo === 'undefined' || typeof arg.BankNo === 'string' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // CashPaymentAmount?: number
    ( typeof arg.CashPaymentAmount === 'undefined' || typeof arg.CashPaymentAmount === 'number' ) &&
    // CheckNo?: string
    ( typeof arg.CheckNo === 'undefined' || typeof arg.CheckNo === 'string' ) &&
    // CheckPaymentAmount?: number
    ( typeof arg.CheckPaymentAmount === 'undefined' || typeof arg.CheckPaymentAmount === 'number' ) &&
    // ClearentJWTToken?: string
    ( typeof arg.ClearentJWTToken === 'undefined' || typeof arg.ClearentJWTToken === 'string' ) &&
    // CreditCardPaymentAmount?: number
    ( typeof arg.CreditCardPaymentAmount === 'undefined' || typeof arg.CreditCardPaymentAmount === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // IsAutoBill?: boolean
    ( typeof arg.IsAutoBill === 'undefined' || typeof arg.IsAutoBill === 'boolean' ) &&
    // PayerId?: string
    ( typeof arg.PayerId === 'undefined' || typeof arg.PayerId === 'string' ) &&
    // SavePaymentInfo?: boolean
    ( typeof arg.SavePaymentInfo === 'undefined' || typeof arg.SavePaymentInfo === 'boolean' ) &&
    // SubmittedTransactionIdentifier?: string
    ( typeof arg.SubmittedTransactionIdentifier === 'undefined' || typeof arg.SubmittedTransactionIdentifier === 'string' ) &&
    // TransactionIdentifier?: string
    ( typeof arg.TransactionIdentifier === 'undefined' || typeof arg.TransactionIdentifier === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // UseCardOnFile?: boolean
    ( typeof arg.UseCardOnFile === 'undefined' || typeof arg.UseCardOnFile === 'boolean' ) &&

  true
  );
  }

export function isCancelTransactionResponse(arg: any): arg is models.CancelTransactionResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Total?: number
    ( typeof arg.Total === 'undefined' || typeof arg.Total === 'number' ) &&
    // TotalCredit?: number
    ( typeof arg.TotalCredit === 'undefined' || typeof arg.TotalCredit === 'number' ) &&
    // TotalPaid?: number
    ( typeof arg.TotalPaid === 'undefined' || typeof arg.TotalPaid === 'number' ) &&
    // TransactionDetails?: TransactionDetailResponse[]
    ( typeof arg.TransactionDetails === 'undefined' || (Array.isArray(arg.TransactionDetails) && arg.TransactionDetails.every((item: unknown) => isTransactionDetailResponse(item))) ) &&

  true
  );
  }

export function isCancelTransactionVoidRespone(arg: any): arg is models.CancelTransactionVoidRespone {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsVoidFaild?: boolean
    ( typeof arg.IsVoidFaild === 'undefined' || typeof arg.IsVoidFaild === 'boolean' ) &&
    // Message?: string
    ( typeof arg.Message === 'undefined' || typeof arg.Message === 'string' ) &&

  true
  );
  }

export function isCancelTransRequest(arg: any): arg is models.CancelTransRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // IsVoidFaild?: boolean
    ( typeof arg.IsVoidFaild === 'undefined' || typeof arg.IsVoidFaild === 'boolean' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&

  true
  );
  }

export function isCardVerificationOption(arg: any): arg is models.CardVerificationOption {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CVVMatch0?: boolean
    ( typeof arg.CVVMatch0 === 'undefined' || typeof arg.CVVMatch0 === 'boolean' ) &&
    // CVVMatch1?: boolean
    ( typeof arg.CVVMatch1 === 'undefined' || typeof arg.CVVMatch1 === 'boolean' ) &&
    // CVVMatch2?: boolean
    ( typeof arg.CVVMatch2 === 'undefined' || typeof arg.CVVMatch2 === 'boolean' ) &&
    // CVVMatch3?: boolean
    ( typeof arg.CVVMatch3 === 'undefined' || typeof arg.CVVMatch3 === 'boolean' ) &&
    // CVVMatch4?: boolean
    ( typeof arg.CVVMatch4 === 'undefined' || typeof arg.CVVMatch4 === 'boolean' ) &&
    // CVVMatch5?: boolean
    ( typeof arg.CVVMatch5 === 'undefined' || typeof arg.CVVMatch5 === 'boolean' ) &&

  true
  );
  }

export function isChargeRequest(arg: any): arg is models.ChargeRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // MiscItems?: MiscItem[]
    ( typeof arg.MiscItems === 'undefined' || (Array.isArray(arg.MiscItems) && arg.MiscItems.every((item: unknown) => isMiscItem(item))) ) &&

  true
  );
  }

export function isCheckSystemChargerResponse(arg: any): arg is models.CheckSystemChargerResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // LDChargeDate?: string
    ( typeof arg.LDChargeDate === 'undefined' || typeof arg.LDChargeDate === 'string' ) &&
    // Message?: string
    ( typeof arg.Message === 'undefined' || typeof arg.Message === 'string' ) &&
    // MissingCloseItems?: MissingCloseItem[]
    ( typeof arg.MissingCloseItems === 'undefined' || (Array.isArray(arg.MissingCloseItems) && arg.MissingCloseItems.every((item: unknown) => isMissingCloseItem(item))) ) &&
    // Result?: '1' | '2' | '3' | '4'
    ( typeof arg.Result === 'undefined' || ['1', '2', '3', '4'].includes(arg.Result) ) &&

  true
  );
  }

export function isClearentConfigModel(arg: any): arg is models.ClearentConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // APIKey?: string
    ( typeof arg.APIKey === 'undefined' || typeof arg.APIKey === 'string' ) &&
    // BaseUrl?: string
    ( typeof arg.BaseUrl === 'undefined' || typeof arg.BaseUrl === 'string' ) &&
    // KeyType?: string
    ( typeof arg.KeyType === 'undefined' || typeof arg.KeyType === 'string' ) &&
    // LastACHOrderID?: number
    ( typeof arg.LastACHOrderID === 'undefined' || typeof arg.LastACHOrderID === 'number' ) &&
    // MerchantAccountNo?: number
    ( typeof arg.MerchantAccountNo === 'undefined' || typeof arg.MerchantAccountNo === 'number' ) &&
    // PaymentUrl?: string
    ( typeof arg.PaymentUrl === 'undefined' || typeof arg.PaymentUrl === 'string' ) &&
    // ProductName?: string
    ( typeof arg.ProductName === 'undefined' || typeof arg.ProductName === 'string' ) &&
    // PublicKey?: string
    ( typeof arg.PublicKey === 'undefined' || typeof arg.PublicKey === 'string' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SiteGroupId?: number
    ( typeof arg.SiteGroupId === 'undefined' || typeof arg.SiteGroupId === 'number' ) &&
    // TerminalID?: number
    ( typeof arg.TerminalID === 'undefined' || typeof arg.TerminalID === 'number' ) &&

  true
  );
  }

export function isClosestGateRequestModel(arg: any): arg is models.ClosestGateRequestModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ActiveKeypadIds?: string[]
    ( typeof arg.ActiveKeypadIds === 'undefined' || (Array.isArray(arg.ActiveKeypadIds) && arg.ActiveKeypadIds.every((item: unknown) => typeof item === 'string')) ) &&
    // Latitude?: string
    ( typeof arg.Latitude === 'undefined' || typeof arg.Latitude === 'string' ) &&
    // Longtitude?: string
    ( typeof arg.Longtitude === 'undefined' || typeof arg.Longtitude === 'string' ) &&

  true
  );
  }

export function isClosestGateResponseModel(arg: any): arg is models.ClosestGateResponseModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // GateDesc?: string
    ( typeof arg.GateDesc === 'undefined' || typeof arg.GateDesc === 'string' ) &&
    // KeyPadID?: number
    ( typeof arg.KeyPadID === 'undefined' || typeof arg.KeyPadID === 'number' ) &&
    // OpenActionAvailable?: boolean
    ( typeof arg.OpenActionAvailable === 'undefined' || typeof arg.OpenActionAvailable === 'boolean' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&

  true
  );
  }

export function isClosingModel(arg: any): arg is models.ClosingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CloseDate?: string
    ( typeof arg.CloseDate === 'undefined' || typeof arg.CloseDate === 'string' ) &&
    // CloseType?: '1' | '2' | '3' | '4' | '5' | '7'
    ( typeof arg.CloseType === 'undefined' || ['1', '2', '3', '4', '5', '7'].includes(arg.CloseType) ) &&
    // ClosingID?: number
    ( typeof arg.ClosingID === 'undefined' || typeof arg.ClosingID === 'number' ) &&
    // CreatedDate?: string
    ( typeof arg.CreatedDate === 'undefined' || typeof arg.CreatedDate === 'string' ) &&
    // EndDate?: string
    ( typeof arg.EndDate === 'undefined' || typeof arg.EndDate === 'string' ) &&
    // StartDate?: string
    ( typeof arg.StartDate === 'undefined' || typeof arg.StartDate === 'string' ) &&
    // Status?: number
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'number' ) &&

  true
  );
  }

export function isCompleteLienResponse(arg: any): arg is models.CompleteLienResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // LienStatus?: number
    ( typeof arg.LienStatus === 'undefined' || typeof arg.LienStatus === 'number' ) &&
    // TransId?: number
    ( typeof arg.TransId === 'undefined' || typeof arg.TransId === 'number' ) &&

  true
  );
  }

export function isCostRequest(arg: any): arg is models.CostRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // IsManagerPortal?: boolean
    ( typeof arg.IsManagerPortal === 'undefined' || typeof arg.IsManagerPortal === 'boolean' ) &&
    // Units?: UnitRequest[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => isUnitRequest(item))) ) &&

  true
  );
  }

export function isCountryModel(arg: any): arg is models.CountryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Abbreviation?: string
    ( typeof arg.Abbreviation === 'undefined' || typeof arg.Abbreviation === 'string' ) &&
    // CounrtyID?: number
    ( typeof arg.CounrtyID === 'undefined' || typeof arg.CounrtyID === 'number' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&

  true
  );
  }

export function isCoverageModel(arg: any): arg is models.CoverageModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Coverage?: string
    ( typeof arg.Coverage === 'undefined' || typeof arg.Coverage === 'string' ) &&
    // Premium?: string
    ( typeof arg.Premium === 'undefined' || typeof arg.Premium === 'string' ) &&
    // RateNo?: number
    ( typeof arg.RateNo === 'undefined' || typeof arg.RateNo === 'number' ) &&
    // Selected?: boolean
    ( typeof arg.Selected === 'undefined' || typeof arg.Selected === 'boolean' ) &&
    // TheftPercent?: string
    ( typeof arg.TheftPercent === 'undefined' || typeof arg.TheftPercent === 'string' ) &&

  true
  );
  }

export function isCreditCartProcessModel(arg: any): arg is models.CreditCartProcessModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CommTimeout?: number
    ( typeof arg.CommTimeout === 'undefined' || typeof arg.CommTimeout === 'number' ) &&
    // LastOrderID?: number
    ( typeof arg.LastOrderID === 'undefined' || typeof arg.LastOrderID === 'number' ) &&
    // MerchantAccount?: string
    ( typeof arg.MerchantAccount === 'undefined' || typeof arg.MerchantAccount === 'string' ) &&
    // WebServiceURL?: string
    ( typeof arg.WebServiceURL === 'undefined' || typeof arg.WebServiceURL === 'string' ) &&

  true
  );
  }

export function isCustomerCreditCardResponse(arg: any): arg is models.CustomerCreditCardResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CardType?: string
    ( typeof arg.CardType === 'undefined' || typeof arg.CardType === 'string' ) &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // ExpDate?: string
    ( typeof arg.ExpDate === 'undefined' || typeof arg.ExpDate === 'string' ) &&
    // IsAutoBill?: boolean
    ( typeof arg.IsAutoBill === 'undefined' || typeof arg.IsAutoBill === 'boolean' ) &&
    // LastFour?: string
    ( typeof arg.LastFour === 'undefined' || typeof arg.LastFour === 'string' ) &&
    // PayerID?: string
    ( typeof arg.PayerID === 'undefined' || typeof arg.PayerID === 'string' ) &&

  true
  );
  }

export function isCustomerDashboardResponse(arg: any): arg is models.CustomerDashboardResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountBalanceDue?: number
    ( typeof arg.AccountBalanceDue === 'undefined' || typeof arg.AccountBalanceDue === 'number' ) &&
    // Address?: string
    ( typeof arg.Address === 'undefined' || typeof arg.Address === 'string' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // Fullname?: string
    ( typeof arg.Fullname === 'undefined' || typeof arg.Fullname === 'string' ) &&

  true
  );
  }

export function isCustomerDemoprapichItem(arg: any): arg is models.CustomerDemoprapichItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // DemoGrapicGroupID?: number
    ( typeof arg.DemoGrapicGroupID === 'undefined' || typeof arg.DemoGrapicGroupID === 'number' ) &&
    // DemoGrapicItemID?: number
    ( typeof arg.DemoGrapicItemID === 'undefined' || typeof arg.DemoGrapicItemID === 'number' ) &&

  true
  );
  }

export function isCustomerEventHistoryResponse(arg: any): arg is models.CustomerEventHistoryResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Count?: number
    ( typeof arg.Count === 'undefined' || typeof arg.Count === 'number' ) &&
    // Events?: EventItem[]
    ( typeof arg.Events === 'undefined' || (Array.isArray(arg.Events) && arg.Events.every((item: unknown) => isEventItem(item))) ) &&

  true
  );
  }

export function isCustomerFollowUpActionModel(arg: any): arg is models.CustomerFollowUpActionModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ActionID?: number
    ( typeof arg.ActionID === 'undefined' || typeof arg.ActionID === 'number' ) &&
    // ActualFollowUpDate?: string
    ( typeof arg.ActualFollowUpDate === 'undefined' || typeof arg.ActualFollowUpDate === 'string' ) &&
    // Finalized?: boolean
    ( typeof arg.Finalized === 'undefined' || typeof arg.Finalized === 'boolean' ) &&
    // FollowUpDate?: string
    ( typeof arg.FollowUpDate === 'undefined' || typeof arg.FollowUpDate === 'string' ) &&
    // Selected?: boolean
    ( typeof arg.Selected === 'undefined' || typeof arg.Selected === 'boolean' ) &&

  true
  );
  }

export function isCustomerInsuranceInfoRequest(arg: any): arg is models.CustomerInsuranceInfoRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isCustomerLoginRequest(arg: any): arg is models.CustomerLoginRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Email: string
    ( typeof arg.Email === 'string' ) &&
    // Password: string
    ( typeof arg.Password === 'string' ) &&

  true
  );
  }

export function isCustomerModel(arg: any): arg is models.CustomerModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessCardNumber?: number
    ( typeof arg.AccessCardNumber === 'undefined' || typeof arg.AccessCardNumber === 'number' ) &&
    // AccessLevels?: AccessLevelsItem[]
    ( typeof arg.AccessLevels === 'undefined' || (Array.isArray(arg.AccessLevels) && arg.AccessLevels.every((item: unknown) => isAccessLevelsItem(item))) ) &&
    // Address1: string
    ( typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AnticipatedLengthOfStay?: number
    ( typeof arg.AnticipatedLengthOfStay === 'undefined' || typeof arg.AnticipatedLengthOfStay === 'number' ) &&
    // Appointment?: string
    ( typeof arg.Appointment === 'undefined' || typeof arg.Appointment === 'string' ) &&
    // AppointmentKept?: boolean
    ( typeof arg.AppointmentKept === 'undefined' || typeof arg.AppointmentKept === 'boolean' ) &&
    // AutoPay?: boolean
    ( typeof arg.AutoPay === 'undefined' || typeof arg.AutoPay === 'boolean' ) &&
    // BankAccountClass?: number
    ( typeof arg.BankAccountClass === 'undefined' || typeof arg.BankAccountClass === 'number' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankAccountType?: number
    ( typeof arg.BankAccountType === 'undefined' || typeof arg.BankAccountType === 'number' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // BusinessName?: string
    ( typeof arg.BusinessName === 'undefined' || typeof arg.BusinessName === 'string' ) &&
    // Category?: string
    ( typeof arg.Category === 'undefined' || typeof arg.Category === 'string' ) &&
    // ChangePassword?: boolean
    ( typeof arg.ChangePassword === 'undefined' || typeof arg.ChangePassword === 'boolean' ) &&
    // City: string
    ( typeof arg.City === 'string' ) &&
    // ClearentToken?: string
    ( typeof arg.ClearentToken === 'undefined' || typeof arg.ClearentToken === 'string' ) &&
    // ConfirmPassword: string
    ( typeof arg.ConfirmPassword === 'string' ) &&
    // Country?: string
    ( typeof arg.Country === 'undefined' || typeof arg.Country === 'string' ) &&
    // CountryID?: string
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'string' ) &&
    // CreditCardExpMonth?: number
    ( typeof arg.CreditCardExpMonth === 'undefined' || typeof arg.CreditCardExpMonth === 'number' ) &&
    // CreditCardExpYear?: number
    ( typeof arg.CreditCardExpYear === 'undefined' || typeof arg.CreditCardExpYear === 'number' ) &&
    // CreditCardNo?: string
    ( typeof arg.CreditCardNo === 'undefined' || typeof arg.CreditCardNo === 'string' ) &&
    // CreditCardType?: string
    ( typeof arg.CreditCardType === 'undefined' || typeof arg.CreditCardType === 'string' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // CustomerTypeId?: number
    ( typeof arg.CustomerTypeId === 'undefined' || typeof arg.CustomerTypeId === 'number' ) &&
    // DateNeededBy?: string
    ( typeof arg.DateNeededBy === 'undefined' || typeof arg.DateNeededBy === 'string' ) &&
    // DateOfBirth?: string
    ( typeof arg.DateOfBirth === 'undefined' || typeof arg.DateOfBirth === 'string' ) &&
    // DriversLicenseNo: string
    ( typeof arg.DriversLicenseNo === 'string' ) &&
    // EMailAddress: string
    ( typeof arg.EMailAddress === 'string' ) &&
    // EmployerAddress1?: string
    ( typeof arg.EmployerAddress1 === 'undefined' || typeof arg.EmployerAddress1 === 'string' ) &&
    // EmployerAddress2?: string
    ( typeof arg.EmployerAddress2 === 'undefined' || typeof arg.EmployerAddress2 === 'string' ) &&
    // EmployerCity?: string
    ( typeof arg.EmployerCity === 'undefined' || typeof arg.EmployerCity === 'string' ) &&
    // EmployerCountry?: string
    ( typeof arg.EmployerCountry === 'undefined' || typeof arg.EmployerCountry === 'string' ) &&
    // EmployerCountryID?: number
    ( typeof arg.EmployerCountryID === 'undefined' || typeof arg.EmployerCountryID === 'number' ) &&
    // EmployerName?: string
    ( typeof arg.EmployerName === 'undefined' || typeof arg.EmployerName === 'string' ) &&
    // EmployerPhone?: string
    ( typeof arg.EmployerPhone === 'undefined' || typeof arg.EmployerPhone === 'string' ) &&
    // EmployerState?: string
    ( typeof arg.EmployerState === 'undefined' || typeof arg.EmployerState === 'string' ) &&
    // EmployerStateID?: number
    ( typeof arg.EmployerStateID === 'undefined' || typeof arg.EmployerStateID === 'number' ) &&
    // EmployerZipCode?: string
    ( typeof arg.EmployerZipCode === 'undefined' || typeof arg.EmployerZipCode === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName: string
    ( typeof arg.FirstName === 'string' ) &&
    // FollowUpActionEntries?: CustomerFollowUpActionModel[]
    ( typeof arg.FollowUpActionEntries === 'undefined' || (Array.isArray(arg.FollowUpActionEntries) && arg.FollowUpActionEntries.every((item: unknown) => isCustomerFollowUpActionModel(item))) ) &&
    // FullName?: string
    ( typeof arg.FullName === 'undefined' || typeof arg.FullName === 'string' ) &&
    // HomePhone: string
    ( typeof arg.HomePhone === 'string' ) &&
    // LastName: string
    ( typeof arg.LastName === 'string' ) &&
    // LicenseExpDate?: string
    ( typeof arg.LicenseExpDate === 'undefined' || typeof arg.LicenseExpDate === 'string' ) &&
    // LicenseState?: string
    ( typeof arg.LicenseState === 'undefined' || typeof arg.LicenseState === 'string' ) &&
    // LicenseStateID?: string
    ( typeof arg.LicenseStateID === 'undefined' || typeof arg.LicenseStateID === 'string' ) &&
    // MilitaryStatus?: string
    ( typeof arg.MilitaryStatus === 'undefined' || typeof arg.MilitaryStatus === 'string' ) &&
    // MilitaryStatusID?: number
    ( typeof arg.MilitaryStatusID === 'undefined' || typeof arg.MilitaryStatusID === 'number' ) &&
    // MobilePhone: string
    ( typeof arg.MobilePhone === 'string' ) &&
    // NeedBy?: string
    ( typeof arg.NeedBy === 'undefined' || typeof arg.NeedBy === 'string' ) &&
    // OnlinePassword: string
    ( typeof arg.OnlinePassword === 'string' ) &&
    // Passcode?: number
    ( typeof arg.Passcode === 'undefined' || typeof arg.Passcode === 'number' ) &&
    // RentalDate?: string
    ( typeof arg.RentalDate === 'undefined' || typeof arg.RentalDate === 'string' ) &&
    // RVLength?: number
    ( typeof arg.RVLength === 'undefined' || typeof arg.RVLength === 'number' ) &&
    // SendNotices?: boolean
    ( typeof arg.SendNotices === 'undefined' || typeof arg.SendNotices === 'boolean' ) &&
    // SMSCareerID?: number
    ( typeof arg.SMSCareerID === 'undefined' || typeof arg.SMSCareerID === 'number' ) &&
    // SocialSecurityNo?: string
    ( typeof arg.SocialSecurityNo === 'undefined' || typeof arg.SocialSecurityNo === 'string' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // StateID?: string
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'string' ) &&
    // StoredBefore?: boolean
    ( typeof arg.StoredBefore === 'undefined' || typeof arg.StoredBefore === 'boolean' ) &&
    // Taxable?: boolean
    ( typeof arg.Taxable === 'undefined' || typeof arg.Taxable === 'boolean' ) &&
    // TimeZoneID?: number
    ( typeof arg.TimeZoneID === 'undefined' || typeof arg.TimeZoneID === 'number' ) &&
    // TimeZoneNo?: number
    ( typeof arg.TimeZoneNo === 'undefined' || typeof arg.TimeZoneNo === 'number' ) &&
    // UseBusinessName?: boolean
    ( typeof arg.UseBusinessName === 'undefined' || typeof arg.UseBusinessName === 'boolean' ) &&
    // ZipCode: string
    ( typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isCustomerPostModel(arg: any): arg is models.CustomerPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessCardNo?: number
    ( typeof arg.AccessCardNo === 'undefined' || typeof arg.AccessCardNo === 'number' ) &&
    // AccessLevels?: AccessLevelPostModel[]
    ( typeof arg.AccessLevels === 'undefined' || (Array.isArray(arg.AccessLevels) && arg.AccessLevels.every((item: unknown) => isAccessLevelPostModel(item))) ) &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AlertFlag?: boolean
    ( typeof arg.AlertFlag === 'undefined' || typeof arg.AlertFlag === 'boolean' ) &&
    // AutoBill?: boolean
    ( typeof arg.AutoBill === 'undefined' || typeof arg.AutoBill === 'boolean' ) &&
    // BusinessName?: string
    ( typeof arg.BusinessName === 'undefined' || typeof arg.BusinessName === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CountryId?: number
    ( typeof arg.CountryId === 'undefined' || typeof arg.CountryId === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // CustomerTaxable?: boolean
    ( typeof arg.CustomerTaxable === 'undefined' || typeof arg.CustomerTaxable === 'boolean' ) &&
    // DateOfBirth?: string
    ( typeof arg.DateOfBirth === 'undefined' || typeof arg.DateOfBirth === 'string' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // EMailNotices?: boolean
    ( typeof arg.EMailNotices === 'undefined' || typeof arg.EMailNotices === 'boolean' ) &&
    // EmpAddress1?: string
    ( typeof arg.EmpAddress1 === 'undefined' || typeof arg.EmpAddress1 === 'string' ) &&
    // EmpAddress2?: string
    ( typeof arg.EmpAddress2 === 'undefined' || typeof arg.EmpAddress2 === 'string' ) &&
    // EmpCity?: string
    ( typeof arg.EmpCity === 'undefined' || typeof arg.EmpCity === 'string' ) &&
    // EmpCountryID?: number
    ( typeof arg.EmpCountryID === 'undefined' || typeof arg.EmpCountryID === 'number' ) &&
    // EmpName?: string
    ( typeof arg.EmpName === 'undefined' || typeof arg.EmpName === 'string' ) &&
    // EmpPhone?: string
    ( typeof arg.EmpPhone === 'undefined' || typeof arg.EmpPhone === 'string' ) &&
    // EmpStateID?: number
    ( typeof arg.EmpStateID === 'undefined' || typeof arg.EmpStateID === 'number' ) &&
    // EmpZipCode?: string
    ( typeof arg.EmpZipCode === 'undefined' || typeof arg.EmpZipCode === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // IsActive?: boolean
    ( typeof arg.IsActive === 'undefined' || typeof arg.IsActive === 'boolean' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // License: string
    ( typeof arg.License === 'string' ) &&
    // LicenseExpDate?: string
    ( typeof arg.LicenseExpDate === 'undefined' || typeof arg.LicenseExpDate === 'string' ) &&
    // LicenseStateID?: number
    ( typeof arg.LicenseStateID === 'undefined' || typeof arg.LicenseStateID === 'number' ) &&
    // MilitaryStatus?: number
    ( typeof arg.MilitaryStatus === 'undefined' || typeof arg.MilitaryStatus === 'number' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // PassCode?: number
    ( typeof arg.PassCode === 'undefined' || typeof arg.PassCode === 'number' ) &&
    // SendNotices?: boolean
    ( typeof arg.SendNotices === 'undefined' || typeof arg.SendNotices === 'boolean' ) &&
    // SMSCarrierId?: number
    ( typeof arg.SMSCarrierId === 'undefined' || typeof arg.SMSCarrierId === 'number' ) &&
    // SMSNotices?: boolean
    ( typeof arg.SMSNotices === 'undefined' || typeof arg.SMSNotices === 'boolean' ) &&
    // SocSecNo?: string
    ( typeof arg.SocSecNo === 'undefined' || typeof arg.SocSecNo === 'string' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // TimeZoneId?: number
    ( typeof arg.TimeZoneId === 'undefined' || typeof arg.TimeZoneId === 'number' ) &&
    // TrackingNumber?: string
    ( typeof arg.TrackingNumber === 'undefined' || typeof arg.TrackingNumber === 'string' ) &&
    // UseBusinessName?: boolean
    ( typeof arg.UseBusinessName === 'undefined' || typeof arg.UseBusinessName === 'boolean' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isCustomerSearchResponse(arg: any): arg is models.CustomerSearchResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // Category?: number
    ( typeof arg.Category === 'undefined' || typeof arg.Category === 'number' ) &&
    // CategoryName?: string
    ( typeof arg.CategoryName === 'undefined' || typeof arg.CategoryName === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // CreditCardInfo?: CustomerCreditCardResponse
    ( typeof arg.CreditCardInfo === 'undefined' || isCustomerCreditCardResponse(arg.CreditCardInfo) ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // CustomerSizes?: CustomerSizeModel[]
    ( typeof arg.CustomerSizes === 'undefined' || (Array.isArray(arg.CustomerSizes) && arg.CustomerSizes.every((item: unknown) => isCustomerSizeModel(item))) ) &&
    // CustomerUnits?: CustomerUnitModel[]
    ( typeof arg.CustomerUnits === 'undefined' || (Array.isArray(arg.CustomerUnits) && arg.CustomerUnits.every((item: unknown) => isCustomerUnitModel(item))) ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // FullName?: string
    ( typeof arg.FullName === 'undefined' || typeof arg.FullName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // LastRateChange?: string
    ( typeof arg.LastRateChange === 'undefined' || typeof arg.LastRateChange === 'string' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // MontlyCharges?: number
    ( typeof arg.MontlyCharges === 'undefined' || typeof arg.MontlyCharges === 'number' ) &&
    // MoveInDate?: string
    ( typeof arg.MoveInDate === 'undefined' || typeof arg.MoveInDate === 'string' ) &&
    // PaidToDate?: string
    ( typeof arg.PaidToDate === 'undefined' || typeof arg.PaidToDate === 'string' ) &&
    // PromoID?: number
    ( typeof arg.PromoID === 'undefined' || typeof arg.PromoID === 'number' ) &&
    // RentDueDay?: number
    ( typeof arg.RentDueDay === 'undefined' || typeof arg.RentDueDay === 'number' ) &&
    // ReservedDeposit?: number
    ( typeof arg.ReservedDeposit === 'undefined' || typeof arg.ReservedDeposit === 'number' ) &&
    // ReservedToDate?: string
    ( typeof arg.ReservedToDate === 'undefined' || typeof arg.ReservedToDate === 'string' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // StandardRate?: number
    ( typeof arg.StandardRate === 'undefined' || typeof arg.StandardRate === 'number' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // UnitCustomerID?: string
    ( typeof arg.UnitCustomerID === 'undefined' || typeof arg.UnitCustomerID === 'string' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&
    // UnitStatus?: number
    ( typeof arg.UnitStatus === 'undefined' || typeof arg.UnitStatus === 'number' ) &&
    // UnitStatusName?: string
    ( typeof arg.UnitStatusName === 'undefined' || typeof arg.UnitStatusName === 'string' ) &&
    // UnitTypeID?: number
    ( typeof arg.UnitTypeID === 'undefined' || typeof arg.UnitTypeID === 'number' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isCustomerSig(arg: any): arg is models.CustomerSig {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AcceptIns?: boolean
    ( typeof arg.AcceptIns === 'undefined' || typeof arg.AcceptIns === 'boolean' ) &&
    // AcceptTerms: boolean
    ( typeof arg.AcceptTerms === 'boolean' ) &&
    // ContractOption1?: boolean
    ( typeof arg.ContractOption1 === 'undefined' || typeof arg.ContractOption1 === 'boolean' ) &&
    // ContractOption2?: boolean
    ( typeof arg.ContractOption2 === 'undefined' || typeof arg.ContractOption2 === 'boolean' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // FirstName: string
    ( typeof arg.FirstName === 'string' ) &&
    // Initials: string
    ( typeof arg.Initials === 'string' ) &&
    // IsContractSigned?: boolean
    ( typeof arg.IsContractSigned === 'undefined' || typeof arg.IsContractSigned === 'boolean' ) &&
    // LastName: string
    ( typeof arg.LastName === 'string' ) &&
    // PDFFile?: string
    ( typeof arg.PDFFile === 'undefined' || typeof arg.PDFFile === 'string' ) &&
    // RateNo?: number
    ( typeof arg.RateNo === 'undefined' || typeof arg.RateNo === 'number' ) &&
    // SignatureDate?: string
    ( typeof arg.SignatureDate === 'undefined' || typeof arg.SignatureDate === 'string' ) &&
    // TransId?: number
    ( typeof arg.TransId === 'undefined' || typeof arg.TransId === 'number' ) &&

  true
  );
  }

export function isCustomerSizeModel(arg: any): arg is models.CustomerSizeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Area?: number
    ( typeof arg.Area === 'undefined' || typeof arg.Area === 'number' ) &&
    // Depth?: number
    ( typeof arg.Depth === 'undefined' || typeof arg.Depth === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // Height?: number
    ( typeof arg.Height === 'undefined' || typeof arg.Height === 'number' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // StandardRate?: number
    ( typeof arg.StandardRate === 'undefined' || typeof arg.StandardRate === 'number' ) &&
    // Width?: number
    ( typeof arg.Width === 'undefined' || typeof arg.Width === 'number' ) &&

  true
  );
  }

export function isCustomerSizesAndUnitsModel(arg: any): arg is models.CustomerSizesAndUnitsModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Sizes?: CustomerSizeModel[]
    ( typeof arg.Sizes === 'undefined' || (Array.isArray(arg.Sizes) && arg.Sizes.every((item: unknown) => isCustomerSizeModel(item))) ) &&
    // Units?: CustomerUnitModel[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => isCustomerUnitModel(item))) ) &&

  true
  );
  }

export function isCustomerSummaryModel(arg: any): arg is models.CustomerSummaryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1: string
    ( typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // ChangePassword?: boolean
    ( typeof arg.ChangePassword === 'undefined' || typeof arg.ChangePassword === 'boolean' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // ConfirmPassword?: string
    ( typeof arg.ConfirmPassword === 'undefined' || typeof arg.ConfirmPassword === 'string' ) &&
    // CountryID: number
    ( typeof arg.CountryID === 'number' ) &&
    // CustomerID: number
    ( typeof arg.CustomerID === 'number' ) &&
    // EMailAddress: string
    ( typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName: string
    ( typeof arg.FirstName === 'string' ) &&
    // HomePhone: string
    ( typeof arg.HomePhone === 'string' ) &&
    // LastName: string
    ( typeof arg.LastName === 'string' ) &&
    // MobilePhone: string
    ( typeof arg.MobilePhone === 'string' ) &&
    // NewPassword?: string
    ( typeof arg.NewPassword === 'undefined' || typeof arg.NewPassword === 'string' ) &&
    // OnlinePassword?: string
    ( typeof arg.OnlinePassword === 'undefined' || typeof arg.OnlinePassword === 'string' ) &&
    // Picture?: Picture
    ( typeof arg.Picture === 'undefined' || isPicture(arg.Picture) ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // ZipCode: string
    ( typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isCustomerTransModel(arg: any): arg is models.CustomerTransModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // CardExp?: string
    ( typeof arg.CardExp === 'undefined' || typeof arg.CardExp === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CreditCardNo?: string
    ( typeof arg.CreditCardNo === 'undefined' || typeof arg.CreditCardNo === 'string' ) &&
    // CreditCardType?: string
    ( typeof arg.CreditCardType === 'undefined' || typeof arg.CreditCardType === 'string' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // SocialSecurityNo?: string
    ( typeof arg.SocialSecurityNo === 'undefined' || typeof arg.SocialSecurityNo === 'string' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // Taxable?: boolean
    ( typeof arg.Taxable === 'undefined' || typeof arg.Taxable === 'boolean' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isCustomerUnitModel(arg: any): arg is models.CustomerUnitModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AINumber?: number
    ( typeof arg.AINumber === 'undefined' || typeof arg.AINumber === 'number' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // Rentable?: boolean
    ( typeof arg.Rentable === 'undefined' || typeof arg.Rentable === 'boolean' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitNotes?: string
    ( typeof arg.UnitNotes === 'undefined' || typeof arg.UnitNotes === 'string' ) &&
    // UnitType?: string
    ( typeof arg.UnitType === 'undefined' || typeof arg.UnitType === 'string' ) &&
    // UnitTypeID?: number
    ( typeof arg.UnitTypeID === 'undefined' || typeof arg.UnitTypeID === 'number' ) &&

  true
  );
  }

export function isDashboardBillingInfoResponse(arg: any): arg is models.DashboardBillingInfoResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AutoBills?: number
    ( typeof arg.AutoBills === 'undefined' || typeof arg.AutoBills === 'number' ) &&
    // Leeds?: number
    ( typeof arg.Leeds === 'undefined' || typeof arg.Leeds === 'number' ) &&
    // PastDues?: number
    ( typeof arg.PastDues === 'undefined' || typeof arg.PastDues === 'number' ) &&
    // WaitingList?: number
    ( typeof arg.WaitingList === 'undefined' || typeof arg.WaitingList === 'number' ) &&

  true
  );
  }

export function isDashboardEventItem(arg: any): arg is models.DashboardEventItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // EventCategory?: string
    ( typeof arg.EventCategory === 'undefined' || typeof arg.EventCategory === 'string' ) &&
    // EventDate?: string
    ( typeof arg.EventDate === 'undefined' || typeof arg.EventDate === 'string' ) &&
    // EventDescription?: string
    ( typeof arg.EventDescription === 'undefined' || typeof arg.EventDescription === 'string' ) &&
    // EventID?: number
    ( typeof arg.EventID === 'undefined' || typeof arg.EventID === 'number' ) &&
    // Username?: string
    ( typeof arg.Username === 'undefined' || typeof arg.Username === 'string' ) &&

  true
  );
  }

export function isDeleteDepositGroupRequest(arg: any): arg is models.DeleteDepositGroupRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CompanyID?: number
    ( typeof arg.CompanyID === 'undefined' || typeof arg.CompanyID === 'number' ) &&
    // DepositGroupNo?: number
    ( typeof arg.DepositGroupNo === 'undefined' || typeof arg.DepositGroupNo === 'number' ) &&

  true
  );
  }

export function isDelinquencyReportResponse(arg: any): arg is models.DelinquencyReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Over90Amount?: number
    ( typeof arg.Over90Amount === 'undefined' || typeof arg.Over90Amount === 'number' ) &&
    // Range1To30Amount?: number
    ( typeof arg.Range1To30Amount === 'undefined' || typeof arg.Range1To30Amount === 'number' ) &&
    // Range31To60Amount?: number
    ( typeof arg.Range31To60Amount === 'undefined' || typeof arg.Range31To60Amount === 'number' ) &&
    // Range61To90Amount?: number
    ( typeof arg.Range61To90Amount === 'undefined' || typeof arg.Range61To90Amount === 'number' ) &&

  true
  );
  }

export function isDemographicGroup(arg: any): arg is models.DemographicGroup {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // DemographicGroupID?: number
    ( typeof arg.DemographicGroupID === 'undefined' || typeof arg.DemographicGroupID === 'number' ) &&
    // DemographicItems?: DemographicItem[]
    ( typeof arg.DemographicItems === 'undefined' || (Array.isArray(arg.DemographicItems) && arg.DemographicItems.every((item: unknown) => isDemographicItem(item))) ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&

  true
  );
  }

export function isDemographicItem(arg: any): arg is models.DemographicItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // DemographicGroupID?: number
    ( typeof arg.DemographicGroupID === 'undefined' || typeof arg.DemographicGroupID === 'number' ) &&
    // DemographicItemID?: number
    ( typeof arg.DemographicItemID === 'undefined' || typeof arg.DemographicItemID === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // IsApply?: boolean
    ( typeof arg.IsApply === 'undefined' || typeof arg.IsApply === 'boolean' ) &&

  true
  );
  }

export function isDepositGroupModel(arg: any): arg is models.DepositGroupModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CompanyID?: number
    ( typeof arg.CompanyID === 'undefined' || typeof arg.CompanyID === 'number' ) &&
    // DepositGroupNo?: number
    ( typeof arg.DepositGroupNo === 'undefined' || typeof arg.DepositGroupNo === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DiscountAccountNo?: number
    ( typeof arg.DiscountAccountNo === 'undefined' || typeof arg.DiscountAccountNo === 'number' ) &&
    // DiscountAccountTitle?: string
    ( typeof arg.DiscountAccountTitle === 'undefined' || typeof arg.DiscountAccountTitle === 'string' ) &&
    // DiscountAmount?: number
    ( typeof arg.DiscountAmount === 'undefined' || typeof arg.DiscountAmount === 'number' ) &&
    // ExportAccountNo?: number
    ( typeof arg.ExportAccountNo === 'undefined' || typeof arg.ExportAccountNo === 'number' ) &&
    // ExportAccountTitle?: string
    ( typeof arg.ExportAccountTitle === 'undefined' || typeof arg.ExportAccountTitle === 'string' ) &&

  true
  );
  }

export function isDictionaryInsuranceConfigModel(arg: any): arg is models.DictionaryInsuranceConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CarrierName?: string
    ( typeof arg.CarrierName === 'undefined' || typeof arg.CarrierName === 'string' ) &&
    // InsuranceConfigID?: number
    ( typeof arg.InsuranceConfigID === 'undefined' || typeof arg.InsuranceConfigID === 'number' ) &&

  true
  );
  }

export function isDisplayRequest(arg: any): arg is models.DisplayRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // Index?: number
    ( typeof arg.Index === 'undefined' || typeof arg.Index === 'number' ) &&
    // LeaseId?: number
    ( typeof arg.LeaseId === 'undefined' || typeof arg.LeaseId === 'number' ) &&

  true
  );
  }

export function isDocImgSigRequest(arg: any): arg is models.DocImgSigRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ImgData: string
    ( typeof arg.ImgData === 'string' ) &&
    // PDFFile?: string
    ( typeof arg.PDFFile === 'undefined' || typeof arg.PDFFile === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&

  true
  );
  }

export function isDocumentModel(arg: any): arg is models.DocumentModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // PDFFile?: string
    ( typeof arg.PDFFile === 'undefined' || typeof arg.PDFFile === 'string' ) &&
    // TransId?: number
    ( typeof arg.TransId === 'undefined' || typeof arg.TransId === 'number' ) &&

  true
  );
  }

export function isEditorRequestModel(arg: any): arg is models.EditorRequestModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // Deposit?: number
    ( typeof arg.Deposit === 'undefined' || typeof arg.Deposit === 'number' ) &&
    // MIDate?: string
    ( typeof arg.MIDate === 'undefined' || typeof arg.MIDate === 'string' ) &&
    // PaidToDate?: string
    ( typeof arg.PaidToDate === 'undefined' || typeof arg.PaidToDate === 'string' ) &&
    // RentDueDay?: number
    ( typeof arg.RentDueDay === 'undefined' || typeof arg.RentDueDay === 'number' ) &&
    // UnitCustomerId?: string
    ( typeof arg.UnitCustomerId === 'undefined' || typeof arg.UnitCustomerId === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&

  true
  );
  }

export function isEditorResponseModel(arg: any): arg is models.EditorResponseModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // Deposit?: number
    ( typeof arg.Deposit === 'undefined' || typeof arg.Deposit === 'number' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // MIDate?: string
    ( typeof arg.MIDate === 'undefined' || typeof arg.MIDate === 'string' ) &&
    // OverLock?: boolean
    ( typeof arg.OverLock === 'undefined' || typeof arg.OverLock === 'boolean' ) &&
    // PaidToDate?: string
    ( typeof arg.PaidToDate === 'undefined' || typeof arg.PaidToDate === 'string' ) &&
    // RentDueDay?: number
    ( typeof arg.RentDueDay === 'undefined' || typeof arg.RentDueDay === 'number' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // Status?: string
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'string' ) &&
    // TotalAmount?: number
    ( typeof arg.TotalAmount === 'undefined' || typeof arg.TotalAmount === 'number' ) &&
    // TransDetails?: EditorTransDetail[]
    ( typeof arg.TransDetails === 'undefined' || (Array.isArray(arg.TransDetails) && arg.TransDetails.every((item: unknown) => isEditorTransDetail(item))) ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&

  true
  );
  }

export function isEditorTransDetail(arg: any): arg is models.EditorTransDetail {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountNo?: number
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'number' ) &&
    // AccountTitle?: string
    ( typeof arg.AccountTitle === 'undefined' || typeof arg.AccountTitle === 'string' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // Credit?: number
    ( typeof arg.Credit === 'undefined' || typeof arg.Credit === 'number' ) &&
    // Debit?: number
    ( typeof arg.Debit === 'undefined' || typeof arg.Debit === 'number' ) &&
    // PostDate?: string
    ( typeof arg.PostDate === 'undefined' || typeof arg.PostDate === 'string' ) &&
    // Quantity?: number
    ( typeof arg.Quantity === 'undefined' || typeof arg.Quantity === 'number' ) &&
    // TransDetailId?: number
    ( typeof arg.TransDetailId === 'undefined' || typeof arg.TransDetailId === 'number' ) &&
    // TransId?: number
    ( typeof arg.TransId === 'undefined' || typeof arg.TransId === 'number' ) &&
    // TransNo?: number
    ( typeof arg.TransNo === 'undefined' || typeof arg.TransNo === 'number' ) &&
    // UnitOfMeasure?: string
    ( typeof arg.UnitOfMeasure === 'undefined' || typeof arg.UnitOfMeasure === 'string' ) &&

  true
  );
  }

export function isEditorTransItem(arg: any): arg is models.EditorTransItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountId?: number
    ( typeof arg.AccountId === 'undefined' || typeof arg.AccountId === 'number' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // CStatus?: number
    ( typeof arg.CStatus === 'undefined' || typeof arg.CStatus === 'number' ) &&
    // Quantity?: number
    ( typeof arg.Quantity === 'undefined' || typeof arg.Quantity === 'number' ) &&
    // TransDetailId?: number
    ( typeof arg.TransDetailId === 'undefined' || typeof arg.TransDetailId === 'number' ) &&
    // TransId?: number
    ( typeof arg.TransId === 'undefined' || typeof arg.TransId === 'number' ) &&
    // UnitOfMeasure?: number
    ( typeof arg.UnitOfMeasure === 'undefined' || typeof arg.UnitOfMeasure === 'number' ) &&

  true
  );
  }

export function isEditorTransRequest(arg: any): arg is models.EditorTransRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // TransDetails?: EditorTransItem[]
    ( typeof arg.TransDetails === 'undefined' || (Array.isArray(arg.TransDetails) && arg.TransDetails.every((item: unknown) => isEditorTransItem(item))) ) &&
    // unitCustomerId?: string
    ( typeof arg.unitCustomerId === 'undefined' || typeof arg.unitCustomerId === 'string' ) &&

  true
  );
  }

export function isEmailData(arg: any): arg is models.EmailData {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AdminEMailAddress?: string
    ( typeof arg.AdminEMailAddress === 'undefined' || typeof arg.AdminEMailAddress === 'string' ) &&
    // EMailAddressBCC?: string
    ( typeof arg.EMailAddressBCC === 'undefined' || typeof arg.EMailAddressBCC === 'string' ) &&
    // EMailBody?: string
    ( typeof arg.EMailBody === 'undefined' || typeof arg.EMailBody === 'string' ) &&
    // EMailFrom?: string
    ( typeof arg.EMailFrom === 'undefined' || typeof arg.EMailFrom === 'string' ) &&
    // EmailFromFriendly?: string
    ( typeof arg.EmailFromFriendly === 'undefined' || typeof arg.EmailFromFriendly === 'string' ) &&
    // EMailSubject?: string
    ( typeof arg.EMailSubject === 'undefined' || typeof arg.EMailSubject === 'string' ) &&
    // IsHtmBody?: boolean
    ( typeof arg.IsHtmBody === 'undefined' || typeof arg.IsHtmBody === 'boolean' ) &&
    // Recipient?: string
    ( typeof arg.Recipient === 'undefined' || typeof arg.Recipient === 'string' ) &&
    // RecipientFriendlyName?: string
    ( typeof arg.RecipientFriendlyName === 'undefined' || typeof arg.RecipientFriendlyName === 'string' ) &&
    // ReplyTo?: string
    ( typeof arg.ReplyTo === 'undefined' || typeof arg.ReplyTo === 'string' ) &&

  true
  );
  }

export function isEmailResponse(arg: any): arg is models.EmailResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucced?: boolean
    ( typeof arg.IsSucced === 'undefined' || typeof arg.IsSucced === 'boolean' ) &&

  true
  );
  }

export function isEmailSettingModel(arg: any): arg is models.EmailSettingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // EmailFrom?: string
    ( typeof arg.EmailFrom === 'undefined' || typeof arg.EmailFrom === 'string' ) &&
    // EmailFromFriendly?: string
    ( typeof arg.EmailFromFriendly === 'undefined' || typeof arg.EmailFromFriendly === 'string' ) &&
    // SMSFrom?: string
    ( typeof arg.SMSFrom === 'undefined' || typeof arg.SMSFrom === 'string' ) &&
    // TestEmailAddress?: string
    ( typeof arg.TestEmailAddress === 'undefined' || typeof arg.TestEmailAddress === 'string' ) &&

  true
  );
  }

export function isEventAlarmRelayModel(arg: any): arg is models.EventAlarmRelayModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AlarmRelayID?: number
    ( typeof arg.AlarmRelayID === 'undefined' || typeof arg.AlarmRelayID === 'number' ) &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // RelayOn?: boolean
    ( typeof arg.RelayOn === 'undefined' || typeof arg.RelayOn === 'boolean' ) &&

  true
  );
  }

export function isEventCategoriesModel(arg: any): arg is models.EventCategoriesModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Checked?: boolean
    ( typeof arg.Checked === 'undefined' || typeof arg.Checked === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EventCatID?: number
    ( typeof arg.EventCatID === 'undefined' || typeof arg.EventCatID === 'number' ) &&

  true
  );
  }

export function isEventDescriptionPostModel(arg: any): arg is models.EventDescriptionPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EventAlarmRelays?: EventAlarmRelayModel[]
    ( typeof arg.EventAlarmRelays === 'undefined' || (Array.isArray(arg.EventAlarmRelays) && arg.EventAlarmRelays.every((item: unknown) => isEventAlarmRelayModel(item))) ) &&
    // EventDescriptionID?: number
    ( typeof arg.EventDescriptionID === 'undefined' || typeof arg.EventDescriptionID === 'number' ) &&
    // EventNo?: number
    ( typeof arg.EventNo === 'undefined' || typeof arg.EventNo === 'number' ) &&
    // EventType?: number
    ( typeof arg.EventType === 'undefined' || typeof arg.EventType === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SpokenWords?: string
    ( typeof arg.SpokenWords === 'undefined' || typeof arg.SpokenWords === 'string' ) &&

  true
  );
  }

export function isEventDescriptionViewModel(arg: any): arg is models.EventDescriptionViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AlarmRelays?: AlarmRelay[]
    ( typeof arg.AlarmRelays === 'undefined' || (Array.isArray(arg.AlarmRelays) && arg.AlarmRelays.every((item: unknown) => isAlarmRelay(item))) ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EventDescriptionID?: number
    ( typeof arg.EventDescriptionID === 'undefined' || typeof arg.EventDescriptionID === 'number' ) &&
    // EventNo?: number
    ( typeof arg.EventNo === 'undefined' || typeof arg.EventNo === 'number' ) &&
    // EventType?: number
    ( typeof arg.EventType === 'undefined' || typeof arg.EventType === 'number' ) &&
    // EventTypeName?: string
    ( typeof arg.EventTypeName === 'undefined' || typeof arg.EventTypeName === 'string' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SpokenWords?: string
    ( typeof arg.SpokenWords === 'undefined' || typeof arg.SpokenWords === 'string' ) &&

  true
  );
  }

export function isEventItem(arg: any): arg is models.EventItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Customer?: string
    ( typeof arg.Customer === 'undefined' || typeof arg.Customer === 'string' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EventCategory?: string
    ( typeof arg.EventCategory === 'undefined' || typeof arg.EventCategory === 'string' ) &&
    // EventDate?: string
    ( typeof arg.EventDate === 'undefined' || typeof arg.EventDate === 'string' ) &&
    // EventDescriptionID?: number
    ( typeof arg.EventDescriptionID === 'undefined' || typeof arg.EventDescriptionID === 'number' ) &&
    // EventID?: number
    ( typeof arg.EventID === 'undefined' || typeof arg.EventID === 'number' ) &&
    // UnitNo?: number
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'number' ) &&

  true
  );
  }

export function isEventRequest(arg: any): arg is models.EventRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EventCategory?: string
    ( typeof arg.EventCategory === 'undefined' || typeof arg.EventCategory === 'string' ) &&
    // EventCategoryID?: number
    ( typeof arg.EventCategoryID === 'undefined' || typeof arg.EventCategoryID === 'number' ) &&
    // EventDate?: string
    ( typeof arg.EventDate === 'undefined' || typeof arg.EventDate === 'string' ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // SiteGroupId?: number
    ( typeof arg.SiteGroupId === 'undefined' || typeof arg.SiteGroupId === 'number' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isExportQuickBooksItem(arg: any): arg is models.ExportQuickBooksItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Account?: string
    ( typeof arg.Account === 'undefined' || typeof arg.Account === 'string' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // DepositGroupNo?: number
    ( typeof arg.DepositGroupNo === 'undefined' || typeof arg.DepositGroupNo === 'number' ) &&
    // Memo?: string
    ( typeof arg.Memo === 'undefined' || typeof arg.Memo === 'string' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // TransDate?: string
    ( typeof arg.TransDate === 'undefined' || typeof arg.TransDate === 'string' ) &&
    // TransID?: string
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'string' ) &&
    // TransName?: string
    ( typeof arg.TransName === 'undefined' || typeof arg.TransName === 'string' ) &&

  true
  );
  }

export function isExportQuickBooksRequest(arg: any): arg is models.ExportQuickBooksRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // EndDate?: string
    ( typeof arg.EndDate === 'undefined' || typeof arg.EndDate === 'string' ) &&
    // IncludeAllDates?: boolean
    ( typeof arg.IncludeAllDates === 'undefined' || typeof arg.IncludeAllDates === 'boolean' ) &&
    // IncludeAllSites?: boolean
    ( typeof arg.IncludeAllSites === 'undefined' || typeof arg.IncludeAllSites === 'boolean' ) &&
    // Sites?: number[]
    ( typeof arg.Sites === 'undefined' || (Array.isArray(arg.Sites) && arg.Sites.every((item: unknown) => typeof item === 'number')) ) &&
    // StartDate?: string
    ( typeof arg.StartDate === 'undefined' || typeof arg.StartDate === 'string' ) &&

  true
  );
  }

export function isFeeOptionPostModel(arg: any): arg is models.FeeOptionPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AdminFee?: number
    ( typeof arg.AdminFee === 'undefined' || typeof arg.AdminFee === 'number' ) &&
    // CashRefunds?: boolean
    ( typeof arg.CashRefunds === 'undefined' || typeof arg.CashRefunds === 'boolean' ) &&
    // CashRounding?: boolean
    ( typeof arg.CashRounding === 'undefined' || typeof arg.CashRounding === 'boolean' ) &&
    // NSFCharge?: number
    ( typeof arg.NSFCharge === 'undefined' || typeof arg.NSFCharge === 'number' ) &&
    // ProrateMoveout?: boolean
    ( typeof arg.ProrateMoveout === 'undefined' || typeof arg.ProrateMoveout === 'boolean' ) &&
    // RefundOption?: number
    ( typeof arg.RefundOption === 'undefined' || typeof arg.RefundOption === 'number' ) &&
    // RefundRent?: boolean
    ( typeof arg.RefundRent === 'undefined' || typeof arg.RefundRent === 'boolean' ) &&
    // RoundingMethod?: number
    ( typeof arg.RoundingMethod === 'undefined' || typeof arg.RoundingMethod === 'number' ) &&
    // TransferFee?: number
    ( typeof arg.TransferFee === 'undefined' || typeof arg.TransferFee === 'number' ) &&

  true
  );
  }

export function isFileResponse(arg: any): arg is models.FileResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Base64String?: string
    ( typeof arg.Base64String === 'undefined' || typeof arg.Base64String === 'string' ) &&
    // EmailResponse?: EmailResponse[]
    ( typeof arg.EmailResponse === 'undefined' || (Array.isArray(arg.EmailResponse) && arg.EmailResponse.every((item: unknown) => isEmailResponse(item))) ) &&
    // Extension?: string
    ( typeof arg.Extension === 'undefined' || typeof arg.Extension === 'string' ) &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&

  true
  );
  }

export function isFinanceBalanceRequest(arg: any): arg is models.FinanceBalanceRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // Summarize?: boolean
    ( typeof arg.Summarize === 'undefined' || typeof arg.Summarize === 'boolean' ) &&

  true
  );
  }

export function isFollowUpAction(arg: any): arg is models.FollowUpAction {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ActionID?: number
    ( typeof arg.ActionID === 'undefined' || typeof arg.ActionID === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // LetterID?: number
    ( typeof arg.LetterID === 'undefined' || typeof arg.LetterID === 'number' ) &&
    // MethodNo?: number
    ( typeof arg.MethodNo === 'undefined' || typeof arg.MethodNo === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&

  true
  );
  }

export function isFollowUpActionModel(arg: any): arg is models.FollowUpActionModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ActionID?: number
    ( typeof arg.ActionID === 'undefined' || typeof arg.ActionID === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // LetterID?: number
    ( typeof arg.LetterID === 'undefined' || typeof arg.LetterID === 'number' ) &&
    // MethodNo?: number
    ( typeof arg.MethodNo === 'undefined' || typeof arg.MethodNo === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&

  true
  );
  }

export function isForgotPasswordRequest(arg: any): arg is models.ForgotPasswordRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // EmailAddress?: string
    ( typeof arg.EmailAddress === 'undefined' || typeof arg.EmailAddress === 'string' ) &&
    // RedirectURL?: string
    ( typeof arg.RedirectURL === 'undefined' || typeof arg.RedirectURL === 'string' ) &&

  true
  );
  }

export function isGateAccessDataModel(arg: any): arg is models.GateAccessDataModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AINumber?: number
    ( typeof arg.AINumber === 'undefined' || typeof arg.AINumber === 'number' ) &&
    // PassCode?: number
    ( typeof arg.PassCode === 'undefined' || typeof arg.PassCode === 'number' ) &&

  true
  );
  }

export function isGateConfigModel(arg: any): arg is models.GateConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // GateDirectory?: string
    ( typeof arg.GateDirectory === 'undefined' || typeof arg.GateDirectory === 'string' ) &&
    // GateInterface?: number
    ( typeof arg.GateInterface === 'undefined' || typeof arg.GateInterface === 'number' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SiteName?: string
    ( typeof arg.SiteName === 'undefined' || typeof arg.SiteName === 'string' ) &&
    // SiteNo?: number
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'number' ) &&

  true
  );
  }

export function isGateInfo(arg: any): arg is models.GateInfo {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // GateDesc?: string
    ( typeof arg.GateDesc === 'undefined' || typeof arg.GateDesc === 'string' ) &&
    // KeypadID?: number
    ( typeof arg.KeypadID === 'undefined' || typeof arg.KeypadID === 'number' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&

  true
  );
  }

export function isGateInfoPostModel(arg: any): arg is models.GateInfoPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CurrentDistance?: number
    ( typeof arg.CurrentDistance === 'undefined' || typeof arg.CurrentDistance === 'number' ) &&
    // GateDesc?: string
    ( typeof arg.GateDesc === 'undefined' || typeof arg.GateDesc === 'string' ) &&
    // KeypadID?: number
    ( typeof arg.KeypadID === 'undefined' || typeof arg.KeypadID === 'number' ) &&
    // Latitude?: string
    ( typeof arg.Latitude === 'undefined' || typeof arg.Latitude === 'string' ) &&
    // Longitude?: string
    ( typeof arg.Longitude === 'undefined' || typeof arg.Longitude === 'string' ) &&
    // PSSiteConfigID?: number
    ( typeof arg.PSSiteConfigID === 'undefined' || typeof arg.PSSiteConfigID === 'number' ) &&

  true
  );
  }

export function isGateInterfaceModel(arg: any): arg is models.GateInterfaceModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // GateInterface?: number
    ( typeof arg.GateInterface === 'undefined' || typeof arg.GateInterface === 'number' ) &&
    // Is3rdPartyInterface?: boolean
    ( typeof arg.Is3rdPartyInterface === 'undefined' || typeof arg.Is3rdPartyInterface === 'boolean' ) &&
    // PassCodeLRange?: number
    ( typeof arg.PassCodeLRange === 'undefined' || typeof arg.PassCodeLRange === 'number' ) &&
    // PassCodeURange?: number
    ( typeof arg.PassCodeURange === 'undefined' || typeof arg.PassCodeURange === 'number' ) &&

  true
  );
  }

export function isGenerateReportRequest(arg: any): arg is models.GenerateReportRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // EndingDate?: string
    ( typeof arg.EndingDate === 'undefined' || typeof arg.EndingDate === 'string' ) &&
    // IncludeAllUsers?: boolean
    ( typeof arg.IncludeAllUsers === 'undefined' || typeof arg.IncludeAllUsers === 'boolean' ) &&
    // PSReportList?: SentinelReportListModel[]
    ( typeof arg.PSReportList === 'undefined' || (Array.isArray(arg.PSReportList) && arg.PSReportList.every((item: unknown) => isSentinelReportListModel(item))) ) &&
    // ReportList?: ReportListModel[]
    ( typeof arg.ReportList === 'undefined' || (Array.isArray(arg.ReportList) && arg.ReportList.every((item: unknown) => isReportListModel(item))) ) &&
    // SelectedCustID?: number
    ( typeof arg.SelectedCustID === 'undefined' || typeof arg.SelectedCustID === 'number' ) &&
    // SelectedEventCatIds?: number[]
    ( typeof arg.SelectedEventCatIds === 'undefined' || (Array.isArray(arg.SelectedEventCatIds) && arg.SelectedEventCatIds.every((item: unknown) => typeof item === 'number')) ) &&
    // SelectedUnitID?: number
    ( typeof arg.SelectedUnitID === 'undefined' || typeof arg.SelectedUnitID === 'number' ) &&
    // SelectedUserID?: number
    ( typeof arg.SelectedUserID === 'undefined' || typeof arg.SelectedUserID === 'number' ) &&
    // StartingDate?: string
    ( typeof arg.StartingDate === 'undefined' || typeof arg.StartingDate === 'string' ) &&
    // UseAllDates?: boolean
    ( typeof arg.UseAllDates === 'undefined' || typeof arg.UseAllDates === 'boolean' ) &&

  true
  );
  }

export function isGetAccountActivityRequest(arg: any): arg is models.GetAccountActivityRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessOnlyEvents?: boolean
    ( typeof arg.AccessOnlyEvents === 'undefined' || typeof arg.AccessOnlyEvents === 'boolean' ) &&
    // FromDate?: string
    ( typeof arg.FromDate === 'undefined' || typeof arg.FromDate === 'string' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isGetClosingRequest(arg: any): arg is models.GetClosingRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CloseTypes?: number[]
    ( typeof arg.CloseTypes === 'undefined' || (Array.isArray(arg.CloseTypes) && arg.CloseTypes.every((item: unknown) => typeof item === 'number')) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isGetContactsRequest(arg: any): arg is models.GetContactsRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // FullName?: string
    ( typeof arg.FullName === 'undefined' || typeof arg.FullName === 'string' ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isGetCustomerContractResponse(arg: any): arg is models.GetCustomerContractResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&
    // IsSigned?: boolean
    ( typeof arg.IsSigned === 'undefined' || typeof arg.IsSigned === 'boolean' ) &&
    // LeaseId?: number
    ( typeof arg.LeaseId === 'undefined' || typeof arg.LeaseId === 'number' ) &&
    // TransactionId?: number
    ( typeof arg.TransactionId === 'undefined' || typeof arg.TransactionId === 'number' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isGetCustomersHaveReservedResponse(arg: any): arg is models.GetCustomersHaveReservedResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // MoveInDate?: string
    ( typeof arg.MoveInDate === 'undefined' || typeof arg.MoveInDate === 'string' ) &&
    // ReservedToDate?: string
    ( typeof arg.ReservedToDate === 'undefined' || typeof arg.ReservedToDate === 'string' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // UnitCustomerId?: string
    ( typeof arg.UnitCustomerId === 'undefined' || typeof arg.UnitCustomerId === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isGetGateMaxDistanceRequest(arg: any): arg is models.GetGateMaxDistanceRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // GateDesc?: string
    ( typeof arg.GateDesc === 'undefined' || typeof arg.GateDesc === 'string' ) &&
    // KeypadID?: number
    ( typeof arg.KeypadID === 'undefined' || typeof arg.KeypadID === 'number' ) &&

  true
  );
  }

export function isGetLogsRequest(arg: any): arg is models.GetLogsRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BackgroundJobID?: number
    ( typeof arg.BackgroundJobID === 'undefined' || typeof arg.BackgroundJobID === 'number' ) &&
    // FilterTime?: string
    ( typeof arg.FilterTime === 'undefined' || typeof arg.FilterTime === 'string' ) &&

  true
  );
  }

export function isGetPaymentInfoRequest(arg: any): arg is models.GetPaymentInfoRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // Units?: PaymentInfoUnitItem[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => isPaymentInfoUnitItem(item))) ) &&

  true
  );
  }

export function isGetReportRequest(arg: any): arg is models.GetReportRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // ReportCategories?: number[]
    ( typeof arg.ReportCategories === 'undefined' || (Array.isArray(arg.ReportCategories) && arg.ReportCategories.every((item: unknown) => typeof item === 'number')) ) &&
    // ReportNos?: number[]
    ( typeof arg.ReportNos === 'undefined' || (Array.isArray(arg.ReportNos) && arg.ReportNos.every((item: unknown) => typeof item === 'number')) ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isGetSizesRequest(arg: any): arg is models.GetSizesRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsTenantPortal?: boolean
    ( typeof arg.IsTenantPortal === 'undefined' || typeof arg.IsTenantPortal === 'boolean' ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // SiteConfigId?: number
    ( typeof arg.SiteConfigId === 'undefined' || typeof arg.SiteConfigId === 'number' ) &&
    // SizeType?: '0' | '1' | '2' | '3'
    ( typeof arg.SizeType === 'undefined' || ['0', '1', '2', '3'].includes(arg.SizeType) ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&
    // UnitTypeId?: number
    ( typeof arg.UnitTypeId === 'undefined' || typeof arg.UnitTypeId === 'number' ) &&

  true
  );
  }

export function isGetTenantHistoryRequest(arg: any): arg is models.GetTenantHistoryRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&
    // TransTypes?: string[]
    ( typeof arg.TransTypes === 'undefined' || (Array.isArray(arg.TransTypes) && arg.TransTypes.every((item: unknown) => typeof item === 'string')) ) &&
    // UnitNo?: string[]
    ( typeof arg.UnitNo === 'undefined' || (Array.isArray(arg.UnitNo) && arg.UnitNo.every((item: unknown) => typeof item === 'string')) ) &&

  true
  );
  }

export function isGetTenantRequest(arg: any): arg is models.GetTenantRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsDistinct?: boolean
    ( typeof arg.IsDistinct === 'undefined' || typeof arg.IsDistinct === 'boolean' ) &&
    // IsPastDue?: boolean
    ( typeof arg.IsPastDue === 'undefined' || typeof arg.IsPastDue === 'boolean' ) &&
    // LockStatus?: number
    ( typeof arg.LockStatus === 'undefined' || typeof arg.LockStatus === 'number' ) &&
    // OldTenants?: boolean
    ( typeof arg.OldTenants === 'undefined' || typeof arg.OldTenants === 'boolean' ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // Sizes?: number[]
    ( typeof arg.Sizes === 'undefined' || (Array.isArray(arg.Sizes) && arg.Sizes.every((item: unknown) => typeof item === 'number')) ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&
    // TenantName?: string
    ( typeof arg.TenantName === 'undefined' || typeof arg.TenantName === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitStatuses?: number[]
    ( typeof arg.UnitStatuses === 'undefined' || (Array.isArray(arg.UnitStatuses) && arg.UnitStatuses.every((item: unknown) => typeof item === 'number')) ) &&

  true
  );
  }

export function isGetTransactionRequest(arg: any): arg is models.GetTransactionRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // FromDate?: string
    ( typeof arg.FromDate === 'undefined' || typeof arg.FromDate === 'string' ) &&
    // IsOldTenant?: boolean
    ( typeof arg.IsOldTenant === 'undefined' || typeof arg.IsOldTenant === 'boolean' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&

  true
  );
  }

export function isGetUnitCustomersRequest(arg: any): arg is models.GetUnitCustomersRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Sizes?: number[]
    ( typeof arg.Sizes === 'undefined' || (Array.isArray(arg.Sizes) && arg.Sizes.every((item: unknown) => typeof item === 'number')) ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&
    // Statuses?: number[]
    ( typeof arg.Statuses === 'undefined' || (Array.isArray(arg.Statuses) && arg.Statuses.every((item: unknown) => typeof item === 'number')) ) &&

  true
  );
  }

export function isGetUnitsRequest(arg: any): arg is models.GetUnitsRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsTenantPortal?: boolean
    ( typeof arg.IsTenantPortal === 'undefined' || typeof arg.IsTenantPortal === 'boolean' ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // PromoID?: number
    ( typeof arg.PromoID === 'undefined' || typeof arg.PromoID === 'number' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // SizeType?: '0' | '1' | '2' | '3'
    ( typeof arg.SizeType === 'undefined' || ['0', '1', '2', '3'].includes(arg.SizeType) ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitTypeID?: number
    ( typeof arg.UnitTypeID === 'undefined' || typeof arg.UnitTypeID === 'number' ) &&

  true
  );
  }

export function isGetVacantUnitsRequest(arg: any): arg is models.GetVacantUnitsRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsTenantPortal?: boolean
    ( typeof arg.IsTenantPortal === 'undefined' || typeof arg.IsTenantPortal === 'boolean' ) &&
    // OrderBy?: string
    ( typeof arg.OrderBy === 'undefined' || typeof arg.OrderBy === 'string' ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // PromoID?: number
    ( typeof arg.PromoID === 'undefined' || typeof arg.PromoID === 'number' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // SizeType?: '0' | '1' | '2' | '3'
    ( typeof arg.SizeType === 'undefined' || ['0', '1', '2', '3'].includes(arg.SizeType) ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isGetWaitingListCustomerResponse(arg: any): arg is models.GetWaitingListCustomerResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerSizes?: CustomerSizeModel[]
    ( typeof arg.CustomerSizes === 'undefined' || (Array.isArray(arg.CustomerSizes) && arg.CustomerSizes.every((item: unknown) => isCustomerSizeModel(item))) ) &&
    // CustomerUnits?: CustomerUnitModel[]
    ( typeof arg.CustomerUnits === 'undefined' || (Array.isArray(arg.CustomerUnits) && arg.CustomerUnits.every((item: unknown) => isCustomerUnitModel(item))) ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&

  true
  );
  }

export function isInsuranceConfigModel(arg: any): arg is models.InsuranceConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // CancelDays?: number
    ( typeof arg.CancelDays === 'undefined' || typeof arg.CancelDays === 'number' ) &&
    // Carrier?: string
    ( typeof arg.Carrier === 'undefined' || typeof arg.Carrier === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // Country?: string
    ( typeof arg.Country === 'undefined' || typeof arg.Country === 'string' ) &&
    // InsuranceConfigID?: number
    ( typeof arg.InsuranceConfigID === 'undefined' || typeof arg.InsuranceConfigID === 'number' ) &&
    // InsuranceConfigNo?: number
    ( typeof arg.InsuranceConfigNo === 'undefined' || typeof arg.InsuranceConfigNo === 'number' ) &&
    // InsuranceRates?: InsuranceRate[]
    ( typeof arg.InsuranceRates === 'undefined' || (Array.isArray(arg.InsuranceRates) && arg.InsuranceRates.every((item: unknown) => isInsuranceRate(item))) ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // ProjectNo?: number
    ( typeof arg.ProjectNo === 'undefined' || typeof arg.ProjectNo === 'number' ) &&
    // SiteNo?: number
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'number' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // UnderWriter?: string
    ( typeof arg.UnderWriter === 'undefined' || typeof arg.UnderWriter === 'string' ) &&
    // WarningDays?: number
    ( typeof arg.WarningDays === 'undefined' || typeof arg.WarningDays === 'number' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isInsuranceConfigRequest(arg: any): arg is models.InsuranceConfigRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Active?: boolean
    ( typeof arg.Active === 'undefined' || typeof arg.Active === 'boolean' ) &&
    // CancelDays?: number
    ( typeof arg.CancelDays === 'undefined' || typeof arg.CancelDays === 'number' ) &&
    // Carrier?: string
    ( typeof arg.Carrier === 'undefined' || typeof arg.Carrier === 'string' ) &&
    // CommissionAmt?: number
    ( typeof arg.CommissionAmt === 'undefined' || typeof arg.CommissionAmt === 'number' ) &&
    // CommissionType?: number
    ( typeof arg.CommissionType === 'undefined' || typeof arg.CommissionType === 'number' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // InsAddr1?: string
    ( typeof arg.InsAddr1 === 'undefined' || typeof arg.InsAddr1 === 'string' ) &&
    // InsAddr2?: string
    ( typeof arg.InsAddr2 === 'undefined' || typeof arg.InsAddr2 === 'string' ) &&
    // InsCity?: string
    ( typeof arg.InsCity === 'undefined' || typeof arg.InsCity === 'string' ) &&
    // InsuranceConfigID?: number
    ( typeof arg.InsuranceConfigID === 'undefined' || typeof arg.InsuranceConfigID === 'number' ) &&
    // InsuranceConfigNo?: number
    ( typeof arg.InsuranceConfigNo === 'undefined' || typeof arg.InsuranceConfigNo === 'number' ) &&
    // InsuranceRates?: InsuranceRate[]
    ( typeof arg.InsuranceRates === 'undefined' || (Array.isArray(arg.InsuranceRates) && arg.InsuranceRates.every((item: unknown) => isInsuranceRate(item))) ) &&
    // NoticeCopies?: number
    ( typeof arg.NoticeCopies === 'undefined' || typeof arg.NoticeCopies === 'number' ) &&
    // OneInsRate?: boolean
    ( typeof arg.OneInsRate === 'undefined' || typeof arg.OneInsRate === 'boolean' ) &&
    // PayableAccountID?: number
    ( typeof arg.PayableAccountID === 'undefined' || typeof arg.PayableAccountID === 'number' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // PrepaidAccountID?: number
    ( typeof arg.PrepaidAccountID === 'undefined' || typeof arg.PrepaidAccountID === 'number' ) &&
    // ProjectNo?: number
    ( typeof arg.ProjectNo === 'undefined' || typeof arg.ProjectNo === 'number' ) &&
    // ProrateIns?: boolean
    ( typeof arg.ProrateIns === 'undefined' || typeof arg.ProrateIns === 'boolean' ) &&
    // ProrateInsOnMoveout?: boolean
    ( typeof arg.ProrateInsOnMoveout === 'undefined' || typeof arg.ProrateInsOnMoveout === 'boolean' ) &&
    // ReceivableAccountID?: number
    ( typeof arg.ReceivableAccountID === 'undefined' || typeof arg.ReceivableAccountID === 'number' ) &&
    // SiteNo?: number
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'number' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // UnderWriter?: string
    ( typeof arg.UnderWriter === 'undefined' || typeof arg.UnderWriter === 'string' ) &&
    // UserConfigurable?: boolean
    ( typeof arg.UserConfigurable === 'undefined' || typeof arg.UserConfigurable === 'boolean' ) &&
    // WarningDays?: number
    ( typeof arg.WarningDays === 'undefined' || typeof arg.WarningDays === 'number' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isInsuranceConfigResponse(arg: any): arg is models.InsuranceConfigResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Active?: boolean
    ( typeof arg.Active === 'undefined' || typeof arg.Active === 'boolean' ) &&
    // CancelDays?: number
    ( typeof arg.CancelDays === 'undefined' || typeof arg.CancelDays === 'number' ) &&
    // Carrier?: string
    ( typeof arg.Carrier === 'undefined' || typeof arg.Carrier === 'string' ) &&
    // CommissionAmt?: number
    ( typeof arg.CommissionAmt === 'undefined' || typeof arg.CommissionAmt === 'number' ) &&
    // CommissionType?: number
    ( typeof arg.CommissionType === 'undefined' || typeof arg.CommissionType === 'number' ) &&
    // Country?: string
    ( typeof arg.Country === 'undefined' || typeof arg.Country === 'string' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // InsAddr1?: string
    ( typeof arg.InsAddr1 === 'undefined' || typeof arg.InsAddr1 === 'string' ) &&
    // InsAddr2?: string
    ( typeof arg.InsAddr2 === 'undefined' || typeof arg.InsAddr2 === 'string' ) &&
    // InsCity?: string
    ( typeof arg.InsCity === 'undefined' || typeof arg.InsCity === 'string' ) &&
    // InsuranceConfigID?: number
    ( typeof arg.InsuranceConfigID === 'undefined' || typeof arg.InsuranceConfigID === 'number' ) &&
    // InsuranceConfigNo?: number
    ( typeof arg.InsuranceConfigNo === 'undefined' || typeof arg.InsuranceConfigNo === 'number' ) &&
    // InsuranceRates?: InsuranceRate[]
    ( typeof arg.InsuranceRates === 'undefined' || (Array.isArray(arg.InsuranceRates) && arg.InsuranceRates.every((item: unknown) => isInsuranceRate(item))) ) &&
    // NoticeCopies?: number
    ( typeof arg.NoticeCopies === 'undefined' || typeof arg.NoticeCopies === 'number' ) &&
    // OneInsRate?: boolean
    ( typeof arg.OneInsRate === 'undefined' || typeof arg.OneInsRate === 'boolean' ) &&
    // PayableAccount?: string
    ( typeof arg.PayableAccount === 'undefined' || typeof arg.PayableAccount === 'string' ) &&
    // PayableAccountID?: number
    ( typeof arg.PayableAccountID === 'undefined' || typeof arg.PayableAccountID === 'number' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // PrepaidAccount?: string
    ( typeof arg.PrepaidAccount === 'undefined' || typeof arg.PrepaidAccount === 'string' ) &&
    // PrepaidAccountID?: number
    ( typeof arg.PrepaidAccountID === 'undefined' || typeof arg.PrepaidAccountID === 'number' ) &&
    // ProjectNo?: number
    ( typeof arg.ProjectNo === 'undefined' || typeof arg.ProjectNo === 'number' ) &&
    // ProrateIns?: boolean
    ( typeof arg.ProrateIns === 'undefined' || typeof arg.ProrateIns === 'boolean' ) &&
    // ProrateInsOnMoveout?: boolean
    ( typeof arg.ProrateInsOnMoveout === 'undefined' || typeof arg.ProrateInsOnMoveout === 'boolean' ) &&
    // ReceivableAccount?: string
    ( typeof arg.ReceivableAccount === 'undefined' || typeof arg.ReceivableAccount === 'string' ) &&
    // ReceivableAccountID?: number
    ( typeof arg.ReceivableAccountID === 'undefined' || typeof arg.ReceivableAccountID === 'number' ) &&
    // SiteNo?: number
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'number' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // UnderWriter?: string
    ( typeof arg.UnderWriter === 'undefined' || typeof arg.UnderWriter === 'string' ) &&
    // UserConfigurable?: boolean
    ( typeof arg.UserConfigurable === 'undefined' || typeof arg.UserConfigurable === 'boolean' ) &&
    // WarningDays?: number
    ( typeof arg.WarningDays === 'undefined' || typeof arg.WarningDays === 'number' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isInsuranceModel(arg: any): arg is models.InsuranceModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CarrierName?: string
    ( typeof arg.CarrierName === 'undefined' || typeof arg.CarrierName === 'string' ) &&
    // InsCoverage?: number
    ( typeof arg.InsCoverage === 'undefined' || typeof arg.InsCoverage === 'number' ) &&
    // InsPaidtoDate?: string
    ( typeof arg.InsPaidtoDate === 'undefined' || typeof arg.InsPaidtoDate === 'string' ) &&
    // InsPolicyNo?: string
    ( typeof arg.InsPolicyNo === 'undefined' || typeof arg.InsPolicyNo === 'string' ) &&
    // InsPremium?: number
    ( typeof arg.InsPremium === 'undefined' || typeof arg.InsPremium === 'number' ) &&
    // InsStartDate?: string
    ( typeof arg.InsStartDate === 'undefined' || typeof arg.InsStartDate === 'string' ) &&
    // InsTheftPercent?: number
    ( typeof arg.InsTheftPercent === 'undefined' || typeof arg.InsTheftPercent === 'number' ) &&
    // InsuranceConfigId?: number
    ( typeof arg.InsuranceConfigId === 'undefined' || typeof arg.InsuranceConfigId === 'number' ) &&
    // InsuranceStatus?: string
    ( typeof arg.InsuranceStatus === 'undefined' || typeof arg.InsuranceStatus === 'string' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // RateNo?: number
    ( typeof arg.RateNo === 'undefined' || typeof arg.RateNo === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isInsurancePaymentRequest(arg: any): arg is models.InsurancePaymentRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AchPaymentAmount?: number
    ( typeof arg.AchPaymentAmount === 'undefined' || typeof arg.AchPaymentAmount === 'number' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankNo?: string
    ( typeof arg.BankNo === 'undefined' || typeof arg.BankNo === 'string' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // CashPaymentAmount?: number
    ( typeof arg.CashPaymentAmount === 'undefined' || typeof arg.CashPaymentAmount === 'number' ) &&
    // CheckNo?: string
    ( typeof arg.CheckNo === 'undefined' || typeof arg.CheckNo === 'string' ) &&
    // CheckPaymentAmount?: number
    ( typeof arg.CheckPaymentAmount === 'undefined' || typeof arg.CheckPaymentAmount === 'number' ) &&
    // ClearentJWTToken?: string
    ( typeof arg.ClearentJWTToken === 'undefined' || typeof arg.ClearentJWTToken === 'string' ) &&
    // CreditCardPaymentAmount?: number
    ( typeof arg.CreditCardPaymentAmount === 'undefined' || typeof arg.CreditCardPaymentAmount === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // InsPaidDate?: string
    ( typeof arg.InsPaidDate === 'undefined' || typeof arg.InsPaidDate === 'string' ) &&
    // InsPolicyNo?: string
    ( typeof arg.InsPolicyNo === 'undefined' || typeof arg.InsPolicyNo === 'string' ) &&
    // InsStartDate?: string
    ( typeof arg.InsStartDate === 'undefined' || typeof arg.InsStartDate === 'string' ) &&
    // InsuranceConfigId?: number
    ( typeof arg.InsuranceConfigId === 'undefined' || typeof arg.InsuranceConfigId === 'number' ) &&
    // InsuranceSelected?: boolean
    ( typeof arg.InsuranceSelected === 'undefined' || typeof arg.InsuranceSelected === 'boolean' ) &&
    // IsAutoBill?: boolean
    ( typeof arg.IsAutoBill === 'undefined' || typeof arg.IsAutoBill === 'boolean' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // PayerId?: string
    ( typeof arg.PayerId === 'undefined' || typeof arg.PayerId === 'string' ) &&
    // RateNo?: number
    ( typeof arg.RateNo === 'undefined' || typeof arg.RateNo === 'number' ) &&
    // SavePaymentInfo?: boolean
    ( typeof arg.SavePaymentInfo === 'undefined' || typeof arg.SavePaymentInfo === 'boolean' ) &&
    // SubmittedTransactionIdentifier?: string
    ( typeof arg.SubmittedTransactionIdentifier === 'undefined' || typeof arg.SubmittedTransactionIdentifier === 'string' ) &&
    // TransactionIdentifier?: string
    ( typeof arg.TransactionIdentifier === 'undefined' || typeof arg.TransactionIdentifier === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // UseCardOnFile?: boolean
    ( typeof arg.UseCardOnFile === 'undefined' || typeof arg.UseCardOnFile === 'boolean' ) &&

  true
  );
  }

export function isInsuranceRate(arg: any): arg is models.InsuranceRate {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // Coverage?: number
    ( typeof arg.Coverage === 'undefined' || typeof arg.Coverage === 'number' ) &&
    // Premium?: number
    ( typeof arg.Premium === 'undefined' || typeof arg.Premium === 'number' ) &&
    // RateNo?: number
    ( typeof arg.RateNo === 'undefined' || typeof arg.RateNo === 'number' ) &&
    // TheftPercent?: number
    ( typeof arg.TheftPercent === 'undefined' || typeof arg.TheftPercent === 'number' ) &&

  true
  );
  }

export function isInsuranceTransactionModel(arg: any): arg is models.InsuranceTransactionModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AmountPaid?: number
    ( typeof arg.AmountPaid === 'undefined' || typeof arg.AmountPaid === 'number' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // Coverage?: number
    ( typeof arg.Coverage === 'undefined' || typeof arg.Coverage === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerName?: string
    ( typeof arg.CustomerName === 'undefined' || typeof arg.CustomerName === 'string' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // InsuranceCarrier?: string
    ( typeof arg.InsuranceCarrier === 'undefined' || typeof arg.InsuranceCarrier === 'string' ) &&
    // PaidtoDate?: string
    ( typeof arg.PaidtoDate === 'undefined' || typeof arg.PaidtoDate === 'string' ) &&
    // PolicyNo?: string
    ( typeof arg.PolicyNo === 'undefined' || typeof arg.PolicyNo === 'string' ) &&
    // Premium?: number
    ( typeof arg.Premium === 'undefined' || typeof arg.Premium === 'number' ) &&
    // ProjectNo?: number
    ( typeof arg.ProjectNo === 'undefined' || typeof arg.ProjectNo === 'number' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // TheftPercent?: number
    ( typeof arg.TheftPercent === 'undefined' || typeof arg.TheftPercent === 'number' ) &&
    // TransDate?: string
    ( typeof arg.TransDate === 'undefined' || typeof arg.TransDate === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // TransType?: string
    ( typeof arg.TransType === 'undefined' || typeof arg.TransType === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isInsuranceTransDetailResponse(arg: any): arg is models.InsuranceTransDetailResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // PastDue?: boolean
    ( typeof arg.PastDue === 'undefined' || typeof arg.PastDue === 'boolean' ) &&
    // PaymentAmount?: number
    ( typeof arg.PaymentAmount === 'undefined' || typeof arg.PaymentAmount === 'number' ) &&
    // TransDetails?: TransactionHistoryDetail[]
    ( typeof arg.TransDetails === 'undefined' || (Array.isArray(arg.TransDetails) && arg.TransDetails.every((item: unknown) => isTransactionHistoryDetail(item))) ) &&

  true
  );
  }

export function isInvertory(arg: any): arg is models.Invertory {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // InventoryNo?: number
    ( typeof arg.InventoryNo === 'undefined' || typeof arg.InventoryNo === 'number' ) &&

  true
  );
  }

export function isKioskOptionModel(arg: any): arg is models.KioskOptionModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AllowLienStatusPayments?: boolean
    ( typeof arg.AllowLienStatusPayments === 'undefined' || typeof arg.AllowLienStatusPayments === 'boolean' ) &&
    // AllowZeroAmountReservations?: boolean
    ( typeof arg.AllowZeroAmountReservations === 'undefined' || typeof arg.AllowZeroAmountReservations === 'boolean' ) &&
    // MaxReserveDays?: number
    ( typeof arg.MaxReserveDays === 'undefined' || typeof arg.MaxReserveDays === 'number' ) &&
    // MinProrateDays?: number
    ( typeof arg.MinProrateDays === 'undefined' || typeof arg.MinProrateDays === 'number' ) &&

  true
  );
  }

export function isLateCycleModel(arg: any): arg is models.LateCycleModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AuctionDate?: string
    ( typeof arg.AuctionDate === 'undefined' || typeof arg.AuctionDate === 'string' ) &&
    // AuctionLocationID?: number
    ( typeof arg.AuctionLocationID === 'undefined' || typeof arg.AuctionLocationID === 'number' ) &&
    // DaysToLien?: number
    ( typeof arg.DaysToLien === 'undefined' || typeof arg.DaysToLien === 'number' ) &&
    // DaysToOverlock?: number
    ( typeof arg.DaysToOverlock === 'undefined' || typeof arg.DaysToOverlock === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // LateCycleID?: number
    ( typeof arg.LateCycleID === 'undefined' || typeof arg.LateCycleID === 'number' ) &&
    // LateCycleNo?: number
    ( typeof arg.LateCycleNo === 'undefined' || typeof arg.LateCycleNo === 'number' ) &&
    // LateFeeSchedules?: LateFeeScheduleModel[]
    ( typeof arg.LateFeeSchedules === 'undefined' || (Array.isArray(arg.LateFeeSchedules) && arg.LateFeeSchedules.every((item: unknown) => isLateFeeScheduleModel(item))) ) &&
    // LateNoticeSchedules?: LateNoticeScheduleModel[]
    ( typeof arg.LateNoticeSchedules === 'undefined' || (Array.isArray(arg.LateNoticeSchedules) && arg.LateNoticeSchedules.every((item: unknown) => isLateNoticeScheduleModel(item))) ) &&
    // MinLateFeeBalance?: number
    ( typeof arg.MinLateFeeBalance === 'undefined' || typeof arg.MinLateFeeBalance === 'number' ) &&
    // MinOverLockBalance?: number
    ( typeof arg.MinOverLockBalance === 'undefined' || typeof arg.MinOverLockBalance === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&

  true
  );
  }

export function isLateCycleRequest(arg: any): arg is models.LateCycleRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // DaysToOverlock?: number
    ( typeof arg.DaysToOverlock === 'undefined' || typeof arg.DaysToOverlock === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // IsAdminMode?: boolean
    ( typeof arg.IsAdminMode === 'undefined' || typeof arg.IsAdminMode === 'boolean' ) &&
    // LateCycleID?: number
    ( typeof arg.LateCycleID === 'undefined' || typeof arg.LateCycleID === 'number' ) &&
    // LateCycleNo?: number
    ( typeof arg.LateCycleNo === 'undefined' || typeof arg.LateCycleNo === 'number' ) &&
    // LateFeeSchedules?: LateFeeScheduleModel[]
    ( typeof arg.LateFeeSchedules === 'undefined' || (Array.isArray(arg.LateFeeSchedules) && arg.LateFeeSchedules.every((item: unknown) => isLateFeeScheduleModel(item))) ) &&
    // LateNoticeSchedules?: LateNoticeScheduleRequestItem[]
    ( typeof arg.LateNoticeSchedules === 'undefined' || (Array.isArray(arg.LateNoticeSchedules) && arg.LateNoticeSchedules.every((item: unknown) => isLateNoticeScheduleRequestItem(item))) ) &&
    // MinLateFeeBalance?: number
    ( typeof arg.MinLateFeeBalance === 'undefined' || typeof arg.MinLateFeeBalance === 'number' ) &&
    // MinOverLockBalance?: number
    ( typeof arg.MinOverLockBalance === 'undefined' || typeof arg.MinOverLockBalance === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&

  true
  );
  }

export function isLateCycleScheduleModel(arg: any): arg is models.LateCycleScheduleModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&

  true
  );
  }

export function isLateFeeScheduleModel(arg: any): arg is models.LateFeeScheduleModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // CycleNo?: number
    ( typeof arg.CycleNo === 'undefined' || typeof arg.CycleNo === 'number' ) &&
    // DaysLate?: number
    ( typeof arg.DaysLate === 'undefined' || typeof arg.DaysLate === 'number' ) &&
    // IsApply?: boolean
    ( typeof arg.IsApply === 'undefined' || typeof arg.IsApply === 'boolean' ) &&
    // LateCycleID?: number
    ( typeof arg.LateCycleID === 'undefined' || typeof arg.LateCycleID === 'number' ) &&
    // LateFeeScheduleType?: number
    ( typeof arg.LateFeeScheduleType === 'undefined' || typeof arg.LateFeeScheduleType === 'number' ) &&
    // LateFeeScheduleTypename?: string
    ( typeof arg.LateFeeScheduleTypename === 'undefined' || typeof arg.LateFeeScheduleTypename === 'string' ) &&
    // LateFeeType?: number
    ( typeof arg.LateFeeType === 'undefined' || typeof arg.LateFeeType === 'number' ) &&
    // LateFeeTypeName?: string
    ( typeof arg.LateFeeTypeName === 'undefined' || typeof arg.LateFeeTypeName === 'string' ) &&

  true
  );
  }

export function isLateNoticeScheduleModel(arg: any): arg is models.LateNoticeScheduleModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CycleNo?: number
    ( typeof arg.CycleNo === 'undefined' || typeof arg.CycleNo === 'number' ) &&
    // DaysLate?: number
    ( typeof arg.DaysLate === 'undefined' || typeof arg.DaysLate === 'number' ) &&
    // IsApply?: boolean
    ( typeof arg.IsApply === 'undefined' || typeof arg.IsApply === 'boolean' ) &&
    // LateCycleID?: number
    ( typeof arg.LateCycleID === 'undefined' || typeof arg.LateCycleID === 'number' ) &&
    // PrimaryLetter?: string
    ( typeof arg.PrimaryLetter === 'undefined' || typeof arg.PrimaryLetter === 'string' ) &&
    // PrimaryLetterID?: number
    ( typeof arg.PrimaryLetterID === 'undefined' || typeof arg.PrimaryLetterID === 'number' ) &&
    // SecondaryLetter?: string
    ( typeof arg.SecondaryLetter === 'undefined' || typeof arg.SecondaryLetter === 'string' ) &&
    // SecondaryLetterID?: number
    ( typeof arg.SecondaryLetterID === 'undefined' || typeof arg.SecondaryLetterID === 'number' ) &&

  true
  );
  }

export function isLateNoticeScheduleRequestItem(arg: any): arg is models.LateNoticeScheduleRequestItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CycleNo?: number
    ( typeof arg.CycleNo === 'undefined' || typeof arg.CycleNo === 'number' ) &&
    // DaysLate?: number
    ( typeof arg.DaysLate === 'undefined' || typeof arg.DaysLate === 'number' ) &&
    // IsApply?: boolean
    ( typeof arg.IsApply === 'undefined' || typeof arg.IsApply === 'boolean' ) &&
    // LateCycleID?: number
    ( typeof arg.LateCycleID === 'undefined' || typeof arg.LateCycleID === 'number' ) &&
    // PrimaryLetterID?: number
    ( typeof arg.PrimaryLetterID === 'undefined' || typeof arg.PrimaryLetterID === 'number' ) &&
    // SecondaryLetterID?: number
    ( typeof arg.SecondaryLetterID === 'undefined' || typeof arg.SecondaryLetterID === 'number' ) &&

  true
  );
  }

export function isLatestLeadsResponse(arg: any): arg is models.LatestLeadsResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // EntryDate?: string
    ( typeof arg.EntryDate === 'undefined' || typeof arg.EntryDate === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // SizeName?: string
    ( typeof arg.SizeName === 'undefined' || typeof arg.SizeName === 'string' ) &&

  true
  );
  }

export function isLeaseItem(arg: any): arg is models.LeaseItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsLock?: boolean
    ( typeof arg.IsLock === 'undefined' || typeof arg.IsLock === 'boolean' ) &&
    // LeaseId?: number
    ( typeof arg.LeaseId === 'undefined' || typeof arg.LeaseId === 'number' ) &&

  true
  );
  }

export function isLeaseStatusModel(arg: any): arg is models.LeaseStatusModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessControlOnly?: boolean
    ( typeof arg.AccessControlOnly === 'undefined' || typeof arg.AccessControlOnly === 'boolean' ) &&
    // Complimentary?: boolean
    ( typeof arg.Complimentary === 'undefined' || typeof arg.Complimentary === 'boolean' ) &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // IsMultipleUnitEnable?: boolean
    ( typeof arg.IsMultipleUnitEnable === 'undefined' || typeof arg.IsMultipleUnitEnable === 'boolean' ) &&
    // Message?: string
    ( typeof arg.Message === 'undefined' || typeof arg.Message === 'string' ) &&
    // Rented?: boolean
    ( typeof arg.Rented === 'undefined' || typeof arg.Rented === 'boolean' ) &&
    // Status?: string
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // VacantRentable?: boolean
    ( typeof arg.VacantRentable === 'undefined' || typeof arg.VacantRentable === 'boolean' ) &&
    // VacantUnRentable?: boolean
    ( typeof arg.VacantUnRentable === 'undefined' || typeof arg.VacantUnRentable === 'boolean' ) &&

  true
  );
  }

export function isLetterCategoryOutputTypeModel(arg: any): arg is models.LetterCategoryOutputTypeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CategoryID?: number
    ( typeof arg.CategoryID === 'undefined' || typeof arg.CategoryID === 'number' ) &&
    // CategoryName?: string
    ( typeof arg.CategoryName === 'undefined' || typeof arg.CategoryName === 'string' ) &&
    // UseEmail?: boolean
    ( typeof arg.UseEmail === 'undefined' || typeof arg.UseEmail === 'boolean' ) &&
    // UsePrinter?: boolean
    ( typeof arg.UsePrinter === 'undefined' || typeof arg.UsePrinter === 'boolean' ) &&
    // UseSms?: boolean
    ( typeof arg.UseSms === 'undefined' || typeof arg.UseSms === 'boolean' ) &&

  true
  );
  }

export function isLetterCorrespondenceModel(arg: any): arg is models.LetterCorrespondenceModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AttachmentFormat?: string
    ( typeof arg.AttachmentFormat === 'undefined' || typeof arg.AttachmentFormat === 'string' ) &&
    // EmailSendCopyAddress?: string
    ( typeof arg.EmailSendCopyAddress === 'undefined' || typeof arg.EmailSendCopyAddress === 'string' ) &&
    // LetterCategoryOutputTypes?: LetterCategoryOutputTypeModel[]
    ( typeof arg.LetterCategoryOutputTypes === 'undefined' || (Array.isArray(arg.LetterCategoryOutputTypes) && arg.LetterCategoryOutputTypes.every((item: unknown) => isLetterCategoryOutputTypeModel(item))) ) &&
    // SendCopy?: boolean
    ( typeof arg.SendCopy === 'undefined' || typeof arg.SendCopy === 'boolean' ) &&
    // TestEmailAddress?: string
    ( typeof arg.TestEmailAddress === 'undefined' || typeof arg.TestEmailAddress === 'string' ) &&
    // TestMode?: boolean
    ( typeof arg.TestMode === 'undefined' || typeof arg.TestMode === 'boolean' ) &&
    // TestSMSCarrierID?: number
    ( typeof arg.TestSMSCarrierID === 'undefined' || typeof arg.TestSMSCarrierID === 'number' ) &&
    // TestSMSNumber?: string
    ( typeof arg.TestSMSNumber === 'undefined' || typeof arg.TestSMSNumber === 'string' ) &&
    // UseInlineText?: boolean
    ( typeof arg.UseInlineText === 'undefined' || typeof arg.UseInlineText === 'boolean' ) &&
    // UseWord?: boolean
    ( typeof arg.UseWord === 'undefined' || typeof arg.UseWord === 'boolean' ) &&

  true
  );
  }

export function isLetterFileNamePostModel(arg: any): arg is models.LetterFileNamePostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&
    // OutputType?: number
    ( typeof arg.OutputType === 'undefined' || typeof arg.OutputType === 'number' ) &&
    // TemplateType?: number
    ( typeof arg.TemplateType === 'undefined' || typeof arg.TemplateType === 'number' ) &&

  true
  );
  }

export function isLetterFileNameViewModel(arg: any): arg is models.LetterFileNameViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&
    // LetterID?: number
    ( typeof arg.LetterID === 'undefined' || typeof arg.LetterID === 'number' ) &&
    // OutputType?: number
    ( typeof arg.OutputType === 'undefined' || typeof arg.OutputType === 'number' ) &&
    // OutputTypeName?: string
    ( typeof arg.OutputTypeName === 'undefined' || typeof arg.OutputTypeName === 'string' ) &&
    // TemplateType?: number
    ( typeof arg.TemplateType === 'undefined' || typeof arg.TemplateType === 'number' ) &&
    // TemplateTypeName?: string
    ( typeof arg.TemplateTypeName === 'undefined' || typeof arg.TemplateTypeName === 'string' ) &&

  true
  );
  }

export function isLetterFilterRequest(arg: any): arg is models.LetterFilterRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Extension?: string
    ( typeof arg.Extension === 'undefined' || typeof arg.Extension === 'string' ) &&

  true
  );
  }

export function isLetterPostModel(arg: any): arg is models.LetterPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // DefaultRecordSelectionFormula?: string
    ( typeof arg.DefaultRecordSelectionFormula === 'undefined' || typeof arg.DefaultRecordSelectionFormula === 'string' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // EMailBody?: string
    ( typeof arg.EMailBody === 'undefined' || typeof arg.EMailBody === 'string' ) &&
    // EMailSubjectLine?: string
    ( typeof arg.EMailSubjectLine === 'undefined' || typeof arg.EMailSubjectLine === 'string' ) &&
    // LetterCategory?: number
    ( typeof arg.LetterCategory === 'undefined' || typeof arg.LetterCategory === 'number' ) &&
    // LetterFileNames?: LetterFileNamePostModel[]
    ( typeof arg.LetterFileNames === 'undefined' || (Array.isArray(arg.LetterFileNames) && arg.LetterFileNames.every((item: unknown) => isLetterFileNamePostModel(item))) ) &&
    // LetterID?: number
    ( typeof arg.LetterID === 'undefined' || typeof arg.LetterID === 'number' ) &&
    // LetterNo?: number
    ( typeof arg.LetterNo === 'undefined' || typeof arg.LetterNo === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SystemRow?: boolean
    ( typeof arg.SystemRow === 'undefined' || typeof arg.SystemRow === 'boolean' ) &&
    // Title?: string
    ( typeof arg.Title === 'undefined' || typeof arg.Title === 'string' ) &&

  true
  );
  }

export function isLetterPrintRequest(arg: any): arg is models.LetterPrintRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // EmailAddress?: string
    ( typeof arg.EmailAddress === 'undefined' || typeof arg.EmailAddress === 'string' ) &&
    // EmailBody?: string
    ( typeof arg.EmailBody === 'undefined' || typeof arg.EmailBody === 'string' ) &&
    // EmailSubject?: string
    ( typeof arg.EmailSubject === 'undefined' || typeof arg.EmailSubject === 'string' ) &&
    // IsPrint?: boolean
    ( typeof arg.IsPrint === 'undefined' || typeof arg.IsPrint === 'boolean' ) &&
    // IsPrintMultipleUnits?: boolean
    ( typeof arg.IsPrintMultipleUnits === 'undefined' || typeof arg.IsPrintMultipleUnits === 'boolean' ) &&
    // IsSecondaryCustomer?: boolean
    ( typeof arg.IsSecondaryCustomer === 'undefined' || typeof arg.IsSecondaryCustomer === 'boolean' ) &&
    // IsSendMail?: boolean
    ( typeof arg.IsSendMail === 'undefined' || typeof arg.IsSendMail === 'boolean' ) &&
    // IsSendSms?: boolean
    ( typeof arg.IsSendSms === 'undefined' || typeof arg.IsSendSms === 'boolean' ) &&
    // LeaseId?: number
    ( typeof arg.LeaseId === 'undefined' || typeof arg.LeaseId === 'number' ) &&
    // LetterId?: number
    ( typeof arg.LetterId === 'undefined' || typeof arg.LetterId === 'number' ) &&
    // SmsCarrierId?: number
    ( typeof arg.SmsCarrierId === 'undefined' || typeof arg.SmsCarrierId === 'number' ) &&
    // SmsNumber?: string
    ( typeof arg.SmsNumber === 'undefined' || typeof arg.SmsNumber === 'string' ) &&
    // UseInlineText?: boolean
    ( typeof arg.UseInlineText === 'undefined' || typeof arg.UseInlineText === 'boolean' ) &&

  true
  );
  }

export function isLetterRequest(arg: any): arg is models.LetterRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // LetterCategory?: number
    ( typeof arg.LetterCategory === 'undefined' || typeof arg.LetterCategory === 'number' ) &&
    // SecondaryCustomerID?: number
    ( typeof arg.SecondaryCustomerID === 'undefined' || typeof arg.SecondaryCustomerID === 'number' ) &&

  true
  );
  }

export function isLetterViewModel(arg: any): arg is models.LetterViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // DefaultRecordSelectionFormula?: string
    ( typeof arg.DefaultRecordSelectionFormula === 'undefined' || typeof arg.DefaultRecordSelectionFormula === 'string' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // EMailBody?: string
    ( typeof arg.EMailBody === 'undefined' || typeof arg.EMailBody === 'string' ) &&
    // EMailSubjectLine?: string
    ( typeof arg.EMailSubjectLine === 'undefined' || typeof arg.EMailSubjectLine === 'string' ) &&
    // LetterCategory?: number
    ( typeof arg.LetterCategory === 'undefined' || typeof arg.LetterCategory === 'number' ) &&
    // LetterCategoryName?: string
    ( typeof arg.LetterCategoryName === 'undefined' || typeof arg.LetterCategoryName === 'string' ) &&
    // LetterID?: number
    ( typeof arg.LetterID === 'undefined' || typeof arg.LetterID === 'number' ) &&
    // LetterNo?: number
    ( typeof arg.LetterNo === 'undefined' || typeof arg.LetterNo === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SystemRow?: boolean
    ( typeof arg.SystemRow === 'undefined' || typeof arg.SystemRow === 'boolean' ) &&
    // Title?: string
    ( typeof arg.Title === 'undefined' || typeof arg.Title === 'string' ) &&

  true
  );
  }

export function isLienChargeRequestItem(arg: any): arg is models.LienChargeRequestItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountID?: number
    ( typeof arg.AccountID === 'undefined' || typeof arg.AccountID === 'number' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // IsApply?: boolean
    ( typeof arg.IsApply === 'undefined' || typeof arg.IsApply === 'boolean' ) &&
    // OldAccountID?: number
    ( typeof arg.OldAccountID === 'undefined' || typeof arg.OldAccountID === 'number' ) &&

  true
  );
  }

export function isLienChargeResponse(arg: any): arg is models.LienChargeResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AuctionDate?: string
    ( typeof arg.AuctionDate === 'undefined' || typeof arg.AuctionDate === 'string' ) &&
    // AuctionLocation?: string
    ( typeof arg.AuctionLocation === 'undefined' || typeof arg.AuctionLocation === 'string' ) &&
    // AuctionLocationID?: number
    ( typeof arg.AuctionLocationID === 'undefined' || typeof arg.AuctionLocationID === 'number' ) &&
    // DaysToLien?: number
    ( typeof arg.DaysToLien === 'undefined' || typeof arg.DaysToLien === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // LateCycleID?: number
    ( typeof arg.LateCycleID === 'undefined' || typeof arg.LateCycleID === 'number' ) &&
    // LateCycleNo?: number
    ( typeof arg.LateCycleNo === 'undefined' || typeof arg.LateCycleNo === 'number' ) &&
    // List?: LienListResponse[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isLienListResponse(item))) ) &&

  true
  );
  }

export function isLienListResponse(arg: any): arg is models.LienListResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountID?: number
    ( typeof arg.AccountID === 'undefined' || typeof arg.AccountID === 'number' ) &&
    // AccountNo?: number
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'number' ) &&
    // AccountTitle?: string
    ( typeof arg.AccountTitle === 'undefined' || typeof arg.AccountTitle === 'string' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&

  true
  );
  }

export function isLienPostRequest(arg: any): arg is models.LienPostRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AchPaymentAmount?: number
    ( typeof arg.AchPaymentAmount === 'undefined' || typeof arg.AchPaymentAmount === 'number' ) &&
    // AuctionDate?: string
    ( typeof arg.AuctionDate === 'undefined' || typeof arg.AuctionDate === 'string' ) &&
    // AuctionLocationId?: number
    ( typeof arg.AuctionLocationId === 'undefined' || typeof arg.AuctionLocationId === 'number' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankNo?: string
    ( typeof arg.BankNo === 'undefined' || typeof arg.BankNo === 'string' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // CashPaymentAmount?: number
    ( typeof arg.CashPaymentAmount === 'undefined' || typeof arg.CashPaymentAmount === 'number' ) &&
    // CheckNo?: string
    ( typeof arg.CheckNo === 'undefined' || typeof arg.CheckNo === 'string' ) &&
    // CheckPaymentAmount?: number
    ( typeof arg.CheckPaymentAmount === 'undefined' || typeof arg.CheckPaymentAmount === 'number' ) &&
    // ClearentJWTToken?: string
    ( typeof arg.ClearentJWTToken === 'undefined' || typeof arg.ClearentJWTToken === 'string' ) &&
    // CompleteLien?: boolean
    ( typeof arg.CompleteLien === 'undefined' || typeof arg.CompleteLien === 'boolean' ) &&
    // CompleteRetain?: boolean
    ( typeof arg.CompleteRetain === 'undefined' || typeof arg.CompleteRetain === 'boolean' ) &&
    // CompleteVacated?: boolean
    ( typeof arg.CompleteVacated === 'undefined' || typeof arg.CompleteVacated === 'boolean' ) &&
    // CreditCardPaymentAmount?: number
    ( typeof arg.CreditCardPaymentAmount === 'undefined' || typeof arg.CreditCardPaymentAmount === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // Invertories?: Invertory[]
    ( typeof arg.Invertories === 'undefined' || (Array.isArray(arg.Invertories) && arg.Invertories.every((item: unknown) => isInvertory(item))) ) &&
    // IsAutoBill?: boolean
    ( typeof arg.IsAutoBill === 'undefined' || typeof arg.IsAutoBill === 'boolean' ) &&
    // LeaseId?: number
    ( typeof arg.LeaseId === 'undefined' || typeof arg.LeaseId === 'number' ) &&
    // PayerId?: string
    ( typeof arg.PayerId === 'undefined' || typeof arg.PayerId === 'string' ) &&
    // SavePaymentInfo?: boolean
    ( typeof arg.SavePaymentInfo === 'undefined' || typeof arg.SavePaymentInfo === 'boolean' ) &&
    // SubmittedTransactionIdentifier?: string
    ( typeof arg.SubmittedTransactionIdentifier === 'undefined' || typeof arg.SubmittedTransactionIdentifier === 'string' ) &&
    // TransactionIdentifier?: string
    ( typeof arg.TransactionIdentifier === 'undefined' || typeof arg.TransactionIdentifier === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // UseCardOnFile?: boolean
    ( typeof arg.UseCardOnFile === 'undefined' || typeof arg.UseCardOnFile === 'boolean' ) &&

  true
  );
  }

export function isLienPrintLetter(arg: any): arg is models.LienPrintLetter {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Base64String?: string
    ( typeof arg.Base64String === 'undefined' || typeof arg.Base64String === 'string' ) &&
    // Extension?: string
    ( typeof arg.Extension === 'undefined' || typeof arg.Extension === 'string' ) &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&

  true
  );
  }

export function isLienPrintRequest(arg: any): arg is models.LienPrintRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // EmailAddress?: string
    ( typeof arg.EmailAddress === 'undefined' || typeof arg.EmailAddress === 'string' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // LienStatus?: number
    ( typeof arg.LienStatus === 'undefined' || typeof arg.LienStatus === 'number' ) &&
    // Print?: boolean
    ( typeof arg.Print === 'undefined' || typeof arg.Print === 'boolean' ) &&
    // SendEmail?: boolean
    ( typeof arg.SendEmail === 'undefined' || typeof arg.SendEmail === 'boolean' ) &&
    // SendSms?: boolean
    ( typeof arg.SendSms === 'undefined' || typeof arg.SendSms === 'boolean' ) &&
    // SmsCarrierId?: number
    ( typeof arg.SmsCarrierId === 'undefined' || typeof arg.SmsCarrierId === 'number' ) &&
    // SmsNumber?: string
    ( typeof arg.SmsNumber === 'undefined' || typeof arg.SmsNumber === 'string' ) &&
    // TenantHistory?: boolean
    ( typeof arg.TenantHistory === 'undefined' || typeof arg.TenantHistory === 'boolean' ) &&
    // TenantSummary?: boolean
    ( typeof arg.TenantSummary === 'undefined' || typeof arg.TenantSummary === 'boolean' ) &&

  true
  );
  }

export function isLienPrintResponse(arg: any): arg is models.LienPrintResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Letters?: LienPrintLetter[]
    ( typeof arg.Letters === 'undefined' || (Array.isArray(arg.Letters) && arg.Letters.every((item: unknown) => isLienPrintLetter(item))) ) &&
    // Reports?: object[]
    ( typeof arg.Reports === 'undefined' || (Array.isArray(arg.Reports) && arg.Reports.every((item: unknown) => typeof item === 'object')) ) &&

  true
  );
  }

export function isLienResponse(arg: any): arg is models.LienResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address?: string
    ( typeof arg.Address === 'undefined' || typeof arg.Address === 'string' ) &&
    // AuctionDate?: string
    ( typeof arg.AuctionDate === 'undefined' || typeof arg.AuctionDate === 'string' ) &&
    // AuctionLocationId?: number
    ( typeof arg.AuctionLocationId === 'undefined' || typeof arg.AuctionLocationId === 'number' ) &&
    // CustomerNo?: string
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'string' ) &&
    // LienPlaced?: string
    ( typeof arg.LienPlaced === 'undefined' || typeof arg.LienPlaced === 'string' ) &&
    // LienStatus?: string
    ( typeof arg.LienStatus === 'undefined' || typeof arg.LienStatus === 'string' ) &&
    // MIDate?: string
    ( typeof arg.MIDate === 'undefined' || typeof arg.MIDate === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // Modified?: string
    ( typeof arg.Modified === 'undefined' || typeof arg.Modified === 'string' ) &&
    // PaidToDate?: string
    ( typeof arg.PaidToDate === 'undefined' || typeof arg.PaidToDate === 'string' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // RentDueDay?: number
    ( typeof arg.RentDueDay === 'undefined' || typeof arg.RentDueDay === 'number' ) &&
    // StroredGoods?: Invertory[]
    ( typeof arg.StroredGoods === 'undefined' || (Array.isArray(arg.StroredGoods) && arg.StroredGoods.every((item: unknown) => isInvertory(item))) ) &&
    // TenantName?: string
    ( typeof arg.TenantName === 'undefined' || typeof arg.TenantName === 'string' ) &&
    // TotalDue?: number
    ( typeof arg.TotalDue === 'undefined' || typeof arg.TotalDue === 'number' ) &&
    // TransDetails?: TransactionDetail[]
    ( typeof arg.TransDetails === 'undefined' || (Array.isArray(arg.TransDetails) && arg.TransDetails.every((item: unknown) => isTransactionDetail(item))) ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&
    // UnitStatus?: string
    ( typeof arg.UnitStatus === 'undefined' || typeof arg.UnitStatus === 'string' ) &&

  true
  );
  }

export function isLinkedUnit(arg: any): arg is models.LinkedUnit {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BalanceDue?: number
    ( typeof arg.BalanceDue === 'undefined' || typeof arg.BalanceDue === 'number' ) &&
    // CanUnlink?: boolean
    ( typeof arg.CanUnlink === 'undefined' || typeof arg.CanUnlink === 'boolean' ) &&
    // PaidToDate?: string
    ( typeof arg.PaidToDate === 'undefined' || typeof arg.PaidToDate === 'string' ) &&
    // Size?: string
    ( typeof arg.Size === 'undefined' || typeof arg.Size === 'string' ) &&
    // Status?: string
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&

  true
  );
  }

export function isLinkUnitPostModel(arg: any): arg is models.LinkUnitPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Data?: UnlinkPostModel[]
    ( typeof arg.Data === 'undefined' || (Array.isArray(arg.Data) && arg.Data.every((item: unknown) => isUnlinkPostModel(item))) ) &&
    // MainCustomerID?: number
    ( typeof arg.MainCustomerID === 'undefined' || typeof arg.MainCustomerID === 'number' ) &&

  true
  );
  }

export function isLoginResponse(arg: any): arg is models.LoginResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Result?: '1' | '100' | '101' | '102' | '103' | '104' | '105' | '106'
    ( typeof arg.Result === 'undefined' || ['1', '100', '101', '102', '103', '104', '105', '106'].includes(arg.Result) ) &&
    // Token?: string
    ( typeof arg.Token === 'undefined' || typeof arg.Token === 'string' ) &&
    // UserID?: number
    ( typeof arg.UserID === 'undefined' || typeof arg.UserID === 'number' ) &&

  true
  );
  }

export function isMainOfficePostModel(arg: any): arg is models.MainOfficePostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // Contracts?: number
    ( typeof arg.Contracts === 'undefined' || typeof arg.Contracts === 'number' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FeeOption?: FeeOptionPostModel
    ( typeof arg.FeeOption === 'undefined' || isFeeOptionPostModel(arg.FeeOption) ) &&
    // IsDemo?: boolean
    ( typeof arg.IsDemo === 'undefined' || typeof arg.IsDemo === 'boolean' ) &&
    // LedgerOption?: number
    ( typeof arg.LedgerOption === 'undefined' || typeof arg.LedgerOption === 'number' ) &&
    // Other?: OtherModel
    ( typeof arg.Other === 'undefined' || isOtherModel(arg.Other) ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // ReceiptMessage?: string
    ( typeof arg.ReceiptMessage === 'undefined' || typeof arg.ReceiptMessage === 'string' ) &&
    // Receipts?: number
    ( typeof arg.Receipts === 'undefined' || typeof arg.Receipts === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SiteGroupNo?: number
    ( typeof arg.SiteGroupNo === 'undefined' || typeof arg.SiteGroupNo === 'number' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // SystemDate?: SystemDateModel
    ( typeof arg.SystemDate === 'undefined' || isSystemDateModel(arg.SystemDate) ) &&
    // TenantOption?: TenantOptionPostModel
    ( typeof arg.TenantOption === 'undefined' || isTenantOptionPostModel(arg.TenantOption) ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isMainOfficeViewModel(arg: any): arg is models.MainOfficeViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // Contracts?: number
    ( typeof arg.Contracts === 'undefined' || typeof arg.Contracts === 'number' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // IsDemo?: boolean
    ( typeof arg.IsDemo === 'undefined' || typeof arg.IsDemo === 'boolean' ) &&
    // LedgerOption?: number
    ( typeof arg.LedgerOption === 'undefined' || typeof arg.LedgerOption === 'number' ) &&
    // Other?: OtherModel
    ( typeof arg.Other === 'undefined' || isOtherModel(arg.Other) ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // ReceiptMessage?: string
    ( typeof arg.ReceiptMessage === 'undefined' || typeof arg.ReceiptMessage === 'string' ) &&
    // Receipts?: number
    ( typeof arg.Receipts === 'undefined' || typeof arg.Receipts === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SiteGroupNo?: number
    ( typeof arg.SiteGroupNo === 'undefined' || typeof arg.SiteGroupNo === 'number' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isMakePaymentRequest(arg: any): arg is models.MakePaymentRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AchPaymentAmount?: number
    ( typeof arg.AchPaymentAmount === 'undefined' || typeof arg.AchPaymentAmount === 'number' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankNo?: string
    ( typeof arg.BankNo === 'undefined' || typeof arg.BankNo === 'string' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // CashPaymentAmount?: number
    ( typeof arg.CashPaymentAmount === 'undefined' || typeof arg.CashPaymentAmount === 'number' ) &&
    // CheckNo?: string
    ( typeof arg.CheckNo === 'undefined' || typeof arg.CheckNo === 'string' ) &&
    // CheckPaymentAmount?: number
    ( typeof arg.CheckPaymentAmount === 'undefined' || typeof arg.CheckPaymentAmount === 'number' ) &&
    // ClearentJWTToken?: string
    ( typeof arg.ClearentJWTToken === 'undefined' || typeof arg.ClearentJWTToken === 'string' ) &&
    // CreditCardPaymentAmount?: number
    ( typeof arg.CreditCardPaymentAmount === 'undefined' || typeof arg.CreditCardPaymentAmount === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // IsAutoBill?: boolean
    ( typeof arg.IsAutoBill === 'undefined' || typeof arg.IsAutoBill === 'boolean' ) &&
    // IsTenantPortal?: boolean
    ( typeof arg.IsTenantPortal === 'undefined' || typeof arg.IsTenantPortal === 'boolean' ) &&
    // PayerId?: string
    ( typeof arg.PayerId === 'undefined' || typeof arg.PayerId === 'string' ) &&
    // SavePaymentInfo?: boolean
    ( typeof arg.SavePaymentInfo === 'undefined' || typeof arg.SavePaymentInfo === 'boolean' ) &&
    // SubmittedTransactionIdentifier?: string
    ( typeof arg.SubmittedTransactionIdentifier === 'undefined' || typeof arg.SubmittedTransactionIdentifier === 'string' ) &&
    // TransactionIdentifier?: string
    ( typeof arg.TransactionIdentifier === 'undefined' || typeof arg.TransactionIdentifier === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // Units?: PaymentUnitItem[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => isPaymentUnitItem(item))) ) &&
    // UseCardOnFile?: boolean
    ( typeof arg.UseCardOnFile === 'undefined' || typeof arg.UseCardOnFile === 'boolean' ) &&

  true
  );
  }

export function isManagerLoginRequest(arg: any): arg is models.ManagerLoginRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Password: string
    ( typeof arg.Password === 'string' ) &&
    // UserName: string
    ( typeof arg.UserName === 'string' ) &&

  true
  );
  }

export function isMarketingReportResponse(arg: any): arg is models.MarketingReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AdvertisingOption?: string
    ( typeof arg.AdvertisingOption === 'undefined' || typeof arg.AdvertisingOption === 'string' ) &&
    // Count?: number
    ( typeof arg.Count === 'undefined' || typeof arg.Count === 'number' ) &&
    // UnitPercent?: number
    ( typeof arg.UnitPercent === 'undefined' || typeof arg.UnitPercent === 'number' ) &&

  true
  );
  }

export function isMerchandiseAjustMentModel(arg: any): arg is models.MerchandiseAjustMentModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Cost?: number
    ( typeof arg.Cost === 'undefined' || typeof arg.Cost === 'number' ) &&
    // DataChanged?: boolean
    ( typeof arg.DataChanged === 'undefined' || typeof arg.DataChanged === 'boolean' ) &&
    // MerchandiseID?: number
    ( typeof arg.MerchandiseID === 'undefined' || typeof arg.MerchandiseID === 'number' ) &&
    // OnHand?: number
    ( typeof arg.OnHand === 'undefined' || typeof arg.OnHand === 'number' ) &&
    // Qunatity?: number
    ( typeof arg.Qunatity === 'undefined' || typeof arg.Qunatity === 'number' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // TransType?: number
    ( typeof arg.TransType === 'undefined' || typeof arg.TransType === 'number' ) &&

  true
  );
  }

export function isMerchandiseCategoryModel(arg: any): arg is models.MerchandiseCategoryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // CategoryNo?: number
    ( typeof arg.CategoryNo === 'undefined' || typeof arg.CategoryNo === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // MerchandiseCategoryID?: number
    ( typeof arg.MerchandiseCategoryID === 'undefined' || typeof arg.MerchandiseCategoryID === 'number' ) &&

  true
  );
  }

export function isMerchandiseCommonModel(arg: any): arg is models.MerchandiseCommonModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Categories?: MerchandiseCategoryModel[]
    ( typeof arg.Categories === 'undefined' || (Array.isArray(arg.Categories) && arg.Categories.every((item: unknown) => isMerchandiseCategoryModel(item))) ) &&
    // MerchandiseAjustMent?: MerchandiseAjustMentModel
    ( typeof arg.MerchandiseAjustMent === 'undefined' || isMerchandiseAjustMentModel(arg.MerchandiseAjustMent) ) &&
    // Merchandises?: MerchandisePostModel[]
    ( typeof arg.Merchandises === 'undefined' || (Array.isArray(arg.Merchandises) && arg.Merchandises.every((item: unknown) => isMerchandisePostModel(item))) ) &&

  true
  );
  }

export function isMerchandiseModel(arg: any): arg is models.MerchandiseModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // Price?: number
    ( typeof arg.Price === 'undefined' || typeof arg.Price === 'number' ) &&

  true
  );
  }

export function isMerchandisePostModel(arg: any): arg is models.MerchandisePostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountID?: number
    ( typeof arg.AccountID === 'undefined' || typeof arg.AccountID === 'number' ) &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // Cost?: number
    ( typeof arg.Cost === 'undefined' || typeof arg.Cost === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // ItemNo?: number
    ( typeof arg.ItemNo === 'undefined' || typeof arg.ItemNo === 'number' ) &&
    // MerchandiseCategoryID?: number
    ( typeof arg.MerchandiseCategoryID === 'undefined' || typeof arg.MerchandiseCategoryID === 'number' ) &&
    // MerchandiseID?: number
    ( typeof arg.MerchandiseID === 'undefined' || typeof arg.MerchandiseID === 'number' ) &&
    // OnHand?: number
    ( typeof arg.OnHand === 'undefined' || typeof arg.OnHand === 'number' ) &&
    // PartNo?: string
    ( typeof arg.PartNo === 'undefined' || typeof arg.PartNo === 'string' ) &&
    // Price?: number
    ( typeof arg.Price === 'undefined' || typeof arg.Price === 'number' ) &&
    // ReorderQty?: number
    ( typeof arg.ReorderQty === 'undefined' || typeof arg.ReorderQty === 'number' ) &&
    // Vendor?: string
    ( typeof arg.Vendor === 'undefined' || typeof arg.Vendor === 'string' ) &&

  true
  );
  }

export function isMerchandiseViewModel(arg: any): arg is models.MerchandiseViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Account?: string
    ( typeof arg.Account === 'undefined' || typeof arg.Account === 'string' ) &&
    // AccountID?: number
    ( typeof arg.AccountID === 'undefined' || typeof arg.AccountID === 'number' ) &&
    // Cost?: number
    ( typeof arg.Cost === 'undefined' || typeof arg.Cost === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // ItemNo?: number
    ( typeof arg.ItemNo === 'undefined' || typeof arg.ItemNo === 'number' ) &&
    // MerchandiseCategory?: string
    ( typeof arg.MerchandiseCategory === 'undefined' || typeof arg.MerchandiseCategory === 'string' ) &&
    // MerchandiseCategoryID?: number
    ( typeof arg.MerchandiseCategoryID === 'undefined' || typeof arg.MerchandiseCategoryID === 'number' ) &&
    // MerchandiseID?: number
    ( typeof arg.MerchandiseID === 'undefined' || typeof arg.MerchandiseID === 'number' ) &&
    // OnHand?: number
    ( typeof arg.OnHand === 'undefined' || typeof arg.OnHand === 'number' ) &&
    // PartNo?: string
    ( typeof arg.PartNo === 'undefined' || typeof arg.PartNo === 'string' ) &&
    // Price?: number
    ( typeof arg.Price === 'undefined' || typeof arg.Price === 'number' ) &&
    // ReorderQty?: number
    ( typeof arg.ReorderQty === 'undefined' || typeof arg.ReorderQty === 'number' ) &&
    // Vendor?: string
    ( typeof arg.Vendor === 'undefined' || typeof arg.Vendor === 'string' ) &&

  true
  );
  }

export function isMiscItem(arg: any): arg is models.MiscItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountId?: number
    ( typeof arg.AccountId === 'undefined' || typeof arg.AccountId === 'number' ) &&
    // AcctMode?: '1' | '2' | '3' | '4' | '5' | '6' | '7'
    ( typeof arg.AcctMode === 'undefined' || ['1', '2', '3', '4', '5', '6', '7'].includes(arg.AcctMode) ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // MerchandiseId?: number
    ( typeof arg.MerchandiseId === 'undefined' || typeof arg.MerchandiseId === 'number' ) &&
    // Quantity?: number
    ( typeof arg.Quantity === 'undefined' || typeof arg.Quantity === 'number' ) &&
    // TransDetailId?: number
    ( typeof arg.TransDetailId === 'undefined' || typeof arg.TransDetailId === 'number' ) &&
    // TransNo?: number
    ( typeof arg.TransNo === 'undefined' || typeof arg.TransNo === 'number' ) &&

  true
  );
  }

export function isMissingCloseItem(arg: any): arg is models.MissingCloseItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // MissingCloseOption?: '1' | '2' | '3'
    ( typeof arg.MissingCloseOption === 'undefined' || ['1', '2', '3'].includes(arg.MissingCloseOption) ) &&
    // Text?: string
    ( typeof arg.Text === 'undefined' || typeof arg.Text === 'string' ) &&

  true
  );
  }

export function isModelItem(arg: any): arg is models.ModelItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&

  true
  );
  }

export function isMoveInError(arg: any): arg is models.MoveInError {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // Type?: string
    ( typeof arg.Type === 'undefined' || typeof arg.Type === 'string' ) &&

  true
  );
  }

export function isMoveinRequest(arg: any): arg is models.MoveinRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AchPaymentAmount?: number
    ( typeof arg.AchPaymentAmount === 'undefined' || typeof arg.AchPaymentAmount === 'number' ) &&
    // AdvConfigs?: AdvertisingConfigModel[]
    ( typeof arg.AdvConfigs === 'undefined' || (Array.isArray(arg.AdvConfigs) && arg.AdvConfigs.every((item: unknown) => isAdvertisingConfigModel(item))) ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankNo?: string
    ( typeof arg.BankNo === 'undefined' || typeof arg.BankNo === 'string' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // CashPaymentAmount?: number
    ( typeof arg.CashPaymentAmount === 'undefined' || typeof arg.CashPaymentAmount === 'number' ) &&
    // CheckNo?: string
    ( typeof arg.CheckNo === 'undefined' || typeof arg.CheckNo === 'string' ) &&
    // CheckPaymentAmount?: number
    ( typeof arg.CheckPaymentAmount === 'undefined' || typeof arg.CheckPaymentAmount === 'number' ) &&
    // ClearentJWTToken?: string
    ( typeof arg.ClearentJWTToken === 'undefined' || typeof arg.ClearentJWTToken === 'string' ) &&
    // CreditCardPaymentAmount?: number
    ( typeof arg.CreditCardPaymentAmount === 'undefined' || typeof arg.CreditCardPaymentAmount === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerTypeID?: number
    ( typeof arg.CustomerTypeID === 'undefined' || typeof arg.CustomerTypeID === 'number' ) &&
    // DemoGraphics?: DemographicItem[]
    ( typeof arg.DemoGraphics === 'undefined' || (Array.isArray(arg.DemoGraphics) && arg.DemoGraphics.every((item: unknown) => isDemographicItem(item))) ) &&
    // IsAutoBill?: boolean
    ( typeof arg.IsAutoBill === 'undefined' || typeof arg.IsAutoBill === 'boolean' ) &&
    // IsManagerPortal?: boolean
    ( typeof arg.IsManagerPortal === 'undefined' || typeof arg.IsManagerPortal === 'boolean' ) &&
    // PayerId?: string
    ( typeof arg.PayerId === 'undefined' || typeof arg.PayerId === 'string' ) &&
    // ReservedToDate?: string
    ( typeof arg.ReservedToDate === 'undefined' || typeof arg.ReservedToDate === 'string' ) &&
    // SavePaymentInfo?: boolean
    ( typeof arg.SavePaymentInfo === 'undefined' || typeof arg.SavePaymentInfo === 'boolean' ) &&
    // SubmittedTransactionIdentifier?: string
    ( typeof arg.SubmittedTransactionIdentifier === 'undefined' || typeof arg.SubmittedTransactionIdentifier === 'string' ) &&
    // TransactionIdentifier?: string
    ( typeof arg.TransactionIdentifier === 'undefined' || typeof arg.TransactionIdentifier === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // Units?: UnitRequest[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => isUnitRequest(item))) ) &&
    // UseCardOnFile?: boolean
    ( typeof arg.UseCardOnFile === 'undefined' || typeof arg.UseCardOnFile === 'boolean' ) &&

  true
  );
  }

export function isMoveOutLeases(arg: any): arg is models.MoveOutLeases {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // DepositRefund?: number
    ( typeof arg.DepositRefund === 'undefined' || typeof arg.DepositRefund === 'number' ) &&
    // LeaseId?: number
    ( typeof arg.LeaseId === 'undefined' || typeof arg.LeaseId === 'number' ) &&
    // MIDate?: string
    ( typeof arg.MIDate === 'undefined' || typeof arg.MIDate === 'string' ) &&
    // MoveOut?: boolean
    ( typeof arg.MoveOut === 'undefined' || typeof arg.MoveOut === 'boolean' ) &&
    // NewPaidToDate?: string
    ( typeof arg.NewPaidToDate === 'undefined' || typeof arg.NewPaidToDate === 'string' ) &&
    // PaidToDate?: string
    ( typeof arg.PaidToDate === 'undefined' || typeof arg.PaidToDate === 'string' ) &&
    // StatusDesc?: string
    ( typeof arg.StatusDesc === 'undefined' || typeof arg.StatusDesc === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&

  true
  );
  }

export function isMoveOutRequest(arg: any): arg is models.MoveOutRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AchPaymentAmount?: number
    ( typeof arg.AchPaymentAmount === 'undefined' || typeof arg.AchPaymentAmount === 'number' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankNo?: string
    ( typeof arg.BankNo === 'undefined' || typeof arg.BankNo === 'string' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // CashPaymentAmount?: number
    ( typeof arg.CashPaymentAmount === 'undefined' || typeof arg.CashPaymentAmount === 'number' ) &&
    // CheckNo?: string
    ( typeof arg.CheckNo === 'undefined' || typeof arg.CheckNo === 'string' ) &&
    // CheckPaymentAmount?: number
    ( typeof arg.CheckPaymentAmount === 'undefined' || typeof arg.CheckPaymentAmount === 'number' ) &&
    // ClearentJWTToken?: string
    ( typeof arg.ClearentJWTToken === 'undefined' || typeof arg.ClearentJWTToken === 'string' ) &&
    // CreditCardPaymentAmount?: number
    ( typeof arg.CreditCardPaymentAmount === 'undefined' || typeof arg.CreditCardPaymentAmount === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // IsAutoBill?: boolean
    ( typeof arg.IsAutoBill === 'undefined' || typeof arg.IsAutoBill === 'boolean' ) &&
    // IsCreditCustomer?: boolean
    ( typeof arg.IsCreditCustomer === 'undefined' || typeof arg.IsCreditCustomer === 'boolean' ) &&
    // PayerId?: string
    ( typeof arg.PayerId === 'undefined' || typeof arg.PayerId === 'string' ) &&
    // SavePaymentInfo?: boolean
    ( typeof arg.SavePaymentInfo === 'undefined' || typeof arg.SavePaymentInfo === 'boolean' ) &&
    // SelectedLeases?: MoveOutLeases[]
    ( typeof arg.SelectedLeases === 'undefined' || (Array.isArray(arg.SelectedLeases) && arg.SelectedLeases.every((item: unknown) => isMoveOutLeases(item))) ) &&
    // SubmittedTransactionIdentifier?: string
    ( typeof arg.SubmittedTransactionIdentifier === 'undefined' || typeof arg.SubmittedTransactionIdentifier === 'string' ) &&
    // TransactionIdentifier?: string
    ( typeof arg.TransactionIdentifier === 'undefined' || typeof arg.TransactionIdentifier === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // UseCardOnFile?: boolean
    ( typeof arg.UseCardOnFile === 'undefined' || typeof arg.UseCardOnFile === 'boolean' ) &&

  true
  );
  }

export function isMoveOutTransDetails(arg: any): arg is models.MoveOutTransDetails {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountId?: number
    ( typeof arg.AccountId === 'undefined' || typeof arg.AccountId === 'number' ) &&
    // Balance?: number
    ( typeof arg.Balance === 'undefined' || typeof arg.Balance === 'number' ) &&
    // Charges?: number
    ( typeof arg.Charges === 'undefined' || typeof arg.Charges === 'number' ) &&
    // Credits?: number
    ( typeof arg.Credits === 'undefined' || typeof arg.Credits === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // PostDate?: string
    ( typeof arg.PostDate === 'undefined' || typeof arg.PostDate === 'string' ) &&
    // Price?: number
    ( typeof arg.Price === 'undefined' || typeof arg.Price === 'number' ) &&
    // Quantity?: number
    ( typeof arg.Quantity === 'undefined' || typeof arg.Quantity === 'number' ) &&
    // TransId?: number
    ( typeof arg.TransId === 'undefined' || typeof arg.TransId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitOfMeasure?: number
    ( typeof arg.UnitOfMeasure === 'undefined' || typeof arg.UnitOfMeasure === 'number' ) &&

  true
  );
  }

export function isMoveOutTransDetailsRequest(arg: any): arg is models.MoveOutTransDetailsRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // LeaseIds?: number[]
    ( typeof arg.LeaseIds === 'undefined' || (Array.isArray(arg.LeaseIds) && arg.LeaseIds.every((item: unknown) => typeof item === 'number')) ) &&

  true
  );
  }

export function isNextDefaultValue(arg: any): arg is models.NextDefaultValue {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountCategory?: number
    ( typeof arg.AccountCategory === 'undefined' || typeof arg.AccountCategory === 'number' ) &&
    // AccountNo?: number
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'number' ) &&
    // AccountType?: number
    ( typeof arg.AccountType === 'undefined' || typeof arg.AccountType === 'number' ) &&
    // Debit?: boolean
    ( typeof arg.Debit === 'undefined' || typeof arg.Debit === 'boolean' ) &&
    // PaymentOrder?: number
    ( typeof arg.PaymentOrder === 'undefined' || typeof arg.PaymentOrder === 'number' ) &&
    // ReceiptOrder?: number
    ( typeof arg.ReceiptOrder === 'undefined' || typeof arg.ReceiptOrder === 'number' ) &&

  true
  );
  }

export function isNoteModel(arg: any): arg is models.NoteModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CreatedDate?: string
    ( typeof arg.CreatedDate === 'undefined' || typeof arg.CreatedDate === 'string' ) &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // CustomerName?: string
    ( typeof arg.CustomerName === 'undefined' || typeof arg.CustomerName === 'string' ) &&
    // EnteredBy?: string
    ( typeof arg.EnteredBy === 'undefined' || typeof arg.EnteredBy === 'string' ) &&
    // NoteAlert?: boolean
    ( typeof arg.NoteAlert === 'undefined' || typeof arg.NoteAlert === 'boolean' ) &&
    // NoteId?: number
    ( typeof arg.NoteId === 'undefined' || typeof arg.NoteId === 'number' ) &&
    // NoteText?: string
    ( typeof arg.NoteText === 'undefined' || typeof arg.NoteText === 'string' ) &&
    // UserId?: number
    ( typeof arg.UserId === 'undefined' || typeof arg.UserId === 'number' ) &&

  true
  );
  }

export function isNoteRequest(arg: any): arg is models.NoteRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // Filter?: string
    ( typeof arg.Filter === 'undefined' || typeof arg.Filter === 'string' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isNoticeResponse(arg: any): arg is models.NoticeResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CountryName?: string
    ( typeof arg.CountryName === 'undefined' || typeof arg.CountryName === 'string' ) &&
    // CustomerFollowupActionID?: number
    ( typeof arg.CustomerFollowupActionID === 'undefined' || typeof arg.CustomerFollowupActionID === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // DeliveryMethodDescription?: string
    ( typeof arg.DeliveryMethodDescription === 'undefined' || typeof arg.DeliveryMethodDescription === 'string' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // EMailSubject?: string
    ( typeof arg.EMailSubject === 'undefined' || typeof arg.EMailSubject === 'string' ) &&
    // InputFileName?: string
    ( typeof arg.InputFileName === 'undefined' || typeof arg.InputFileName === 'string' ) &&
    // IsOldTenant?: boolean
    ( typeof arg.IsOldTenant === 'undefined' || typeof arg.IsOldTenant === 'boolean' ) &&
    // IsReprintingReceipt?: boolean
    ( typeof arg.IsReprintingReceipt === 'undefined' || typeof arg.IsReprintingReceipt === 'boolean' ) &&
    // IsSpecialLetter?: boolean
    ( typeof arg.IsSpecialLetter === 'undefined' || typeof arg.IsSpecialLetter === 'boolean' ) &&
    // IsSpecialMailing?: boolean
    ( typeof arg.IsSpecialMailing === 'undefined' || typeof arg.IsSpecialMailing === 'boolean' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // LetterID?: number
    ( typeof arg.LetterID === 'undefined' || typeof arg.LetterID === 'number' ) &&
    // LienID?: number
    ( typeof arg.LienID === 'undefined' || typeof arg.LienID === 'number' ) &&
    // OutputFileName?: string
    ( typeof arg.OutputFileName === 'undefined' || typeof arg.OutputFileName === 'string' ) &&
    // OutPutType?: '1' | '2' | '3' | '4' | '5'
    ( typeof arg.OutPutType === 'undefined' || ['1', '2', '3', '4', '5'].includes(arg.OutPutType) ) &&
    // PrintMultipleUnits?: boolean
    ( typeof arg.PrintMultipleUnits === 'undefined' || typeof arg.PrintMultipleUnits === 'boolean' ) &&
    // RecipientName?: string
    ( typeof arg.RecipientName === 'undefined' || typeof arg.RecipientName === 'string' ) &&
    // SecondaryCustomerID?: number
    ( typeof arg.SecondaryCustomerID === 'undefined' || typeof arg.SecondaryCustomerID === 'number' ) &&
    // StateName?: string
    ( typeof arg.StateName === 'undefined' || typeof arg.StateName === 'string' ) &&
    // Status?: '1' | '2' | '3' | '4' | '5'
    ( typeof arg.Status === 'undefined' || ['1', '2', '3', '4', '5'].includes(arg.Status) ) &&
    // StatusDescription?: string
    ( typeof arg.StatusDescription === 'undefined' || typeof arg.StatusDescription === 'string' ) &&
    // TemplateType?: '1' | '2'
    ( typeof arg.TemplateType === 'undefined' || ['1', '2'].includes(arg.TemplateType) ) &&
    // TemplateTypeDescription?: string
    ( typeof arg.TemplateTypeDescription === 'undefined' || typeof arg.TemplateTypeDescription === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UserID?: number
    ( typeof arg.UserID === 'undefined' || typeof arg.UserID === 'number' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isOccupancyReportResponse(arg: any): arg is models.OccupancyReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Delinquent?: number
    ( typeof arg.Delinquent === 'undefined' || typeof arg.Delinquent === 'number' ) &&
    // Pending?: number
    ( typeof arg.Pending === 'undefined' || typeof arg.Pending === 'number' ) &&
    // RentedUnits?: number
    ( typeof arg.RentedUnits === 'undefined' || typeof arg.RentedUnits === 'number' ) &&
    // ReservedUnits?: number
    ( typeof arg.ReservedUnits === 'undefined' || typeof arg.ReservedUnits === 'number' ) &&

  true
  );
  }

export function isODataQueryParameters(arg: any): arg is models.ODataQueryParameters {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // $expand?: string
    ( typeof arg.$expand === 'undefined' || typeof arg.$expand === 'string' ) &&
    // $filter?: string
    ( typeof arg.$filter === 'undefined' || typeof arg.$filter === 'string' ) &&
    // $orderby?: string
    ( typeof arg.$orderby === 'undefined' || typeof arg.$orderby === 'string' ) &&
    // $select?: string
    ( typeof arg.$select === 'undefined' || typeof arg.$select === 'string' ) &&
    // $skip?: number
    ( typeof arg.$skip === 'undefined' || typeof arg.$skip === 'number' ) &&
    // $top?: number
    ( typeof arg.$top === 'undefined' || typeof arg.$top === 'number' ) &&

  true
  );
  }

export function isOnlineConfigModel(arg: any): arg is models.OnlineConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AutoPrint?: boolean
    ( typeof arg.AutoPrint === 'undefined' || typeof arg.AutoPrint === 'boolean' ) &&
    // OnlineEnabled?: boolean
    ( typeof arg.OnlineEnabled === 'undefined' || typeof arg.OnlineEnabled === 'boolean' ) &&
    // Password?: string
    ( typeof arg.Password === 'undefined' || typeof arg.Password === 'string' ) &&
    // PrintReceipts?: boolean
    ( typeof arg.PrintReceipts === 'undefined' || typeof arg.PrintReceipts === 'boolean' ) &&
    // Reservation?: boolean
    ( typeof arg.Reservation === 'undefined' || typeof arg.Reservation === 'boolean' ) &&
    // RunPostCharger?: boolean
    ( typeof arg.RunPostCharger === 'undefined' || typeof arg.RunPostCharger === 'boolean' ) &&
    // RunPreCharger?: boolean
    ( typeof arg.RunPreCharger === 'undefined' || typeof arg.RunPreCharger === 'boolean' ) &&
    // ShowTransOnImport?: boolean
    ( typeof arg.ShowTransOnImport === 'undefined' || typeof arg.ShowTransOnImport === 'boolean' ) &&
    // SiteID?: number
    ( typeof arg.SiteID === 'undefined' || typeof arg.SiteID === 'number' ) &&
    // TaxAmt?: number
    ( typeof arg.TaxAmt === 'undefined' || typeof arg.TaxAmt === 'number' ) &&
    // TotalAmt?: number
    ( typeof arg.TotalAmt === 'undefined' || typeof arg.TotalAmt === 'number' ) &&
    // TransFee?: string
    ( typeof arg.TransFee === 'undefined' || typeof arg.TransFee === 'string' ) &&
    // TransFeeAccountID?: number
    ( typeof arg.TransFeeAccountID === 'undefined' || typeof arg.TransFeeAccountID === 'number' ) &&
    // WebServiceUrl?: string
    ( typeof arg.WebServiceUrl === 'undefined' || typeof arg.WebServiceUrl === 'string' ) &&

  true
  );
  }

export function isOperationModel(arg: any): arg is models.OperationModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // OperationCategory?: number
    ( typeof arg.OperationCategory === 'undefined' || typeof arg.OperationCategory === 'number' ) &&
    // OperationNo?: number
    ( typeof arg.OperationNo === 'undefined' || typeof arg.OperationNo === 'number' ) &&
    // SecurityConfigurable?: boolean
    ( typeof arg.SecurityConfigurable === 'undefined' || typeof arg.SecurityConfigurable === 'boolean' ) &&
    // Title?: string
    ( typeof arg.Title === 'undefined' || typeof arg.Title === 'string' ) &&

  true
  );
  }

export function isOperationReportInfoModel(arg: any): arg is models.OperationReportInfoModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // OperationNo?: number
    ( typeof arg.OperationNo === 'undefined' || typeof arg.OperationNo === 'number' ) &&
    // ReportInfoID?: number
    ( typeof arg.ReportInfoID === 'undefined' || typeof arg.ReportInfoID === 'number' ) &&

  true
  );
  }

export function isOperationRequest(arg: any): arg is models.OperationRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // OperationCategories?: number[]
    ( typeof arg.OperationCategories === 'undefined' || (Array.isArray(arg.OperationCategories) && arg.OperationCategories.every((item: unknown) => typeof item === 'number')) ) &&
    // OperationNos?: number[]
    ( typeof arg.OperationNos === 'undefined' || (Array.isArray(arg.OperationNos) && arg.OperationNos.every((item: unknown) => typeof item === 'number')) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isOtherModel(arg: any): arg is models.OtherModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AutoBillReceipts?: boolean
    ( typeof arg.AutoBillReceipts === 'undefined' || typeof arg.AutoBillReceipts === 'boolean' ) &&
    // AutoPrintNotices?: boolean
    ( typeof arg.AutoPrintNotices === 'undefined' || typeof arg.AutoPrintNotices === 'boolean' ) &&
    // CCPOSVendor?: number
    ( typeof arg.CCPOSVendor === 'undefined' || typeof arg.CCPOSVendor === 'number' ) &&
    // EnableAVS?: boolean
    ( typeof arg.EnableAVS === 'undefined' || typeof arg.EnableAVS === 'boolean' ) &&
    // EnableCVV2?: boolean
    ( typeof arg.EnableCVV2 === 'undefined' || typeof arg.EnableCVV2 === 'boolean' ) &&
    // RequireProof?: boolean
    ( typeof arg.RequireProof === 'undefined' || typeof arg.RequireProof === 'boolean' ) &&
    // UseCashAccounting?: boolean
    ( typeof arg.UseCashAccounting === 'undefined' || typeof arg.UseCashAccounting === 'boolean' ) &&
    // UseCCPOS?: boolean
    ( typeof arg.UseCCPOS === 'undefined' || typeof arg.UseCCPOS === 'boolean' ) &&

  true
  );
  }

export function isOtherSetting(arg: any): arg is models.OtherSetting {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AllowLienStatusPayments?: boolean
    ( typeof arg.AllowLienStatusPayments === 'undefined' || typeof arg.AllowLienStatusPayments === 'boolean' ) &&
    // AllowNoCardOnReserve?: boolean
    ( typeof arg.AllowNoCardOnReserve === 'undefined' || typeof arg.AllowNoCardOnReserve === 'boolean' ) &&
    // AllowZeroAmountReservations?: boolean
    ( typeof arg.AllowZeroAmountReservations === 'undefined' || typeof arg.AllowZeroAmountReservations === 'boolean' ) &&
    // MaxReserveDays?: number
    ( typeof arg.MaxReserveDays === 'undefined' || typeof arg.MaxReserveDays === 'number' ) &&
    // MinProrateDays?: number
    ( typeof arg.MinProrateDays === 'undefined' || typeof arg.MinProrateDays === 'number' ) &&

  true
  );
  }

export function isOverLockRequest(arg: any): arg is models.OverLockRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // LeaseItem?: LeaseItem[]
    ( typeof arg.LeaseItem === 'undefined' || (Array.isArray(arg.LeaseItem) && arg.LeaseItem.every((item: unknown) => isLeaseItem(item))) ) &&

  true
  );
  }

export function isOverLockResponse(arg: any): arg is models.OverLockResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Balance?: number
    ( typeof arg.Balance === 'undefined' || typeof arg.Balance === 'number' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // Locked?: boolean
    ( typeof arg.Locked === 'undefined' || typeof arg.Locked === 'boolean' ) &&
    // LockedDate?: string
    ( typeof arg.LockedDate === 'undefined' || typeof arg.LockedDate === 'string' ) &&
    // PaidToDate?: string
    ( typeof arg.PaidToDate === 'undefined' || typeof arg.PaidToDate === 'string' ) &&
    // Status?: string
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnLockedDate?: string
    ( typeof arg.UnLockedDate === 'undefined' || typeof arg.UnLockedDate === 'string' ) &&

  true
  );
  }

export function isPaginatedListRequest(arg: any): arg is models.PaginatedListRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&

  true
  );
  }

export function isPaginatedListResponseAlarmPointResponse(arg: any): arg is models.PaginatedListResponseAlarmPointResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: AlarmPointResponse[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isAlarmPointResponse(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseAllContactResponse(arg: any): arg is models.PaginatedListResponseAllContactResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: AllContactResponse[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isAllContactResponse(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseClosingModel(arg: any): arg is models.PaginatedListResponseClosingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: ClosingModel[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isClosingModel(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseDashboardEventItem(arg: any): arg is models.PaginatedListResponseDashboardEventItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: DashboardEventItem[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isDashboardEventItem(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseGetCustomersHaveReservedResponse(arg: any): arg is models.PaginatedListResponseGetCustomersHaveReservedResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: GetCustomersHaveReservedResponse[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isGetCustomersHaveReservedResponse(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseGetWaitingListCustomerResponse(arg: any): arg is models.PaginatedListResponseGetWaitingListCustomerResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: GetWaitingListCustomerResponse[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isGetWaitingListCustomerResponse(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseOperationModel(arg: any): arg is models.PaginatedListResponseOperationModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: OperationModel[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isOperationModel(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseProspectCustomerModel(arg: any): arg is models.PaginatedListResponseProspectCustomerModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: ProspectCustomerModel[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isProspectCustomerModel(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseReportListModel(arg: any): arg is models.PaginatedListResponseReportListModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: ReportListModel[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isReportListModel(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseSizeModel(arg: any): arg is models.PaginatedListResponseSizeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: SizeModel[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isSizeModel(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseTenantModel(arg: any): arg is models.PaginatedListResponseTenantModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: TenantModel[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isTenantModel(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseUnitSummaryModel(arg: any): arg is models.PaginatedListResponseUnitSummaryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: UnitSummaryModel[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isUnitSummaryModel(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaginatedListResponseUnitViewModel(arg: any): arg is models.PaginatedListResponseUnitViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // HasNextPage?: boolean
    ( typeof arg.HasNextPage === 'undefined' || typeof arg.HasNextPage === 'boolean' ) &&
    // HasPreviousPage?: boolean
    ( typeof arg.HasPreviousPage === 'undefined' || typeof arg.HasPreviousPage === 'boolean' ) &&
    // List?: UnitViewModel[]
    ( typeof arg.List === 'undefined' || (Array.isArray(arg.List) && arg.List.every((item: unknown) => isUnitViewModel(item))) ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // TotalCount?: number
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'number' ) &&
    // TotalPages?: number
    ( typeof arg.TotalPages === 'undefined' || typeof arg.TotalPages === 'number' ) &&

  true
  );
  }

export function isPaymentInfoResponse(arg: any): arg is models.PaymentInfoResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Charges?: number
    ( typeof arg.Charges === 'undefined' || typeof arg.Charges === 'number' ) &&
    // CustomerCredit?: number
    ( typeof arg.CustomerCredit === 'undefined' || typeof arg.CustomerCredit === 'number' ) &&
    // Insurance?: number
    ( typeof arg.Insurance === 'undefined' || typeof arg.Insurance === 'number' ) &&
    // Taxes?: number
    ( typeof arg.Taxes === 'undefined' || typeof arg.Taxes === 'number' ) &&
    // TotalDue?: number
    ( typeof arg.TotalDue === 'undefined' || typeof arg.TotalDue === 'number' ) &&
    // UnitAmount?: number
    ( typeof arg.UnitAmount === 'undefined' || typeof arg.UnitAmount === 'number' ) &&
    // Units?: PaymentInfoUnitResponse[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => isPaymentInfoUnitResponse(item))) ) &&

  true
  );
  }

export function isPaymentInfoTransactionDetail(arg: any): arg is models.PaymentInfoTransactionDetail {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountId?: number
    ( typeof arg.AccountId === 'undefined' || typeof arg.AccountId === 'number' ) &&
    // AccountNo?: number
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'number' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // Balance?: number
    ( typeof arg.Balance === 'undefined' || typeof arg.Balance === 'number' ) &&
    // Credits?: number
    ( typeof arg.Credits === 'undefined' || typeof arg.Credits === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // PostDate?: string
    ( typeof arg.PostDate === 'undefined' || typeof arg.PostDate === 'string' ) &&
    // Quantity?: number
    ( typeof arg.Quantity === 'undefined' || typeof arg.Quantity === 'number' ) &&
    // TransNo?: number
    ( typeof arg.TransNo === 'undefined' || typeof arg.TransNo === 'number' ) &&

  true
  );
  }

export function isPaymentInfoUnitItem(arg: any): arg is models.PaymentInfoUnitItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AdditionalCharges?: AdditionalCharge[]
    ( typeof arg.AdditionalCharges === 'undefined' || (Array.isArray(arg.AdditionalCharges) && arg.AdditionalCharges.every((item: unknown) => isAdditionalCharge(item))) ) &&
    // MonthsToPay?: number
    ( typeof arg.MonthsToPay === 'undefined' || typeof arg.MonthsToPay === 'number' ) &&
    // NewRentDueDay?: number
    ( typeof arg.NewRentDueDay === 'undefined' || typeof arg.NewRentDueDay === 'number' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&

  true
  );
  }

export function isPaymentInfoUnitResponse(arg: any): arg is models.PaymentInfoUnitResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AdditionalCharges?: UnitAdditionalChargeResponse[]
    ( typeof arg.AdditionalCharges === 'undefined' || (Array.isArray(arg.AdditionalCharges) && arg.AdditionalCharges.every((item: unknown) => isUnitAdditionalChargeResponse(item))) ) &&
    // Details?: PaymentInfoTransactionDetail[]
    ( typeof arg.Details === 'undefined' || (Array.isArray(arg.Details) && arg.Details.every((item: unknown) => isPaymentInfoTransactionDetail(item))) ) &&
    // MonthsToPay?: number
    ( typeof arg.MonthsToPay === 'undefined' || typeof arg.MonthsToPay === 'number' ) &&
    // NewPaidtoDate?: string
    ( typeof arg.NewPaidtoDate === 'undefined' || typeof arg.NewPaidtoDate === 'string' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isPaymentUnitItem(arg: any): arg is models.PaymentUnitItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AdditionalCharges?: AdditionalCharge[]
    ( typeof arg.AdditionalCharges === 'undefined' || (Array.isArray(arg.AdditionalCharges) && arg.AdditionalCharges.every((item: unknown) => isAdditionalCharge(item))) ) &&
    // MonthsToPay?: number
    ( typeof arg.MonthsToPay === 'undefined' || typeof arg.MonthsToPay === 'number' ) &&
    // NewRentDueDay?: number
    ( typeof arg.NewRentDueDay === 'undefined' || typeof arg.NewRentDueDay === 'number' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&

  true
  );
  }

export function isPayMoverAchModel(arg: any): arg is models.PayMoverAchModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ACHManagerUrl?: string
    ( typeof arg.ACHManagerUrl === 'undefined' || typeof arg.ACHManagerUrl === 'string' ) &&
    // LastTransactionId?: number
    ( typeof arg.LastTransactionId === 'undefined' || typeof arg.LastTransactionId === 'number' ) &&

  true
  );
  }

export function isPayMoverAdditionalAccountModel(arg: any): arg is models.PayMoverAdditionalAccountModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountToken?: string
    ( typeof arg.AccountToken === 'undefined' || typeof arg.AccountToken === 'string' ) &&
    // SiteName?: string
    ( typeof arg.SiteName === 'undefined' || typeof arg.SiteName === 'string' ) &&
    // SiteNo?: number
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'number' ) &&

  true
  );
  }

export function isPayMoverCreditCardModel(arg: any): arg is models.PayMoverCreditCardModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountToken?: string
    ( typeof arg.AccountToken === 'undefined' || typeof arg.AccountToken === 'string' ) &&
    // AddrVerification?: AddressVerificationOption
    ( typeof arg.AddrVerification === 'undefined' || isAddressVerificationOption(arg.AddrVerification) ) &&
    // CardVerification?: CardVerificationOption
    ( typeof arg.CardVerification === 'undefined' || isCardVerificationOption(arg.CardVerification) ) &&
    // CommTimeout?: number
    ( typeof arg.CommTimeout === 'undefined' || typeof arg.CommTimeout === 'number' ) &&
    // CSCManagerUrl?: string
    ( typeof arg.CSCManagerUrl === 'undefined' || typeof arg.CSCManagerUrl === 'string' ) &&
    // ForceDuplicateTrans?: boolean
    ( typeof arg.ForceDuplicateTrans === 'undefined' || typeof arg.ForceDuplicateTrans === 'boolean' ) &&
    // LastOrderID?: number
    ( typeof arg.LastOrderID === 'undefined' || typeof arg.LastOrderID === 'number' ) &&
    // UseTokenization?: boolean
    ( typeof arg.UseTokenization === 'undefined' || typeof arg.UseTokenization === 'boolean' ) &&

  true
  );
  }

export function isPayMoverModel(arg: any): arg is models.PayMoverModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ACHModel?: PayMoverAchModel
    ( typeof arg.ACHModel === 'undefined' || isPayMoverAchModel(arg.ACHModel) ) &&
    // CreditCard?: PayMoverCreditCardModel
    ( typeof arg.CreditCard === 'undefined' || isPayMoverCreditCardModel(arg.CreditCard) ) &&
    // ModifiedDate?: string
    ( typeof arg.ModifiedDate === 'undefined' || typeof arg.ModifiedDate === 'string' ) &&
    // SiteGroupId?: number
    ( typeof arg.SiteGroupId === 'undefined' || typeof arg.SiteGroupId === 'number' ) &&

  true
  );
  }

export function isPdfFile(arg: any): arg is models.PdfFile {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // PDFFile?: string
    ( typeof arg.PDFFile === 'undefined' || typeof arg.PDFFile === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&

  true
  );
  }

export function isPicture(arg: any): arg is models.Picture {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // ModifiedDate?: string
    ( typeof arg.ModifiedDate === 'undefined' || typeof arg.ModifiedDate === 'string' ) &&
    // PictureBase64String?: string
    ( typeof arg.PictureBase64String === 'undefined' || typeof arg.PictureBase64String === 'string' ) &&
    // PictureId?: number
    ( typeof arg.PictureId === 'undefined' || typeof arg.PictureId === 'number' ) &&

  true
  );
  }

export function isPreviewExportQuickBooksResponse(arg: any): arg is models.PreviewExportQuickBooksResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // DepositAmount?: number
    ( typeof arg.DepositAmount === 'undefined' || typeof arg.DepositAmount === 'number' ) &&
    // Items?: ExportQuickBooksItem[]
    ( typeof arg.Items === 'undefined' || (Array.isArray(arg.Items) && arg.Items.every((item: unknown) => isExportQuickBooksItem(item))) ) &&

  true
  );
  }

export function isPrinterModel(arg: any): arg is models.PrinterModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ContractPrinter?: string
    ( typeof arg.ContractPrinter === 'undefined' || typeof arg.ContractPrinter === 'string' ) &&
    // LetterPrinter?: string
    ( typeof arg.LetterPrinter === 'undefined' || typeof arg.LetterPrinter === 'string' ) &&
    // ReceiptPrinter?: string
    ( typeof arg.ReceiptPrinter === 'undefined' || typeof arg.ReceiptPrinter === 'string' ) &&
    // ReportPrinter?: string
    ( typeof arg.ReportPrinter === 'undefined' || typeof arg.ReportPrinter === 'string' ) &&

  true
  );
  }

export function isPrintNoticeRequest(arg: any): arg is models.PrintNoticeRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // LetterID?: number
    ( typeof arg.LetterID === 'undefined' || typeof arg.LetterID === 'number' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&

  true
  );
  }

export function isPrintOrSendLetterRequest(arg: any): arg is models.PrintOrSendLetterRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Body?: string
    ( typeof arg.Body === 'undefined' || typeof arg.Body === 'string' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // LetterID?: number
    ( typeof arg.LetterID === 'undefined' || typeof arg.LetterID === 'number' ) &&
    // PrintMultipleUnits?: boolean
    ( typeof arg.PrintMultipleUnits === 'undefined' || typeof arg.PrintMultipleUnits === 'boolean' ) &&
    // SecondaryCustomerID?: number
    ( typeof arg.SecondaryCustomerID === 'undefined' || typeof arg.SecondaryCustomerID === 'number' ) &&
    // Subject?: string
    ( typeof arg.Subject === 'undefined' || typeof arg.Subject === 'string' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&

  true
  );
  }

export function isPrintProofEditReportRequest(arg: any): arg is models.PrintProofEditReportRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // EmailTo?: string
    ( typeof arg.EmailTo === 'undefined' || typeof arg.EmailTo === 'string' ) &&
    // EndDate?: string
    ( typeof arg.EndDate === 'undefined' || typeof arg.EndDate === 'string' ) &&
    // FileFormatCategory?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11'
    ( typeof arg.FileFormatCategory === 'undefined' || ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'].includes(arg.FileFormatCategory) ) &&
    // StartDate?: string
    ( typeof arg.StartDate === 'undefined' || typeof arg.StartDate === 'string' ) &&

  true
  );
  }

export function isPromoModel(arg: any): arg is models.PromoModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Active?: boolean
    ( typeof arg.Active === 'undefined' || typeof arg.Active === 'boolean' ) &&
    // AmountOff?: number
    ( typeof arg.AmountOff === 'undefined' || typeof arg.AmountOff === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EffectiveDate?: string
    ( typeof arg.EffectiveDate === 'undefined' || typeof arg.EffectiveDate === 'string' ) &&
    // ExpirationDate?: string
    ( typeof arg.ExpirationDate === 'undefined' || typeof arg.ExpirationDate === 'string' ) &&
    // FixedRate?: number
    ( typeof arg.FixedRate === 'undefined' || typeof arg.FixedRate === 'number' ) &&
    // IsAll?: boolean
    ( typeof arg.IsAll === 'undefined' || typeof arg.IsAll === 'boolean' ) &&
    // MinOccupancyPercent?: number
    ( typeof arg.MinOccupancyPercent === 'undefined' || typeof arg.MinOccupancyPercent === 'number' ) &&
    // Months?: number
    ( typeof arg.Months === 'undefined' || typeof arg.Months === 'number' ) &&
    // PercentOff?: number
    ( typeof arg.PercentOff === 'undefined' || typeof arg.PercentOff === 'number' ) &&
    // PromoAmount?: number
    ( typeof arg.PromoAmount === 'undefined' || typeof arg.PromoAmount === 'number' ) &&
    // PromoDiscountType?: number
    ( typeof arg.PromoDiscountType === 'undefined' || typeof arg.PromoDiscountType === 'number' ) &&
    // PromoDiscountTypeText?: string
    ( typeof arg.PromoDiscountTypeText === 'undefined' || typeof arg.PromoDiscountTypeText === 'string' ) &&
    // PromoID?: number
    ( typeof arg.PromoID === 'undefined' || typeof arg.PromoID === 'number' ) &&
    // PromoLocationID?: number
    ( typeof arg.PromoLocationID === 'undefined' || typeof arg.PromoLocationID === 'number' ) &&
    // PromoType?: number
    ( typeof arg.PromoType === 'undefined' || typeof arg.PromoType === 'number' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // StandardRate?: number
    ( typeof arg.StandardRate === 'undefined' || typeof arg.StandardRate === 'number' ) &&

  true
  );
  }

export function isProofEditInfo(arg: any): arg is models.ProofEditInfo {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ACHAmt?: number
    ( typeof arg.ACHAmt === 'undefined' || typeof arg.ACHAmt === 'number' ) &&
    // CardAmt?: number
    ( typeof arg.CardAmt === 'undefined' || typeof arg.CardAmt === 'number' ) &&
    // CashAmt?: number
    ( typeof arg.CashAmt === 'undefined' || typeof arg.CashAmt === 'number' ) &&
    // CheckAmt?: number
    ( typeof arg.CheckAmt === 'undefined' || typeof arg.CheckAmt === 'number' ) &&

  true
  );
  }

export function isProspectCustomerModel(arg: any): arg is models.ProspectCustomerModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AdvertisingEntries?: number[]
    ( typeof arg.AdvertisingEntries === 'undefined' || (Array.isArray(arg.AdvertisingEntries) && arg.AdvertisingEntries.every((item: unknown) => typeof item === 'number')) ) &&
    // AnticipatedLengthOfStay?: number
    ( typeof arg.AnticipatedLengthOfStay === 'undefined' || typeof arg.AnticipatedLengthOfStay === 'number' ) &&
    // Appointment?: string
    ( typeof arg.Appointment === 'undefined' || typeof arg.Appointment === 'string' ) &&
    // AppointmentKept?: boolean
    ( typeof arg.AppointmentKept === 'undefined' || typeof arg.AppointmentKept === 'boolean' ) &&
    // BusinessName?: string
    ( typeof arg.BusinessName === 'undefined' || typeof arg.BusinessName === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerType?: number
    ( typeof arg.CustomerType === 'undefined' || typeof arg.CustomerType === 'number' ) &&
    // DateNeededBy?: string
    ( typeof arg.DateNeededBy === 'undefined' || typeof arg.DateNeededBy === 'string' ) &&
    // DemographicEntries?: number[]
    ( typeof arg.DemographicEntries === 'undefined' || (Array.isArray(arg.DemographicEntries) && arg.DemographicEntries.every((item: unknown) => typeof item === 'number')) ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // EMailNotices?: boolean
    ( typeof arg.EMailNotices === 'undefined' || typeof arg.EMailNotices === 'boolean' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // FollowUpActionEntries?: CustomerFollowUpActionModel[]
    ( typeof arg.FollowUpActionEntries === 'undefined' || (Array.isArray(arg.FollowUpActionEntries) && arg.FollowUpActionEntries.every((item: unknown) => isCustomerFollowUpActionModel(item))) ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // Notes?: string
    ( typeof arg.Notes === 'undefined' || typeof arg.Notes === 'string' ) &&
    // ProspectStatus?: '1' | '2' | '3'
    ( typeof arg.ProspectStatus === 'undefined' || ['1', '2', '3'].includes(arg.ProspectStatus) ) &&
    // RentalDate?: string
    ( typeof arg.RentalDate === 'undefined' || typeof arg.RentalDate === 'string' ) &&
    // Sizes?: number[]
    ( typeof arg.Sizes === 'undefined' || (Array.isArray(arg.Sizes) && arg.Sizes.every((item: unknown) => typeof item === 'number')) ) &&
    // SizesDetails?: CustomerSizeModel[]
    ( typeof arg.SizesDetails === 'undefined' || (Array.isArray(arg.SizesDetails) && arg.SizesDetails.every((item: unknown) => isCustomerSizeModel(item))) ) &&
    // SMSCarrierID?: number
    ( typeof arg.SMSCarrierID === 'undefined' || typeof arg.SMSCarrierID === 'number' ) &&
    // SMSNotices?: boolean
    ( typeof arg.SMSNotices === 'undefined' || typeof arg.SMSNotices === 'boolean' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // StoredBefore?: boolean
    ( typeof arg.StoredBefore === 'undefined' || typeof arg.StoredBefore === 'boolean' ) &&
    // UseBusinessName?: boolean
    ( typeof arg.UseBusinessName === 'undefined' || typeof arg.UseBusinessName === 'boolean' ) &&
    // WorkPhone?: string
    ( typeof arg.WorkPhone === 'undefined' || typeof arg.WorkPhone === 'string' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isQBAccountModel(arg: any): arg is models.QBAccountModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccntType?: string
    ( typeof arg.AccntType === 'undefined' || typeof arg.AccntType === 'string' ) &&
    // AccNum?: string
    ( typeof arg.AccNum === 'undefined' || typeof arg.AccNum === 'string' ) &&
    // BankNum?: string
    ( typeof arg.BankNum === 'undefined' || typeof arg.BankNum === 'string' ) &&
    // Delcount?: string
    ( typeof arg.Delcount === 'undefined' || typeof arg.Delcount === 'string' ) &&
    // Desc?: string
    ( typeof arg.Desc === 'undefined' || typeof arg.Desc === 'string' ) &&
    // Extra?: string
    ( typeof arg.Extra === 'undefined' || typeof arg.Extra === 'string' ) &&
    // Hidden?: string
    ( typeof arg.Hidden === 'undefined' || typeof arg.Hidden === 'string' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // OBAmount?: string
    ( typeof arg.OBAmount === 'undefined' || typeof arg.OBAmount === 'string' ) &&
    // RefNum?: string
    ( typeof arg.RefNum === 'undefined' || typeof arg.RefNum === 'string' ) &&
    // SCD?: string
    ( typeof arg.SCD === 'undefined' || typeof arg.SCD === 'string' ) &&
    // TimeStamp?: string
    ( typeof arg.TimeStamp === 'undefined' || typeof arg.TimeStamp === 'string' ) &&
    // UseID?: string
    ( typeof arg.UseID === 'undefined' || typeof arg.UseID === 'string' ) &&

  true
  );
  }

export function isRateChangeModel(arg: any): arg is models.RateChangeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ChangeAmount?: number
    ( typeof arg.ChangeAmount === 'undefined' || typeof arg.ChangeAmount === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EffectiveDate?: string
    ( typeof arg.EffectiveDate === 'undefined' || typeof arg.EffectiveDate === 'string' ) &&
    // EffectiveDays?: number
    ( typeof arg.EffectiveDays === 'undefined' || typeof arg.EffectiveDays === 'number' ) &&
    // IncreasePercent?: number
    ( typeof arg.IncreasePercent === 'undefined' || typeof arg.IncreasePercent === 'number' ) &&
    // Leases?: UnitRateChangeModel[]
    ( typeof arg.Leases === 'undefined' || (Array.isArray(arg.Leases) && arg.Leases.every((item: unknown) => isUnitRateChangeModel(item))) ) &&
    // MaxRate?: number
    ( typeof arg.MaxRate === 'undefined' || typeof arg.MaxRate === 'number' ) &&
    // NewRate?: number
    ( typeof arg.NewRate === 'undefined' || typeof arg.NewRate === 'number' ) &&
    // NewRateCalcMethodID?: number
    ( typeof arg.NewRateCalcMethodID === 'undefined' || typeof arg.NewRateCalcMethodID === 'number' ) &&
    // NoticeDays?: number
    ( typeof arg.NoticeDays === 'undefined' || typeof arg.NoticeDays === 'number' ) &&
    // RateChangeID?: number
    ( typeof arg.RateChangeID === 'undefined' || typeof arg.RateChangeID === 'number' ) &&
    // RateChangeTypeID?: number
    ( typeof arg.RateChangeTypeID === 'undefined' || typeof arg.RateChangeTypeID === 'number' ) &&
    // RecurPeriods?: number
    ( typeof arg.RecurPeriods === 'undefined' || typeof arg.RecurPeriods === 'number' ) &&
    // RoundingMethodID?: number
    ( typeof arg.RoundingMethodID === 'undefined' || typeof arg.RoundingMethodID === 'number' ) &&
    // TargetPercent?: number
    ( typeof arg.TargetPercent === 'undefined' || typeof arg.TargetPercent === 'number' ) &&

  true
  );
  }

export function isRecurringChargePostModel(arg: any): arg is models.RecurringChargePostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountID?: number
    ( typeof arg.AccountID === 'undefined' || typeof arg.AccountID === 'number' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // IsNewItem?: boolean
    ( typeof arg.IsNewItem === 'undefined' || typeof arg.IsNewItem === 'boolean' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&

  true
  );
  }

export function isRecurringChargesItem(arg: any): arg is models.RecurringChargesItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountCategory?: '1' | '5' | '10' | '12' | '15' | '20' | '25' | '30' | '35' | '40' | '45' | '50' | '55' | '70' | '80' | '85' | '90' | '92' | '95' | '97' | '100'
    ( typeof arg.AccountCategory === 'undefined' || ['1', '5', '10', '12', '15', '20', '25', '30', '35', '40', '45', '50', '55', '70', '80', '85', '90', '92', '95', '97', '100'].includes(arg.AccountCategory) ) &&
    // AccountId?: number
    ( typeof arg.AccountId === 'undefined' || typeof arg.AccountId === 'number' ) &&
    // AccountNo?: string
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'string' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // IsEditable?: boolean
    ( typeof arg.IsEditable === 'undefined' || typeof arg.IsEditable === 'boolean' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isReferanceTablePostRequest(arg: any): arg is models.ReferanceTablePostRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Data?: object
    ( typeof arg.Data === 'undefined' || typeof arg.Data === 'object' ) &&
    // TableIndex?: number
    ( typeof arg.TableIndex === 'undefined' || typeof arg.TableIndex === 'number' ) &&

  true
  );
  }

export function isReferanceTableRequest(arg: any): arg is models.ReferanceTableRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // RegionCode?: number
    ( typeof arg.RegionCode === 'undefined' || typeof arg.RegionCode === 'number' ) &&
    // TableIndex?: number
    ( typeof arg.TableIndex === 'undefined' || typeof arg.TableIndex === 'number' ) &&

  true
  );
  }

export function isRelayModel(arg: any): arg is models.RelayModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // RelayID?: number
    ( typeof arg.RelayID === 'undefined' || typeof arg.RelayID === 'number' ) &&

  true
  );
  }

export function isReminderEntryResponse(arg: any): arg is models.ReminderEntryResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Date?: string
    ( typeof arg.Date === 'undefined' || typeof arg.Date === 'string' ) &&
    // ReminderID?: number
    ( typeof arg.ReminderID === 'undefined' || typeof arg.ReminderID === 'number' ) &&
    // ReminderText?: string
    ( typeof arg.ReminderText === 'undefined' || typeof arg.ReminderText === 'string' ) &&

  true
  );
  }

export function isReminderRequest(arg: any): arg is models.ReminderRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // EndDate?: string
    ( typeof arg.EndDate === 'undefined' || typeof arg.EndDate === 'string' ) &&
    // ReminderId?: number
    ( typeof arg.ReminderId === 'undefined' || typeof arg.ReminderId === 'number' ) &&
    // ReminderText?: string
    ( typeof arg.ReminderText === 'undefined' || typeof arg.ReminderText === 'string' ) &&
    // ReminderTypeId?: number
    ( typeof arg.ReminderTypeId === 'undefined' || typeof arg.ReminderTypeId === 'number' ) &&
    // StartDate?: string
    ( typeof arg.StartDate === 'undefined' || typeof arg.StartDate === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&

  true
  );
  }

export function isReminderResponseModel(arg: any): arg is models.ReminderResponseModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerFirstName?: string
    ( typeof arg.CustomerFirstName === 'undefined' || typeof arg.CustomerFirstName === 'string' ) &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // CustomerLastName?: string
    ( typeof arg.CustomerLastName === 'undefined' || typeof arg.CustomerLastName === 'string' ) &&
    // EndDate?: string
    ( typeof arg.EndDate === 'undefined' || typeof arg.EndDate === 'string' ) &&
    // ReminderDate?: string
    ( typeof arg.ReminderDate === 'undefined' || typeof arg.ReminderDate === 'string' ) &&
    // ReminderId?: number
    ( typeof arg.ReminderId === 'undefined' || typeof arg.ReminderId === 'number' ) &&
    // ReminderText?: string
    ( typeof arg.ReminderText === 'undefined' || typeof arg.ReminderText === 'string' ) &&
    // ReminderTypeDescription?: string
    ( typeof arg.ReminderTypeDescription === 'undefined' || typeof arg.ReminderTypeDescription === 'string' ) &&
    // ReminderTypeId?: number
    ( typeof arg.ReminderTypeId === 'undefined' || typeof arg.ReminderTypeId === 'number' ) &&
    // StartDate?: string
    ( typeof arg.StartDate === 'undefined' || typeof arg.StartDate === 'string' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isReminderTypeModel(arg: any): arg is models.ReminderTypeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // ReminderTypeId?: number
    ( typeof arg.ReminderTypeId === 'undefined' || typeof arg.ReminderTypeId === 'number' ) &&

  true
  );
  }

export function isReportCategoryListItem(arg: any): arg is models.ReportCategoryListItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // Selected?: boolean
    ( typeof arg.Selected === 'undefined' || typeof arg.Selected === 'boolean' ) &&

  true
  );
  }

export function isReportCategoryModel(arg: any): arg is models.ReportCategoryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CanDelete?: boolean
    ( typeof arg.CanDelete === 'undefined' || typeof arg.CanDelete === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // ReportCategory?: number
    ( typeof arg.ReportCategory === 'undefined' || typeof arg.ReportCategory === 'number' ) &&
    // ReportCategoryID?: number
    ( typeof arg.ReportCategoryID === 'undefined' || typeof arg.ReportCategoryID === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&

  true
  );
  }

export function isReportInfoPostModel(arg: any): arg is models.ReportInfoPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AllTenants?: boolean
    ( typeof arg.AllTenants === 'undefined' || typeof arg.AllTenants === 'boolean' ) &&
    // DateRangeColumn?: string
    ( typeof arg.DateRangeColumn === 'undefined' || typeof arg.DateRangeColumn === 'string' ) &&
    // DefaultDateRange?: number
    ( typeof arg.DefaultDateRange === 'undefined' || typeof arg.DefaultDateRange === 'number' ) &&
    // DefaultGroupSelectionFormula?: string
    ( typeof arg.DefaultGroupSelectionFormula === 'undefined' || typeof arg.DefaultGroupSelectionFormula === 'string' ) &&
    // DefaultRecordSelectionFormula?: string
    ( typeof arg.DefaultRecordSelectionFormula === 'undefined' || typeof arg.DefaultRecordSelectionFormula === 'string' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // EffectiveDateApplies?: boolean
    ( typeof arg.EffectiveDateApplies === 'undefined' || typeof arg.EffectiveDateApplies === 'boolean' ) &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&
    // OperationNo?: number
    ( typeof arg.OperationNo === 'undefined' || typeof arg.OperationNo === 'number' ) &&
    // ReportCategoryID?: number
    ( typeof arg.ReportCategoryID === 'undefined' || typeof arg.ReportCategoryID === 'number' ) &&
    // ReportInfoID?: number
    ( typeof arg.ReportInfoID === 'undefined' || typeof arg.ReportInfoID === 'number' ) &&
    // ReportNo?: number
    ( typeof arg.ReportNo === 'undefined' || typeof arg.ReportNo === 'number' ) &&
    // ReportType?: number
    ( typeof arg.ReportType === 'undefined' || typeof arg.ReportType === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SiteOptionsApply?: boolean
    ( typeof arg.SiteOptionsApply === 'undefined' || typeof arg.SiteOptionsApply === 'boolean' ) &&
    // SystemRow?: boolean
    ( typeof arg.SystemRow === 'undefined' || typeof arg.SystemRow === 'boolean' ) &&
    // TenantOptionsApply?: boolean
    ( typeof arg.TenantOptionsApply === 'undefined' || typeof arg.TenantOptionsApply === 'boolean' ) &&
    // Title?: string
    ( typeof arg.Title === 'undefined' || typeof arg.Title === 'string' ) &&
    // UserOptionsApply?: boolean
    ( typeof arg.UserOptionsApply === 'undefined' || typeof arg.UserOptionsApply === 'boolean' ) &&
    // UsesDateRange?: boolean
    ( typeof arg.UsesDateRange === 'undefined' || typeof arg.UsesDateRange === 'boolean' ) &&
    // UsesSortOptions?: boolean
    ( typeof arg.UsesSortOptions === 'undefined' || typeof arg.UsesSortOptions === 'boolean' ) &&

  true
  );
  }

export function isReportInfoViewModel(arg: any): arg is models.ReportInfoViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AllTenants?: boolean
    ( typeof arg.AllTenants === 'undefined' || typeof arg.AllTenants === 'boolean' ) &&
    // DateRangeColumn?: string
    ( typeof arg.DateRangeColumn === 'undefined' || typeof arg.DateRangeColumn === 'string' ) &&
    // DefaultDateRange?: number
    ( typeof arg.DefaultDateRange === 'undefined' || typeof arg.DefaultDateRange === 'number' ) &&
    // DefaultGroupSelectionFormula?: string
    ( typeof arg.DefaultGroupSelectionFormula === 'undefined' || typeof arg.DefaultGroupSelectionFormula === 'string' ) &&
    // DefaultRecordSelectionFormula?: string
    ( typeof arg.DefaultRecordSelectionFormula === 'undefined' || typeof arg.DefaultRecordSelectionFormula === 'string' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // EffectiveDateApplies?: boolean
    ( typeof arg.EffectiveDateApplies === 'undefined' || typeof arg.EffectiveDateApplies === 'boolean' ) &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&
    // Modifieddate?: string
    ( typeof arg.Modifieddate === 'undefined' || typeof arg.Modifieddate === 'string' ) &&
    // OperationNo?: number
    ( typeof arg.OperationNo === 'undefined' || typeof arg.OperationNo === 'number' ) &&
    // ReportCategoryID?: number
    ( typeof arg.ReportCategoryID === 'undefined' || typeof arg.ReportCategoryID === 'number' ) &&
    // ReportCategoryName?: string
    ( typeof arg.ReportCategoryName === 'undefined' || typeof arg.ReportCategoryName === 'string' ) &&
    // ReportInfoID?: number
    ( typeof arg.ReportInfoID === 'undefined' || typeof arg.ReportInfoID === 'number' ) &&
    // ReportNo?: number
    ( typeof arg.ReportNo === 'undefined' || typeof arg.ReportNo === 'number' ) &&
    // ReportType?: number
    ( typeof arg.ReportType === 'undefined' || typeof arg.ReportType === 'number' ) &&
    // ReportTypeName?: string
    ( typeof arg.ReportTypeName === 'undefined' || typeof arg.ReportTypeName === 'string' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SiteOptionsApply?: boolean
    ( typeof arg.SiteOptionsApply === 'undefined' || typeof arg.SiteOptionsApply === 'boolean' ) &&
    // SystemRow?: boolean
    ( typeof arg.SystemRow === 'undefined' || typeof arg.SystemRow === 'boolean' ) &&
    // TenantOptionsApply?: boolean
    ( typeof arg.TenantOptionsApply === 'undefined' || typeof arg.TenantOptionsApply === 'boolean' ) &&
    // Title?: string
    ( typeof arg.Title === 'undefined' || typeof arg.Title === 'string' ) &&
    // UserOptionsApply?: boolean
    ( typeof arg.UserOptionsApply === 'undefined' || typeof arg.UserOptionsApply === 'boolean' ) &&
    // UsesDateRange?: boolean
    ( typeof arg.UsesDateRange === 'undefined' || typeof arg.UsesDateRange === 'boolean' ) &&
    // UsesSortOptions?: boolean
    ( typeof arg.UsesSortOptions === 'undefined' || typeof arg.UsesSortOptions === 'boolean' ) &&

  true
  );
  }

export function isReportListModel(arg: any): arg is models.ReportListModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AllDates?: boolean
    ( typeof arg.AllDates === 'undefined' || typeof arg.AllDates === 'boolean' ) &&
    // AllTenants?: boolean
    ( typeof arg.AllTenants === 'undefined' || typeof arg.AllTenants === 'boolean' ) &&
    // Checked?: boolean
    ( typeof arg.Checked === 'undefined' || typeof arg.Checked === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // ReportNo?: number
    ( typeof arg.ReportNo === 'undefined' || typeof arg.ReportNo === 'number' ) &&
    // TenantOptionsApply?: boolean
    ( typeof arg.TenantOptionsApply === 'undefined' || typeof arg.TenantOptionsApply === 'boolean' ) &&
    // UseDateRange?: boolean
    ( typeof arg.UseDateRange === 'undefined' || typeof arg.UseDateRange === 'boolean' ) &&
    // UserOptionsApply?: boolean
    ( typeof arg.UserOptionsApply === 'undefined' || typeof arg.UserOptionsApply === 'boolean' ) &&

  true
  );
  }

export function isReportResponse(arg: any): arg is models.ReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // FileNames?: object[]
    ( typeof arg.FileNames === 'undefined' || (Array.isArray(arg.FileNames) && arg.FileNames.every((item: unknown) => typeof item === 'object')) ) &&

  true
  );
  }

export function isReprintCloseRequest(arg: any): arg is models.ReprintCloseRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ClosingID?: number
    ( typeof arg.ClosingID === 'undefined' || typeof arg.ClosingID === 'number' ) &&
    // EmailTo?: string
    ( typeof arg.EmailTo === 'undefined' || typeof arg.EmailTo === 'string' ) &&
    // FileFormatCategory?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11'
    ( typeof arg.FileFormatCategory === 'undefined' || ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'].includes(arg.FileFormatCategory) ) &&

  true
  );
  }

export function isResetPasswordRequest(arg: any): arg is models.ResetPasswordRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Code?: string
    ( typeof arg.Code === 'undefined' || typeof arg.Code === 'string' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // EmailAddress: string
    ( typeof arg.EmailAddress === 'string' ) &&
    // Password: string
    ( typeof arg.Password === 'string' ) &&

  true
  );
  }

export function isResponseAccessControlModel(arg: any): arg is models.ResponseAccessControlModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AccessControlModel
    ( typeof arg.Result === 'undefined' || isAccessControlModel(arg.Result) ) &&

  true
  );
  }

export function isResponseAccountInfo(arg: any): arg is models.ResponseAccountInfo {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AccountInfo
    ( typeof arg.Result === 'undefined' || isAccountInfo(arg.Result) ) &&

  true
  );
  }

export function isResponseAuctionLocation(arg: any): arg is models.ResponseAuctionLocation {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AuctionLocation
    ( typeof arg.Result === 'undefined' || isAuctionLocation(arg.Result) ) &&

  true
  );
  }

export function isResponseBalanceResponse(arg: any): arg is models.ResponseBalanceResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: BalanceResponse
    ( typeof arg.Result === 'undefined' || isBalanceResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseBillingInfoModel(arg: any): arg is models.ResponseBillingInfoModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: BillingInfoModel
    ( typeof arg.Result === 'undefined' || isBillingInfoModel(arg.Result) ) &&

  true
  );
  }

export function isResponseBlueMoonSettingModel(arg: any): arg is models.ResponseBlueMoonSettingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: BlueMoonSettingModel
    ( typeof arg.Result === 'undefined' || isBlueMoonSettingModel(arg.Result) ) &&

  true
  );
  }

export function isResponseBoolean(arg: any): arg is models.ResponseBoolean {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: boolean
    ( typeof arg.Result === 'undefined' || typeof arg.Result === 'boolean' ) &&

  true
  );
  }

export function isResponseCancelTransactionResponse(arg: any): arg is models.ResponseCancelTransactionResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CancelTransactionResponse
    ( typeof arg.Result === 'undefined' || isCancelTransactionResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseCancelTransactionVoidRespone(arg: any): arg is models.ResponseCancelTransactionVoidRespone {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CancelTransactionVoidRespone
    ( typeof arg.Result === 'undefined' || isCancelTransactionVoidRespone(arg.Result) ) &&

  true
  );
  }

export function isResponseCheckSystemChargerResponse(arg: any): arg is models.ResponseCheckSystemChargerResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CheckSystemChargerResponse
    ( typeof arg.Result === 'undefined' || isCheckSystemChargerResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseClearentConfigModel(arg: any): arg is models.ResponseClearentConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ClearentConfigModel
    ( typeof arg.Result === 'undefined' || isClearentConfigModel(arg.Result) ) &&

  true
  );
  }

export function isResponseClosestGateResponseModel(arg: any): arg is models.ResponseClosestGateResponseModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ClosestGateResponseModel
    ( typeof arg.Result === 'undefined' || isClosestGateResponseModel(arg.Result) ) &&

  true
  );
  }

export function isResponseCompleteLienResponse(arg: any): arg is models.ResponseCompleteLienResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CompleteLienResponse
    ( typeof arg.Result === 'undefined' || isCompleteLienResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseCustomerDashboardResponse(arg: any): arg is models.ResponseCustomerDashboardResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CustomerDashboardResponse
    ( typeof arg.Result === 'undefined' || isCustomerDashboardResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseCustomerEventHistoryResponse(arg: any): arg is models.ResponseCustomerEventHistoryResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CustomerEventHistoryResponse
    ( typeof arg.Result === 'undefined' || isCustomerEventHistoryResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseCustomerModel(arg: any): arg is models.ResponseCustomerModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CustomerModel
    ( typeof arg.Result === 'undefined' || isCustomerModel(arg.Result) ) &&

  true
  );
  }

export function isResponseCustomerSizesAndUnitsModel(arg: any): arg is models.ResponseCustomerSizesAndUnitsModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CustomerSizesAndUnitsModel
    ( typeof arg.Result === 'undefined' || isCustomerSizesAndUnitsModel(arg.Result) ) &&

  true
  );
  }

export function isResponseDashboardBillingInfoResponse(arg: any): arg is models.ResponseDashboardBillingInfoResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: DashboardBillingInfoResponse
    ( typeof arg.Result === 'undefined' || isDashboardBillingInfoResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseDecimal(arg: any): arg is models.ResponseDecimal {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: number
    ( typeof arg.Result === 'undefined' || typeof arg.Result === 'number' ) &&

  true
  );
  }

export function isResponseDelinquencyReportResponse(arg: any): arg is models.ResponseDelinquencyReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: DelinquencyReportResponse
    ( typeof arg.Result === 'undefined' || isDelinquencyReportResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseDouble(arg: any): arg is models.ResponseDouble {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: number
    ( typeof arg.Result === 'undefined' || typeof arg.Result === 'number' ) &&

  true
  );
  }

export function isResponseEditorResponseModel(arg: any): arg is models.ResponseEditorResponseModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: EditorResponseModel
    ( typeof arg.Result === 'undefined' || isEditorResponseModel(arg.Result) ) &&

  true
  );
  }

export function isResponseEmailSettingModel(arg: any): arg is models.ResponseEmailSettingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: EmailSettingModel
    ( typeof arg.Result === 'undefined' || isEmailSettingModel(arg.Result) ) &&

  true
  );
  }

export function isResponseFeeOptionPostModel(arg: any): arg is models.ResponseFeeOptionPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: FeeOptionPostModel
    ( typeof arg.Result === 'undefined' || isFeeOptionPostModel(arg.Result) ) &&

  true
  );
  }

export function isResponseFileResponse(arg: any): arg is models.ResponseFileResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: FileResponse
    ( typeof arg.Result === 'undefined' || isFileResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseGateAccessDataModel(arg: any): arg is models.ResponseGateAccessDataModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: GateAccessDataModel
    ( typeof arg.Result === 'undefined' || isGateAccessDataModel(arg.Result) ) &&

  true
  );
  }

export function isResponseIEnumerableAccessDeviceModel(arg: any): arg is models.ResponseIEnumerableAccessDeviceModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AccessDeviceModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAccessDeviceModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableAdditionalAccountModel(arg: any): arg is models.ResponseIEnumerableAdditionalAccountModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AdditionalAccountModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAdditionalAccountModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableAdvertisingConfigModel(arg: any): arg is models.ResponseIEnumerableAdvertisingConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AdvertisingConfigModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAdvertisingConfigModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableAutoBillResponse(arg: any): arg is models.ResponseIEnumerableAutoBillResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AutoBillResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAutoBillResponse(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableClearentConfigModel(arg: any): arg is models.ResponseIEnumerableClearentConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ClearentConfigModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isClearentConfigModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableCustomerDemoprapichItem(arg: any): arg is models.ResponseIEnumerableCustomerDemoprapichItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CustomerDemoprapichItem[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isCustomerDemoprapichItem(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableDemographicItem(arg: any): arg is models.ResponseIEnumerableDemographicItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: DemographicItem[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isDemographicItem(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableFollowUpActionModel(arg: any): arg is models.ResponseIEnumerableFollowUpActionModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: FollowUpActionModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isFollowUpActionModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableLetterFileNameViewModel(arg: any): arg is models.ResponseIEnumerableLetterFileNameViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LetterFileNameViewModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isLetterFileNameViewModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableLetterViewModel(arg: any): arg is models.ResponseIEnumerableLetterViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LetterViewModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isLetterViewModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableModelItem(arg: any): arg is models.ResponseIEnumerableModelItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ModelItem[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isModelItem(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableOverLockResponse(arg: any): arg is models.ResponseIEnumerableOverLockResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: OverLockResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isOverLockResponse(item))) ) &&

  true
  );
  }

export function isResponseIEnumerablePayMoverAdditionalAccountModel(arg: any): arg is models.ResponseIEnumerablePayMoverAdditionalAccountModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PayMoverAdditionalAccountModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isPayMoverAdditionalAccountModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerablePicture(arg: any): arg is models.ResponseIEnumerablePicture {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: Picture[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isPicture(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableRelayModel(arg: any): arg is models.ResponseIEnumerableRelayModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: RelayModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isRelayModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableSiteGroupModel(arg: any): arg is models.ResponseIEnumerableSiteGroupModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SiteGroupModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isSiteGroupModel(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableSizesFeatureResponse(arg: any): arg is models.ResponseIEnumerableSizesFeatureResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SizesFeatureResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isSizesFeatureResponse(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableString(arg: any): arg is models.ResponseIEnumerableString {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: string[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => typeof item === 'string')) ) &&

  true
  );
  }

export function isResponseIEnumerableTransactionDetailResponse(arg: any): arg is models.ResponseIEnumerableTransactionDetailResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TransactionDetailResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isTransactionDetailResponse(item))) ) &&

  true
  );
  }

export function isResponseIEnumerableUserAccount(arg: any): arg is models.ResponseIEnumerableUserAccount {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UserAccount[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isUserAccount(item))) ) &&

  true
  );
  }

export function isResponseInsuranceTransDetailResponse(arg: any): arg is models.ResponseInsuranceTransDetailResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: InsuranceTransDetailResponse
    ( typeof arg.Result === 'undefined' || isInsuranceTransDetailResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseInt32(arg: any): arg is models.ResponseInt32 {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: number
    ( typeof arg.Result === 'undefined' || typeof arg.Result === 'number' ) &&

  true
  );
  }

export function isResponseKioskOptionModel(arg: any): arg is models.ResponseKioskOptionModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: KioskOptionModel
    ( typeof arg.Result === 'undefined' || isKioskOptionModel(arg.Result) ) &&

  true
  );
  }

export function isResponseLeaseStatusModel(arg: any): arg is models.ResponseLeaseStatusModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LeaseStatusModel
    ( typeof arg.Result === 'undefined' || isLeaseStatusModel(arg.Result) ) &&

  true
  );
  }

export function isResponseLetterCorrespondenceModel(arg: any): arg is models.ResponseLetterCorrespondenceModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LetterCorrespondenceModel
    ( typeof arg.Result === 'undefined' || isLetterCorrespondenceModel(arg.Result) ) &&

  true
  );
  }

export function isResponseLienChargeResponse(arg: any): arg is models.ResponseLienChargeResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LienChargeResponse
    ( typeof arg.Result === 'undefined' || isLienChargeResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseLienPrintResponse(arg: any): arg is models.ResponseLienPrintResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LienPrintResponse
    ( typeof arg.Result === 'undefined' || isLienPrintResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseLienResponse(arg: any): arg is models.ResponseLienResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LienResponse
    ( typeof arg.Result === 'undefined' || isLienResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseListAccessLevelsItem(arg: any): arg is models.ResponseListAccessLevelsItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AccessLevelsItem[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAccessLevelsItem(item))) ) &&

  true
  );
  }

export function isResponseListAccLevelModel(arg: any): arg is models.ResponseListAccLevelModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AccLevelModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAccLevelModel(item))) ) &&

  true
  );
  }

export function isResponseListAccLevelViewModel(arg: any): arg is models.ResponseListAccLevelViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AccLevelViewModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAccLevelViewModel(item))) ) &&

  true
  );
  }

export function isResponseListAdditionalChargeTypesResponse(arg: any): arg is models.ResponseListAdditionalChargeTypesResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AdditionalChargeTypesResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAdditionalChargeTypesResponse(item))) ) &&

  true
  );
  }

export function isResponseListAuctionLocationModel(arg: any): arg is models.ResponseListAuctionLocationModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AuctionLocationModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAuctionLocationModel(item))) ) &&

  true
  );
  }

export function isResponseListAuctionResponse(arg: any): arg is models.ResponseListAuctionResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: AuctionResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isAuctionResponse(item))) ) &&

  true
  );
  }

export function isResponseListBaseReferenceTable(arg: any): arg is models.ResponseListBaseReferenceTable {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: BaseReferenceTable[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isBaseReferenceTable(item))) ) &&

  true
  );
  }

export function isResponseListCountryModel(arg: any): arg is models.ResponseListCountryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CountryModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isCountryModel(item))) ) &&

  true
  );
  }

export function isResponseListCoverageModel(arg: any): arg is models.ResponseListCoverageModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CoverageModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isCoverageModel(item))) ) &&

  true
  );
  }

export function isResponseListCustomerCreditCardResponse(arg: any): arg is models.ResponseListCustomerCreditCardResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CustomerCreditCardResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isCustomerCreditCardResponse(item))) ) &&

  true
  );
  }

export function isResponseListCustomerModel(arg: any): arg is models.ResponseListCustomerModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: CustomerModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isCustomerModel(item))) ) &&

  true
  );
  }

export function isResponseListDepositGroupModel(arg: any): arg is models.ResponseListDepositGroupModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: DepositGroupModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isDepositGroupModel(item))) ) &&

  true
  );
  }

export function isResponseListDictionaryInsuranceConfigModel(arg: any): arg is models.ResponseListDictionaryInsuranceConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: DictionaryInsuranceConfigModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isDictionaryInsuranceConfigModel(item))) ) &&

  true
  );
  }

export function isResponseListEventCategoriesModel(arg: any): arg is models.ResponseListEventCategoriesModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: EventCategoriesModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isEventCategoriesModel(item))) ) &&

  true
  );
  }

export function isResponseListFollowUpAction(arg: any): arg is models.ResponseListFollowUpAction {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: FollowUpAction[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isFollowUpAction(item))) ) &&

  true
  );
  }

export function isResponseListGateConfigModel(arg: any): arg is models.ResponseListGateConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: GateConfigModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isGateConfigModel(item))) ) &&

  true
  );
  }

export function isResponseListGateInfo(arg: any): arg is models.ResponseListGateInfo {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: GateInfo[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isGateInfo(item))) ) &&

  true
  );
  }

export function isResponseListGateInterfaceModel(arg: any): arg is models.ResponseListGateInterfaceModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: GateInterfaceModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isGateInterfaceModel(item))) ) &&

  true
  );
  }

export function isResponseListGetCustomerContractResponse(arg: any): arg is models.ResponseListGetCustomerContractResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: GetCustomerContractResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isGetCustomerContractResponse(item))) ) &&

  true
  );
  }

export function isResponseListInsuranceConfigModel(arg: any): arg is models.ResponseListInsuranceConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: InsuranceConfigModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isInsuranceConfigModel(item))) ) &&

  true
  );
  }

export function isResponseListInsuranceModel(arg: any): arg is models.ResponseListInsuranceModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: InsuranceModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isInsuranceModel(item))) ) &&

  true
  );
  }

export function isResponseListInsuranceTransactionModel(arg: any): arg is models.ResponseListInsuranceTransactionModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: InsuranceTransactionModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isInsuranceTransactionModel(item))) ) &&

  true
  );
  }

export function isResponseListLateCycleScheduleModel(arg: any): arg is models.ResponseListLateCycleScheduleModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LateCycleScheduleModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isLateCycleScheduleModel(item))) ) &&

  true
  );
  }

export function isResponseListLatestLeadsResponse(arg: any): arg is models.ResponseListLatestLeadsResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LatestLeadsResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isLatestLeadsResponse(item))) ) &&

  true
  );
  }

export function isResponseListMainOfficeViewModel(arg: any): arg is models.ResponseListMainOfficeViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: MainOfficeViewModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isMainOfficeViewModel(item))) ) &&

  true
  );
  }

export function isResponseListMarketingReportResponse(arg: any): arg is models.ResponseListMarketingReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: MarketingReportResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isMarketingReportResponse(item))) ) &&

  true
  );
  }

export function isResponseListMerchandiseModel(arg: any): arg is models.ResponseListMerchandiseModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: MerchandiseModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isMerchandiseModel(item))) ) &&

  true
  );
  }

export function isResponseListModelItem(arg: any): arg is models.ResponseListModelItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ModelItem[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isModelItem(item))) ) &&

  true
  );
  }

export function isResponseListMoveOutLeases(arg: any): arg is models.ResponseListMoveOutLeases {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: MoveOutLeases[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isMoveOutLeases(item))) ) &&

  true
  );
  }

export function isResponseListMoveOutTransDetails(arg: any): arg is models.ResponseListMoveOutTransDetails {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: MoveOutTransDetails[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isMoveOutTransDetails(item))) ) &&

  true
  );
  }

export function isResponseListNoteModel(arg: any): arg is models.ResponseListNoteModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: NoteModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isNoteModel(item))) ) &&

  true
  );
  }

export function isResponseListNoticeResponse(arg: any): arg is models.ResponseListNoticeResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: NoticeResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isNoticeResponse(item))) ) &&

  true
  );
  }

export function isResponseListOperationReportInfoModel(arg: any): arg is models.ResponseListOperationReportInfoModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: OperationReportInfoModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isOperationReportInfoModel(item))) ) &&

  true
  );
  }

export function isResponseListPromoModel(arg: any): arg is models.ResponseListPromoModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PromoModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isPromoModel(item))) ) &&

  true
  );
  }

export function isResponseListQBAccountModel(arg: any): arg is models.ResponseListQBAccountModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: QBAccountModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isQBAccountModel(item))) ) &&

  true
  );
  }

export function isResponseListRateChangeModel(arg: any): arg is models.ResponseListRateChangeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: RateChangeModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isRateChangeModel(item))) ) &&

  true
  );
  }

export function isResponseListRecurringChargesItem(arg: any): arg is models.ResponseListRecurringChargesItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: RecurringChargesItem[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isRecurringChargesItem(item))) ) &&

  true
  );
  }

export function isResponseListReminderEntryResponse(arg: any): arg is models.ResponseListReminderEntryResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ReminderEntryResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isReminderEntryResponse(item))) ) &&

  true
  );
  }

export function isResponseListReminderResponseModel(arg: any): arg is models.ResponseListReminderResponseModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ReminderResponseModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isReminderResponseModel(item))) ) &&

  true
  );
  }

export function isResponseListReminderTypeModel(arg: any): arg is models.ResponseListReminderTypeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ReminderTypeModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isReminderTypeModel(item))) ) &&

  true
  );
  }

export function isResponseListReportCategoryListItem(arg: any): arg is models.ResponseListReportCategoryListItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ReportCategoryListItem[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isReportCategoryListItem(item))) ) &&

  true
  );
  }

export function isResponseListSentinelReportListModel(arg: any): arg is models.ResponseListSentinelReportListModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SentinelReportListModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isSentinelReportListModel(item))) ) &&

  true
  );
  }

export function isResponseListSiteConfigViewModel(arg: any): arg is models.ResponseListSiteConfigViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SiteConfigViewModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isSiteConfigViewModel(item))) ) &&

  true
  );
  }

export function isResponseListSiteModel(arg: any): arg is models.ResponseListSiteModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SiteModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isSiteModel(item))) ) &&

  true
  );
  }

export function isResponseListStateModel(arg: any): arg is models.ResponseListStateModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: StateModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isStateModel(item))) ) &&

  true
  );
  }

export function isResponseListString(arg: any): arg is models.ResponseListString {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: string[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => typeof item === 'string')) ) &&

  true
  );
  }

export function isResponseListTenantHistoryModel(arg: any): arg is models.ResponseListTenantHistoryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TenantHistoryModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isTenantHistoryModel(item))) ) &&

  true
  );
  }

export function isResponseListTimeZoneModel(arg: any): arg is models.ResponseListTimeZoneModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TimeZoneModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isTimeZoneModel(item))) ) &&

  true
  );
  }

export function isResponseListUnitCustomerDataClass(arg: any): arg is models.ResponseListUnitCustomerDataClass {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UnitCustomerDataClass[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isUnitCustomerDataClass(item))) ) &&

  true
  );
  }

export function isResponseListUnitCustomerResponse(arg: any): arg is models.ResponseListUnitCustomerResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UnitCustomerResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isUnitCustomerResponse(item))) ) &&

  true
  );
  }

export function isResponseListUnitTypeResponse(arg: any): arg is models.ResponseListUnitTypeResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UnitTypeResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isUnitTypeResponse(item))) ) &&

  true
  );
  }

export function isResponseListUserGroupModel(arg: any): arg is models.ResponseListUserGroupModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UserGroupModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isUserGroupModel(item))) ) &&

  true
  );
  }

export function isResponseListUserPostModel(arg: any): arg is models.ResponseListUserPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UserPostModel[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isUserPostModel(item))) ) &&

  true
  );
  }

export function isResponseListUserResponse(arg: any): arg is models.ResponseListUserResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UserResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isUserResponse(item))) ) &&

  true
  );
  }

export function isResponseListYearlyRentReportResponse(arg: any): arg is models.ResponseListYearlyRentReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: YearlyRentReportResponse[]
    ( typeof arg.Result === 'undefined' || (Array.isArray(arg.Result) && arg.Result.every((item: unknown) => isYearlyRentReportResponse(item))) ) &&

  true
  );
  }

export function isResponseLoginResponse(arg: any): arg is models.ResponseLoginResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: LoginResponse
    ( typeof arg.Result === 'undefined' || isLoginResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseNextDefaultValue(arg: any): arg is models.ResponseNextDefaultValue {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: NextDefaultValue
    ( typeof arg.Result === 'undefined' || isNextDefaultValue(arg.Result) ) &&

  true
  );
  }

export function isResponseNoteModel(arg: any): arg is models.ResponseNoteModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: NoteModel
    ( typeof arg.Result === 'undefined' || isNoteModel(arg.Result) ) &&

  true
  );
  }

export function isResponseOccupancyReportResponse(arg: any): arg is models.ResponseOccupancyReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: OccupancyReportResponse
    ( typeof arg.Result === 'undefined' || isOccupancyReportResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseOnlineConfigModel(arg: any): arg is models.ResponseOnlineConfigModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: OnlineConfigModel
    ( typeof arg.Result === 'undefined' || isOnlineConfigModel(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseAlarmPointResponse(arg: any): arg is models.ResponsePaginatedListResponseAlarmPointResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseAlarmPointResponse
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseAlarmPointResponse(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseAllContactResponse(arg: any): arg is models.ResponsePaginatedListResponseAllContactResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseAllContactResponse
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseAllContactResponse(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseClosingModel(arg: any): arg is models.ResponsePaginatedListResponseClosingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseClosingModel
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseClosingModel(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseGetCustomersHaveReservedResponse(arg: any): arg is models.ResponsePaginatedListResponseGetCustomersHaveReservedResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseGetCustomersHaveReservedResponse
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseGetCustomersHaveReservedResponse(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseGetWaitingListCustomerResponse(arg: any): arg is models.ResponsePaginatedListResponseGetWaitingListCustomerResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseGetWaitingListCustomerResponse
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseGetWaitingListCustomerResponse(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseOperationModel(arg: any): arg is models.ResponsePaginatedListResponseOperationModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseOperationModel
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseOperationModel(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseProspectCustomerModel(arg: any): arg is models.ResponsePaginatedListResponseProspectCustomerModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseProspectCustomerModel
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseProspectCustomerModel(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseReportListModel(arg: any): arg is models.ResponsePaginatedListResponseReportListModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseReportListModel
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseReportListModel(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseSizeModel(arg: any): arg is models.ResponsePaginatedListResponseSizeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseSizeModel
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseSizeModel(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseTenantModel(arg: any): arg is models.ResponsePaginatedListResponseTenantModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseTenantModel
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseTenantModel(arg.Result) ) &&

  true
  );
  }

export function isResponsePaginatedListResponseUnitSummaryModel(arg: any): arg is models.ResponsePaginatedListResponseUnitSummaryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaginatedListResponseUnitSummaryModel
    ( typeof arg.Result === 'undefined' || isPaginatedListResponseUnitSummaryModel(arg.Result) ) &&

  true
  );
  }

export function isResponsePaymentInfoResponse(arg: any): arg is models.ResponsePaymentInfoResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PaymentInfoResponse
    ( typeof arg.Result === 'undefined' || isPaymentInfoResponse(arg.Result) ) &&

  true
  );
  }

export function isResponsePayMoverModel(arg: any): arg is models.ResponsePayMoverModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PayMoverModel
    ( typeof arg.Result === 'undefined' || isPayMoverModel(arg.Result) ) &&

  true
  );
  }

export function isResponsePreviewExportQuickBooksResponse(arg: any): arg is models.ResponsePreviewExportQuickBooksResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PreviewExportQuickBooksResponse
    ( typeof arg.Result === 'undefined' || isPreviewExportQuickBooksResponse(arg.Result) ) &&

  true
  );
  }

export function isResponsePrinterModel(arg: any): arg is models.ResponsePrinterModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: PrinterModel
    ( typeof arg.Result === 'undefined' || isPrinterModel(arg.Result) ) &&

  true
  );
  }

export function isResponseProofEditInfo(arg: any): arg is models.ResponseProofEditInfo {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ProofEditInfo
    ( typeof arg.Result === 'undefined' || isProofEditInfo(arg.Result) ) &&

  true
  );
  }

export function isResponseProspectCustomerModel(arg: any): arg is models.ResponseProspectCustomerModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ProspectCustomerModel
    ( typeof arg.Result === 'undefined' || isProspectCustomerModel(arg.Result) ) &&

  true
  );
  }

export function isResponseReminderResponseModel(arg: any): arg is models.ResponseReminderResponseModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ReminderResponseModel
    ( typeof arg.Result === 'undefined' || isReminderResponseModel(arg.Result) ) &&

  true
  );
  }

export function isResponseReportInfoViewModel(arg: any): arg is models.ResponseReportInfoViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ReportInfoViewModel
    ( typeof arg.Result === 'undefined' || isReportInfoViewModel(arg.Result) ) &&

  true
  );
  }

export function isResponseReportResponse(arg: any): arg is models.ResponseReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ReportResponse
    ( typeof arg.Result === 'undefined' || isReportResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseRetailSalesCostResponse(arg: any): arg is models.ResponseRetailSalesCostResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: RetailSalesCostResponse
    ( typeof arg.Result === 'undefined' || isRetailSalesCostResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseRetailSalesResponse(arg: any): arg is models.ResponseRetailSalesResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: RetailSalesResponse
    ( typeof arg.Result === 'undefined' || isRetailSalesResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseReturnCheckModel(arg: any): arg is models.ResponseReturnCheckModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: ReturnCheckModel
    ( typeof arg.Result === 'undefined' || isReturnCheckModel(arg.Result) ) &&

  true
  );
  }

export function isResponseRevenueReportResponse(arg: any): arg is models.ResponseRevenueReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: RevenueReportResponse
    ( typeof arg.Result === 'undefined' || isRevenueReportResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseSaveCreditCardClearentResponse(arg: any): arg is models.ResponseSaveCreditCardClearentResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SaveCreditCardClearentResponse
    ( typeof arg.Result === 'undefined' || isSaveCreditCardClearentResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseSignalRConfigurationModel(arg: any): arg is models.ResponseSignalRConfigurationModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SignalRConfigurationModel
    ( typeof arg.Result === 'undefined' || isSignalRConfigurationModel(arg.Result) ) &&

  true
  );
  }

export function isResponseSiteModel(arg: any): arg is models.ResponseSiteModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SiteModel
    ( typeof arg.Result === 'undefined' || isSiteModel(arg.Result) ) &&

  true
  );
  }

export function isResponseSiteSettingModel(arg: any): arg is models.ResponseSiteSettingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SiteSettingModel
    ( typeof arg.Result === 'undefined' || isSiteSettingModel(arg.Result) ) &&

  true
  );
  }

export function isResponseSMTPSettingModel(arg: any): arg is models.ResponseSMTPSettingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SMTPSettingModel
    ( typeof arg.Result === 'undefined' || isSMTPSettingModel(arg.Result) ) &&

  true
  );
  }

export function isResponseString(arg: any): arg is models.ResponseString {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: string
    ( typeof arg.Result === 'undefined' || typeof arg.Result === 'string' ) &&

  true
  );
  }

export function isResponseSystemChargerLogResponse(arg: any): arg is models.ResponseSystemChargerLogResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SystemChargerLogResponse
    ( typeof arg.Result === 'undefined' || isSystemChargerLogResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseSystemChargerRunResponse(arg: any): arg is models.ResponseSystemChargerRunResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SystemChargerRunResponse
    ( typeof arg.Result === 'undefined' || isSystemChargerRunResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseSystemDateModel(arg: any): arg is models.ResponseSystemDateModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: SystemDateModel
    ( typeof arg.Result === 'undefined' || isSystemDateModel(arg.Result) ) &&

  true
  );
  }

export function isResponseTenantGeneralInfoResponse(arg: any): arg is models.ResponseTenantGeneralInfoResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TenantGeneralInfoResponse
    ( typeof arg.Result === 'undefined' || isTenantGeneralInfoResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseTenantLetterPrintResponse(arg: any): arg is models.ResponseTenantLetterPrintResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TenantLetterPrintResponse
    ( typeof arg.Result === 'undefined' || isTenantLetterPrintResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseTenantOptionPostModel(arg: any): arg is models.ResponseTenantOptionPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TenantOptionPostModel
    ( typeof arg.Result === 'undefined' || isTenantOptionPostModel(arg.Result) ) &&

  true
  );
  }

export function isResponseTimeZoneModel(arg: any): arg is models.ResponseTimeZoneModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TimeZoneModel
    ( typeof arg.Result === 'undefined' || isTimeZoneModel(arg.Result) ) &&

  true
  );
  }

export function isResponseTrackingModel(arg: any): arg is models.ResponseTrackingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TrackingModel
    ( typeof arg.Result === 'undefined' || isTrackingModel(arg.Result) ) &&

  true
  );
  }

export function isResponseTransactionHistoryResponse(arg: any): arg is models.ResponseTransactionHistoryResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TransactionHistoryResponse
    ( typeof arg.Result === 'undefined' || isTransactionHistoryResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseTransactionResult(arg: any): arg is models.ResponseTransactionResult {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TransactionResult
    ( typeof arg.Result === 'undefined' || isTransactionResult(arg.Result) ) &&

  true
  );
  }

export function isResponseTransferCostResponse(arg: any): arg is models.ResponseTransferCostResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TransferCostResponse
    ( typeof arg.Result === 'undefined' || isTransferCostResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseTransferResponse(arg: any): arg is models.ResponseTransferResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TransferResponse
    ( typeof arg.Result === 'undefined' || isTransferResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseTransFitModel(arg: any): arg is models.ResponseTransFitModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: TransFitModel
    ( typeof arg.Result === 'undefined' || isTransFitModel(arg.Result) ) &&

  true
  );
  }

export function isResponseUnitCustomerDetailResponse(arg: any): arg is models.ResponseUnitCustomerDetailResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UnitCustomerDetailResponse
    ( typeof arg.Result === 'undefined' || isUnitCustomerDetailResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseUnitLinkModel(arg: any): arg is models.ResponseUnitLinkModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UnitLinkModel
    ( typeof arg.Result === 'undefined' || isUnitLinkModel(arg.Result) ) &&

  true
  );
  }

export function isResponseUnitStatusModel(arg: any): arg is models.ResponseUnitStatusModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UnitStatusModel
    ( typeof arg.Result === 'undefined' || isUnitStatusModel(arg.Result) ) &&

  true
  );
  }

export function isResponseUpsertCustomerResponse(arg: any): arg is models.ResponseUpsertCustomerResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UpsertCustomerResponse
    ( typeof arg.Result === 'undefined' || isUpsertCustomerResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseUserResponse(arg: any): arg is models.ResponseUserResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UserResponse
    ( typeof arg.Result === 'undefined' || isUserResponse(arg.Result) ) &&

  true
  );
  }

export function isResponseUserSecurityModel(arg: any): arg is models.ResponseUserSecurityModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: UserSecurityModel
    ( typeof arg.Result === 'undefined' || isUserSecurityModel(arg.Result) ) &&

  true
  );
  }

export function isResponseWaitingListCustomerModel(arg: any): arg is models.ResponseWaitingListCustomerModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ErrorMessage?: string
    ( typeof arg.ErrorMessage === 'undefined' || typeof arg.ErrorMessage === 'string' ) &&
    // IsSucceed?: boolean
    ( typeof arg.IsSucceed === 'undefined' || typeof arg.IsSucceed === 'boolean' ) &&
    // Result?: WaitingListCustomerModel
    ( typeof arg.Result === 'undefined' || isWaitingListCustomerModel(arg.Result) ) &&

  true
  );
  }

export function isRetailSaleItem(arg: any): arg is models.RetailSaleItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountId?: number
    ( typeof arg.AccountId === 'undefined' || typeof arg.AccountId === 'number' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // MerchandiseId?: number
    ( typeof arg.MerchandiseId === 'undefined' || typeof arg.MerchandiseId === 'number' ) &&
    // Price?: number
    ( typeof arg.Price === 'undefined' || typeof arg.Price === 'number' ) &&
    // Quantity?: number
    ( typeof arg.Quantity === 'undefined' || typeof arg.Quantity === 'number' ) &&
    // TransactionType?: '1' | '2'
    ( typeof arg.TransactionType === 'undefined' || ['1', '2'].includes(arg.TransactionType) ) &&

  true
  );
  }

export function isRetailSalesCostRequest(arg: any): arg is models.RetailSalesCostRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // SaleItems?: RetailSaleItem[]
    ( typeof arg.SaleItems === 'undefined' || (Array.isArray(arg.SaleItems) && arg.SaleItems.every((item: unknown) => isRetailSaleItem(item))) ) &&

  true
  );
  }

export function isRetailSalesCostResponse(arg: any): arg is models.RetailSalesCostResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BalanceDue?: number
    ( typeof arg.BalanceDue === 'undefined' || typeof arg.BalanceDue === 'number' ) &&
    // TransactionDetails?: TransactionDetailResponse[]
    ( typeof arg.TransactionDetails === 'undefined' || (Array.isArray(arg.TransactionDetails) && arg.TransactionDetails.every((item: unknown) => isTransactionDetailResponse(item))) ) &&

  true
  );
  }

export function isRetailSalesRequest(arg: any): arg is models.RetailSalesRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AchPaymentAmount?: number
    ( typeof arg.AchPaymentAmount === 'undefined' || typeof arg.AchPaymentAmount === 'number' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankNo?: string
    ( typeof arg.BankNo === 'undefined' || typeof arg.BankNo === 'string' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // CashPaymentAmount?: number
    ( typeof arg.CashPaymentAmount === 'undefined' || typeof arg.CashPaymentAmount === 'number' ) &&
    // CheckNo?: string
    ( typeof arg.CheckNo === 'undefined' || typeof arg.CheckNo === 'string' ) &&
    // CheckPaymentAmount?: number
    ( typeof arg.CheckPaymentAmount === 'undefined' || typeof arg.CheckPaymentAmount === 'number' ) &&
    // ClearentJWTToken?: string
    ( typeof arg.ClearentJWTToken === 'undefined' || typeof arg.ClearentJWTToken === 'string' ) &&
    // CreditCardPaymentAmount?: number
    ( typeof arg.CreditCardPaymentAmount === 'undefined' || typeof arg.CreditCardPaymentAmount === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // IsAutoBill?: boolean
    ( typeof arg.IsAutoBill === 'undefined' || typeof arg.IsAutoBill === 'boolean' ) &&
    // PayerId?: string
    ( typeof arg.PayerId === 'undefined' || typeof arg.PayerId === 'string' ) &&
    // SaleItems?: RetailSaleItem[]
    ( typeof arg.SaleItems === 'undefined' || (Array.isArray(arg.SaleItems) && arg.SaleItems.every((item: unknown) => isRetailSaleItem(item))) ) &&
    // SavePaymentInfo?: boolean
    ( typeof arg.SavePaymentInfo === 'undefined' || typeof arg.SavePaymentInfo === 'boolean' ) &&
    // SubmittedTransactionIdentifier?: string
    ( typeof arg.SubmittedTransactionIdentifier === 'undefined' || typeof arg.SubmittedTransactionIdentifier === 'string' ) &&
    // TransactionIdentifier?: string
    ( typeof arg.TransactionIdentifier === 'undefined' || typeof arg.TransactionIdentifier === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // UseCardOnFile?: boolean
    ( typeof arg.UseCardOnFile === 'undefined' || typeof arg.UseCardOnFile === 'boolean' ) &&

  true
  );
  }

export function isRetailSalesResponse(arg: any): arg is models.RetailSalesResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // TransactionDetails?: TransactionDetailResponse[]
    ( typeof arg.TransactionDetails === 'undefined' || (Array.isArray(arg.TransactionDetails) && arg.TransactionDetails.every((item: unknown) => isTransactionDetailResponse(item))) ) &&
    // TransactionId?: number
    ( typeof arg.TransactionId === 'undefined' || typeof arg.TransactionId === 'number' ) &&

  true
  );
  }

export function isReturnCheckCustomer(arg: any): arg is models.ReturnCheckCustomer {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address?: string
    ( typeof arg.Address === 'undefined' || typeof arg.Address === 'string' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&

  true
  );
  }

export function isReturnCheckLeaseItem(arg: any): arg is models.ReturnCheckLeaseItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CurrentPaidToDate?: string
    ( typeof arg.CurrentPaidToDate === 'undefined' || typeof arg.CurrentPaidToDate === 'string' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // MoveInDate?: string
    ( typeof arg.MoveInDate === 'undefined' || typeof arg.MoveInDate === 'string' ) &&
    // NewPaidToDate?: string
    ( typeof arg.NewPaidToDate === 'undefined' || typeof arg.NewPaidToDate === 'string' ) &&
    // Status?: string
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&

  true
  );
  }

export function isReturnCheckModel(arg: any): arg is models.ReturnCheckModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BalanceDue?: number
    ( typeof arg.BalanceDue === 'undefined' || typeof arg.BalanceDue === 'number' ) &&
    // Customer?: ReturnCheckCustomer
    ( typeof arg.Customer === 'undefined' || isReturnCheckCustomer(arg.Customer) ) &&
    // NFSCharge?: number
    ( typeof arg.NFSCharge === 'undefined' || typeof arg.NFSCharge === 'number' ) &&
    // RetCheckAmount?: number
    ( typeof arg.RetCheckAmount === 'undefined' || typeof arg.RetCheckAmount === 'number' ) &&
    // RetCheckNumber?: string
    ( typeof arg.RetCheckNumber === 'undefined' || typeof arg.RetCheckNumber === 'string' ) &&
    // RetLateFee?: number
    ( typeof arg.RetLateFee === 'undefined' || typeof arg.RetLateFee === 'number' ) &&
    // TransDetails?: ReturnCheckTransDetail[]
    ( typeof arg.TransDetails === 'undefined' || (Array.isArray(arg.TransDetails) && arg.TransDetails.every((item: unknown) => isReturnCheckTransDetail(item))) ) &&
    // Units?: ReturnCheckLeaseItem[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => isReturnCheckLeaseItem(item))) ) &&

  true
  );
  }

export function isReturnCheckPostModel(arg: any): arg is models.ReturnCheckPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // NFSCharge?: number
    ( typeof arg.NFSCharge === 'undefined' || typeof arg.NFSCharge === 'number' ) &&
    // RetCheckNumber?: string
    ( typeof arg.RetCheckNumber === 'undefined' || typeof arg.RetCheckNumber === 'string' ) &&
    // RetLateFee?: number
    ( typeof arg.RetLateFee === 'undefined' || typeof arg.RetLateFee === 'number' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&

  true
  );
  }

export function isReturnCheckTransDetail(arg: any): arg is models.ReturnCheckTransDetail {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountNo?: number
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'number' ) &&
    // Balance?: number
    ( typeof arg.Balance === 'undefined' || typeof arg.Balance === 'number' ) &&
    // Charges?: number
    ( typeof arg.Charges === 'undefined' || typeof arg.Charges === 'number' ) &&
    // Credits?: number
    ( typeof arg.Credits === 'undefined' || typeof arg.Credits === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isRevenueReportResponse(arg: any): arg is models.RevenueReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Range1To30Amount?: number
    ( typeof arg.Range1To30Amount === 'undefined' || typeof arg.Range1To30Amount === 'number' ) &&
    // Range31To60Amount?: number
    ( typeof arg.Range31To60Amount === 'undefined' || typeof arg.Range31To60Amount === 'number' ) &&
    // Range61To90Amount?: number
    ( typeof arg.Range61To90Amount === 'undefined' || typeof arg.Range61To90Amount === 'number' ) &&
    // Range91To180Amount?: number
    ( typeof arg.Range91To180Amount === 'undefined' || typeof arg.Range91To180Amount === 'number' ) &&

  true
  );
  }

export function isRunCloserRequest(arg: any): arg is models.RunCloserRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CloseType?: '1' | '2' | '3' | '4' | '5' | '7'
    ( typeof arg.CloseType === 'undefined' || ['1', '2', '3', '4', '5', '7'].includes(arg.CloseType) ) &&
    // EmailTo?: string
    ( typeof arg.EmailTo === 'undefined' || typeof arg.EmailTo === 'string' ) &&
    // EndDate?: string
    ( typeof arg.EndDate === 'undefined' || typeof arg.EndDate === 'string' ) &&
    // FileFormatCategory?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11'
    ( typeof arg.FileFormatCategory === 'undefined' || ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'].includes(arg.FileFormatCategory) ) &&
    // StartDate?: string
    ( typeof arg.StartDate === 'undefined' || typeof arg.StartDate === 'string' ) &&

  true
  );
  }

export function isSaveCreditCardClearentRequest(arg: any): arg is models.SaveCreditCardClearentRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address?: string
    ( typeof arg.Address === 'undefined' || typeof arg.Address === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CountryId?: number
    ( typeof arg.CountryId === 'undefined' || typeof arg.CountryId === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // MobileJWT?: string
    ( typeof arg.MobileJWT === 'undefined' || typeof arg.MobileJWT === 'string' ) &&
    // StateId?: number
    ( typeof arg.StateId === 'undefined' || typeof arg.StateId === 'number' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isSaveCreditCardClearentResponse(arg: any): arg is models.SaveCreditCardClearentResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CardType?: string
    ( typeof arg.CardType === 'undefined' || typeof arg.CardType === 'string' ) &&
    // ExpDate?: string
    ( typeof arg.ExpDate === 'undefined' || typeof arg.ExpDate === 'string' ) &&
    // LastFour?: string
    ( typeof arg.LastFour === 'undefined' || typeof arg.LastFour === 'string' ) &&
    // PayerID?: string
    ( typeof arg.PayerID === 'undefined' || typeof arg.PayerID === 'string' ) &&

  true
  );
  }

export function isSecondaryTenant(arg: any): arg is models.SecondaryTenant {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessLevel?: string
    ( typeof arg.AccessLevel === 'undefined' || typeof arg.AccessLevel === 'string' ) &&
    // Active?: boolean
    ( typeof arg.Active === 'undefined' || typeof arg.Active === 'boolean' ) &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // BusinessName?: string
    ( typeof arg.BusinessName === 'undefined' || typeof arg.BusinessName === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // Country?: string
    ( typeof arg.Country === 'undefined' || typeof arg.Country === 'string' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // FullName?: string
    ( typeof arg.FullName === 'undefined' || typeof arg.FullName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // SMSCarrier?: string
    ( typeof arg.SMSCarrier === 'undefined' || typeof arg.SMSCarrier === 'string' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // Taxable?: boolean
    ( typeof arg.Taxable === 'undefined' || typeof arg.Taxable === 'boolean' ) &&
    // UseBusinessName?: boolean
    ( typeof arg.UseBusinessName === 'undefined' || typeof arg.UseBusinessName === 'boolean' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isSentinelReportListModel(arg: any): arg is models.SentinelReportListModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Checked?: boolean
    ( typeof arg.Checked === 'undefined' || typeof arg.Checked === 'boolean' ) &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // ReportNo?: number
    ( typeof arg.ReportNo === 'undefined' || typeof arg.ReportNo === 'number' ) &&

  true
  );
  }

export function isSignalRConfigurationModel(arg: any): arg is models.SignalRConfigurationModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // SignalRSiteNumber?: number
    ( typeof arg.SignalRSiteNumber === 'undefined' || typeof arg.SignalRSiteNumber === 'number' ) &&
    // SignalRURI?: string
    ( typeof arg.SignalRURI === 'undefined' || typeof arg.SignalRURI === 'string' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&

  true
  );
  }

export function isSiteConfigPostModel(arg: any): arg is models.SiteConfigPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Active?: boolean
    ( typeof arg.Active === 'undefined' || typeof arg.Active === 'boolean' ) &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // BusinessNo?: string
    ( typeof arg.BusinessNo === 'undefined' || typeof arg.BusinessNo === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // DefaultAccLevelID?: number
    ( typeof arg.DefaultAccLevelID === 'undefined' || typeof arg.DefaultAccLevelID === 'number' ) &&
    // DefaultAutoBill?: boolean
    ( typeof arg.DefaultAutoBill === 'undefined' || typeof arg.DefaultAutoBill === 'boolean' ) &&
    // DefaultLateCycleID?: number
    ( typeof arg.DefaultLateCycleID === 'undefined' || typeof arg.DefaultLateCycleID === 'number' ) &&
    // EditOnlyGate?: boolean
    ( typeof arg.EditOnlyGate === 'undefined' || typeof arg.EditOnlyGate === 'boolean' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // GateDirectory?: string
    ( typeof arg.GateDirectory === 'undefined' || typeof arg.GateDirectory === 'string' ) &&
    // GateInterface?: number
    ( typeof arg.GateInterface === 'undefined' || typeof arg.GateInterface === 'number' ) &&
    // ManagerName?: string
    ( typeof arg.ManagerName === 'undefined' || typeof arg.ManagerName === 'string' ) &&
    // MerchantID?: string
    ( typeof arg.MerchantID === 'undefined' || typeof arg.MerchantID === 'string' ) &&
    // PaymentURL?: string
    ( typeof arg.PaymentURL === 'undefined' || typeof arg.PaymentURL === 'string' ) &&
    // PerFoot?: number
    ( typeof arg.PerFoot === 'undefined' || typeof arg.PerFoot === 'number' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // Processor?: string
    ( typeof arg.Processor === 'undefined' || typeof arg.Processor === 'string' ) &&
    // PSSiteConfigID?: number
    ( typeof arg.PSSiteConfigID === 'undefined' || typeof arg.PSSiteConfigID === 'number' ) &&
    // RateChargeMethod?: number
    ( typeof arg.RateChargeMethod === 'undefined' || typeof arg.RateChargeMethod === 'number' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SiteName?: string
    ( typeof arg.SiteName === 'undefined' || typeof arg.SiteName === 'string' ) &&
    // SiteNo?: number
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'number' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // UnitNoPadLength?: number
    ( typeof arg.UnitNoPadLength === 'undefined' || typeof arg.UnitNoPadLength === 'number' ) &&
    // WebURL?: string
    ( typeof arg.WebURL === 'undefined' || typeof arg.WebURL === 'string' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isSiteConfigTax(arg: any): arg is models.SiteConfigTax {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsApply?: boolean
    ( typeof arg.IsApply === 'undefined' || typeof arg.IsApply === 'boolean' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&

  true
  );
  }

export function isSiteConfigViewModel(arg: any): arg is models.SiteConfigViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Active?: boolean
    ( typeof arg.Active === 'undefined' || typeof arg.Active === 'boolean' ) &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // BusinessNo?: string
    ( typeof arg.BusinessNo === 'undefined' || typeof arg.BusinessNo === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // Country?: string
    ( typeof arg.Country === 'undefined' || typeof arg.Country === 'string' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // DefaultAccLevelID?: number
    ( typeof arg.DefaultAccLevelID === 'undefined' || typeof arg.DefaultAccLevelID === 'number' ) &&
    // DefaultAccLevelName?: string
    ( typeof arg.DefaultAccLevelName === 'undefined' || typeof arg.DefaultAccLevelName === 'string' ) &&
    // DefaultAutoBill?: boolean
    ( typeof arg.DefaultAutoBill === 'undefined' || typeof arg.DefaultAutoBill === 'boolean' ) &&
    // DefaultLateCycleID?: number
    ( typeof arg.DefaultLateCycleID === 'undefined' || typeof arg.DefaultLateCycleID === 'number' ) &&
    // DefaultLateCycleName?: string
    ( typeof arg.DefaultLateCycleName === 'undefined' || typeof arg.DefaultLateCycleName === 'string' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // GateDirectory?: string
    ( typeof arg.GateDirectory === 'undefined' || typeof arg.GateDirectory === 'string' ) &&
    // GateInterface?: number
    ( typeof arg.GateInterface === 'undefined' || typeof arg.GateInterface === 'number' ) &&
    // GateInterfaceName?: string
    ( typeof arg.GateInterfaceName === 'undefined' || typeof arg.GateInterfaceName === 'string' ) &&
    // ManagerName?: string
    ( typeof arg.ManagerName === 'undefined' || typeof arg.ManagerName === 'string' ) &&
    // MerchantID?: string
    ( typeof arg.MerchantID === 'undefined' || typeof arg.MerchantID === 'string' ) &&
    // ModifiedDate?: string
    ( typeof arg.ModifiedDate === 'undefined' || typeof arg.ModifiedDate === 'string' ) &&
    // PaymentURL?: string
    ( typeof arg.PaymentURL === 'undefined' || typeof arg.PaymentURL === 'string' ) &&
    // PerFoot?: number
    ( typeof arg.PerFoot === 'undefined' || typeof arg.PerFoot === 'number' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // Processor?: string
    ( typeof arg.Processor === 'undefined' || typeof arg.Processor === 'string' ) &&
    // PSSiteConfigID?: number
    ( typeof arg.PSSiteConfigID === 'undefined' || typeof arg.PSSiteConfigID === 'number' ) &&
    // PSSiteConfigName?: string
    ( typeof arg.PSSiteConfigName === 'undefined' || typeof arg.PSSiteConfigName === 'string' ) &&
    // RateChargeMethod?: number
    ( typeof arg.RateChargeMethod === 'undefined' || typeof arg.RateChargeMethod === 'number' ) &&
    // RateChargeMethodName?: string
    ( typeof arg.RateChargeMethodName === 'undefined' || typeof arg.RateChargeMethodName === 'string' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SiteName?: string
    ( typeof arg.SiteName === 'undefined' || typeof arg.SiteName === 'string' ) &&
    // SiteNo?: number
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'number' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // UnitNoPadLength?: number
    ( typeof arg.UnitNoPadLength === 'undefined' || typeof arg.UnitNoPadLength === 'number' ) &&
    // WebURL?: string
    ( typeof arg.WebURL === 'undefined' || typeof arg.WebURL === 'string' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isSiteGroupModel(arg: any): arg is models.SiteGroupModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CompanyID?: number
    ( typeof arg.CompanyID === 'undefined' || typeof arg.CompanyID === 'number' ) &&
    // CompanyName?: string
    ( typeof arg.CompanyName === 'undefined' || typeof arg.CompanyName === 'string' ) &&
    // CustomerNumber?: number
    ( typeof arg.CustomerNumber === 'undefined' || typeof arg.CustomerNumber === 'number' ) &&
    // SiteGroupId?: number
    ( typeof arg.SiteGroupId === 'undefined' || typeof arg.SiteGroupId === 'number' ) &&
    // SiteNo?: string
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'string' ) &&

  true
  );
  }

export function isSiteLogoModel(arg: any): arg is models.SiteLogoModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Extension?: string
    ( typeof arg.Extension === 'undefined' || typeof arg.Extension === 'string' ) &&
    // Image?: string
    ( typeof arg.Image === 'undefined' || typeof arg.Image === 'string' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&

  true
  );
  }

export function isSiteModel(arg: any): arg is models.SiteModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AddendumFileName?: string
    ( typeof arg.AddendumFileName === 'undefined' || typeof arg.AddendumFileName === 'string' ) &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AdminFee?: number
    ( typeof arg.AdminFee === 'undefined' || typeof arg.AdminFee === 'number' ) &&
    // AllowACH?: boolean
    ( typeof arg.AllowACH === 'undefined' || typeof arg.AllowACH === 'boolean' ) &&
    // AllowMoveins?: boolean
    ( typeof arg.AllowMoveins === 'undefined' || typeof arg.AllowMoveins === 'boolean' ) &&
    // AllowOpenGate?: boolean
    ( typeof arg.AllowOpenGate === 'undefined' || typeof arg.AllowOpenGate === 'boolean' ) &&
    // AllowPartialPayments?: boolean
    ( typeof arg.AllowPartialPayments === 'undefined' || typeof arg.AllowPartialPayments === 'boolean' ) &&
    // CCPOSVendor?: number
    ( typeof arg.CCPOSVendor === 'undefined' || typeof arg.CCPOSVendor === 'number' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // ClearentBaseURL?: string
    ( typeof arg.ClearentBaseURL === 'undefined' || typeof arg.ClearentBaseURL === 'string' ) &&
    // ContractInitials?: boolean
    ( typeof arg.ContractInitials === 'undefined' || typeof arg.ContractInitials === 'boolean' ) &&
    // ContractOpt1OffsetFX?: number
    ( typeof arg.ContractOpt1OffsetFX === 'undefined' || typeof arg.ContractOpt1OffsetFX === 'number' ) &&
    // ContractOpt1OffsetFY?: number
    ( typeof arg.ContractOpt1OffsetFY === 'undefined' || typeof arg.ContractOpt1OffsetFY === 'number' ) &&
    // ContractOpt1OffsetTX?: number
    ( typeof arg.ContractOpt1OffsetTX === 'undefined' || typeof arg.ContractOpt1OffsetTX === 'number' ) &&
    // ContractOpt1OffsetTY?: number
    ( typeof arg.ContractOpt1OffsetTY === 'undefined' || typeof arg.ContractOpt1OffsetTY === 'number' ) &&
    // ContractOpt1SrchTxtF?: string
    ( typeof arg.ContractOpt1SrchTxtF === 'undefined' || typeof arg.ContractOpt1SrchTxtF === 'string' ) &&
    // ContractOpt1SrchTxtT?: string
    ( typeof arg.ContractOpt1SrchTxtT === 'undefined' || typeof arg.ContractOpt1SrchTxtT === 'string' ) &&
    // ContractOpt2OffsetFX?: number
    ( typeof arg.ContractOpt2OffsetFX === 'undefined' || typeof arg.ContractOpt2OffsetFX === 'number' ) &&
    // ContractOpt2OffsetFY?: number
    ( typeof arg.ContractOpt2OffsetFY === 'undefined' || typeof arg.ContractOpt2OffsetFY === 'number' ) &&
    // ContractOpt2OffsetTX?: number
    ( typeof arg.ContractOpt2OffsetTX === 'undefined' || typeof arg.ContractOpt2OffsetTX === 'number' ) &&
    // ContractOpt2OffsetTY?: number
    ( typeof arg.ContractOpt2OffsetTY === 'undefined' || typeof arg.ContractOpt2OffsetTY === 'number' ) &&
    // ContractOpt2SrchTxtF?: string
    ( typeof arg.ContractOpt2SrchTxtF === 'undefined' || typeof arg.ContractOpt2SrchTxtF === 'string' ) &&
    // ContractOpt2SrchTxtT?: string
    ( typeof arg.ContractOpt2SrchTxtT === 'undefined' || typeof arg.ContractOpt2SrchTxtT === 'string' ) &&
    // ContractOption1Text?: string
    ( typeof arg.ContractOption1Text === 'undefined' || typeof arg.ContractOption1Text === 'string' ) &&
    // ContractOption2Text?: string
    ( typeof arg.ContractOption2Text === 'undefined' || typeof arg.ContractOption2Text === 'string' ) &&
    // ContractOptions?: boolean
    ( typeof arg.ContractOptions === 'undefined' || typeof arg.ContractOptions === 'boolean' ) &&
    // Country?: string
    ( typeof arg.Country === 'undefined' || typeof arg.Country === 'string' ) &&
    // CustomCoords?: boolean
    ( typeof arg.CustomCoords === 'undefined' || typeof arg.CustomCoords === 'boolean' ) &&
    // CustomSig?: boolean
    ( typeof arg.CustomSig === 'undefined' || typeof arg.CustomSig === 'boolean' ) &&
    // DarkLogoImage?: string
    ( typeof arg.DarkLogoImage === 'undefined' || typeof arg.DarkLogoImage === 'string' ) &&
    // DefaultCountryID?: number
    ( typeof arg.DefaultCountryID === 'undefined' || typeof arg.DefaultCountryID === 'number' ) &&
    // DefaultStateID?: number
    ( typeof arg.DefaultStateID === 'undefined' || typeof arg.DefaultStateID === 'number' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // HasAddendum?: boolean
    ( typeof arg.HasAddendum === 'undefined' || typeof arg.HasAddendum === 'boolean' ) &&
    // Init1X?: number
    ( typeof arg.Init1X === 'undefined' || typeof arg.Init1X === 'number' ) &&
    // Init1Y?: number
    ( typeof arg.Init1Y === 'undefined' || typeof arg.Init1Y === 'number' ) &&
    // Init2X?: number
    ( typeof arg.Init2X === 'undefined' || typeof arg.Init2X === 'number' ) &&
    // Init2Y?: number
    ( typeof arg.Init2Y === 'undefined' || typeof arg.Init2Y === 'number' ) &&
    // Init3X?: number
    ( typeof arg.Init3X === 'undefined' || typeof arg.Init3X === 'number' ) &&
    // Init3Y?: number
    ( typeof arg.Init3Y === 'undefined' || typeof arg.Init3Y === 'number' ) &&
    // InsInits?: boolean
    ( typeof arg.InsInits === 'undefined' || typeof arg.InsInits === 'boolean' ) &&
    // InsNoX?: number
    ( typeof arg.InsNoX === 'undefined' || typeof arg.InsNoX === 'number' ) &&
    // InsNoY?: number
    ( typeof arg.InsNoY === 'undefined' || typeof arg.InsNoY === 'number' ) &&
    // InsYesX?: number
    ( typeof arg.InsYesX === 'undefined' || typeof arg.InsYesX === 'number' ) &&
    // InsYesY?: number
    ( typeof arg.InsYesY === 'undefined' || typeof arg.InsYesY === 'number' ) &&
    // LocalSiteName?: string
    ( typeof arg.LocalSiteName === 'undefined' || typeof arg.LocalSiteName === 'string' ) &&
    // LocalSiteNo?: number
    ( typeof arg.LocalSiteNo === 'undefined' || typeof arg.LocalSiteNo === 'number' ) &&
    // LogoImage?: string
    ( typeof arg.LogoImage === 'undefined' || typeof arg.LogoImage === 'string' ) &&
    // ManagerName?: string
    ( typeof arg.ManagerName === 'undefined' || typeof arg.ManagerName === 'string' ) &&
    // ManagerSig?: boolean
    ( typeof arg.ManagerSig === 'undefined' || typeof arg.ManagerSig === 'boolean' ) &&
    // ManagerSigText?: string
    ( typeof arg.ManagerSigText === 'undefined' || typeof arg.ManagerSigText === 'string' ) &&
    // ManagerSigTextX?: number
    ( typeof arg.ManagerSigTextX === 'undefined' || typeof arg.ManagerSigTextX === 'number' ) &&
    // ManagerSigTextY?: number
    ( typeof arg.ManagerSigTextY === 'undefined' || typeof arg.ManagerSigTextY === 'number' ) &&
    // MSigXCoord?: number
    ( typeof arg.MSigXCoord === 'undefined' || typeof arg.MSigXCoord === 'number' ) &&
    // MSigXCoord2?: number
    ( typeof arg.MSigXCoord2 === 'undefined' || typeof arg.MSigXCoord2 === 'number' ) &&
    // MSigYCoord?: number
    ( typeof arg.MSigYCoord === 'undefined' || typeof arg.MSigYCoord === 'number' ) &&
    // MSigYCoord2?: number
    ( typeof arg.MSigYCoord2 === 'undefined' || typeof arg.MSigYCoord2 === 'number' ) &&
    // OnlineTransAccID?: number
    ( typeof arg.OnlineTransAccID === 'undefined' || typeof arg.OnlineTransAccID === 'number' ) &&
    // OnlineTransFee?: number
    ( typeof arg.OnlineTransFee === 'undefined' || typeof arg.OnlineTransFee === 'number' ) &&
    // PaymentUrl?: string
    ( typeof arg.PaymentUrl === 'undefined' || typeof arg.PaymentUrl === 'string' ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // RequireDLN?: boolean
    ( typeof arg.RequireDLN === 'undefined' || typeof arg.RequireDLN === 'boolean' ) &&
    // RequireDLP?: boolean
    ( typeof arg.RequireDLP === 'undefined' || typeof arg.RequireDLP === 'boolean' ) &&
    // RequireProof?: boolean
    ( typeof arg.RequireProof === 'undefined' || typeof arg.RequireProof === 'boolean' ) &&
    // RequireSSN?: boolean
    ( typeof arg.RequireSSN === 'undefined' || typeof arg.RequireSSN === 'boolean' ) &&
    // RVPerFoot?: number
    ( typeof arg.RVPerFoot === 'undefined' || typeof arg.RVPerFoot === 'number' ) &&
    // SentinelLogo?: string
    ( typeof arg.SentinelLogo === 'undefined' || typeof arg.SentinelLogo === 'string' ) &&
    // SentinelOnly?: boolean
    ( typeof arg.SentinelOnly === 'undefined' || typeof arg.SentinelOnly === 'boolean' ) &&
    // SignatureX?: number
    ( typeof arg.SignatureX === 'undefined' || typeof arg.SignatureX === 'number' ) &&
    // SignatureY?: number
    ( typeof arg.SignatureY === 'undefined' || typeof arg.SignatureY === 'number' ) &&
    // SigPage?: number
    ( typeof arg.SigPage === 'undefined' || typeof arg.SigPage === 'number' ) &&
    // SigText?: string
    ( typeof arg.SigText === 'undefined' || typeof arg.SigText === 'string' ) &&
    // SigTextX?: number
    ( typeof arg.SigTextX === 'undefined' || typeof arg.SigTextX === 'number' ) &&
    // SigTextY?: number
    ( typeof arg.SigTextY === 'undefined' || typeof arg.SigTextY === 'number' ) &&
    // SigXCoord?: number
    ( typeof arg.SigXCoord === 'undefined' || typeof arg.SigXCoord === 'number' ) &&
    // SigXCoord2?: number
    ( typeof arg.SigXCoord2 === 'undefined' || typeof arg.SigXCoord2 === 'number' ) &&
    // SigYCoord?: number
    ( typeof arg.SigYCoord === 'undefined' || typeof arg.SigYCoord === 'number' ) &&
    // SigYCoord2?: number
    ( typeof arg.SigYCoord2 === 'undefined' || typeof arg.SigYCoord2 === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // SiteName?: string
    ( typeof arg.SiteName === 'undefined' || typeof arg.SiteName === 'string' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // TimeZones?: TimeZoneModel
    ( typeof arg.TimeZones === 'undefined' || isTimeZoneModel(arg.TimeZones) ) &&
    // TSSAContract?: boolean
    ( typeof arg.TSSAContract === 'undefined' || typeof arg.TSSAContract === 'boolean' ) &&
    // UtcOffSet?: number
    ( typeof arg.UtcOffSet === 'undefined' || typeof arg.UtcOffSet === 'number' ) &&
    // WebUrl?: string
    ( typeof arg.WebUrl === 'undefined' || typeof arg.WebUrl === 'string' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isSiteSettingModel(arg: any): arg is models.SiteSettingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AllowACH?: boolean
    ( typeof arg.AllowACH === 'undefined' || typeof arg.AllowACH === 'boolean' ) &&
    // AllowMoveins?: boolean
    ( typeof arg.AllowMoveins === 'undefined' || typeof arg.AllowMoveins === 'boolean' ) &&
    // AllowOpenGate?: boolean
    ( typeof arg.AllowOpenGate === 'undefined' || typeof arg.AllowOpenGate === 'boolean' ) &&
    // AllowPartialPayments?: boolean
    ( typeof arg.AllowPartialPayments === 'undefined' || typeof arg.AllowPartialPayments === 'boolean' ) &&
    // AllowReservations?: boolean
    ( typeof arg.AllowReservations === 'undefined' || typeof arg.AllowReservations === 'boolean' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // Country?: string
    ( typeof arg.Country === 'undefined' || typeof arg.Country === 'string' ) &&
    // IsActive?: boolean
    ( typeof arg.IsActive === 'undefined' || typeof arg.IsActive === 'boolean' ) &&
    // OtherSetting?: OtherSetting
    ( typeof arg.OtherSetting === 'undefined' || isOtherSetting(arg.OtherSetting) ) &&
    // Phone?: string
    ( typeof arg.Phone === 'undefined' || typeof arg.Phone === 'string' ) &&
    // RedirectURL?: string
    ( typeof arg.RedirectURL === 'undefined' || typeof arg.RedirectURL === 'string' ) &&
    // RequireDLN?: boolean
    ( typeof arg.RequireDLN === 'undefined' || typeof arg.RequireDLN === 'boolean' ) &&
    // RequireSecondContact?: boolean
    ( typeof arg.RequireSecondContact === 'undefined' || typeof arg.RequireSecondContact === 'boolean' ) &&
    // RequireSSN?: boolean
    ( typeof arg.RequireSSN === 'undefined' || typeof arg.RequireSSN === 'boolean' ) &&
    // SentinelOnly?: boolean
    ( typeof arg.SentinelOnly === 'undefined' || typeof arg.SentinelOnly === 'boolean' ) &&
    // SiteName?: string
    ( typeof arg.SiteName === 'undefined' || typeof arg.SiteName === 'string' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isSizeFeaturePostModel(arg: any): arg is models.SizeFeaturePostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // FeatureId?: number
    ( typeof arg.FeatureId === 'undefined' || typeof arg.FeatureId === 'number' ) &&

  true
  );
  }

export function isSizeModel(arg: any): arg is models.SizeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Area?: number
    ( typeof arg.Area === 'undefined' || typeof arg.Area === 'number' ) &&
    // Depth?: number
    ( typeof arg.Depth === 'undefined' || typeof arg.Depth === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // Height?: number
    ( typeof arg.Height === 'undefined' || typeof arg.Height === 'number' ) &&
    // ImageUrl?: string
    ( typeof arg.ImageUrl === 'undefined' || typeof arg.ImageUrl === 'string' ) &&
    // ImageUrlDark?: string
    ( typeof arg.ImageUrlDark === 'undefined' || typeof arg.ImageUrlDark === 'string' ) &&
    // PromoModel?: PromoModel
    ( typeof arg.PromoModel === 'undefined' || isPromoModel(arg.PromoModel) ) &&
    // Reservable?: boolean
    ( typeof arg.Reservable === 'undefined' || typeof arg.Reservable === 'boolean' ) &&
    // ReservationDeposit?: number
    ( typeof arg.ReservationDeposit === 'undefined' || typeof arg.ReservationDeposit === 'number' ) &&
    // SecurityDeposit?: number
    ( typeof arg.SecurityDeposit === 'undefined' || typeof arg.SecurityDeposit === 'number' ) &&
    // SizeFeatures?: SizesFeatureResponse[]
    ( typeof arg.SizeFeatures === 'undefined' || (Array.isArray(arg.SizeFeatures) && arg.SizeFeatures.every((item: unknown) => isSizesFeatureResponse(item))) ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // StandardRate?: number
    ( typeof arg.StandardRate === 'undefined' || typeof arg.StandardRate === 'number' ) &&
    // TotalUnits?: number
    ( typeof arg.TotalUnits === 'undefined' || typeof arg.TotalUnits === 'number' ) &&
    // VacantUnits?: number
    ( typeof arg.VacantUnits === 'undefined' || typeof arg.VacantUnits === 'number' ) &&
    // Width?: number
    ( typeof arg.Width === 'undefined' || typeof arg.Width === 'number' ) &&

  true
  );
  }

export function isSizePostModel(arg: any): arg is models.SizePostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Area?: number
    ( typeof arg.Area === 'undefined' || typeof arg.Area === 'number' ) &&
    // Depth?: number
    ( typeof arg.Depth === 'undefined' || typeof arg.Depth === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // Height?: number
    ( typeof arg.Height === 'undefined' || typeof arg.Height === 'number' ) &&
    // MinVacants?: number
    ( typeof arg.MinVacants === 'undefined' || typeof arg.MinVacants === 'number' ) &&
    // OnLine?: boolean
    ( typeof arg.OnLine === 'undefined' || typeof arg.OnLine === 'boolean' ) &&
    // ReservationDeposit?: number
    ( typeof arg.ReservationDeposit === 'undefined' || typeof arg.ReservationDeposit === 'number' ) &&
    // SecurityDeposit?: number
    ( typeof arg.SecurityDeposit === 'undefined' || typeof arg.SecurityDeposit === 'number' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // StandardRate?: number
    ( typeof arg.StandardRate === 'undefined' || typeof arg.StandardRate === 'number' ) &&
    // Width?: number
    ( typeof arg.Width === 'undefined' || typeof arg.Width === 'number' ) &&

  true
  );
  }

export function isSizesFeatureAssignment(arg: any): arg is models.SizesFeatureAssignment {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // FeatureIds?: SizeFeaturePostModel[]
    ( typeof arg.FeatureIds === 'undefined' || (Array.isArray(arg.FeatureIds) && arg.FeatureIds.every((item: unknown) => isSizeFeaturePostModel(item))) ) &&
    // SizeId?: number
    ( typeof arg.SizeId === 'undefined' || typeof arg.SizeId === 'number' ) &&

  true
  );
  }

export function isSizesFeatureRequest(arg: any): arg is models.SizesFeatureRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // FeatureId?: number
    ( typeof arg.FeatureId === 'undefined' || typeof arg.FeatureId === 'number' ) &&

  true
  );
  }

export function isSizesFeatureResponse(arg: any): arg is models.SizesFeatureResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // FeatureId?: number
    ( typeof arg.FeatureId === 'undefined' || typeof arg.FeatureId === 'number' ) &&

  true
  );
  }

export function isSizeViewModel(arg: any): arg is models.SizeViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Area?: number
    ( typeof arg.Area === 'undefined' || typeof arg.Area === 'number' ) &&
    // Depth?: number
    ( typeof arg.Depth === 'undefined' || typeof arg.Depth === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // HasVacantUnit?: boolean
    ( typeof arg.HasVacantUnit === 'undefined' || typeof arg.HasVacantUnit === 'boolean' ) &&
    // Height?: number
    ( typeof arg.Height === 'undefined' || typeof arg.Height === 'number' ) &&
    // MinVacants?: number
    ( typeof arg.MinVacants === 'undefined' || typeof arg.MinVacants === 'number' ) &&
    // Modifieddate?: string
    ( typeof arg.Modifieddate === 'undefined' || typeof arg.Modifieddate === 'string' ) &&
    // OnLine?: boolean
    ( typeof arg.OnLine === 'undefined' || typeof arg.OnLine === 'boolean' ) &&
    // Reservable?: boolean
    ( typeof arg.Reservable === 'undefined' || typeof arg.Reservable === 'boolean' ) &&
    // ReservationDeposit?: number
    ( typeof arg.ReservationDeposit === 'undefined' || typeof arg.ReservationDeposit === 'number' ) &&
    // SecurityDeposit?: number
    ( typeof arg.SecurityDeposit === 'undefined' || typeof arg.SecurityDeposit === 'number' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // StandardRate?: number
    ( typeof arg.StandardRate === 'undefined' || typeof arg.StandardRate === 'number' ) &&
    // Width?: number
    ( typeof arg.Width === 'undefined' || typeof arg.Width === 'number' ) &&

  true
  );
  }

export function isSkipSignModel(arg: any): arg is models.SkipSignModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID: number
    ( typeof arg.CustomerID === 'number' ) &&
    // TransID: number
    ( typeof arg.TransID === 'number' ) &&
    // UnitContact?: UnitContactFile[]
    ( typeof arg.UnitContact === 'undefined' || (Array.isArray(arg.UnitContact) && arg.UnitContact.every((item: unknown) => isUnitContactFile(item))) ) &&

  true
  );
  }

export function isSMTPSettingModel(arg: any): arg is models.SMTPSettingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AuthenticateSMTP?: boolean
    ( typeof arg.AuthenticateSMTP === 'undefined' || typeof arg.AuthenticateSMTP === 'boolean' ) &&
    // EnableSSL?: boolean
    ( typeof arg.EnableSSL === 'undefined' || typeof arg.EnableSSL === 'boolean' ) &&
    // IsLoadingDefaultGmailSetting?: boolean
    ( typeof arg.IsLoadingDefaultGmailSetting === 'undefined' || typeof arg.IsLoadingDefaultGmailSetting === 'boolean' ) &&
    // ServerTimeOut?: number
    ( typeof arg.ServerTimeOut === 'undefined' || typeof arg.ServerTimeOut === 'number' ) &&
    // SMTPLoginOption?: number
    ( typeof arg.SMTPLoginOption === 'undefined' || typeof arg.SMTPLoginOption === 'number' ) &&
    // SMTPPassword?: string
    ( typeof arg.SMTPPassword === 'undefined' || typeof arg.SMTPPassword === 'string' ) &&
    // SMTPPort?: number
    ( typeof arg.SMTPPort === 'undefined' || typeof arg.SMTPPort === 'number' ) &&
    // SMTPUserName?: string
    ( typeof arg.SMTPUserName === 'undefined' || typeof arg.SMTPUserName === 'string' ) &&

  true
  );
  }

export function isSortBy(arg: any): arg is models.SortBy {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Direction?: '0' | '1'
    ( typeof arg.Direction === 'undefined' || ['0', '1'].includes(arg.Direction) ) &&
    // Value?: string
    ( typeof arg.Value === 'undefined' || typeof arg.Value === 'string' ) &&

  true
  );
  }

export function isSpecialMailingRequest(arg: any): arg is models.SpecialMailingRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // EmailLetter?: boolean
    ( typeof arg.EmailLetter === 'undefined' || typeof arg.EmailLetter === 'boolean' ) &&
    // EmailMessage?: string
    ( typeof arg.EmailMessage === 'undefined' || typeof arg.EmailMessage === 'string' ) &&
    // EmailSubject?: string
    ( typeof arg.EmailSubject === 'undefined' || typeof arg.EmailSubject === 'string' ) &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&
    // IsUsingSpecialLetter?: boolean
    ( typeof arg.IsUsingSpecialLetter === 'undefined' || typeof arg.IsUsingSpecialLetter === 'boolean' ) &&
    // LabelPrinting?: boolean
    ( typeof arg.LabelPrinting === 'undefined' || typeof arg.LabelPrinting === 'boolean' ) &&
    // LeaseStatus?: '0' | '1' | '2' | '3' | '4' | '5' | '11' | '12' | '13' | '14'
    ( typeof arg.LeaseStatus === 'undefined' || ['0', '1', '2', '3', '4', '5', '11', '12', '13', '14'].includes(arg.LeaseStatus) ) &&
    // LetterID?: number
    ( typeof arg.LetterID === 'undefined' || typeof arg.LetterID === 'number' ) &&
    // NoticeDate?: string
    ( typeof arg.NoticeDate === 'undefined' || typeof arg.NoticeDate === 'string' ) &&
    // PrintLetter?: boolean
    ( typeof arg.PrintLetter === 'undefined' || typeof arg.PrintLetter === 'boolean' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // SMSLetter?: boolean
    ( typeof arg.SMSLetter === 'undefined' || typeof arg.SMSLetter === 'boolean' ) &&
    // TypeOfMailing?: '1' | '2' | '3' | '4' | '5'
    ( typeof arg.TypeOfMailing === 'undefined' || ['1', '2', '3', '4', '5'].includes(arg.TypeOfMailing) ) &&
    // UnitList?: string[]
    ( typeof arg.UnitList === 'undefined' || (Array.isArray(arg.UnitList) && arg.UnitList.every((item: unknown) => typeof item === 'string')) ) &&

  true
  );
  }

export function isStateModel(arg: any): arg is models.StateModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Abbreviation?: string
    ( typeof arg.Abbreviation === 'undefined' || typeof arg.Abbreviation === 'string' ) &&
    // CounrtyID?: number
    ( typeof arg.CounrtyID === 'undefined' || typeof arg.CounrtyID === 'number' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&

  true
  );
  }

export function isSupportEmailModel(arg: any): arg is models.SupportEmailModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Message?: string
    ( typeof arg.Message === 'undefined' || typeof arg.Message === 'string' ) &&

  true
  );
  }

export function isSystemChargerLogItem(arg: any): arg is models.SystemChargerLogItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Content?: string
    ( typeof arg.Content === 'undefined' || typeof arg.Content === 'string' ) &&
    // CreatedDate?: string
    ( typeof arg.CreatedDate === 'undefined' || typeof arg.CreatedDate === 'string' ) &&
    // Type?: string
    ( typeof arg.Type === 'undefined' || typeof arg.Type === 'string' ) &&

  true
  );
  }

export function isSystemChargerLogResponse(arg: any): arg is models.SystemChargerLogResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Logs?: SystemChargerLogItem[]
    ( typeof arg.Logs === 'undefined' || (Array.isArray(arg.Logs) && arg.Logs.every((item: unknown) => isSystemChargerLogItem(item))) ) &&
    // ProgressInfo?: SystemChargerProgressInfo
    ( typeof arg.ProgressInfo === 'undefined' || isSystemChargerProgressInfo(arg.ProgressInfo) ) &&

  true
  );
  }

export function isSystemChargerProgressInfo(arg: any): arg is models.SystemChargerProgressInfo {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CurrentRecordNo?: string
    ( typeof arg.CurrentRecordNo === 'undefined' || typeof arg.CurrentRecordNo === 'string' ) &&
    // TotalCount?: string
    ( typeof arg.TotalCount === 'undefined' || typeof arg.TotalCount === 'string' ) &&

  true
  );
  }

export function isSystemChargerRunResponse(arg: any): arg is models.SystemChargerRunResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BackgroundJobID?: number
    ( typeof arg.BackgroundJobID === 'undefined' || typeof arg.BackgroundJobID === 'number' ) &&
    // LDChargeDate?: string
    ( typeof arg.LDChargeDate === 'undefined' || typeof arg.LDChargeDate === 'string' ) &&
    // Message?: string
    ( typeof arg.Message === 'undefined' || typeof arg.Message === 'string' ) &&
    // Runnable?: boolean
    ( typeof arg.Runnable === 'undefined' || typeof arg.Runnable === 'boolean' ) &&

  true
  );
  }

export function isSystemDateModel(arg: any): arg is models.SystemDateModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // LDCharge?: string
    ( typeof arg.LDCharge === 'undefined' || typeof arg.LDCharge === 'string' ) &&
    // LDClose?: string
    ( typeof arg.LDClose === 'undefined' || typeof arg.LDClose === 'string' ) &&
    // LMClose?: string
    ( typeof arg.LMClose === 'undefined' || typeof arg.LMClose === 'string' ) &&
    // LQClose?: string
    ( typeof arg.LQClose === 'undefined' || typeof arg.LQClose === 'string' ) &&
    // LWClose?: string
    ( typeof arg.LWClose === 'undefined' || typeof arg.LWClose === 'string' ) &&
    // LYClose?: string
    ( typeof arg.LYClose === 'undefined' || typeof arg.LYClose === 'string' ) &&
    // NDClose?: string
    ( typeof arg.NDClose === 'undefined' || typeof arg.NDClose === 'string' ) &&
    // NMClose?: string
    ( typeof arg.NMClose === 'undefined' || typeof arg.NMClose === 'string' ) &&
    // NoticeDate?: string
    ( typeof arg.NoticeDate === 'undefined' || typeof arg.NoticeDate === 'string' ) &&
    // NQClose?: string
    ( typeof arg.NQClose === 'undefined' || typeof arg.NQClose === 'string' ) &&
    // NWClose?: string
    ( typeof arg.NWClose === 'undefined' || typeof arg.NWClose === 'string' ) &&
    // NYClose?: string
    ( typeof arg.NYClose === 'undefined' || typeof arg.NYClose === 'string' ) &&

  true
  );
  }

export function isTaxPostModel(arg: any): arg is models.TaxPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // PayableAccountID?: number
    ( typeof arg.PayableAccountID === 'undefined' || typeof arg.PayableAccountID === 'number' ) &&
    // SiteConfigTaxes?: SiteConfigTax[]
    ( typeof arg.SiteConfigTaxes === 'undefined' || (Array.isArray(arg.SiteConfigTaxes) && arg.SiteConfigTaxes.every((item: unknown) => isSiteConfigTax(item))) ) &&
    // TaxAuthorityID?: number
    ( typeof arg.TaxAuthorityID === 'undefined' || typeof arg.TaxAuthorityID === 'number' ) &&
    // TaxAuthorityNo?: number
    ( typeof arg.TaxAuthorityNo === 'undefined' || typeof arg.TaxAuthorityNo === 'number' ) &&
    // TaxCategory?: number
    ( typeof arg.TaxCategory === 'undefined' || typeof arg.TaxCategory === 'number' ) &&
    // TaxInclusive?: boolean
    ( typeof arg.TaxInclusive === 'undefined' || typeof arg.TaxInclusive === 'boolean' ) &&
    // TaxRate?: number
    ( typeof arg.TaxRate === 'undefined' || typeof arg.TaxRate === 'number' ) &&

  true
  );
  }

export function isTaxSiteConfig(arg: any): arg is models.TaxSiteConfig {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsApply?: boolean
    ( typeof arg.IsApply === 'undefined' || typeof arg.IsApply === 'boolean' ) &&
    // SiteConfigId?: number
    ( typeof arg.SiteConfigId === 'undefined' || typeof arg.SiteConfigId === 'number' ) &&
    // SiteName?: string
    ( typeof arg.SiteName === 'undefined' || typeof arg.SiteName === 'string' ) &&
    // SiteNo?: number
    ( typeof arg.SiteNo === 'undefined' || typeof arg.SiteNo === 'number' ) &&

  true
  );
  }

export function isTaxViewAccount(arg: any): arg is models.TaxViewAccount {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // IsApply?: boolean
    ( typeof arg.IsApply === 'undefined' || typeof arg.IsApply === 'boolean' ) &&
    // TaxAuthID?: number
    ( typeof arg.TaxAuthID === 'undefined' || typeof arg.TaxAuthID === 'number' ) &&
    // TaxRate?: number
    ( typeof arg.TaxRate === 'undefined' || typeof arg.TaxRate === 'number' ) &&

  true
  );
  }

export function isTaxViewModel(arg: any): arg is models.TaxViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // PayableAccountDescription?: string
    ( typeof arg.PayableAccountDescription === 'undefined' || typeof arg.PayableAccountDescription === 'string' ) &&
    // PayableAccountID?: number
    ( typeof arg.PayableAccountID === 'undefined' || typeof arg.PayableAccountID === 'number' ) &&
    // PayableAccountNo?: number
    ( typeof arg.PayableAccountNo === 'undefined' || typeof arg.PayableAccountNo === 'number' ) &&
    // PrepaidAccountDescription?: string
    ( typeof arg.PrepaidAccountDescription === 'undefined' || typeof arg.PrepaidAccountDescription === 'string' ) &&
    // PrepaidAccountNo?: number
    ( typeof arg.PrepaidAccountNo === 'undefined' || typeof arg.PrepaidAccountNo === 'number' ) &&
    // ReceivableAccountDescription?: string
    ( typeof arg.ReceivableAccountDescription === 'undefined' || typeof arg.ReceivableAccountDescription === 'string' ) &&
    // ReceivableAccountNo?: number
    ( typeof arg.ReceivableAccountNo === 'undefined' || typeof arg.ReceivableAccountNo === 'number' ) &&
    // SiteConfigs?: TaxSiteConfig[]
    ( typeof arg.SiteConfigs === 'undefined' || (Array.isArray(arg.SiteConfigs) && arg.SiteConfigs.every((item: unknown) => isTaxSiteConfig(item))) ) &&
    // TaxAuthorityID?: number
    ( typeof arg.TaxAuthorityID === 'undefined' || typeof arg.TaxAuthorityID === 'number' ) &&
    // TaxAuthorityNo?: number
    ( typeof arg.TaxAuthorityNo === 'undefined' || typeof arg.TaxAuthorityNo === 'number' ) &&
    // TaxCategory?: number
    ( typeof arg.TaxCategory === 'undefined' || typeof arg.TaxCategory === 'number' ) &&
    // TaxCategoryName?: string
    ( typeof arg.TaxCategoryName === 'undefined' || typeof arg.TaxCategoryName === 'string' ) &&
    // TaxInclusive?: boolean
    ( typeof arg.TaxInclusive === 'undefined' || typeof arg.TaxInclusive === 'boolean' ) &&
    // TaxRate?: number
    ( typeof arg.TaxRate === 'undefined' || typeof arg.TaxRate === 'number' ) &&

  true
  );
  }

export function isTenantGeneralInfoResponse(arg: any): arg is models.TenantGeneralInfoResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BalanceItems?: BalanceItem[]
    ( typeof arg.BalanceItems === 'undefined' || (Array.isArray(arg.BalanceItems) && arg.BalanceItems.every((item: unknown) => isBalanceItem(item))) ) &&
    // BalanceTotal?: number
    ( typeof arg.BalanceTotal === 'undefined' || typeof arg.BalanceTotal === 'number' ) &&
    // TenantDetails?: UnitCustomerDataClass
    ( typeof arg.TenantDetails === 'undefined' || isUnitCustomerDataClass(arg.TenantDetails) ) &&

  true
  );
  }

export function isTenantHistoryModel(arg: any): arg is models.TenantHistoryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // Date?: string
    ( typeof arg.Date === 'undefined' || typeof arg.Date === 'string' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // TenantName?: string
    ( typeof arg.TenantName === 'undefined' || typeof arg.TenantName === 'string' ) &&
    // UnitCustomerID?: string
    ( typeof arg.UnitCustomerID === 'undefined' || typeof arg.UnitCustomerID === 'string' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isTenantLetterPrintResponse(arg: any): arg is models.TenantLetterPrintResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Base64String?: string
    ( typeof arg.Base64String === 'undefined' || typeof arg.Base64String === 'string' ) &&
    // Extension?: string
    ( typeof arg.Extension === 'undefined' || typeof arg.Extension === 'string' ) &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&
    // Message?: string
    ( typeof arg.Message === 'undefined' || typeof arg.Message === 'string' ) &&

  true
  );
  }

export function isTenantModel(arg: any): arg is models.TenantModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // IsOldTenant?: boolean
    ( typeof arg.IsOldTenant === 'undefined' || typeof arg.IsOldTenant === 'boolean' ) &&
    // LastOnSite?: string
    ( typeof arg.LastOnSite === 'undefined' || typeof arg.LastOnSite === 'string' ) &&
    // Locked?: boolean
    ( typeof arg.Locked === 'undefined' || typeof arg.Locked === 'boolean' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // OnSite?: boolean
    ( typeof arg.OnSite === 'undefined' || typeof arg.OnSite === 'boolean' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // TenantName?: string
    ( typeof arg.TenantName === 'undefined' || typeof arg.TenantName === 'string' ) &&
    // UnitCustomerID?: string
    ( typeof arg.UnitCustomerID === 'undefined' || typeof arg.UnitCustomerID === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitStatus?: string
    ( typeof arg.UnitStatus === 'undefined' || typeof arg.UnitStatus === 'string' ) &&

  true
  );
  }

export function isTenantOptionPostModel(arg: any): arg is models.TenantOptionPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AgingDays?: number
    ( typeof arg.AgingDays === 'undefined' || typeof arg.AgingDays === 'number' ) &&
    // DefaultAreaCode?: string
    ( typeof arg.DefaultAreaCode === 'undefined' || typeof arg.DefaultAreaCode === 'string' ) &&
    // DefaultBillDays?: number
    ( typeof arg.DefaultBillDays === 'undefined' || typeof arg.DefaultBillDays === 'number' ) &&
    // DefaultBillType?: number
    ( typeof arg.DefaultBillType === 'undefined' || typeof arg.DefaultBillType === 'number' ) &&
    // DefaultCity?: string
    ( typeof arg.DefaultCity === 'undefined' || typeof arg.DefaultCity === 'string' ) &&
    // DefaultCountryID?: number
    ( typeof arg.DefaultCountryID === 'undefined' || typeof arg.DefaultCountryID === 'number' ) &&
    // DefaultSMSCarrierID?: number
    ( typeof arg.DefaultSMSCarrierID === 'undefined' || typeof arg.DefaultSMSCarrierID === 'number' ) &&
    // DefaultStateID?: number
    ( typeof arg.DefaultStateID === 'undefined' || typeof arg.DefaultStateID === 'number' ) &&
    // DefaultTimeZoneID?: number
    ( typeof arg.DefaultTimeZoneID === 'undefined' || typeof arg.DefaultTimeZoneID === 'number' ) &&
    // DefaultZipCode?: string
    ( typeof arg.DefaultZipCode === 'undefined' || typeof arg.DefaultZipCode === 'string' ) &&
    // DuplicatePasscodeCheck?: boolean
    ( typeof arg.DuplicatePasscodeCheck === 'undefined' || typeof arg.DuplicatePasscodeCheck === 'boolean' ) &&
    // MaxPasscode?: number
    ( typeof arg.MaxPasscode === 'undefined' || typeof arg.MaxPasscode === 'number' ) &&
    // MaxPasscodeLength?: number
    ( typeof arg.MaxPasscodeLength === 'undefined' || typeof arg.MaxPasscodeLength === 'number' ) &&
    // MinPasscodeLength?: number
    ( typeof arg.MinPasscodeLength === 'undefined' || typeof arg.MinPasscodeLength === 'number' ) &&
    // MinStay?: number
    ( typeof arg.MinStay === 'undefined' || typeof arg.MinStay === 'number' ) &&
    // RandomPasscode?: boolean
    ( typeof arg.RandomPasscode === 'undefined' || typeof arg.RandomPasscode === 'boolean' ) &&
    // Thanks?: number
    ( typeof arg.Thanks === 'undefined' || typeof arg.Thanks === 'number' ) &&
    // Welcome?: number
    ( typeof arg.Welcome === 'undefined' || typeof arg.Welcome === 'number' ) &&

  true
  );
  }

export function isTenantPostModel(arg: any): arg is models.TenantPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessControl?: AccessControlPostModel
    ( typeof arg.AccessControl === 'undefined' || isAccessControlPostModel(arg.AccessControl) ) &&
    // AccessLevels?: AccessLevelPostModel[]
    ( typeof arg.AccessLevels === 'undefined' || (Array.isArray(arg.AccessLevels) && arg.AccessLevels.every((item: unknown) => isAccessLevelPostModel(item))) ) &&
    // Address1: string
    ( typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AdvConfigs?: AdvertisingConfigModel[]
    ( typeof arg.AdvConfigs === 'undefined' || (Array.isArray(arg.AdvConfigs) && arg.AdvConfigs.every((item: unknown) => isAdvertisingConfigModel(item))) ) &&
    // BusinessName?: string
    ( typeof arg.BusinessName === 'undefined' || typeof arg.BusinessName === 'string' ) &&
    // City: string
    ( typeof arg.City === 'string' ) &&
    // CountryId: number
    ( typeof arg.CountryId === 'number' ) &&
    // CustomerID: number
    ( typeof arg.CustomerID === 'number' ) &&
    // CustomerTypeID?: number
    ( typeof arg.CustomerTypeID === 'undefined' || typeof arg.CustomerTypeID === 'number' ) &&
    // DateOfBirth?: string
    ( typeof arg.DateOfBirth === 'undefined' || typeof arg.DateOfBirth === 'string' ) &&
    // DemoGraphics?: DemographicItem[]
    ( typeof arg.DemoGraphics === 'undefined' || (Array.isArray(arg.DemoGraphics) && arg.DemoGraphics.every((item: unknown) => isDemographicItem(item))) ) &&
    // EMailAddress: string
    ( typeof arg.EMailAddress === 'string' ) &&
    // EMailNotices?: boolean
    ( typeof arg.EMailNotices === 'undefined' || typeof arg.EMailNotices === 'boolean' ) &&
    // EmpAddress1?: string
    ( typeof arg.EmpAddress1 === 'undefined' || typeof arg.EmpAddress1 === 'string' ) &&
    // EmpAddress2?: string
    ( typeof arg.EmpAddress2 === 'undefined' || typeof arg.EmpAddress2 === 'string' ) &&
    // EmpCity?: string
    ( typeof arg.EmpCity === 'undefined' || typeof arg.EmpCity === 'string' ) &&
    // EmpCountryId?: number
    ( typeof arg.EmpCountryId === 'undefined' || typeof arg.EmpCountryId === 'number' ) &&
    // EmpName?: string
    ( typeof arg.EmpName === 'undefined' || typeof arg.EmpName === 'string' ) &&
    // EmpPhone?: string
    ( typeof arg.EmpPhone === 'undefined' || typeof arg.EmpPhone === 'string' ) &&
    // EmpStateID?: number
    ( typeof arg.EmpStateID === 'undefined' || typeof arg.EmpStateID === 'number' ) &&
    // EmpZipCode?: string
    ( typeof arg.EmpZipCode === 'undefined' || typeof arg.EmpZipCode === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName: string
    ( typeof arg.FirstName === 'string' ) &&
    // HomePhone: string
    ( typeof arg.HomePhone === 'string' ) &&
    // LastName: string
    ( typeof arg.LastName === 'string' ) &&
    // License?: string
    ( typeof arg.License === 'undefined' || typeof arg.License === 'string' ) &&
    // LicenseCountryID?: number
    ( typeof arg.LicenseCountryID === 'undefined' || typeof arg.LicenseCountryID === 'number' ) &&
    // LicenseExpDate?: string
    ( typeof arg.LicenseExpDate === 'undefined' || typeof arg.LicenseExpDate === 'string' ) &&
    // LicenseStateID?: number
    ( typeof arg.LicenseStateID === 'undefined' || typeof arg.LicenseStateID === 'number' ) &&
    // MilitaryStatus: number
    ( typeof arg.MilitaryStatus === 'number' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // MonthlyCharges?: RecurringChargePostModel[]
    ( typeof arg.MonthlyCharges === 'undefined' || (Array.isArray(arg.MonthlyCharges) && arg.MonthlyCharges.every((item: unknown) => isRecurringChargePostModel(item))) ) &&
    // OnlinePassword?: string
    ( typeof arg.OnlinePassword === 'undefined' || typeof arg.OnlinePassword === 'string' ) &&
    // SecondaryCustomers?: CustomerPostModel[]
    ( typeof arg.SecondaryCustomers === 'undefined' || (Array.isArray(arg.SecondaryCustomers) && arg.SecondaryCustomers.every((item: unknown) => isCustomerPostModel(item))) ) &&
    // SMSCarrierId?: number
    ( typeof arg.SMSCarrierId === 'undefined' || typeof arg.SMSCarrierId === 'number' ) &&
    // SMSNotices?: boolean
    ( typeof arg.SMSNotices === 'undefined' || typeof arg.SMSNotices === 'boolean' ) &&
    // SocSecNo?: string
    ( typeof arg.SocSecNo === 'undefined' || typeof arg.SocSecNo === 'string' ) &&
    // StateID: number
    ( typeof arg.StateID === 'number' ) &&
    // TrackingNumber?: string
    ( typeof arg.TrackingNumber === 'undefined' || typeof arg.TrackingNumber === 'string' ) &&
    // UnitCustomerId: string
    ( typeof arg.UnitCustomerId === 'string' ) &&
    // UnitStatus?: UnitStatus
    ( typeof arg.UnitStatus === 'undefined' || isUnitStatus(arg.UnitStatus) ) &&
    // ZipCode: string
    ( typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isTestConnectionModel(arg: any): arg is models.TestConnectionModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Password?: string
    ( typeof arg.Password === 'undefined' || typeof arg.Password === 'string' ) &&
    // WebServiceUrl?: string
    ( typeof arg.WebServiceUrl === 'undefined' || typeof arg.WebServiceUrl === 'string' ) &&

  true
  );
  }

export function isTestEmailModel(arg: any): arg is models.TestEmailModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AddTextBody?: string
    ( typeof arg.AddTextBody === 'undefined' || typeof arg.AddTextBody === 'string' ) &&
    // EmailBodyTemplate?: string
    ( typeof arg.EmailBodyTemplate === 'undefined' || typeof arg.EmailBodyTemplate === 'string' ) &&
    // MasterHTMLTemplate?: string
    ( typeof arg.MasterHTMLTemplate === 'undefined' || typeof arg.MasterHTMLTemplate === 'string' ) &&
    // ShowClient?: boolean
    ( typeof arg.ShowClient === 'undefined' || typeof arg.ShowClient === 'boolean' ) &&
    // TestEmailAddress?: string
    ( typeof arg.TestEmailAddress === 'undefined' || typeof arg.TestEmailAddress === 'string' ) &&
    // UseEmailTemplates?: boolean
    ( typeof arg.UseEmailTemplates === 'undefined' || typeof arg.UseEmailTemplates === 'boolean' ) &&

  true
  );
  }

export function isTimeZoneModel(arg: any): arg is models.TimeZoneModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Administrative?: boolean
    ( typeof arg.Administrative === 'undefined' || typeof arg.Administrative === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // HolidayStart?: string
    ( typeof arg.HolidayStart === 'undefined' || typeof arg.HolidayStart === 'string' ) &&
    // HolidayStop?: string
    ( typeof arg.HolidayStop === 'undefined' || typeof arg.HolidayStop === 'string' ) &&
    // Is24Hours?: boolean
    ( typeof arg.Is24Hours === 'undefined' || typeof arg.Is24Hours === 'boolean' ) &&
    // SatStart?: string
    ( typeof arg.SatStart === 'undefined' || typeof arg.SatStart === 'string' ) &&
    // SatStop?: string
    ( typeof arg.SatStop === 'undefined' || typeof arg.SatStop === 'string' ) &&
    // SunStart?: string
    ( typeof arg.SunStart === 'undefined' || typeof arg.SunStart === 'string' ) &&
    // SunStop?: string
    ( typeof arg.SunStop === 'undefined' || typeof arg.SunStop === 'string' ) &&
    // TimeZoneID?: number
    ( typeof arg.TimeZoneID === 'undefined' || typeof arg.TimeZoneID === 'number' ) &&
    // TimeZoneNo?: number
    ( typeof arg.TimeZoneNo === 'undefined' || typeof arg.TimeZoneNo === 'number' ) &&
    // WeekDayStart?: string
    ( typeof arg.WeekDayStart === 'undefined' || typeof arg.WeekDayStart === 'string' ) &&
    // WeekDayStop?: string
    ( typeof arg.WeekDayStop === 'undefined' || typeof arg.WeekDayStop === 'string' ) &&

  true
  );
  }

export function isTimeZonePostModel(arg: any): arg is models.TimeZonePostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Administrative?: boolean
    ( typeof arg.Administrative === 'undefined' || typeof arg.Administrative === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // HolidayStart?: number
    ( typeof arg.HolidayStart === 'undefined' || typeof arg.HolidayStart === 'number' ) &&
    // HolidayStop?: number
    ( typeof arg.HolidayStop === 'undefined' || typeof arg.HolidayStop === 'number' ) &&
    // Is24Hours?: boolean
    ( typeof arg.Is24Hours === 'undefined' || typeof arg.Is24Hours === 'boolean' ) &&
    // SatStart?: number
    ( typeof arg.SatStart === 'undefined' || typeof arg.SatStart === 'number' ) &&
    // SatStop?: number
    ( typeof arg.SatStop === 'undefined' || typeof arg.SatStop === 'number' ) &&
    // SunStart?: number
    ( typeof arg.SunStart === 'undefined' || typeof arg.SunStart === 'number' ) &&
    // SunStop?: number
    ( typeof arg.SunStop === 'undefined' || typeof arg.SunStop === 'number' ) &&
    // TimeZoneID?: number
    ( typeof arg.TimeZoneID === 'undefined' || typeof arg.TimeZoneID === 'number' ) &&
    // TimeZoneNo?: number
    ( typeof arg.TimeZoneNo === 'undefined' || typeof arg.TimeZoneNo === 'number' ) &&
    // WeekDayStart?: number
    ( typeof arg.WeekDayStart === 'undefined' || typeof arg.WeekDayStart === 'number' ) &&
    // WeekDayStop?: number
    ( typeof arg.WeekDayStop === 'undefined' || typeof arg.WeekDayStop === 'number' ) &&

  true
  );
  }

export function isTipOfDayModel(arg: any): arg is models.TipOfDayModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Id?: string
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'string' ) &&
    // Text?: string
    ( typeof arg.Text === 'undefined' || typeof arg.Text === 'string' ) &&

  true
  );
  }

export function isTrackingModel(arg: any): arg is models.TrackingModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // SecKey?: string
    ( typeof arg.SecKey === 'undefined' || typeof arg.SecKey === 'string' ) &&
    // TrackingNoDate?: string
    ( typeof arg.TrackingNoDate === 'undefined' || typeof arg.TrackingNoDate === 'string' ) &&

  true
  );
  }

export function isTransactionDetail(arg: any): arg is models.TransactionDetail {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountNo?: number
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'number' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // BalanceAmount?: number
    ( typeof arg.BalanceAmount === 'undefined' || typeof arg.BalanceAmount === 'number' ) &&
    // CredtAmount?: number
    ( typeof arg.CredtAmount === 'undefined' || typeof arg.CredtAmount === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // PostDate?: string
    ( typeof arg.PostDate === 'undefined' || typeof arg.PostDate === 'string' ) &&
    // TransDetailId?: number
    ( typeof arg.TransDetailId === 'undefined' || typeof arg.TransDetailId === 'number' ) &&
    // TransNo?: number
    ( typeof arg.TransNo === 'undefined' || typeof arg.TransNo === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isTransactionDetailResponse(arg: any): arg is models.TransactionDetailResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountId?: number
    ( typeof arg.AccountId === 'undefined' || typeof arg.AccountId === 'number' ) &&
    // Balance?: number
    ( typeof arg.Balance === 'undefined' || typeof arg.Balance === 'number' ) &&
    // Calculable?: boolean
    ( typeof arg.Calculable === 'undefined' || typeof arg.Calculable === 'boolean' ) &&
    // Charges?: number
    ( typeof arg.Charges === 'undefined' || typeof arg.Charges === 'number' ) &&
    // Cost?: number
    ( typeof arg.Cost === 'undefined' || typeof arg.Cost === 'number' ) &&
    // Credits?: number
    ( typeof arg.Credits === 'undefined' || typeof arg.Credits === 'number' ) &&
    // DebitAmt?: number
    ( typeof arg.DebitAmt === 'undefined' || typeof arg.DebitAmt === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // MerchandiseID?: number
    ( typeof arg.MerchandiseID === 'undefined' || typeof arg.MerchandiseID === 'number' ) &&
    // PostDate?: string
    ( typeof arg.PostDate === 'undefined' || typeof arg.PostDate === 'string' ) &&
    // Price?: number
    ( typeof arg.Price === 'undefined' || typeof arg.Price === 'number' ) &&
    // Quantity?: number
    ( typeof arg.Quantity === 'undefined' || typeof arg.Quantity === 'number' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // TransactionDetailNo?: number
    ( typeof arg.TransactionDetailNo === 'undefined' || typeof arg.TransactionDetailNo === 'number' ) &&
    // TransactionNo?: number
    ( typeof arg.TransactionNo === 'undefined' || typeof arg.TransactionNo === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitOfMeasure?: number
    ( typeof arg.UnitOfMeasure === 'undefined' || typeof arg.UnitOfMeasure === 'number' ) &&

  true
  );
  }

export function isTransactionHistoryDetail(arg: any): arg is models.TransactionHistoryDetail {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountNo?: number
    ( typeof arg.AccountNo === 'undefined' || typeof arg.AccountNo === 'number' ) &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // BalanceDue?: number
    ( typeof arg.BalanceDue === 'undefined' || typeof arg.BalanceDue === 'number' ) &&
    // Charges?: number
    ( typeof arg.Charges === 'undefined' || typeof arg.Charges === 'number' ) &&
    // Credits?: number
    ( typeof arg.Credits === 'undefined' || typeof arg.Credits === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // Insurances?: number
    ( typeof arg.Insurances === 'undefined' || typeof arg.Insurances === 'number' ) &&
    // Rent?: number
    ( typeof arg.Rent === 'undefined' || typeof arg.Rent === 'number' ) &&
    // Taxes?: number
    ( typeof arg.Taxes === 'undefined' || typeof arg.Taxes === 'number' ) &&
    // TotalCharge?: number
    ( typeof arg.TotalCharge === 'undefined' || typeof arg.TotalCharge === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isTransactionHistoryItem(arg: any): arg is models.TransactionHistoryItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Balance?: number
    ( typeof arg.Balance === 'undefined' || typeof arg.Balance === 'number' ) &&
    // CanCancel?: boolean
    ( typeof arg.CanCancel === 'undefined' || typeof arg.CanCancel === 'boolean' ) &&
    // Cancelled?: boolean
    ( typeof arg.Cancelled === 'undefined' || typeof arg.Cancelled === 'boolean' ) &&
    // Charge?: number
    ( typeof arg.Charge === 'undefined' || typeof arg.Charge === 'number' ) &&
    // CreatedDate?: string
    ( typeof arg.CreatedDate === 'undefined' || typeof arg.CreatedDate === 'string' ) &&
    // Credit?: number
    ( typeof arg.Credit === 'undefined' || typeof arg.Credit === 'number' ) &&
    // Payment?: number
    ( typeof arg.Payment === 'undefined' || typeof arg.Payment === 'number' ) &&
    // TransDetails?: TransactionHistoryDetail[]
    ( typeof arg.TransDetails === 'undefined' || (Array.isArray(arg.TransDetails) && arg.TransDetails.every((item: unknown) => isTransactionHistoryDetail(item))) ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // TransNo?: number
    ( typeof arg.TransNo === 'undefined' || typeof arg.TransNo === 'number' ) &&
    // TransType?: string
    ( typeof arg.TransType === 'undefined' || typeof arg.TransType === 'string' ) &&

  true
  );
  }

export function isTransactionHistoryResponse(arg: any): arg is models.TransactionHistoryResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Customer?: CustomerTransModel
    ( typeof arg.Customer === 'undefined' || isCustomerTransModel(arg.Customer) ) &&
    // Trans?: TransactionHistoryItem[]
    ( typeof arg.Trans === 'undefined' || (Array.isArray(arg.Trans) && arg.Trans.every((item: unknown) => isTransactionHistoryItem(item))) ) &&

  true
  );
  }

export function isTransactionResponse(arg: any): arg is models.TransactionResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Balance?: number
    ( typeof arg.Balance === 'undefined' || typeof arg.Balance === 'number' ) &&
    // CreatedDate?: string
    ( typeof arg.CreatedDate === 'undefined' || typeof arg.CreatedDate === 'string' ) &&
    // Customer?: CustomerSearchResponse
    ( typeof arg.Customer === 'undefined' || isCustomerSearchResponse(arg.Customer) ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // OperationNo?: number
    ( typeof arg.OperationNo === 'undefined' || typeof arg.OperationNo === 'number' ) &&
    // PostDate?: string
    ( typeof arg.PostDate === 'undefined' || typeof arg.PostDate === 'string' ) &&
    // Reversed?: boolean
    ( typeof arg.Reversed === 'undefined' || typeof arg.Reversed === 'boolean' ) &&
    // ReversedTransID?: number
    ( typeof arg.ReversedTransID === 'undefined' || typeof arg.ReversedTransID === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // TransactionNo?: number
    ( typeof arg.TransactionNo === 'undefined' || typeof arg.TransactionNo === 'number' ) &&
    // TransCategory?: number
    ( typeof arg.TransCategory === 'undefined' || typeof arg.TransCategory === 'number' ) &&
    // TransType?: number
    ( typeof arg.TransType === 'undefined' || typeof arg.TransType === 'number' ) &&

  true
  );
  }

export function isTransactionResult(arg: any): arg is models.TransactionResult {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerModel?: CustomerModel
    ( typeof arg.CustomerModel === 'undefined' || isCustomerModel(arg.CustomerModel) ) &&
    // Errors?: MoveInError[]
    ( typeof arg.Errors === 'undefined' || (Array.isArray(arg.Errors) && arg.Errors.every((item: unknown) => isMoveInError(item))) ) &&
    // PDFFile?: string
    ( typeof arg.PDFFile === 'undefined' || typeof arg.PDFFile === 'string' ) &&
    // PDFFiles?: PdfFile[]
    ( typeof arg.PDFFiles === 'undefined' || (Array.isArray(arg.PDFFiles) && arg.PDFFiles.every((item: unknown) => isPdfFile(item))) ) &&
    // TotalDue?: number
    ( typeof arg.TotalDue === 'undefined' || typeof arg.TotalDue === 'number' ) &&
    // TotalPaid?: number
    ( typeof arg.TotalPaid === 'undefined' || typeof arg.TotalPaid === 'number' ) &&
    // TransactionDetails?: TransactionDetail[]
    ( typeof arg.TransactionDetails === 'undefined' || (Array.isArray(arg.TransactionDetails) && arg.TransactionDetails.every((item: unknown) => isTransactionDetail(item))) ) &&
    // TransDate?: string
    ( typeof arg.TransDate === 'undefined' || typeof arg.TransDate === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // TransNo?: number
    ( typeof arg.TransNo === 'undefined' || typeof arg.TransNo === 'number' ) &&
    // TransType?: '1' | '3' | '5' | '10' | '15' | '20' | '21' | '22' | '23' | '25' | '27' | '30' | '31' | '32' | '35' | '37' | '38' | '40' | '42' | '45' | '50' | '55' | '60' | '61' | '65' | '70' | '75' | '80' | '85' | '90' | '95' | '100' | '105' | '110' | '115' | '120' | '125' | '130' | '135' | '140' | '145' | '150' | '155' | '160' | '165' | '170' | '175' | '176' | '177' | '178' | '179' | '180' | '185' | '190' | '195' | '200' | '205' | '210' | '215' | '220' | '225' | '230' | '235' | '240' | '245' | '250' | '255' | '260' | '265'
    ( typeof arg.TransType === 'undefined' || ['1', '3', '5', '10', '15', '20', '21', '22', '23', '25', '27', '30', '31', '32', '35', '37', '38', '40', '42', '45', '50', '55', '60', '61', '65', '70', '75', '80', '85', '90', '95', '100', '105', '110', '115', '120', '125', '130', '135', '140', '145', '150', '155', '160', '165', '170', '175', '176', '177', '178', '179', '180', '185', '190', '195', '200', '205', '210', '215', '220', '225', '230', '235', '240', '245', '250', '255', '260', '265'].includes(arg.TransType) ) &&
    // Units?: UnitSummaryModel[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => isUnitSummaryModel(item))) ) &&

  true
  );
  }

export function isTransferCostRequest(arg: any): arg is models.TransferCostRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // NewUnitId?: number
    ( typeof arg.NewUnitId === 'undefined' || typeof arg.NewUnitId === 'number' ) &&
    // OldUnitId?: number
    ( typeof arg.OldUnitId === 'undefined' || typeof arg.OldUnitId === 'number' ) &&

  true
  );
  }

export function isTransferCostResponse(arg: any): arg is models.TransferCostResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CurrentPaidToDate?: string
    ( typeof arg.CurrentPaidToDate === 'undefined' || typeof arg.CurrentPaidToDate === 'string' ) &&
    // MoveInDate?: string
    ( typeof arg.MoveInDate === 'undefined' || typeof arg.MoveInDate === 'string' ) &&
    // NewPaidToDate?: string
    ( typeof arg.NewPaidToDate === 'undefined' || typeof arg.NewPaidToDate === 'string' ) &&
    // NewUnitDeposit?: number
    ( typeof arg.NewUnitDeposit === 'undefined' || typeof arg.NewUnitDeposit === 'number' ) &&
    // NewUnitMonthlyCharges?: number
    ( typeof arg.NewUnitMonthlyCharges === 'undefined' || typeof arg.NewUnitMonthlyCharges === 'number' ) &&
    // NewUnitNo?: string
    ( typeof arg.NewUnitNo === 'undefined' || typeof arg.NewUnitNo === 'string' ) &&
    // NewUnitRate?: number
    ( typeof arg.NewUnitRate === 'undefined' || typeof arg.NewUnitRate === 'number' ) &&
    // NewUnitStatus?: string
    ( typeof arg.NewUnitStatus === 'undefined' || typeof arg.NewUnitStatus === 'string' ) &&
    // OldUnitDepositRefund?: number
    ( typeof arg.OldUnitDepositRefund === 'undefined' || typeof arg.OldUnitDepositRefund === 'number' ) &&
    // OldUnitMonthlyCharges?: number
    ( typeof arg.OldUnitMonthlyCharges === 'undefined' || typeof arg.OldUnitMonthlyCharges === 'number' ) &&
    // OldUnitNo?: string
    ( typeof arg.OldUnitNo === 'undefined' || typeof arg.OldUnitNo === 'string' ) &&
    // OldUnitRate?: number
    ( typeof arg.OldUnitRate === 'undefined' || typeof arg.OldUnitRate === 'number' ) &&
    // OldUnitRentDueDay?: number
    ( typeof arg.OldUnitRentDueDay === 'undefined' || typeof arg.OldUnitRentDueDay === 'number' ) &&
    // OldUnitStatus?: string
    ( typeof arg.OldUnitStatus === 'undefined' || typeof arg.OldUnitStatus === 'string' ) &&
    // TransactionDetails?: TransactionDetailResponse[]
    ( typeof arg.TransactionDetails === 'undefined' || (Array.isArray(arg.TransactionDetails) && arg.TransactionDetails.every((item: unknown) => isTransactionDetailResponse(item))) ) &&
    // TransferFee?: number
    ( typeof arg.TransferFee === 'undefined' || typeof arg.TransferFee === 'number' ) &&
    // TransferTotal?: number
    ( typeof arg.TransferTotal === 'undefined' || typeof arg.TransferTotal === 'number' ) &&

  true
  );
  }

export function isTransferRequest(arg: any): arg is models.TransferRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AchPaymentAmount?: number
    ( typeof arg.AchPaymentAmount === 'undefined' || typeof arg.AchPaymentAmount === 'number' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankNo?: string
    ( typeof arg.BankNo === 'undefined' || typeof arg.BankNo === 'string' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // CashPaymentAmount?: number
    ( typeof arg.CashPaymentAmount === 'undefined' || typeof arg.CashPaymentAmount === 'number' ) &&
    // CheckNo?: string
    ( typeof arg.CheckNo === 'undefined' || typeof arg.CheckNo === 'string' ) &&
    // CheckPaymentAmount?: number
    ( typeof arg.CheckPaymentAmount === 'undefined' || typeof arg.CheckPaymentAmount === 'number' ) &&
    // ClearentJWTToken?: string
    ( typeof arg.ClearentJWTToken === 'undefined' || typeof arg.ClearentJWTToken === 'string' ) &&
    // CreditCardPaymentAmount?: number
    ( typeof arg.CreditCardPaymentAmount === 'undefined' || typeof arg.CreditCardPaymentAmount === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // IsAutoBill?: boolean
    ( typeof arg.IsAutoBill === 'undefined' || typeof arg.IsAutoBill === 'boolean' ) &&
    // NewUnitId?: number
    ( typeof arg.NewUnitId === 'undefined' || typeof arg.NewUnitId === 'number' ) &&
    // OldUnitId?: number
    ( typeof arg.OldUnitId === 'undefined' || typeof arg.OldUnitId === 'number' ) &&
    // PayerId?: string
    ( typeof arg.PayerId === 'undefined' || typeof arg.PayerId === 'string' ) &&
    // SavePaymentInfo?: boolean
    ( typeof arg.SavePaymentInfo === 'undefined' || typeof arg.SavePaymentInfo === 'boolean' ) &&
    // SubmittedTransactionIdentifier?: string
    ( typeof arg.SubmittedTransactionIdentifier === 'undefined' || typeof arg.SubmittedTransactionIdentifier === 'string' ) &&
    // TransactionIdentifier?: string
    ( typeof arg.TransactionIdentifier === 'undefined' || typeof arg.TransactionIdentifier === 'string' ) &&
    // TransID?: number
    ( typeof arg.TransID === 'undefined' || typeof arg.TransID === 'number' ) &&
    // UseCardOnFile?: boolean
    ( typeof arg.UseCardOnFile === 'undefined' || typeof arg.UseCardOnFile === 'boolean' ) &&

  true
  );
  }

export function isTransferResponse(arg: any): arg is models.TransferResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // NewUnitId?: number
    ( typeof arg.NewUnitId === 'undefined' || typeof arg.NewUnitId === 'number' ) &&
    // OldUnitId?: number
    ( typeof arg.OldUnitId === 'undefined' || typeof arg.OldUnitId === 'number' ) &&
    // TransactionDetails?: TransactionDetailResponse[]
    ( typeof arg.TransactionDetails === 'undefined' || (Array.isArray(arg.TransactionDetails) && arg.TransactionDetails.every((item: unknown) => isTransactionDetailResponse(item))) ) &&
    // TransId?: number
    ( typeof arg.TransId === 'undefined' || typeof arg.TransId === 'number' ) &&

  true
  );
  }

export function isTransFitModel(arg: any): arg is models.TransFitModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ACHProcessModel?: ACHProcessModel
    ( typeof arg.ACHProcessModel === 'undefined' || isACHProcessModel(arg.ACHProcessModel) ) &&
    // CreditCartProcess?: CreditCartProcessModel
    ( typeof arg.CreditCartProcess === 'undefined' || isCreditCartProcessModel(arg.CreditCartProcess) ) &&
    // ModifiedDate?: string
    ( typeof arg.ModifiedDate === 'undefined' || typeof arg.ModifiedDate === 'string' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&

  true
  );
  }

export function isUnitAdditionalChargeResponse(arg: any): arg is models.UnitAdditionalChargeResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Amount?: number
    ( typeof arg.Amount === 'undefined' || typeof arg.Amount === 'number' ) &&
    // ChargeType?: '1' | '2' | '3' | '4' | '5'
    ( typeof arg.ChargeType === 'undefined' || ['1', '2', '3', '4', '5'].includes(arg.ChargeType) ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // Editable?: boolean
    ( typeof arg.Editable === 'undefined' || typeof arg.Editable === 'boolean' ) &&
    // Id?: number
    ( typeof arg.Id === 'undefined' || typeof arg.Id === 'number' ) &&
    // Price?: number
    ( typeof arg.Price === 'undefined' || typeof arg.Price === 'number' ) &&
    // Quantity?: number
    ( typeof arg.Quantity === 'undefined' || typeof arg.Quantity === 'number' ) &&

  true
  );
  }

export function isUnitContactFile(arg: any): arg is models.UnitContactFile {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // PdfFile?: string
    ( typeof arg.PdfFile === 'undefined' || typeof arg.PdfFile === 'string' ) &&
    // UnitID: number
    ( typeof arg.UnitID === 'number' ) &&

  true
  );
  }

export function isUnitCustomerDataClass(arg: any): arg is models.UnitCustomerDataClass {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessCardNo?: number
    ( typeof arg.AccessCardNo === 'undefined' || typeof arg.AccessCardNo === 'number' ) &&
    // AccessLevel?: string
    ( typeof arg.AccessLevel === 'undefined' || typeof arg.AccessLevel === 'string' ) &&
    // AccessLevels?: AccessLevelsItem[]
    ( typeof arg.AccessLevels === 'undefined' || (Array.isArray(arg.AccessLevels) && arg.AccessLevels.every((item: unknown) => isAccessLevelsItem(item))) ) &&
    // AccountNumber?: string
    ( typeof arg.AccountNumber === 'undefined' || typeof arg.AccountNumber === 'string' ) &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AINumber?: number
    ( typeof arg.AINumber === 'undefined' || typeof arg.AINumber === 'number' ) &&
    // AlertFlag?: boolean
    ( typeof arg.AlertFlag === 'undefined' || typeof arg.AlertFlag === 'boolean' ) &&
    // AutoBill?: boolean
    ( typeof arg.AutoBill === 'undefined' || typeof arg.AutoBill === 'boolean' ) &&
    // AutoBillPeriods?: number
    ( typeof arg.AutoBillPeriods === 'undefined' || typeof arg.AutoBillPeriods === 'number' ) &&
    // BalanceTotal?: number
    ( typeof arg.BalanceTotal === 'undefined' || typeof arg.BalanceTotal === 'number' ) &&
    // BankAccountClass?: number
    ( typeof arg.BankAccountClass === 'undefined' || typeof arg.BankAccountClass === 'number' ) &&
    // BankAccountType?: number
    ( typeof arg.BankAccountType === 'undefined' || typeof arg.BankAccountType === 'number' ) &&
    // BillingDays?: number
    ( typeof arg.BillingDays === 'undefined' || typeof arg.BillingDays === 'number' ) &&
    // BillingType?: number
    ( typeof arg.BillingType === 'undefined' || typeof arg.BillingType === 'number' ) &&
    // BusinessName?: string
    ( typeof arg.BusinessName === 'undefined' || typeof arg.BusinessName === 'string' ) &&
    // CardExp?: string
    ( typeof arg.CardExp === 'undefined' || typeof arg.CardExp === 'string' ) &&
    // CardNo?: string
    ( typeof arg.CardNo === 'undefined' || typeof arg.CardNo === 'string' ) &&
    // CardNoHidden?: string
    ( typeof arg.CardNoHidden === 'undefined' || typeof arg.CardNoHidden === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // Country?: string
    ( typeof arg.Country === 'undefined' || typeof arg.Country === 'string' ) &&
    // CountryId?: number
    ( typeof arg.CountryId === 'undefined' || typeof arg.CountryId === 'number' ) &&
    // CreditCardBrandId?: number
    ( typeof arg.CreditCardBrandId === 'undefined' || typeof arg.CreditCardBrandId === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // CustomerTaxable?: boolean
    ( typeof arg.CustomerTaxable === 'undefined' || typeof arg.CustomerTaxable === 'boolean' ) &&
    // CustomerTypeID?: number
    ( typeof arg.CustomerTypeID === 'undefined' || typeof arg.CustomerTypeID === 'number' ) &&
    // DateOfBirth?: string
    ( typeof arg.DateOfBirth === 'undefined' || typeof arg.DateOfBirth === 'string' ) &&
    // DiscountPlanId?: number
    ( typeof arg.DiscountPlanId === 'undefined' || typeof arg.DiscountPlanId === 'number' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // EMailNotices?: boolean
    ( typeof arg.EMailNotices === 'undefined' || typeof arg.EMailNotices === 'boolean' ) &&
    // EmpAddress1?: string
    ( typeof arg.EmpAddress1 === 'undefined' || typeof arg.EmpAddress1 === 'string' ) &&
    // EmpAddress2?: string
    ( typeof arg.EmpAddress2 === 'undefined' || typeof arg.EmpAddress2 === 'string' ) &&
    // EmpCity?: string
    ( typeof arg.EmpCity === 'undefined' || typeof arg.EmpCity === 'string' ) &&
    // EmpCountryID?: number
    ( typeof arg.EmpCountryID === 'undefined' || typeof arg.EmpCountryID === 'number' ) &&
    // EmpName?: string
    ( typeof arg.EmpName === 'undefined' || typeof arg.EmpName === 'string' ) &&
    // EmpPhone?: string
    ( typeof arg.EmpPhone === 'undefined' || typeof arg.EmpPhone === 'string' ) &&
    // EmpState?: string
    ( typeof arg.EmpState === 'undefined' || typeof arg.EmpState === 'string' ) &&
    // EmpStateID?: number
    ( typeof arg.EmpStateID === 'undefined' || typeof arg.EmpStateID === 'number' ) &&
    // EmpZipCode?: string
    ( typeof arg.EmpZipCode === 'undefined' || typeof arg.EmpZipCode === 'string' ) &&
    // ExpVac?: string
    ( typeof arg.ExpVac === 'undefined' || typeof arg.ExpVac === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // FullName?: string
    ( typeof arg.FullName === 'undefined' || typeof arg.FullName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // InsCoverage?: number
    ( typeof arg.InsCoverage === 'undefined' || typeof arg.InsCoverage === 'number' ) &&
    // InsPaidToDate?: string
    ( typeof arg.InsPaidToDate === 'undefined' || typeof arg.InsPaidToDate === 'string' ) &&
    // InsPolicyNo?: string
    ( typeof arg.InsPolicyNo === 'undefined' || typeof arg.InsPolicyNo === 'string' ) &&
    // InsPremium?: number
    ( typeof arg.InsPremium === 'undefined' || typeof arg.InsPremium === 'number' ) &&
    // InsStartDate?: string
    ( typeof arg.InsStartDate === 'undefined' || typeof arg.InsStartDate === 'string' ) &&
    // InsTheftPercent?: number
    ( typeof arg.InsTheftPercent === 'undefined' || typeof arg.InsTheftPercent === 'number' ) &&
    // InsuranceConfigID?: number
    ( typeof arg.InsuranceConfigID === 'undefined' || typeof arg.InsuranceConfigID === 'number' ) &&
    // InsuranceStatus?: number
    ( typeof arg.InsuranceStatus === 'undefined' || typeof arg.InsuranceStatus === 'number' ) &&
    // LastChargeDate?: string
    ( typeof arg.LastChargeDate === 'undefined' || typeof arg.LastChargeDate === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // LastOffSite?: string
    ( typeof arg.LastOffSite === 'undefined' || typeof arg.LastOffSite === 'string' ) &&
    // LastOnSite?: string
    ( typeof arg.LastOnSite === 'undefined' || typeof arg.LastOnSite === 'string' ) &&
    // LastPaid?: string
    ( typeof arg.LastPaid === 'undefined' || typeof arg.LastPaid === 'string' ) &&
    // LastRateChange?: string
    ( typeof arg.LastRateChange === 'undefined' || typeof arg.LastRateChange === 'string' ) &&
    // LateCycleId?: number
    ( typeof arg.LateCycleId === 'undefined' || typeof arg.LateCycleId === 'number' ) &&
    // LeaseId?: number
    ( typeof arg.LeaseId === 'undefined' || typeof arg.LeaseId === 'number' ) &&
    // LeaseLocked?: boolean
    ( typeof arg.LeaseLocked === 'undefined' || typeof arg.LeaseLocked === 'boolean' ) &&
    // LeaseTaxable?: boolean
    ( typeof arg.LeaseTaxable === 'undefined' || typeof arg.LeaseTaxable === 'boolean' ) &&
    // License?: string
    ( typeof arg.License === 'undefined' || typeof arg.License === 'string' ) &&
    // LicenseExpDate?: string
    ( typeof arg.LicenseExpDate === 'undefined' || typeof arg.LicenseExpDate === 'string' ) &&
    // LicenseStateID?: number
    ( typeof arg.LicenseStateID === 'undefined' || typeof arg.LicenseStateID === 'number' ) &&
    // LockedDate?: string
    ( typeof arg.LockedDate === 'undefined' || typeof arg.LockedDate === 'string' ) &&
    // MIDate?: string
    ( typeof arg.MIDate === 'undefined' || typeof arg.MIDate === 'string' ) &&
    // MilitaryStatus?: number
    ( typeof arg.MilitaryStatus === 'undefined' || typeof arg.MilitaryStatus === 'number' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // MODate?: string
    ( typeof arg.MODate === 'undefined' || typeof arg.MODate === 'string' ) &&
    // MonthlyCharges?: number
    ( typeof arg.MonthlyCharges === 'undefined' || typeof arg.MonthlyCharges === 'number' ) &&
    // NextChargeDate?: string
    ( typeof arg.NextChargeDate === 'undefined' || typeof arg.NextChargeDate === 'string' ) &&
    // NoticeGivenDate?: string
    ( typeof arg.NoticeGivenDate === 'undefined' || typeof arg.NoticeGivenDate === 'string' ) &&
    // OnlinePassword?: string
    ( typeof arg.OnlinePassword === 'undefined' || typeof arg.OnlinePassword === 'string' ) &&
    // OnSite?: boolean
    ( typeof arg.OnSite === 'undefined' || typeof arg.OnSite === 'boolean' ) &&
    // PaidToDate?: string
    ( typeof arg.PaidToDate === 'undefined' || typeof arg.PaidToDate === 'string' ) &&
    // PassCode?: number
    ( typeof arg.PassCode === 'undefined' || typeof arg.PassCode === 'number' ) &&
    // PayerID?: string
    ( typeof arg.PayerID === 'undefined' || typeof arg.PayerID === 'string' ) &&
    // PayerIDExp?: string
    ( typeof arg.PayerIDExp === 'undefined' || typeof arg.PayerIDExp === 'string' ) &&
    // RateChargeMethod?: number
    ( typeof arg.RateChargeMethod === 'undefined' || typeof arg.RateChargeMethod === 'number' ) &&
    // RentDueDay?: number
    ( typeof arg.RentDueDay === 'undefined' || typeof arg.RentDueDay === 'number' ) &&
    // RetCheckAmt?: number
    ( typeof arg.RetCheckAmt === 'undefined' || typeof arg.RetCheckAmt === 'number' ) &&
    // RetCheckDate?: string
    ( typeof arg.RetCheckDate === 'undefined' || typeof arg.RetCheckDate === 'string' ) &&
    // RetCheckFee?: number
    ( typeof arg.RetCheckFee === 'undefined' || typeof arg.RetCheckFee === 'number' ) &&
    // RetCheckNo?: string
    ( typeof arg.RetCheckNo === 'undefined' || typeof arg.RetCheckNo === 'string' ) &&
    // RetCheckTimes?: number
    ( typeof arg.RetCheckTimes === 'undefined' || typeof arg.RetCheckTimes === 'number' ) &&
    // RoutingNumber?: string
    ( typeof arg.RoutingNumber === 'undefined' || typeof arg.RoutingNumber === 'string' ) &&
    // RVLength?: number
    ( typeof arg.RVLength === 'undefined' || typeof arg.RVLength === 'number' ) &&
    // SMSCarrier?: string
    ( typeof arg.SMSCarrier === 'undefined' || typeof arg.SMSCarrier === 'string' ) &&
    // SMSCarrierId?: number
    ( typeof arg.SMSCarrierId === 'undefined' || typeof arg.SMSCarrierId === 'number' ) &&
    // SMSNotices?: boolean
    ( typeof arg.SMSNotices === 'undefined' || typeof arg.SMSNotices === 'boolean' ) &&
    // SocSecNo?: string
    ( typeof arg.SocSecNo === 'undefined' || typeof arg.SocSecNo === 'string' ) &&
    // State?: string
    ( typeof arg.State === 'undefined' || typeof arg.State === 'string' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // Status?: number
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'number' ) &&
    // StatusDesc?: string
    ( typeof arg.StatusDesc === 'undefined' || typeof arg.StatusDesc === 'string' ) &&
    // TimeZoneId?: number
    ( typeof arg.TimeZoneId === 'undefined' || typeof arg.TimeZoneId === 'number' ) &&
    // TrackingNumber?: string
    ( typeof arg.TrackingNumber === 'undefined' || typeof arg.TrackingNumber === 'string' ) &&
    // UnitCustomerId?: string
    ( typeof arg.UnitCustomerId === 'undefined' || typeof arg.UnitCustomerId === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&
    // UnlockedDate?: string
    ( typeof arg.UnlockedDate === 'undefined' || typeof arg.UnlockedDate === 'string' ) &&
    // UseBusinessName?: boolean
    ( typeof arg.UseBusinessName === 'undefined' || typeof arg.UseBusinessName === 'boolean' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isUnitCustomerDetail(arg: any): arg is models.UnitCustomerDetail {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Area?: number
    ( typeof arg.Area === 'undefined' || typeof arg.Area === 'number' ) &&
    // BalanceDue?: number
    ( typeof arg.BalanceDue === 'undefined' || typeof arg.BalanceDue === 'number' ) &&
    // ExpectedMoveOutDate?: string
    ( typeof arg.ExpectedMoveOutDate === 'undefined' || typeof arg.ExpectedMoveOutDate === 'string' ) &&
    // InsurancePremium?: number
    ( typeof arg.InsurancePremium === 'undefined' || typeof arg.InsurancePremium === 'number' ) &&
    // LateCycleSchedule?: string
    ( typeof arg.LateCycleSchedule === 'undefined' || typeof arg.LateCycleSchedule === 'string' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // LeaseTaxable?: boolean
    ( typeof arg.LeaseTaxable === 'undefined' || typeof arg.LeaseTaxable === 'boolean' ) &&
    // MonthlyCharges?: number
    ( typeof arg.MonthlyCharges === 'undefined' || typeof arg.MonthlyCharges === 'number' ) &&
    // MoveinDate?: string
    ( typeof arg.MoveinDate === 'undefined' || typeof arg.MoveinDate === 'string' ) &&
    // PaidtoDate?: string
    ( typeof arg.PaidtoDate === 'undefined' || typeof arg.PaidtoDate === 'string' ) &&
    // ProrateDays?: number
    ( typeof arg.ProrateDays === 'undefined' || typeof arg.ProrateDays === 'number' ) &&
    // RVLength?: number
    ( typeof arg.RVLength === 'undefined' || typeof arg.RVLength === 'number' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitNotes?: string
    ( typeof arg.UnitNotes === 'undefined' || typeof arg.UnitNotes === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&
    // UnitStatus?: string
    ( typeof arg.UnitStatus === 'undefined' || typeof arg.UnitStatus === 'string' ) &&

  true
  );
  }

export function isUnitCustomerDetailResponse(arg: any): arg is models.UnitCustomerDetailResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // InsuranceModel?: InsuranceModel
    ( typeof arg.InsuranceModel === 'undefined' || isInsuranceModel(arg.InsuranceModel) ) &&
    // SecondaryTenants?: SecondaryTenant[]
    ( typeof arg.SecondaryTenants === 'undefined' || (Array.isArray(arg.SecondaryTenants) && arg.SecondaryTenants.every((item: unknown) => isSecondaryTenant(item))) ) &&
    // UnitCustomerDetail?: UnitCustomerDetail
    ( typeof arg.UnitCustomerDetail === 'undefined' || isUnitCustomerDetail(arg.UnitCustomerDetail) ) &&

  true
  );
  }

export function isUnitCustomerResponse(arg: any): arg is models.UnitCustomerResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BalanceDue?: number
    ( typeof arg.BalanceDue === 'undefined' || typeof arg.BalanceDue === 'number' ) &&
    // IsPayDisable?: boolean
    ( typeof arg.IsPayDisable === 'undefined' || typeof arg.IsPayDisable === 'boolean' ) &&
    // LeaseStatus?: number
    ( typeof arg.LeaseStatus === 'undefined' || typeof arg.LeaseStatus === 'number' ) &&
    // PaidtoDate?: string
    ( typeof arg.PaidtoDate === 'undefined' || typeof arg.PaidtoDate === 'string' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // Status?: string
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'string' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&

  true
  );
  }

export function isUnitFeaturesItem(arg: any): arg is models.UnitFeaturesItem {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Item?: string
    ( typeof arg.Item === 'undefined' || typeof arg.Item === 'string' ) &&

  true
  );
  }

export function isUnitLinkModel(arg: any): arg is models.UnitLinkModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: string
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'string' ) &&
    // FullName?: string
    ( typeof arg.FullName === 'undefined' || typeof arg.FullName === 'string' ) &&
    // LinkedCount?: number
    ( typeof arg.LinkedCount === 'undefined' || typeof arg.LinkedCount === 'number' ) &&
    // LinkedTotal?: number
    ( typeof arg.LinkedTotal === 'undefined' || typeof arg.LinkedTotal === 'number' ) &&
    // LinkedUnits?: LinkedUnit[]
    ( typeof arg.LinkedUnits === 'undefined' || (Array.isArray(arg.LinkedUnits) && arg.LinkedUnits.every((item: unknown) => isLinkedUnit(item))) ) &&
    // UnLinkedUnits?: UnLinkedUnit[]
    ( typeof arg.UnLinkedUnits === 'undefined' || (Array.isArray(arg.UnLinkedUnits) && arg.UnLinkedUnits.every((item: unknown) => isUnLinkedUnit(item))) ) &&

  true
  );
  }

export function isUnitListModel(arg: any): arg is models.UnitListModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // IsRentDueDayEditable?: boolean
    ( typeof arg.IsRentDueDayEditable === 'undefined' || typeof arg.IsRentDueDayEditable === 'boolean' ) &&
    // LeaseId?: number
    ( typeof arg.LeaseId === 'undefined' || typeof arg.LeaseId === 'number' ) &&
    // MoveOutDate?: string
    ( typeof arg.MoveOutDate === 'undefined' || typeof arg.MoveOutDate === 'string' ) &&
    // Status?: string
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'string' ) &&
    // UnitCustomerId?: string
    ( typeof arg.UnitCustomerId === 'undefined' || typeof arg.UnitCustomerId === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&

  true
  );
  }

export function isUnitListRequest(arg: any): arg is models.UnitListRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccLevelID?: number
    ( typeof arg.AccLevelID === 'undefined' || typeof arg.AccLevelID === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // Notes?: string
    ( typeof arg.Notes === 'undefined' || typeof arg.Notes === 'string' ) &&
    // Occupied?: boolean
    ( typeof arg.Occupied === 'undefined' || typeof arg.Occupied === 'boolean' ) &&
    // PageNumber?: number
    ( typeof arg.PageNumber === 'undefined' || typeof arg.PageNumber === 'number' ) &&
    // PageSize?: number
    ( typeof arg.PageSize === 'undefined' || typeof arg.PageSize === 'number' ) &&
    // Rentable?: boolean
    ( typeof arg.Rentable === 'undefined' || typeof arg.Rentable === 'boolean' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SiteGroupId?: number
    ( typeof arg.SiteGroupId === 'undefined' || typeof arg.SiteGroupId === 'number' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // Sorter?: SortBy
    ( typeof arg.Sorter === 'undefined' || isSortBy(arg.Sorter) ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitTypeID?: number
    ( typeof arg.UnitTypeID === 'undefined' || typeof arg.UnitTypeID === 'number' ) &&

  true
  );
  }

export function isUnitPostModel(arg: any): arg is models.UnitPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccLevelID?: number
    ( typeof arg.AccLevelID === 'undefined' || typeof arg.AccLevelID === 'number' ) &&
    // AINumber?: number
    ( typeof arg.AINumber === 'undefined' || typeof arg.AINumber === 'number' ) &&
    // AutoRearm?: boolean
    ( typeof arg.AutoRearm === 'undefined' || typeof arg.AutoRearm === 'boolean' ) &&
    // AutoRearmPending?: boolean
    ( typeof arg.AutoRearmPending === 'undefined' || typeof arg.AutoRearmPending === 'boolean' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // Floor?: number
    ( typeof arg.Floor === 'undefined' || typeof arg.Floor === 'number' ) &&
    // NeedsMaintenance?: boolean
    ( typeof arg.NeedsMaintenance === 'undefined' || typeof arg.NeedsMaintenance === 'boolean' ) &&
    // NotesCritical?: boolean
    ( typeof arg.NotesCritical === 'undefined' || typeof arg.NotesCritical === 'boolean' ) &&
    // Rentable?: boolean
    ( typeof arg.Rentable === 'undefined' || typeof arg.Rentable === 'boolean' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitNotes?: string
    ( typeof arg.UnitNotes === 'undefined' || typeof arg.UnitNotes === 'string' ) &&
    // UnitTypeID?: number
    ( typeof arg.UnitTypeID === 'undefined' || typeof arg.UnitTypeID === 'number' ) &&
    // WalkOrder?: number
    ( typeof arg.WalkOrder === 'undefined' || typeof arg.WalkOrder === 'number' ) &&

  true
  );
  }

export function isUnitRateChangeModel(arg: any): arg is models.UnitRateChangeModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // EffectiveDate?: string
    ( typeof arg.EffectiveDate === 'undefined' || typeof arg.EffectiveDate === 'string' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // NewRate?: number
    ( typeof arg.NewRate === 'undefined' || typeof arg.NewRate === 'number' ) &&
    // RateChangeID?: number
    ( typeof arg.RateChangeID === 'undefined' || typeof arg.RateChangeID === 'number' ) &&

  true
  );
  }

export function isUnitRequest(arg: any): arg is models.UnitRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AdditionalCharges?: AdditionalCharge[]
    ( typeof arg.AdditionalCharges === 'undefined' || (Array.isArray(arg.AdditionalCharges) && arg.AdditionalCharges.every((item: unknown) => isAdditionalCharge(item))) ) &&
    // AdvConfigs?: AdvertisingConfigModel[]
    ( typeof arg.AdvConfigs === 'undefined' || (Array.isArray(arg.AdvConfigs) && arg.AdvConfigs.every((item: unknown) => isAdvertisingConfigModel(item))) ) &&
    // ExpectedMoveOutDate?: string
    ( typeof arg.ExpectedMoveOutDate === 'undefined' || typeof arg.ExpectedMoveOutDate === 'string' ) &&
    // InsuranceConfigID?: number
    ( typeof arg.InsuranceConfigID === 'undefined' || typeof arg.InsuranceConfigID === 'number' ) &&
    // InsurancePolicyNo?: string
    ( typeof arg.InsurancePolicyNo === 'undefined' || typeof arg.InsurancePolicyNo === 'string' ) &&
    // LateCycleScheduleId?: number
    ( typeof arg.LateCycleScheduleId === 'undefined' || typeof arg.LateCycleScheduleId === 'number' ) &&
    // MonthsToPay?: number
    ( typeof arg.MonthsToPay === 'undefined' || typeof arg.MonthsToPay === 'number' ) &&
    // PromoID?: number
    ( typeof arg.PromoID === 'undefined' || typeof arg.PromoID === 'number' ) &&
    // RateChargeMethod?: '1' | '2' | '3' | '4' | '5' | '6'
    ( typeof arg.RateChargeMethod === 'undefined' || ['1', '2', '3', '4', '5', '6'].includes(arg.RateChargeMethod) ) &&
    // RateNo?: number
    ( typeof arg.RateNo === 'undefined' || typeof arg.RateNo === 'number' ) &&
    // RentDueDay?: number
    ( typeof arg.RentDueDay === 'undefined' || typeof arg.RentDueDay === 'number' ) &&
    // RVLength?: number
    ( typeof arg.RVLength === 'undefined' || typeof arg.RVLength === 'number' ) &&
    // SiteConfigId?: number
    ( typeof arg.SiteConfigId === 'undefined' || typeof arg.SiteConfigId === 'number' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // Taxable?: boolean
    ( typeof arg.Taxable === 'undefined' || typeof arg.Taxable === 'boolean' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&

  true
  );
  }

export function isUnitStatus(arg: any): arg is models.UnitStatus {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ExpVac?: string
    ( typeof arg.ExpVac === 'undefined' || typeof arg.ExpVac === 'string' ) &&
    // LateCycleId?: number
    ( typeof arg.LateCycleId === 'undefined' || typeof arg.LateCycleId === 'number' ) &&
    // LeaseTaxable?: boolean
    ( typeof arg.LeaseTaxable === 'undefined' || typeof arg.LeaseTaxable === 'boolean' ) &&
    // NoticeGivenDate?: string
    ( typeof arg.NoticeGivenDate === 'undefined' || typeof arg.NoticeGivenDate === 'string' ) &&
    // PromoId?: number
    ( typeof arg.PromoId === 'undefined' || typeof arg.PromoId === 'number' ) &&
    // RateChargeMethod?: number
    ( typeof arg.RateChargeMethod === 'undefined' || typeof arg.RateChargeMethod === 'number' ) &&
    // RVLength?: number
    ( typeof arg.RVLength === 'undefined' || typeof arg.RVLength === 'number' ) &&

  true
  );
  }

export function isUnitStatusModel(arg: any): arg is models.UnitStatusModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ExpVac?: string
    ( typeof arg.ExpVac === 'undefined' || typeof arg.ExpVac === 'string' ) &&
    // IsRentDueDayEditable?: boolean
    ( typeof arg.IsRentDueDayEditable === 'undefined' || typeof arg.IsRentDueDayEditable === 'boolean' ) &&
    // LastPaid?: string
    ( typeof arg.LastPaid === 'undefined' || typeof arg.LastPaid === 'string' ) &&
    // LastRateChange?: string
    ( typeof arg.LastRateChange === 'undefined' || typeof arg.LastRateChange === 'string' ) &&
    // LateCycleId?: number
    ( typeof arg.LateCycleId === 'undefined' || typeof arg.LateCycleId === 'number' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // LeaseTaxable?: boolean
    ( typeof arg.LeaseTaxable === 'undefined' || typeof arg.LeaseTaxable === 'boolean' ) &&
    // MONGivendate?: string
    ( typeof arg.MONGivendate === 'undefined' || typeof arg.MONGivendate === 'string' ) &&
    // MonthlyCharges?: number
    ( typeof arg.MonthlyCharges === 'undefined' || typeof arg.MonthlyCharges === 'number' ) &&
    // MoveinDate?: string
    ( typeof arg.MoveinDate === 'undefined' || typeof arg.MoveinDate === 'string' ) &&
    // MoveOutDate?: string
    ( typeof arg.MoveOutDate === 'undefined' || typeof arg.MoveOutDate === 'string' ) &&
    // NewPaidToDate?: string
    ( typeof arg.NewPaidToDate === 'undefined' || typeof arg.NewPaidToDate === 'string' ) &&
    // NextCharge?: string
    ( typeof arg.NextCharge === 'undefined' || typeof arg.NextCharge === 'string' ) &&
    // PaidtoDate?: string
    ( typeof arg.PaidtoDate === 'undefined' || typeof arg.PaidtoDate === 'string' ) &&
    // PromoId?: number
    ( typeof arg.PromoId === 'undefined' || typeof arg.PromoId === 'number' ) &&
    // ProrateDays?: number
    ( typeof arg.ProrateDays === 'undefined' || typeof arg.ProrateDays === 'number' ) &&
    // RateChargeMethod?: number
    ( typeof arg.RateChargeMethod === 'undefined' || typeof arg.RateChargeMethod === 'number' ) &&
    // RentDueday?: number
    ( typeof arg.RentDueday === 'undefined' || typeof arg.RentDueday === 'number' ) &&
    // RVLength?: number
    ( typeof arg.RVLength === 'undefined' || typeof arg.RVLength === 'number' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // SizeId?: number
    ( typeof arg.SizeId === 'undefined' || typeof arg.SizeId === 'number' ) &&
    // TotalDue?: number
    ( typeof arg.TotalDue === 'undefined' || typeof arg.TotalDue === 'number' ) &&
    // UnitCustomerId?: string
    ( typeof arg.UnitCustomerId === 'undefined' || typeof arg.UnitCustomerId === 'string' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&
    // UnitStatus?: string
    ( typeof arg.UnitStatus === 'undefined' || typeof arg.UnitStatus === 'string' ) &&
    // UnSignedContact?: SkipSignModel
    ( typeof arg.UnSignedContact === 'undefined' || isSkipSignModel(arg.UnSignedContact) ) &&

  true
  );
  }

export function isUnitSummaryModel(arg: any): arg is models.UnitSummaryModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessInterfaceNumber?: number
    ( typeof arg.AccessInterfaceNumber === 'undefined' || typeof arg.AccessInterfaceNumber === 'number' ) &&
    // AccLevelNo?: number
    ( typeof arg.AccLevelNo === 'undefined' || typeof arg.AccLevelNo === 'number' ) &&
    // Area?: number
    ( typeof arg.Area === 'undefined' || typeof arg.Area === 'number' ) &&
    // Charges?: UnitAdditionalChargeResponse[]
    ( typeof arg.Charges === 'undefined' || (Array.isArray(arg.Charges) && arg.Charges.every((item: unknown) => isUnitAdditionalChargeResponse(item))) ) &&
    // DisableInsurance?: boolean
    ( typeof arg.DisableInsurance === 'undefined' || typeof arg.DisableInsurance === 'boolean' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // ExpectedMoveOutDate?: string
    ( typeof arg.ExpectedMoveOutDate === 'undefined' || typeof arg.ExpectedMoveOutDate === 'string' ) &&
    // Floor?: number
    ( typeof arg.Floor === 'undefined' || typeof arg.Floor === 'number' ) &&
    // ImageURL?: string
    ( typeof arg.ImageURL === 'undefined' || typeof arg.ImageURL === 'string' ) &&
    // ImageURLDark?: string
    ( typeof arg.ImageURLDark === 'undefined' || typeof arg.ImageURLDark === 'string' ) &&
    // InsurancePremium?: number
    ( typeof arg.InsurancePremium === 'undefined' || typeof arg.InsurancePremium === 'number' ) &&
    // IsOccupied?: boolean
    ( typeof arg.IsOccupied === 'undefined' || typeof arg.IsOccupied === 'boolean' ) &&
    // LateCycleID?: number
    ( typeof arg.LateCycleID === 'undefined' || typeof arg.LateCycleID === 'number' ) &&
    // LeaseID?: number
    ( typeof arg.LeaseID === 'undefined' || typeof arg.LeaseID === 'number' ) &&
    // MaxReservedtoDate?: string
    ( typeof arg.MaxReservedtoDate === 'undefined' || typeof arg.MaxReservedtoDate === 'string' ) &&
    // MonthlyCharges?: number
    ( typeof arg.MonthlyCharges === 'undefined' || typeof arg.MonthlyCharges === 'number' ) &&
    // MonthsToPay?: number
    ( typeof arg.MonthsToPay === 'undefined' || typeof arg.MonthsToPay === 'number' ) &&
    // MoveinDate?: string
    ( typeof arg.MoveinDate === 'undefined' || typeof arg.MoveinDate === 'string' ) &&
    // NewPaidtoDate?: string
    ( typeof arg.NewPaidtoDate === 'undefined' || typeof arg.NewPaidtoDate === 'string' ) &&
    // PaidtoDate?: string
    ( typeof arg.PaidtoDate === 'undefined' || typeof arg.PaidtoDate === 'string' ) &&
    // PromoDescription?: string
    ( typeof arg.PromoDescription === 'undefined' || typeof arg.PromoDescription === 'string' ) &&
    // PromoDiscount?: number
    ( typeof arg.PromoDiscount === 'undefined' || typeof arg.PromoDiscount === 'number' ) &&
    // PromoDiscountType?: string
    ( typeof arg.PromoDiscountType === 'undefined' || typeof arg.PromoDiscountType === 'string' ) &&
    // PromoDiscountTypeValue?: number
    ( typeof arg.PromoDiscountTypeValue === 'undefined' || typeof arg.PromoDiscountTypeValue === 'number' ) &&
    // PromoID?: number
    ( typeof arg.PromoID === 'undefined' || typeof arg.PromoID === 'number' ) &&
    // ProRateDays?: number
    ( typeof arg.ProRateDays === 'undefined' || typeof arg.ProRateDays === 'number' ) &&
    // RateChargeMethod?: '1' | '2' | '3' | '4' | '5' | '6'
    ( typeof arg.RateChargeMethod === 'undefined' || ['1', '2', '3', '4', '5', '6'].includes(arg.RateChargeMethod) ) &&
    // RentDueDay?: number
    ( typeof arg.RentDueDay === 'undefined' || typeof arg.RentDueDay === 'number' ) &&
    // ReservationDeposit?: number
    ( typeof arg.ReservationDeposit === 'undefined' || typeof arg.ReservationDeposit === 'number' ) &&
    // SecurityDeposit?: number
    ( typeof arg.SecurityDeposit === 'undefined' || typeof arg.SecurityDeposit === 'number' ) &&
    // Selected?: boolean
    ( typeof arg.Selected === 'undefined' || typeof arg.Selected === 'boolean' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // Taxable?: boolean
    ( typeof arg.Taxable === 'undefined' || typeof arg.Taxable === 'boolean' ) &&
    // TotalCharges?: number
    ( typeof arg.TotalCharges === 'undefined' || typeof arg.TotalCharges === 'number' ) &&
    // UnitFeaturesItems?: UnitFeaturesItem[]
    ( typeof arg.UnitFeaturesItems === 'undefined' || (Array.isArray(arg.UnitFeaturesItems) && arg.UnitFeaturesItems.every((item: unknown) => isUnitFeaturesItem(item))) ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitNotes?: string
    ( typeof arg.UnitNotes === 'undefined' || typeof arg.UnitNotes === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&
    // UnitRentAmount?: number
    ( typeof arg.UnitRentAmount === 'undefined' || typeof arg.UnitRentAmount === 'number' ) &&
    // UnitStatus?: string
    ( typeof arg.UnitStatus === 'undefined' || typeof arg.UnitStatus === 'string' ) &&
    // UnitTypeDescription?: string
    ( typeof arg.UnitTypeDescription === 'undefined' || typeof arg.UnitTypeDescription === 'string' ) &&
    // UnitTypeID?: number
    ( typeof arg.UnitTypeID === 'undefined' || typeof arg.UnitTypeID === 'number' ) &&
    // WalkOrder?: number
    ( typeof arg.WalkOrder === 'undefined' || typeof arg.WalkOrder === 'number' ) &&

  true
  );
  }

export function isUnitTypePostModel(arg: any): arg is models.UnitTypePostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisableInsurance?: boolean
    ( typeof arg.DisableInsurance === 'undefined' || typeof arg.DisableInsurance === 'boolean' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // EnableLocations?: boolean
    ( typeof arg.EnableLocations === 'undefined' || typeof arg.EnableLocations === 'boolean' ) &&
    // GateInterface?: boolean
    ( typeof arg.GateInterface === 'undefined' || typeof arg.GateInterface === 'boolean' ) &&
    // RentAccountID?: number
    ( typeof arg.RentAccountID === 'undefined' || typeof arg.RentAccountID === 'number' ) &&
    // UnitType?: number
    ( typeof arg.UnitType === 'undefined' || typeof arg.UnitType === 'number' ) &&
    // UnitTypeID?: number
    ( typeof arg.UnitTypeID === 'undefined' || typeof arg.UnitTypeID === 'number' ) &&

  true
  );
  }

export function isUnitTypeResponse(arg: any): arg is models.UnitTypeResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // UnitTypeId?: number
    ( typeof arg.UnitTypeId === 'undefined' || typeof arg.UnitTypeId === 'number' ) &&

  true
  );
  }

export function isUnitTypeViewModel(arg: any): arg is models.UnitTypeViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // CanDelete?: boolean
    ( typeof arg.CanDelete === 'undefined' || typeof arg.CanDelete === 'boolean' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisableInsurance?: boolean
    ( typeof arg.DisableInsurance === 'undefined' || typeof arg.DisableInsurance === 'boolean' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // EnableLocations?: boolean
    ( typeof arg.EnableLocations === 'undefined' || typeof arg.EnableLocations === 'boolean' ) &&
    // GateInterface?: boolean
    ( typeof arg.GateInterface === 'undefined' || typeof arg.GateInterface === 'boolean' ) &&
    // RentAccount?: string
    ( typeof arg.RentAccount === 'undefined' || typeof arg.RentAccount === 'string' ) &&
    // RentAccountID?: number
    ( typeof arg.RentAccountID === 'undefined' || typeof arg.RentAccountID === 'number' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // UnitType?: number
    ( typeof arg.UnitType === 'undefined' || typeof arg.UnitType === 'number' ) &&
    // UnitTypeID?: number
    ( typeof arg.UnitTypeID === 'undefined' || typeof arg.UnitTypeID === 'number' ) &&

  true
  );
  }

export function isUnitViewModel(arg: any): arg is models.UnitViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccLevel?: string
    ( typeof arg.AccLevel === 'undefined' || typeof arg.AccLevel === 'string' ) &&
    // AccLevelID?: number
    ( typeof arg.AccLevelID === 'undefined' || typeof arg.AccLevelID === 'number' ) &&
    // AINumber?: number
    ( typeof arg.AINumber === 'undefined' || typeof arg.AINumber === 'number' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // Floor?: number
    ( typeof arg.Floor === 'undefined' || typeof arg.Floor === 'number' ) &&
    // ModifiedDate?: string
    ( typeof arg.ModifiedDate === 'undefined' || typeof arg.ModifiedDate === 'string' ) &&
    // NeedsMaintenance?: boolean
    ( typeof arg.NeedsMaintenance === 'undefined' || typeof arg.NeedsMaintenance === 'boolean' ) &&
    // Occupied?: boolean
    ( typeof arg.Occupied === 'undefined' || typeof arg.Occupied === 'boolean' ) &&
    // RentAble?: boolean
    ( typeof arg.RentAble === 'undefined' || typeof arg.RentAble === 'boolean' ) &&
    // SiteConfig?: string
    ( typeof arg.SiteConfig === 'undefined' || typeof arg.SiteConfig === 'string' ) &&
    // SiteConfigID?: number
    ( typeof arg.SiteConfigID === 'undefined' || typeof arg.SiteConfigID === 'number' ) &&
    // SizeDescription?: string
    ( typeof arg.SizeDescription === 'undefined' || typeof arg.SizeDescription === 'string' ) &&
    // SizeID?: number
    ( typeof arg.SizeID === 'undefined' || typeof arg.SizeID === 'number' ) &&
    // UnitID?: number
    ( typeof arg.UnitID === 'undefined' || typeof arg.UnitID === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitNotes?: string
    ( typeof arg.UnitNotes === 'undefined' || typeof arg.UnitNotes === 'string' ) &&
    // UnitType?: string
    ( typeof arg.UnitType === 'undefined' || typeof arg.UnitType === 'string' ) &&
    // UnitTypeID?: number
    ( typeof arg.UnitTypeID === 'undefined' || typeof arg.UnitTypeID === 'number' ) &&
    // WalkOrder?: number
    ( typeof arg.WalkOrder === 'undefined' || typeof arg.WalkOrder === 'number' ) &&

  true
  );
  }

export function isUnLinkedUnit(arg: any): arg is models.UnLinkedUnit {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // BalanceDue?: number
    ( typeof arg.BalanceDue === 'undefined' || typeof arg.BalanceDue === 'number' ) &&
    // CanUnlink?: boolean
    ( typeof arg.CanUnlink === 'undefined' || typeof arg.CanUnlink === 'boolean' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: string
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'string' ) &&
    // Name?: string
    ( typeof arg.Name === 'undefined' || typeof arg.Name === 'string' ) &&
    // PaidToDate?: string
    ( typeof arg.PaidToDate === 'undefined' || typeof arg.PaidToDate === 'string' ) &&
    // Size?: string
    ( typeof arg.Size === 'undefined' || typeof arg.Size === 'string' ) &&
    // Status?: string
    ( typeof arg.Status === 'undefined' || typeof arg.Status === 'string' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&
    // UnitNo?: string
    ( typeof arg.UnitNo === 'undefined' || typeof arg.UnitNo === 'string' ) &&
    // UnitRate?: number
    ( typeof arg.UnitRate === 'undefined' || typeof arg.UnitRate === 'number' ) &&

  true
  );
  }

export function isUnlinkPostModel(arg: any): arg is models.UnlinkPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allow?: boolean
    ( typeof arg.Allow === 'undefined' || typeof arg.Allow === 'boolean' ) &&
    // CustomerId?: number
    ( typeof arg.CustomerId === 'undefined' || typeof arg.CustomerId === 'number' ) &&
    // UnitId?: number
    ( typeof arg.UnitId === 'undefined' || typeof arg.UnitId === 'number' ) &&

  true
  );
  }

export function isUpdateLienChargeRequest(arg: any): arg is models.UpdateLienChargeRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AuctionDate?: string
    ( typeof arg.AuctionDate === 'undefined' || typeof arg.AuctionDate === 'string' ) &&
    // AuctionLocationID?: number
    ( typeof arg.AuctionLocationID === 'undefined' || typeof arg.AuctionLocationID === 'number' ) &&
    // DaysToLien?: number
    ( typeof arg.DaysToLien === 'undefined' || typeof arg.DaysToLien === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // IsAdminMode?: boolean
    ( typeof arg.IsAdminMode === 'undefined' || typeof arg.IsAdminMode === 'boolean' ) &&
    // LateCycleID?: number
    ( typeof arg.LateCycleID === 'undefined' || typeof arg.LateCycleID === 'number' ) &&
    // LienCharges?: LienChargeRequestItem[]
    ( typeof arg.LienCharges === 'undefined' || (Array.isArray(arg.LienCharges) && arg.LienCharges.every((item: unknown) => isLienChargeRequestItem(item))) ) &&

  true
  );
  }

export function isUpdateUserRequest(arg: any): arg is models.UpdateUserRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // ConfirmNewPassword?: string
    ( typeof arg.ConfirmNewPassword === 'undefined' || typeof arg.ConfirmNewPassword === 'string' ) &&
    // CurrentPassword?: string
    ( typeof arg.CurrentPassword === 'undefined' || typeof arg.CurrentPassword === 'string' ) &&
    // EmailAddress?: string
    ( typeof arg.EmailAddress === 'undefined' || typeof arg.EmailAddress === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // NewPassword?: string
    ( typeof arg.NewPassword === 'undefined' || typeof arg.NewPassword === 'string' ) &&

  true
  );
  }

export function isUpsertCustomerRequest(arg: any): arg is models.UpsertCustomerRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessCardNumber?: number
    ( typeof arg.AccessCardNumber === 'undefined' || typeof arg.AccessCardNumber === 'number' ) &&
    // AccessLevels?: AccessLevelPostModel[]
    ( typeof arg.AccessLevels === 'undefined' || (Array.isArray(arg.AccessLevels) && arg.AccessLevels.every((item: unknown) => isAccessLevelPostModel(item))) ) &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AlertFlag?: boolean
    ( typeof arg.AlertFlag === 'undefined' || typeof arg.AlertFlag === 'boolean' ) &&
    // AutoBill?: boolean
    ( typeof arg.AutoBill === 'undefined' || typeof arg.AutoBill === 'boolean' ) &&
    // AutoPay?: boolean
    ( typeof arg.AutoPay === 'undefined' || typeof arg.AutoPay === 'boolean' ) &&
    // BankAccountClass?: number
    ( typeof arg.BankAccountClass === 'undefined' || typeof arg.BankAccountClass === 'number' ) &&
    // BankAccountNo?: string
    ( typeof arg.BankAccountNo === 'undefined' || typeof arg.BankAccountNo === 'string' ) &&
    // BankAccountType?: number
    ( typeof arg.BankAccountType === 'undefined' || typeof arg.BankAccountType === 'number' ) &&
    // BankRoutingNo?: string
    ( typeof arg.BankRoutingNo === 'undefined' || typeof arg.BankRoutingNo === 'string' ) &&
    // BillingDays?: number
    ( typeof arg.BillingDays === 'undefined' || typeof arg.BillingDays === 'number' ) &&
    // BillingType?: number
    ( typeof arg.BillingType === 'undefined' || typeof arg.BillingType === 'number' ) &&
    // BusinessName?: string
    ( typeof arg.BusinessName === 'undefined' || typeof arg.BusinessName === 'string' ) &&
    // ChangePassword?: boolean
    ( typeof arg.ChangePassword === 'undefined' || typeof arg.ChangePassword === 'boolean' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // ConfirmPassword?: string
    ( typeof arg.ConfirmPassword === 'undefined' || typeof arg.ConfirmPassword === 'string' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // CustomerCategory?: '1' | '2' | '3' | '4' | '5' | '6'
    ( typeof arg.CustomerCategory === 'undefined' || ['1', '2', '3', '4', '5', '6'].includes(arg.CustomerCategory) ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // CustomerTypeID?: number
    ( typeof arg.CustomerTypeID === 'undefined' || typeof arg.CustomerTypeID === 'number' ) &&
    // DateOfBirth?: string
    ( typeof arg.DateOfBirth === 'undefined' || typeof arg.DateOfBirth === 'string' ) &&
    // DriversLicenseNo?: string
    ( typeof arg.DriversLicenseNo === 'undefined' || typeof arg.DriversLicenseNo === 'string' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // EMailNotices?: boolean
    ( typeof arg.EMailNotices === 'undefined' || typeof arg.EMailNotices === 'boolean' ) &&
    // EmployerAddress1?: string
    ( typeof arg.EmployerAddress1 === 'undefined' || typeof arg.EmployerAddress1 === 'string' ) &&
    // EmployerAddress2?: string
    ( typeof arg.EmployerAddress2 === 'undefined' || typeof arg.EmployerAddress2 === 'string' ) &&
    // EmployerCity?: string
    ( typeof arg.EmployerCity === 'undefined' || typeof arg.EmployerCity === 'string' ) &&
    // EmployerCountryID?: number
    ( typeof arg.EmployerCountryID === 'undefined' || typeof arg.EmployerCountryID === 'number' ) &&
    // EmployerName?: string
    ( typeof arg.EmployerName === 'undefined' || typeof arg.EmployerName === 'string' ) &&
    // EmployerPhone?: string
    ( typeof arg.EmployerPhone === 'undefined' || typeof arg.EmployerPhone === 'string' ) &&
    // EmployerStateID?: number
    ( typeof arg.EmployerStateID === 'undefined' || typeof arg.EmployerStateID === 'number' ) &&
    // EmployerZipCode?: string
    ( typeof arg.EmployerZipCode === 'undefined' || typeof arg.EmployerZipCode === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // IsActive?: boolean
    ( typeof arg.IsActive === 'undefined' || typeof arg.IsActive === 'boolean' ) &&
    // IsTenantPortal?: boolean
    ( typeof arg.IsTenantPortal === 'undefined' || typeof arg.IsTenantPortal === 'boolean' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // LicenseCountryID?: number
    ( typeof arg.LicenseCountryID === 'undefined' || typeof arg.LicenseCountryID === 'number' ) &&
    // LicenseExpDate?: string
    ( typeof arg.LicenseExpDate === 'undefined' || typeof arg.LicenseExpDate === 'string' ) &&
    // LicenseStateID?: number
    ( typeof arg.LicenseStateID === 'undefined' || typeof arg.LicenseStateID === 'number' ) &&
    // MilitaryStatus?: number
    ( typeof arg.MilitaryStatus === 'undefined' || typeof arg.MilitaryStatus === 'number' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // OnlinePassword?: string
    ( typeof arg.OnlinePassword === 'undefined' || typeof arg.OnlinePassword === 'string' ) &&
    // PassCode?: number
    ( typeof arg.PassCode === 'undefined' || typeof arg.PassCode === 'number' ) &&
    // PrimaryCustomerID?: number
    ( typeof arg.PrimaryCustomerID === 'undefined' || typeof arg.PrimaryCustomerID === 'number' ) &&
    // SendNotices?: boolean
    ( typeof arg.SendNotices === 'undefined' || typeof arg.SendNotices === 'boolean' ) &&
    // SMSCarrierID?: number
    ( typeof arg.SMSCarrierID === 'undefined' || typeof arg.SMSCarrierID === 'number' ) &&
    // SMSNotices?: boolean
    ( typeof arg.SMSNotices === 'undefined' || typeof arg.SMSNotices === 'boolean' ) &&
    // SocialSecurityNo?: string
    ( typeof arg.SocialSecurityNo === 'undefined' || typeof arg.SocialSecurityNo === 'string' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // Taxable?: boolean
    ( typeof arg.Taxable === 'undefined' || typeof arg.Taxable === 'boolean' ) &&
    // TimeZoneID?: number
    ( typeof arg.TimeZoneID === 'undefined' || typeof arg.TimeZoneID === 'number' ) &&
    // TrackingNumber?: string
    ( typeof arg.TrackingNumber === 'undefined' || typeof arg.TrackingNumber === 'string' ) &&
    // UseBusinessName?: boolean
    ( typeof arg.UseBusinessName === 'undefined' || typeof arg.UseBusinessName === 'boolean' ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isUpsertCustomerResponse(arg: any): arg is models.UpsertCustomerResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccessCardNumber?: number
    ( typeof arg.AccessCardNumber === 'undefined' || typeof arg.AccessCardNumber === 'number' ) &&
    // AccessLevels?: AccessLevelPostModel[]
    ( typeof arg.AccessLevels === 'undefined' || (Array.isArray(arg.AccessLevels) && arg.AccessLevels.every((item: unknown) => isAccessLevelPostModel(item))) ) &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // AlertFlag?: boolean
    ( typeof arg.AlertFlag === 'undefined' || typeof arg.AlertFlag === 'boolean' ) &&
    // AutoBill?: boolean
    ( typeof arg.AutoBill === 'undefined' || typeof arg.AutoBill === 'boolean' ) &&
    // AutoPay?: boolean
    ( typeof arg.AutoPay === 'undefined' || typeof arg.AutoPay === 'boolean' ) &&
    // BillingDays?: number
    ( typeof arg.BillingDays === 'undefined' || typeof arg.BillingDays === 'number' ) &&
    // BillingType?: number
    ( typeof arg.BillingType === 'undefined' || typeof arg.BillingType === 'number' ) &&
    // BusinessName?: string
    ( typeof arg.BusinessName === 'undefined' || typeof arg.BusinessName === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerNo?: number
    ( typeof arg.CustomerNo === 'undefined' || typeof arg.CustomerNo === 'number' ) &&
    // DateOfBirth?: string
    ( typeof arg.DateOfBirth === 'undefined' || typeof arg.DateOfBirth === 'string' ) &&
    // DriversLicenseNo?: string
    ( typeof arg.DriversLicenseNo === 'undefined' || typeof arg.DriversLicenseNo === 'string' ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // EMailNotices?: boolean
    ( typeof arg.EMailNotices === 'undefined' || typeof arg.EMailNotices === 'boolean' ) &&
    // EmployerAddress1?: string
    ( typeof arg.EmployerAddress1 === 'undefined' || typeof arg.EmployerAddress1 === 'string' ) &&
    // EmployerAddress2?: string
    ( typeof arg.EmployerAddress2 === 'undefined' || typeof arg.EmployerAddress2 === 'string' ) &&
    // EmployerCity?: string
    ( typeof arg.EmployerCity === 'undefined' || typeof arg.EmployerCity === 'string' ) &&
    // EmployerCountryID?: number
    ( typeof arg.EmployerCountryID === 'undefined' || typeof arg.EmployerCountryID === 'number' ) &&
    // EmployerName?: string
    ( typeof arg.EmployerName === 'undefined' || typeof arg.EmployerName === 'string' ) &&
    // EmployerPhone?: string
    ( typeof arg.EmployerPhone === 'undefined' || typeof arg.EmployerPhone === 'string' ) &&
    // EmployerStateID?: number
    ( typeof arg.EmployerStateID === 'undefined' || typeof arg.EmployerStateID === 'number' ) &&
    // EmployerZipCode?: string
    ( typeof arg.EmployerZipCode === 'undefined' || typeof arg.EmployerZipCode === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // IsActive?: boolean
    ( typeof arg.IsActive === 'undefined' || typeof arg.IsActive === 'boolean' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // LicenseCountryID?: number
    ( typeof arg.LicenseCountryID === 'undefined' || typeof arg.LicenseCountryID === 'number' ) &&
    // LicenseExpDate?: string
    ( typeof arg.LicenseExpDate === 'undefined' || typeof arg.LicenseExpDate === 'string' ) &&
    // LicenseStateID?: number
    ( typeof arg.LicenseStateID === 'undefined' || typeof arg.LicenseStateID === 'number' ) &&
    // MilitaryStatus?: number
    ( typeof arg.MilitaryStatus === 'undefined' || typeof arg.MilitaryStatus === 'number' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // PassCode?: number
    ( typeof arg.PassCode === 'undefined' || typeof arg.PassCode === 'number' ) &&
    // PrimaryCustomerID?: number
    ( typeof arg.PrimaryCustomerID === 'undefined' || typeof arg.PrimaryCustomerID === 'number' ) &&
    // SendNotices?: boolean
    ( typeof arg.SendNotices === 'undefined' || typeof arg.SendNotices === 'boolean' ) &&
    // SMSCarrierID?: number
    ( typeof arg.SMSCarrierID === 'undefined' || typeof arg.SMSCarrierID === 'number' ) &&
    // SMSNotices?: boolean
    ( typeof arg.SMSNotices === 'undefined' || typeof arg.SMSNotices === 'boolean' ) &&
    // SocialSecurityNo?: string
    ( typeof arg.SocialSecurityNo === 'undefined' || typeof arg.SocialSecurityNo === 'string' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // Taxable?: boolean
    ( typeof arg.Taxable === 'undefined' || typeof arg.Taxable === 'boolean' ) &&
    // TimeZoneID?: number
    ( typeof arg.TimeZoneID === 'undefined' || typeof arg.TimeZoneID === 'number' ) &&
    // TrackingNumber?: string
    ( typeof arg.TrackingNumber === 'undefined' || typeof arg.TrackingNumber === 'string' ) &&
    // UseBusinessName?: boolean
    ( typeof arg.UseBusinessName === 'undefined' || typeof arg.UseBusinessName === 'boolean' ) &&
    // WaitingListItems?: CustomerSizesAndUnitsModel
    ( typeof arg.WaitingListItems === 'undefined' || isCustomerSizesAndUnitsModel(arg.WaitingListItems) ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isUpsertOperationReportInfoRequest(arg: any): arg is models.UpsertOperationReportInfoRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // OperationNo?: number
    ( typeof arg.OperationNo === 'undefined' || typeof arg.OperationNo === 'number' ) &&
    // ReportInfoIDList?: number[]
    ( typeof arg.ReportInfoIDList === 'undefined' || (Array.isArray(arg.ReportInfoIDList) && arg.ReportInfoIDList.every((item: unknown) => typeof item === 'number')) ) &&

  true
  );
  }

export function isUserAccount(arg: any): arg is models.UserAccount {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AccountCategory?: number
    ( typeof arg.AccountCategory === 'undefined' || typeof arg.AccountCategory === 'number' ) &&
    // AccountID?: number
    ( typeof arg.AccountID === 'undefined' || typeof arg.AccountID === 'number' ) &&
    // AccountType?: number
    ( typeof arg.AccountType === 'undefined' || typeof arg.AccountType === 'number' ) &&
    // Debit?: boolean
    ( typeof arg.Debit === 'undefined' || typeof arg.Debit === 'boolean' ) &&
    // RevenueType?: number
    ( typeof arg.RevenueType === 'undefined' || typeof arg.RevenueType === 'number' ) &&
    // Title?: string
    ( typeof arg.Title === 'undefined' || typeof arg.Title === 'string' ) &&

  true
  );
  }

export function isUserGroupModel(arg: any): arg is models.UserGroupModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AdminRights?: boolean
    ( typeof arg.AdminRights === 'undefined' || typeof arg.AdminRights === 'boolean' ) &&
    // CompanyID?: number
    ( typeof arg.CompanyID === 'undefined' || typeof arg.CompanyID === 'number' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // DisplayOrder?: number
    ( typeof arg.DisplayOrder === 'undefined' || typeof arg.DisplayOrder === 'number' ) &&
    // UserGroupID?: number
    ( typeof arg.UserGroupID === 'undefined' || typeof arg.UserGroupID === 'number' ) &&
    // UserRights?: UserRightPostModel[]
    ( typeof arg.UserRights === 'undefined' || (Array.isArray(arg.UserRights) && arg.UserRights.every((item: unknown) => isUserRightPostModel(item))) ) &&

  true
  );
  }

export function isUserPostModel(arg: any): arg is models.UserPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Active?: boolean
    ( typeof arg.Active === 'undefined' || typeof arg.Active === 'boolean' ) &&
    // AutoLocked?: boolean
    ( typeof arg.AutoLocked === 'undefined' || typeof arg.AutoLocked === 'boolean' ) &&
    // AutoLockedDate?: string
    ( typeof arg.AutoLockedDate === 'undefined' || typeof arg.AutoLockedDate === 'string' ) &&
    // CompanyID?: number
    ( typeof arg.CompanyID === 'undefined' || typeof arg.CompanyID === 'number' ) &&
    // ConfirmPassword?: string
    ( typeof arg.ConfirmPassword === 'undefined' || typeof arg.ConfirmPassword === 'string' ) &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // EmailAddress?: string
    ( typeof arg.EmailAddress === 'undefined' || typeof arg.EmailAddress === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // LastLoginDate?: string
    ( typeof arg.LastLoginDate === 'undefined' || typeof arg.LastLoginDate === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // Locked?: boolean
    ( typeof arg.Locked === 'undefined' || typeof arg.Locked === 'boolean' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // Password?: string
    ( typeof arg.Password === 'undefined' || typeof arg.Password === 'string' ) &&
    // SMSCarrierID?: number
    ( typeof arg.SMSCarrierID === 'undefined' || typeof arg.SMSCarrierID === 'number' ) &&
    // UserEditable?: boolean
    ( typeof arg.UserEditable === 'undefined' || typeof arg.UserEditable === 'boolean' ) &&
    // UserGroupID?: number
    ( typeof arg.UserGroupID === 'undefined' || typeof arg.UserGroupID === 'number' ) &&
    // UserID?: number
    ( typeof arg.UserID === 'undefined' || typeof arg.UserID === 'number' ) &&
    // UserName?: string
    ( typeof arg.UserName === 'undefined' || typeof arg.UserName === 'string' ) &&

  true
  );
  }

export function isUserResponse(arg: any): arg is models.UserResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // EmailAddress?: string
    ( typeof arg.EmailAddress === 'undefined' || typeof arg.EmailAddress === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // UserId?: number
    ( typeof arg.UserId === 'undefined' || typeof arg.UserId === 'number' ) &&
    // UserName?: string
    ( typeof arg.UserName === 'undefined' || typeof arg.UserName === 'string' ) &&

  true
  );
  }

export function isUserRightPostModel(arg: any): arg is models.UserRightPostModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Allowed?: boolean
    ( typeof arg.Allowed === 'undefined' || typeof arg.Allowed === 'boolean' ) &&
    // FullAccess?: boolean
    ( typeof arg.FullAccess === 'undefined' || typeof arg.FullAccess === 'boolean' ) &&
    // OperationNo?: number
    ( typeof arg.OperationNo === 'undefined' || typeof arg.OperationNo === 'number' ) &&
    // ReadOnly?: boolean
    ( typeof arg.ReadOnly === 'undefined' || typeof arg.ReadOnly === 'boolean' ) &&

  true
  );
  }

export function isUserRightViewModel(arg: any): arg is models.UserRightViewModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Description?: string
    ( typeof arg.Description === 'undefined' || typeof arg.Description === 'string' ) &&
    // FullAccess?: boolean
    ( typeof arg.FullAccess === 'undefined' || typeof arg.FullAccess === 'boolean' ) &&
    // OperationNo?: number
    ( typeof arg.OperationNo === 'undefined' || typeof arg.OperationNo === 'number' ) &&
    // ReadOnly?: boolean
    ( typeof arg.ReadOnly === 'undefined' || typeof arg.ReadOnly === 'boolean' ) &&
    // UserGroupID?: number
    ( typeof arg.UserGroupID === 'undefined' || typeof arg.UserGroupID === 'number' ) &&

  true
  );
  }

export function isUserSecurityModel(arg: any): arg is models.UserSecurityModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // AllowPartialPayments?: boolean
    ( typeof arg.AllowPartialPayments === 'undefined' || typeof arg.AllowPartialPayments === 'boolean' ) &&
    // AutoLockout?: boolean
    ( typeof arg.AutoLockout === 'undefined' || typeof arg.AutoLockout === 'boolean' ) &&
    // AutoLogoff?: boolean
    ( typeof arg.AutoLogoff === 'undefined' || typeof arg.AutoLogoff === 'boolean' ) &&
    // AutoLogoffTime?: number
    ( typeof arg.AutoLogoffTime === 'undefined' || typeof arg.AutoLogoffTime === 'number' ) &&
    // BadLoginAttempts?: number
    ( typeof arg.BadLoginAttempts === 'undefined' || typeof arg.BadLoginAttempts === 'number' ) &&
    // ClearAuditLogFreq?: number
    ( typeof arg.ClearAuditLogFreq === 'undefined' || typeof arg.ClearAuditLogFreq === 'number' ) &&
    // DeactivateUserAcctFreq?: number
    ( typeof arg.DeactivateUserAcctFreq === 'undefined' || typeof arg.DeactivateUserAcctFreq === 'number' ) &&
    // DisplayLastUserName?: boolean
    ( typeof arg.DisplayLastUserName === 'undefined' || typeof arg.DisplayLastUserName === 'boolean' ) &&
    // PasswordChangeFreq?: number
    ( typeof arg.PasswordChangeFreq === 'undefined' || typeof arg.PasswordChangeFreq === 'number' ) &&
    // PasswordChangeWarnFreq?: number
    ( typeof arg.PasswordChangeWarnFreq === 'undefined' || typeof arg.PasswordChangeWarnFreq === 'number' ) &&
    // ShowCreditCardNumbers?: boolean
    ( typeof arg.ShowCreditCardNumbers === 'undefined' || typeof arg.ShowCreditCardNumbers === 'boolean' ) &&
    // SiteGroupID?: number
    ( typeof arg.SiteGroupID === 'undefined' || typeof arg.SiteGroupID === 'number' ) &&
    // TrackingNoDate?: string
    ( typeof arg.TrackingNoDate === 'undefined' || typeof arg.TrackingNoDate === 'string' ) &&
    // UserAuditingEnabled?: boolean
    ( typeof arg.UserAuditingEnabled === 'undefined' || typeof arg.UserAuditingEnabled === 'boolean' ) &&
    // UserEnhSecurityEnabled?: boolean
    ( typeof arg.UserEnhSecurityEnabled === 'undefined' || typeof arg.UserEnhSecurityEnabled === 'boolean' ) &&
    // UserLockoutDuration?: number
    ( typeof arg.UserLockoutDuration === 'undefined' || typeof arg.UserLockoutDuration === 'number' ) &&
    // UserSecurityEnabled?: boolean
    ( typeof arg.UserSecurityEnabled === 'undefined' || typeof arg.UserSecurityEnabled === 'boolean' ) &&

  true
  );
  }

export function isVideo(arg: any): arg is models.Video {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // FileName?: string
    ( typeof arg.FileName === 'undefined' || typeof arg.FileName === 'string' ) &&
    // FileNameWithoutExtension?: string
    ( typeof arg.FileNameWithoutExtension === 'undefined' || typeof arg.FileNameWithoutExtension === 'string' ) &&
    // FilePath?: string
    ( typeof arg.FilePath === 'undefined' || typeof arg.FilePath === 'string' ) &&
    // IsWeb?: boolean
    ( typeof arg.IsWeb === 'undefined' || typeof arg.IsWeb === 'boolean' ) &&

  true
  );
  }

export function isWaitingListCustomerModel(arg: any): arg is models.WaitingListCustomerModel {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Address1?: string
    ( typeof arg.Address1 === 'undefined' || typeof arg.Address1 === 'string' ) &&
    // Address2?: string
    ( typeof arg.Address2 === 'undefined' || typeof arg.Address2 === 'string' ) &&
    // BusinessName?: string
    ( typeof arg.BusinessName === 'undefined' || typeof arg.BusinessName === 'string' ) &&
    // City?: string
    ( typeof arg.City === 'undefined' || typeof arg.City === 'string' ) &&
    // CountryID?: number
    ( typeof arg.CountryID === 'undefined' || typeof arg.CountryID === 'number' ) &&
    // CustomerID?: number
    ( typeof arg.CustomerID === 'undefined' || typeof arg.CustomerID === 'number' ) &&
    // CustomerTypeID?: number
    ( typeof arg.CustomerTypeID === 'undefined' || typeof arg.CustomerTypeID === 'number' ) &&
    // DemographicEntries?: number[]
    ( typeof arg.DemographicEntries === 'undefined' || (Array.isArray(arg.DemographicEntries) && arg.DemographicEntries.every((item: unknown) => typeof item === 'number')) ) &&
    // EMailAddress?: string
    ( typeof arg.EMailAddress === 'undefined' || typeof arg.EMailAddress === 'string' ) &&
    // FaxPhone?: string
    ( typeof arg.FaxPhone === 'undefined' || typeof arg.FaxPhone === 'string' ) &&
    // FirstName?: string
    ( typeof arg.FirstName === 'undefined' || typeof arg.FirstName === 'string' ) &&
    // HomePhone?: string
    ( typeof arg.HomePhone === 'undefined' || typeof arg.HomePhone === 'string' ) &&
    // LastName?: string
    ( typeof arg.LastName === 'undefined' || typeof arg.LastName === 'string' ) &&
    // MobilePhone?: string
    ( typeof arg.MobilePhone === 'undefined' || typeof arg.MobilePhone === 'string' ) &&
    // Sizes?: number[]
    ( typeof arg.Sizes === 'undefined' || (Array.isArray(arg.Sizes) && arg.Sizes.every((item: unknown) => typeof item === 'number')) ) &&
    // SMSCarrierID?: number
    ( typeof arg.SMSCarrierID === 'undefined' || typeof arg.SMSCarrierID === 'number' ) &&
    // SocialSecurityNo?: string
    ( typeof arg.SocialSecurityNo === 'undefined' || typeof arg.SocialSecurityNo === 'string' ) &&
    // StateID?: number
    ( typeof arg.StateID === 'undefined' || typeof arg.StateID === 'number' ) &&
    // Units?: number[]
    ( typeof arg.Units === 'undefined' || (Array.isArray(arg.Units) && arg.Units.every((item: unknown) => typeof item === 'number')) ) &&
    // ZipCode?: string
    ( typeof arg.ZipCode === 'undefined' || typeof arg.ZipCode === 'string' ) &&

  true
  );
  }

export function isWhatIsNew(arg: any): arg is models.WhatIsNew {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // field_663?: string
    ( typeof arg.field_663 === 'undefined' || typeof arg.field_663 === 'string' ) &&
    // field_663_raw?: string
    ( typeof arg.field_663_raw === 'undefined' || typeof arg.field_663_raw === 'string' ) &&
    // field_665?: string
    ( typeof arg.field_665 === 'undefined' || typeof arg.field_665 === 'string' ) &&
    // field_665_raw?: string
    ( typeof arg.field_665_raw === 'undefined' || typeof arg.field_665_raw === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&

  true
  );
  }

export function isYearlyRentReportResponse(arg: any): arg is models.YearlyRentReportResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // Count?: number
    ( typeof arg.Count === 'undefined' || typeof arg.Count === 'number' ) &&
    // Month?: number
    ( typeof arg.Month === 'undefined' || typeof arg.Month === 'number' ) &&
    // Year?: number
    ( typeof arg.Year === 'undefined' || typeof arg.Year === 'number' ) &&

  true
  );
  }


