<div class="side-nav" [ngStyle]="{ width: expandedSideNav ? '300px' : '72px' }">
  <div class="side-nav__left"></div>

  <div class="side-nav__content">
    <div class="side-nav__content--menu">
      <img (click)="onUpdateSideNav()" src="../../../assets/icons/side-nav/hamburger-menu.svg" alt="" />
      <ng-container *ngIf="expandedSideNav">
        <img *ngIf="!(themeService.isDarkModeSubject | async) && !!siteDetails" class="sentinel-icon"
          src="{{ siteLogoImageUrl() || '../../../assets/icons/sentinel-logo.svg' }}" alt="" [routerLink]="'/dashboard/home'" />
        <img *ngIf="(themeService.isDarkModeSubject | async) && !!siteDetails" style="max-width: 190px" class="sentinel-icon"
          src="{{ siteLogoImageUrl() || '../../../assets/icons/sentinel-logo-dark.svg'}}" alt="" [routerLink]="'/dashboard/home'" />
      </ng-container>
    </div>
    <div>
      <div class="link-wrapper" [routerLink]="item.path" (click)="onNavigate(item)" *ngFor="let item of navItems"
        [ngClass]="{'active': isItemActive(item.path)}">
        <div class="link-wrapper__image">
          <img [ngStyle]="getIconStyles(item.icon)"src="../../../assets/icons/side-nav/{{ item.icon }}" alt="" />
        </div>

        <div [ngClass]="(themeService.isDarkModeSubject | async)? 'dark': 'light'" class="link-wrapper__text"
          [ngStyle]="{ display: expandedSideNav ? 'block' : 'none' }">
          <span [ngClass]="{ warning: item.name == 'Log out' }">{{
            item.name
            }}</span>
        </div>
      </div>
    </div>

    <div class="side-nav__content--user">
      <div class="avatar" *ngIf="imageSrc || userInitials">
        <span class="image">
          <img [src]="imageSrc" alt="Image" *ngIf="imageSrc">
          <span *ngIf="!imageSrc">
            {{userInitials}}
          </span>
        </span>

        <span class="full-name" *ngIf="expandedSideNav">
          <span class="label">Logged in as</span>
          <span class="username">{{ userFullName }}</span>
        </span>

        <span class="dark-mode-icon" *ngIf="expandedSideNav">
          <img *ngIf="!(themeService.isDarkModeSubject | async)"
            src="../../../assets/icons/mobile-side-nav/background-changer-icon.svg" alt="" (click)="changeDarkMode()" />

          <img *ngIf="themeService.isDarkModeSubject | async"
            src="../../../assets/icons/mobile-side-nav/background-changer-icon-dark.svg" alt=""
            (click)="changeDarkMode()" />
        </span>
      </div>
    </div>
  </div>
</div>