/* tslint:disable */

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultHttpOptions, HttpOptions } from './';
import { USE_DOMAIN, USE_HTTP_OPTIONS, APIClient } from './api-client.service';

import * as models from './models';
import * as guards from './guards';

/**
 * Created with https://github.com/flowup/api-client-generator
 */
@Injectable()
export class GuardedAPIClient extends APIClient {

  constructor(readonly httpClient: HttpClient,
              @Optional() @Inject(USE_DOMAIN) domain?: string,
              @Optional() @Inject(USE_HTTP_OPTIONS) options?: DefaultHttpOptions) {
    super(httpClient, domain, options);
  }

  override accessControlGetAlarms(
    args: {
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseAlarmPointResponse> {
    return super.accessControlGetAlarms(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseAlarmPointResponse(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseAlarmPointResponse' caught inconsistency.`, res)));
  }

  override accessControlSetAlarmStatus(
    args: {
      doorID: number,
      status: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.accessControlSetAlarmStatus(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override accLevelAddOrUpdate(
    args: {
      request: models.AccLevelPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.accLevelAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override accLevelNextAccLevelNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.accLevelNextAccLevelNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override accLevelDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.accLevelDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override accLevelGet(
    args: {
      psSiteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAccLevelViewModel> {
    return super.accLevelGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListAccLevelViewModel(res) || console.error(`TypeGuard for response 'ResponseListAccLevelViewModel' caught inconsistency.`, res)));
  }

  override accLevelGetAccessDevices(
    args: {
      psSiteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableAccessDeviceModel> {
    return super.accLevelGetAccessDevices(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableAccessDeviceModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerableAccessDeviceModel' caught inconsistency.`, res)));
  }

  override accLevelGetRelays(
    args: {
      psSiteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableRelayModel> {
    return super.accLevelGetRelays(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableRelayModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerableRelayModel' caught inconsistency.`, res)));
  }

  override accLevelGetLightZones(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.accLevelGetLightZones(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override accountAddOrUpdate(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.accountAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override accountDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.accountDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override accountGetAll(
    requestHttpOptions?: HttpOptions
  ): Observable<models.AccountViewModel[]> {
    return super.accountGetAll(requestHttpOptions)
      .pipe(tap((res: any) => guards.isAccountViewModel(res) || console.error(`TypeGuard for response 'AccountViewModel' caught inconsistency.`, res)));
  }

  override accountGetUserAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableUserAccount> {
    return super.accountGetUserAccounts(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableUserAccount(res) || console.error(`TypeGuard for response 'ResponseIEnumerableUserAccount' caught inconsistency.`, res)));
  }

  override accountGetDefaultValues(
    args: {
      selectedAccountId: number,
      addingAnyAccount: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseNextDefaultValue> {
    return super.accountGetDefaultValues(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseNextDefaultValue(res) || console.error(`TypeGuard for response 'ResponseNextDefaultValue' caught inconsistency.`, res)));
  }

  override accountGetRevenueTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.accountGetRevenueTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override accountGetAccountTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.accountGetAccountTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override accountGetAccountCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.accountGetAccountCategories(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override advertisingAddOrUpdateSizes(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.advertisingAddOrUpdateSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override advertisingDeleteSizes(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.advertisingDeleteSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override advertisingGet(
    args: {
      leaseID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableAdvertisingConfigModel> {
    return super.advertisingGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableAdvertisingConfigModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerableAdvertisingConfigModel' caught inconsistency.`, res)));
  }

  override auctionGetAuctions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAuctionResponse> {
    return super.auctionGetAuctions(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListAuctionResponse(res) || console.error(`TypeGuard for response 'ResponseListAuctionResponse' caught inconsistency.`, res)));
  }

  override authAPIUserLogin(
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.authAPIUserLogin(requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override authGetClearentConfig(
    args: {
      siteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseClearentConfigModel> {
    return super.authGetClearentConfig(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseClearentConfigModel(res) || console.error(`TypeGuard for response 'ResponseClearentConfigModel' caught inconsistency.`, res)));
  }

  override authCustomerLogin(
    args: {
      request: models.CustomerLoginRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.authCustomerLogin(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override authSwitchToFacility(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLoginResponse> {
    return super.authSwitchToFacility(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseLoginResponse(res) || console.error(`TypeGuard for response 'ResponseLoginResponse' caught inconsistency.`, res)));
  }

  override authManagerLogin(
    args: {
      request: models.ManagerLoginRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.authManagerLogin(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override authManagerLogout(
    args: {
      userId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.authManagerLogout(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override authForgotPassword(
    args: {
      request: models.ForgotPasswordRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.authForgotPassword(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override authResetPassword(
    args: {
      request: models.ResetPasswordRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.authResetPassword(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override blueMoonSettingAddOrUpdate(
    args: {
      request: models.BlueMoonSettingModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.blueMoonSettingAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override blueMoonSettingGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBlueMoonSettingModel> {
    return super.blueMoonSettingGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBlueMoonSettingModel(res) || console.error(`TypeGuard for response 'ResponseBlueMoonSettingModel' caught inconsistency.`, res)));
  }

  override closingGet(
    args: {
      closeTypes?: number[],
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseClosingModel> {
    return super.closingGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseClosingModel(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseClosingModel' caught inconsistency.`, res)));
  }

  override closingGetProofEditData(
    args: {
      startDate: string,
      endDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseProofEditInfo> {
    return super.closingGetProofEditData(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseProofEditInfo(res) || console.error(`TypeGuard for response 'ResponseProofEditInfo' caught inconsistency.`, res)));
  }

  override closingPrintProofEdit(
    args: {
      request: models.PrintProofEditReportRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportResponse> {
    return super.closingPrintProofEdit(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseReportResponse(res) || console.error(`TypeGuard for response 'ResponseReportResponse' caught inconsistency.`, res)));
  }

  override closingRun(
    args: {
      request: models.RunCloserRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportResponse> {
    return super.closingRun(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseReportResponse(res) || console.error(`TypeGuard for response 'ResponseReportResponse' caught inconsistency.`, res)));
  }

  override closingReprintClose(
    args: {
      request: models.ReprintCloseRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportResponse> {
    return super.closingReprintClose(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseReportResponse(res) || console.error(`TypeGuard for response 'ResponseReportResponse' caught inconsistency.`, res)));
  }

  override customersGet(
    args: {
      customerID: number,
      customerNo?: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListCustomerModel> {
    return super.customersGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListCustomerModel(res) || console.error(`TypeGuard for response 'ResponseListCustomerModel' caught inconsistency.`, res)));
  }

  override customersPut(
    args: {
      customerModelDto: models.UpsertCustomerRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUpsertCustomerResponse> {
    return super.customersPut(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseUpsertCustomerResponse(res) || console.error(`TypeGuard for response 'ResponseUpsertCustomerResponse' caught inconsistency.`, res)));
  }

  override customersPost(
    args: {
      customerModelDto: models.UpsertCustomerRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUpsertCustomerResponse> {
    return super.customersPost(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseUpsertCustomerResponse(res) || console.error(`TypeGuard for response 'ResponseUpsertCustomerResponse' caught inconsistency.`, res)));
  }

  override customersGetAcctInfoByPhone(
    args: {
      infoByPhoneRequest: models.AccountInfoByPhoneRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseAccountInfo> {
    return super.customersGetAcctInfoByPhone(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseAccountInfo(res) || console.error(`TypeGuard for response 'ResponseAccountInfo' caught inconsistency.`, res)));
  }

  override customersGetAccountInfo(
    args: {
      accountInfoRequest: models.AccountInfoRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseAccountInfo> {
    return super.customersGetAccountInfo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseAccountInfo(res) || console.error(`TypeGuard for response 'ResponseAccountInfo' caught inconsistency.`, res)));
  }

  override customersGetPaymentInfo(
    args: {
      request: models.GetPaymentInfoRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaymentInfoResponse> {
    return super.customersGetPaymentInfo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaymentInfoResponse(res) || console.error(`TypeGuard for response 'ResponsePaymentInfoResponse' caught inconsistency.`, res)));
  }

  override customersMakePayment(
    args: {
      payment: models.MakePaymentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    return super.customersMakePayment(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransactionResult(res) || console.error(`TypeGuard for response 'ResponseTransactionResult' caught inconsistency.`, res)));
  }

  override customersGetChangeRentDueDayCost(
    args: {
      request: models.GetPaymentInfoRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaymentInfoResponse> {
    return super.customersGetChangeRentDueDayCost(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaymentInfoResponse(res) || console.error(`TypeGuard for response 'ResponsePaymentInfoResponse' caught inconsistency.`, res)));
  }

  override customersChangeRentDueDay(
    args: {
      payment: models.MakePaymentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    return super.customersChangeRentDueDay(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransactionResult(res) || console.error(`TypeGuard for response 'ResponseTransactionResult' caught inconsistency.`, res)));
  }

  override customersGetCustomerDefaults(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTenantOptionPostModel> {
    return super.customersGetCustomerDefaults(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTenantOptionPostModel(res) || console.error(`TypeGuard for response 'ResponseTenantOptionPostModel' caught inconsistency.`, res)));
  }

  override customersGetDashboardData(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCustomerDashboardResponse> {
    return super.customersGetDashboardData(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseCustomerDashboardResponse(res) || console.error(`TypeGuard for response 'ResponseCustomerDashboardResponse' caught inconsistency.`, res)));
  }

  override customersUpdateCustomerSetting(
    args: {
      customerSummary: models.CustomerSummaryModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.customersUpdateCustomerSetting(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override customersGetCustomerTimeZones(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTimeZoneModel> {
    return super.customersGetCustomerTimeZones(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTimeZoneModel(res) || console.error(`TypeGuard for response 'ResponseTimeZoneModel' caught inconsistency.`, res)));
  }

  override customersTransHistory(
    args: {
      customerID?: number,
      transID?: number,
      isOldTenant?: boolean,
      fromDate?: string,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionHistoryResponse> {
    return super.customersTransHistory(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransactionHistoryResponse(res) || console.error(`TypeGuard for response 'ResponseTransactionHistoryResponse' caught inconsistency.`, res)));
  }

  override customersActivities(
    args: {
      customerID: number,
      fromDate?: string,
      unitNo?: string,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
      accessOnlyEvents?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCustomerEventHistoryResponse> {
    return super.customersActivities(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseCustomerEventHistoryResponse(res) || console.error(`TypeGuard for response 'ResponseCustomerEventHistoryResponse' caught inconsistency.`, res)));
  }

  override customersGenerateRandomPassCode(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.customersGenerateRandomPassCode(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override customersGetSecondaryCustomers(
    args: {
      primaryCustomerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUnitCustomerDataClass> {
    return super.customersGetSecondaryCustomers(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListUnitCustomerDataClass(res) || console.error(`TypeGuard for response 'ResponseListUnitCustomerDataClass' caught inconsistency.`, res)));
  }

  override customersGetAccessControl(
    args: {
      customerId: number,
      unitCustomerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseAccessControlModel> {
    return super.customersGetAccessControl(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseAccessControlModel(res) || console.error(`TypeGuard for response 'ResponseAccessControlModel' caught inconsistency.`, res)));
  }

  override customersGetCustomerHistory(
    args: {
      customerId: number,
      transTypes?: string[],
      unitNo?: string[],
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListTenantHistoryModel> {
    return super.customersGetCustomerHistory(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListTenantHistoryModel(res) || console.error(`TypeGuard for response 'ResponseListTenantHistoryModel' caught inconsistency.`, res)));
  }

  override customersGetCustomerHistoryReport(
    args: {
      customerId: number,
      reportName: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.customersGetCustomerHistoryReport(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override customersGetAccessLevels(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAccessLevelsItem> {
    return super.customersGetAccessLevels(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListAccessLevelsItem(res) || console.error(`TypeGuard for response 'ResponseListAccessLevelsItem' caught inconsistency.`, res)));
  }

  override customersGetMonthlyCharges(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListRecurringChargesItem> {
    return super.customersGetMonthlyCharges(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListRecurringChargesItem(res) || console.error(`TypeGuard for response 'ResponseListRecurringChargesItem' caught inconsistency.`, res)));
  }

  override customersGetTenants(
    args: {
      tenantName?: string,
      unitNo?: string,
      sizes?: number[],
      unitStatuses?: number[],
      lockStatus?: number,
      isDistinct?: boolean,
      isPastDue?: boolean,
      oldTenants?: boolean,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseTenantModel> {
    return super.customersGetTenants(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseTenantModel(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseTenantModel' caught inconsistency.`, res)));
  }

  override customersGetCustomerInfo(
    args: {
      customerId: number,
      unitCustomerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTenantGeneralInfoResponse> {
    return super.customersGetCustomerInfo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTenantGeneralInfoResponse(res) || console.error(`TypeGuard for response 'ResponseTenantGeneralInfoResponse' caught inconsistency.`, res)));
  }

  override customersSave(
    args: {
      data: models.TenantPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.customersSave(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override customersGetODataTenants(
    args: {
      isDistinct: number,
      customerType: string,
      $filter?: string,
      $top?: number,
      $skip?: number,
      $orderby?: string,
      $expand?: string,
      $select?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.CustomerSearchResponse[]> {
    return super.customersGetODataTenants(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isCustomerSearchResponse(res) || console.error(`TypeGuard for response 'CustomerSearchResponse' caught inconsistency.`, res)));
  }

  override customersGetAllContacts(
    args: {
      customerName?: string,
      category?: string,
      phone?: string,
      emailAddress?: string,
      siteGroupId?: number,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseAllContactResponse> {
    return super.customersGetAllContacts(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseAllContactResponse(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseAllContactResponse' caught inconsistency.`, res)));
  }

  override customersGetLatestLeads(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListLatestLeadsResponse> {
    return super.customersGetLatestLeads(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListLatestLeadsResponse(res) || console.error(`TypeGuard for response 'ResponseListLatestLeadsResponse' caught inconsistency.`, res)));
  }

  override customersGetProspectCustomers(
    args: {
      fullName?: string,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseProspectCustomerModel> {
    return super.customersGetProspectCustomers(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseProspectCustomerModel(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseProspectCustomerModel' caught inconsistency.`, res)));
  }

  override customersCreateProspectCustomer(
    args: {
      request: models.ProspectCustomerModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseProspectCustomerModel> {
    return super.customersCreateProspectCustomer(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseProspectCustomerModel(res) || console.error(`TypeGuard for response 'ResponseProspectCustomerModel' caught inconsistency.`, res)));
  }

  override customersDeleteProspectCustomer(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.customersDeleteProspectCustomer(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override customersGetWaitingListCustomers(
    args: {
      fullName?: string,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseGetWaitingListCustomerResponse> {
    return super.customersGetWaitingListCustomers(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseGetWaitingListCustomerResponse(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseGetWaitingListCustomerResponse' caught inconsistency.`, res)));
  }

  override customersCreateWaitingListCustomer(
    args: {
      request: models.WaitingListCustomerModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseWaitingListCustomerModel> {
    return super.customersCreateWaitingListCustomer(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseWaitingListCustomerModel(res) || console.error(`TypeGuard for response 'ResponseWaitingListCustomerModel' caught inconsistency.`, res)));
  }

  override customersDeleteWaitingListCustomer(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.customersDeleteWaitingListCustomer(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override customersGetCustomersHaveReserved(
    args: {
      fullName?: string,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseGetCustomersHaveReservedResponse> {
    return super.customersGetCustomersHaveReserved(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseGetCustomersHaveReservedResponse(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseGetCustomersHaveReservedResponse' caught inconsistency.`, res)));
  }

  override customersGetCustomerSizesAndUnits(
    args: {
      customerId: number,
      isReservation: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCustomerSizesAndUnitsModel> {
    return super.customersGetCustomerSizesAndUnits(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseCustomerSizesAndUnitsModel(res) || console.error(`TypeGuard for response 'ResponseCustomerSizesAndUnitsModel' caught inconsistency.`, res)));
  }

  override customersGetDefaultCashCustomer(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCustomerModel> {
    return super.customersGetDefaultCashCustomer(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseCustomerModel(res) || console.error(`TypeGuard for response 'ResponseCustomerModel' caught inconsistency.`, res)));
  }

  override dashboardOtherOperationsGetTipOfDay(
    requestHttpOptions?: HttpOptions
  ): Observable<models.TipOfDayModel> {
    return super.dashboardOtherOperationsGetTipOfDay(requestHttpOptions)
      .pipe(tap((res: any) => guards.isTipOfDayModel(res) || console.error(`TypeGuard for response 'TipOfDayModel' caught inconsistency.`, res)));
  }

  override dashboardOtherOperationsGetWhatIsNew(
    requestHttpOptions?: HttpOptions
  ): Observable<models.WhatIsNew> {
    return super.dashboardOtherOperationsGetWhatIsNew(requestHttpOptions)
      .pipe(tap((res: any) => guards.isWhatIsNew(res) || console.error(`TypeGuard for response 'WhatIsNew' caught inconsistency.`, res)));
  }

  override dashboardOtherOperationsGetSupportVideos(
    requestHttpOptions?: HttpOptions
  ): Observable<models.Video[]> {
    return super.dashboardOtherOperationsGetSupportVideos(requestHttpOptions)
      .pipe(tap((res: any) => guards.isVideo(res) || console.error(`TypeGuard for response 'Video' caught inconsistency.`, res)));
  }

  override dashboardOtherOperationsGetSystemEvents(
    args: {
      request: models.EventRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.PaginatedListResponseDashboardEventItem> {
    return super.dashboardOtherOperationsGetSystemEvents(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isPaginatedListResponseDashboardEventItem(res) || console.error(`TypeGuard for response 'PaginatedListResponseDashboardEventItem' caught inconsistency.`, res)));
  }

  override demographicAddOrUpdateDemographic(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.demographicAddOrUpdateDemographic(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override demographicDeleteSizes(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.demographicDeleteSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override demographicGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.DemographicGroup[]> {
    return super.demographicGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isDemographicGroup(res) || console.error(`TypeGuard for response 'DemographicGroup' caught inconsistency.`, res)));
  }

  override demographicGetDemoGraphicItem(
    args: {
      demoId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableDemographicItem> {
    return super.demographicGetDemoGraphicItem(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableDemographicItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableDemographicItem' caught inconsistency.`, res)));
  }

  override demographicGetDemoGraphicItemByCustomer(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableCustomerDemoprapichItem> {
    return super.demographicGetDemoGraphicItemByCustomer(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableCustomerDemoprapichItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableCustomerDemoprapichItem' caught inconsistency.`, res)));
  }

  override dictionaryGetCountries(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListCountryModel> {
    return super.dictionaryGetCountries(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListCountryModel(res) || console.error(`TypeGuard for response 'ResponseListCountryModel' caught inconsistency.`, res)));
  }

  override dictionaryGetStates(
    args: {
      countryID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListStateModel> {
    return super.dictionaryGetStates(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListStateModel(res) || console.error(`TypeGuard for response 'ResponseListStateModel' caught inconsistency.`, res)));
  }

  override dictionaryGetTimeZones(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListTimeZoneModel> {
    return super.dictionaryGetTimeZones(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListTimeZoneModel(res) || console.error(`TypeGuard for response 'ResponseListTimeZoneModel' caught inconsistency.`, res)));
  }

  override dictionaryGetCustomerTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.dictionaryGetCustomerTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetActionMethods(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.dictionaryGetActionMethods(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetLetters(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.dictionaryGetLetters(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetInsuranceConfigList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListDictionaryInsuranceConfigModel> {
    return super.dictionaryGetInsuranceConfigList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListDictionaryInsuranceConfigModel(res) || console.error(`TypeGuard for response 'ResponseListDictionaryInsuranceConfigModel' caught inconsistency.`, res)));
  }

  override dictionaryGetMilitaryStatuses(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetMilitaryStatuses(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryBillingTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryBillingTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetRefundOptions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetRefundOptions(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetLedgerOptions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetLedgerOptions(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetRoundingMethods(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetRoundingMethods(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetRateChargeMethodList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetRateChargeMethodList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryDiscounPlanList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryDiscounPlanList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryLateCycleList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryLateCycleList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryAutoBillPeriodsList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryAutoBillPeriodsList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryDaysBeforeDueDateList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryDaysBeforeDueDateList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryBankAccountClassesList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryBankAccountClassesList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryBankAccountTypesList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryBankAccountTypesList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionarySmsCarrier(
    args: {
      countryId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionarySmsCarrier(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryRentAccountList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryRentAccountList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetGateInterfaces(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListGateInterfaceModel> {
    return super.dictionaryGetGateInterfaces(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListGateInterfaceModel(res) || console.error(`TypeGuard for response 'ResponseListGateInterfaceModel' caught inconsistency.`, res)));
  }

  override dictionaryGetAccLevels(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAccLevelModel> {
    return super.dictionaryGetAccLevels(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListAccLevelModel(res) || console.error(`TypeGuard for response 'ResponseListAccLevelModel' caught inconsistency.`, res)));
  }

  override dictionaryPayableorPrepaidAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryPayableorPrepaidAccounts(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetAuctionLocation(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetAuctionLocation(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryRevenueAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryRevenueAccounts(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetMiscItems(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetMiscItems(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetAccountTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetAccountTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetAccountList(
    args: {
      accountType: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetAccountList(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetLienItems(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetLienItems(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetMerchItems(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMerchandiseModel> {
    return super.dictionaryGetMerchItems(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListMerchandiseModel(res) || console.error(`TypeGuard for response 'ResponseListMerchandiseModel' caught inconsistency.`, res)));
  }

  override dictionaryTexCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryTexCategories(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryReceivableaccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryReceivableaccounts(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryMerchandiseAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryMerchandiseAccounts(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetTransTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.dictionaryGetTransTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override dictionaryGetFollowUpActions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListFollowUpAction> {
    return super.dictionaryGetFollowUpActions(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListFollowUpAction(res) || console.error(`TypeGuard for response 'ResponseListFollowUpAction' caught inconsistency.`, res)));
  }

  override dictionaryTestEmail(
    args: {
      testMail: models.EmailData,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.dictionaryTestEmail(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override dictionaryPathq(
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.dictionaryPathq(requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override documentsRentalAgreement(
    args: {
      document: models.DocumentModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.documentsRentalAgreement(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override documentsPostSignature(
    args: {
      customerSig: models.CustomerSig,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.documentsPostSignature(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override documentsWelcome(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.documentsWelcome(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override documentsMoveInReceipt(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.documentsMoveInReceipt(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override documentsReservationContract(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.documentsReservationContract(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override documentsReceipt(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.documentsReceipt(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override documentsAddendum(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.documentsAddendum(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override documentsAddSignature(
    args: {
      request: models.DocImgSigRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.documentsAddSignature(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override documentsGetCustomerContracts(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListGetCustomerContractResponse> {
    return super.documentsGetCustomerContracts(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListGetCustomerContractResponse(res) || console.error(`TypeGuard for response 'ResponseListGetCustomerContractResponse' caught inconsistency.`, res)));
  }

  override documentsWelcomeNew(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.documentsWelcomeNew(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override documentsReceiptNew(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.documentsReceiptNew(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override editorGetInfo(
    args: {
      unitCustomerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseEditorResponseModel> {
    return super.editorGetInfo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseEditorResponseModel(res) || console.error(`TypeGuard for response 'ResponseEditorResponseModel' caught inconsistency.`, res)));
  }

  override editorAddTransItem(
    args: {
      request: models.EditorTransRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.editorAddTransItem(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override editorSave(
    args: {
      request: models.EditorRequestModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.editorSave(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override emailSettingCommitSetting(
    args: {
      model: models.EmailSettingModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.emailSettingCommitSetting(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override emailSettingGet(
    args: {
      isLoadingDefaultGmailSeeting: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseEmailSettingModel> {
    return super.emailSettingGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseEmailSettingModel(res) || console.error(`TypeGuard for response 'ResponseEmailSettingModel' caught inconsistency.`, res)));
  }

  override emailSettingGetSMTPSetting(
    args: {
      isLoadingDefaultGmailSeeting: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSMTPSettingModel> {
    return super.emailSettingGetSMTPSetting(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseSMTPSettingModel(res) || console.error(`TypeGuard for response 'ResponseSMTPSettingModel' caught inconsistency.`, res)));
  }

  override emailSettingCommitSMTPSetting(
    args: {
      model: models.SMTPSettingModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.emailSettingCommitSMTPSetting(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override emailSettingGetMailSystems(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.emailSettingGetMailSystems(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override emailSettingGetSMTPLoginOptions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.emailSettingGetSMTPLoginOptions(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override emailSettingSendTestEmail(
    args: {
      model: models.TestEmailModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.emailSettingSendTestEmail(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override eventDescriptionAddOrUpdate(
    args: {
      request: models.EventDescriptionPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.eventDescriptionAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override eventDescriptionNextEventNo(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.eventDescriptionNextEventNo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override eventDescriptionDeleteSizes(
    args: {
      ids: number[],
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.eventDescriptionDeleteSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override eventDescriptionGet(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.EventDescriptionViewModel[]> {
    return super.eventDescriptionGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isEventDescriptionViewModel(res) || console.error(`TypeGuard for response 'EventDescriptionViewModel' caught inconsistency.`, res)));
  }

  override eventDescriptionGetEventType(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.eventDescriptionGetEventType(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override financeGetBillingInfoOfCustomer(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBillingInfoModel> {
    return super.financeGetBillingInfoOfCustomer(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBillingInfoModel(res) || console.error(`TypeGuard for response 'ResponseBillingInfoModel' caught inconsistency.`, res)));
  }

  override financeUpdateBillingInfoOfCustomer(
    args: {
      model: models.BillingInfoPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.financeUpdateBillingInfoOfCustomer(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override financeGetInsurancesInfoOfCustomer(
    args: {
      customerId?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListInsuranceModel> {
    return super.financeGetInsurancesInfoOfCustomer(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListInsuranceModel(res) || console.error(`TypeGuard for response 'ResponseListInsuranceModel' caught inconsistency.`, res)));
  }

  override financeGetInsuranceCoverageList(
    args: {
      insuranceConfigId: number,
      rateNo: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListCoverageModel> {
    return super.financeGetInsuranceCoverageList(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListCoverageModel(res) || console.error(`TypeGuard for response 'ResponseListCoverageModel' caught inconsistency.`, res)));
  }

  override financeGetTransactions(
    args: {
      customerID?: number,
      transID?: number,
      isOldTenant?: boolean,
      fromDate?: string,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionHistoryResponse> {
    return super.financeGetTransactions(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransactionHistoryResponse(res) || console.error(`TypeGuard for response 'ResponseTransactionHistoryResponse' caught inconsistency.`, res)));
  }

  override financeAllocateInsurance(
    args: {
      request: models.InsurancePaymentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInsuranceTransDetailResponse> {
    return super.financeAllocateInsurance(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInsuranceTransDetailResponse(res) || console.error(`TypeGuard for response 'ResponseInsuranceTransDetailResponse' caught inconsistency.`, res)));
  }

  override financeCompleteInsurance(
    args: {
      request: models.InsurancePaymentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.financeCompleteInsurance(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override financeGetBalances(
    args: {
      request: models.FinanceBalanceRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBalanceResponse> {
    return super.financeGetBalances(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBalanceResponse(res) || console.error(`TypeGuard for response 'ResponseBalanceResponse' caught inconsistency.`, res)));
  }

  override financeGetReturnCheck(
    args: {
      customerId: number,
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReturnCheckModel> {
    return super.financeGetReturnCheck(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseReturnCheckModel(res) || console.error(`TypeGuard for response 'ResponseReturnCheckModel' caught inconsistency.`, res)));
  }

  override financeCancelVoidpayment(
    args: {
      customerId: number,
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCancelTransactionVoidRespone> {
    return super.financeCancelVoidpayment(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseCancelTransactionVoidRespone(res) || console.error(`TypeGuard for response 'ResponseCancelTransactionVoidRespone' caught inconsistency.`, res)));
  }

  override financeCancelTransaction(
    args: {
      request: models.CancelTransRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseString> {
    return super.financeCancelTransaction(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseString(res) || console.error(`TypeGuard for response 'ResponseString' caught inconsistency.`, res)));
  }

  override financeSaveReturnCheck(
    args: {
      model: models.ReturnCheckPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.financeSaveReturnCheck(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override financeIsRetCheckAlreadyPosted(
    args: {
      customerId: number,
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.financeIsRetCheckAlreadyPosted(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override followUpActionAddOrUpdate(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.followUpActionAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override followUpActionDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.followUpActionDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override followUpActionGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableFollowUpActionModel> {
    return super.followUpActionGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableFollowUpActionModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerableFollowUpActionModel' caught inconsistency.`, res)));
  }

  override gateAccessGetSignalRConfiguration(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSignalRConfigurationModel> {
    return super.gateAccessGetSignalRConfiguration(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseSignalRConfigurationModel(res) || console.error(`TypeGuard for response 'ResponseSignalRConfigurationModel' caught inconsistency.`, res)));
  }

  override gateAccessGetClosestGate(
    args: {
      customerId: number,
      model: models.ClosestGateRequestModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseClosestGateResponseModel> {
    return super.gateAccessGetClosestGate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseClosestGateResponseModel(res) || console.error(`TypeGuard for response 'ResponseClosestGateResponseModel' caught inconsistency.`, res)));
  }

  override gateAccessGetGateList(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListGateInfo> {
    return super.gateAccessGetGateList(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListGateInfo(res) || console.error(`TypeGuard for response 'ResponseListGateInfo' caught inconsistency.`, res)));
  }

  override gateAccessGetGateAccessData(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseGateAccessDataModel> {
    return super.gateAccessGetGateAccessData(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseGateAccessDataModel(res) || console.error(`TypeGuard for response 'ResponseGateAccessDataModel' caught inconsistency.`, res)));
  }

  override gateAccessSaveGateInfo(
    args: {
      request: models.GateInfoPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.gateAccessSaveGateInfo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override gateAccessGateMaxDistance(
    args: {
      request: models.GetGateMaxDistanceRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseDouble> {
    return super.gateAccessGateMaxDistance(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseDouble(res) || console.error(`TypeGuard for response 'ResponseDouble' caught inconsistency.`, res)));
  }

  override insuranceConfigAddOrUpdate(
    args: {
      request: models.InsuranceConfigRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.insuranceConfigAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override insuranceConfigNextInsuranceConfigNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.insuranceConfigNextInsuranceConfigNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override insuranceConfigDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.insuranceConfigDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override insuranceConfigGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.InsuranceConfigResponse[]> {
    return super.insuranceConfigGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isInsuranceConfigResponse(res) || console.error(`TypeGuard for response 'InsuranceConfigResponse' caught inconsistency.`, res)));
  }

  override kioskOptionAddOrUpdate(
    args: {
      request: models.KioskOptionModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.kioskOptionAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override kioskOptionGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseKioskOptionModel> {
    return super.kioskOptionGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseKioskOptionModel(res) || console.error(`TypeGuard for response 'ResponseKioskOptionModel' caught inconsistency.`, res)));
  }

  override lateCycleAddOrUpdate(
    args: {
      request: models.LateCycleRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.lateCycleAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override LateCycleNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.LateCycleNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override lateCycleNextLateFeeSchedulesNo(
    args: {
      lateCycleId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.lateCycleNextLateFeeSchedulesNo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override lateCycleNextLateNoticeSchedulesNo(
    args: {
      lateCycleId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.lateCycleNextLateNoticeSchedulesNo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override lateCycleDelete(
    args: {
      ids: number[],
      siteGroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.lateCycleDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override lateCycleGet(
    args: {
      sitegroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.LateCycleModel[]> {
    return super.lateCycleGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isLateCycleModel(res) || console.error(`TypeGuard for response 'LateCycleModel' caught inconsistency.`, res)));
  }

  override lateCycleGetLateFetypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.lateCycleGetLateFetypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override lateCycleGetLienCharge(
    args: {
      latecycleID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLienChargeResponse> {
    return super.lateCycleGetLienCharge(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseLienChargeResponse(res) || console.error(`TypeGuard for response 'ResponseLienChargeResponse' caught inconsistency.`, res)));
  }

  override lateCycleUpdateLienCharge(
    args: {
      model: models.UpdateLienChargeRequest,
      latecycleID: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.lateCycleUpdateLienCharge(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override lateCycleDeleteLienCharges(
    args: {
      ids: number[],
      lateCycleId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.lateCycleDeleteLienCharges(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override lateCycleGetLateFeeScheduleTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.lateCycleGetLateFeeScheduleTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override lateLettersAndMailingsGetLateLettersAndMailings(
    args: {
      noticeDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListNoticeResponse> {
    return super.lateLettersAndMailingsGetLateLettersAndMailings(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListNoticeResponse(res) || console.error(`TypeGuard for response 'ResponseListNoticeResponse' caught inconsistency.`, res)));
  }

  override lateLettersAndMailingsPrintNotice(
    args: {
      request: models.PrintNoticeRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.lateLettersAndMailingsPrintNotice(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override lateLettersAndMailingsGetSpecialMailings(
    args: {
      request: models.SpecialMailingRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListNoticeResponse> {
    return super.lateLettersAndMailingsGetSpecialMailings(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListNoticeResponse(res) || console.error(`TypeGuard for response 'ResponseListNoticeResponse' caught inconsistency.`, res)));
  }

  override letterAddOrUpdate(
    args: {
      request: models.LetterPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.letterAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override LetterNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.LetterNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override letterDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.letterDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override LetterFileNames(
    args: {
      letterID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableLetterFileNameViewModel> {
    return super.LetterFileNames(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableLetterFileNameViewModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerableLetterFileNameViewModel' caught inconsistency.`, res)));
  }

  override letterGetLetterCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.letterGetLetterCategories(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override letterGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.LetterViewModel[]> {
    return super.letterGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isLetterViewModel(res) || console.error(`TypeGuard for response 'LetterViewModel' caught inconsistency.`, res)));
  }

  override letterGetLetterNames(
    args: {
      request: models.LetterFilterRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListString> {
    return super.letterGetLetterNames(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListString(res) || console.error(`TypeGuard for response 'ResponseListString' caught inconsistency.`, res)));
  }

  override letterPrint(
    args: {
      request: models.PrintOrSendLetterRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.letterPrint(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override letterSendLetterEmail(
    args: {
      request: models.PrintOrSendLetterRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.letterSendLetterEmail(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override LettersbyFilter(
    args: {
      request: models.LetterRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableLetterViewModel> {
    return super.LettersbyFilter(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableLetterViewModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerableLetterViewModel' caught inconsistency.`, res)));
  }

  override letterGetAttachmentFormats(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListString> {
    return super.letterGetAttachmentFormats(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListString(res) || console.error(`TypeGuard for response 'ResponseListString' caught inconsistency.`, res)));
  }

  override letterGetSmsCarriers(
    args: {
      currentRegionGeoId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ModelItem[]> {
    return super.letterGetSmsCarriers(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isModelItem(res) || console.error(`TypeGuard for response 'ModelItem' caught inconsistency.`, res)));
  }

  override letterPrintLetter(
    args: {
      request: models.LetterPrintRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTenantLetterPrintResponse> {
    return super.letterPrintLetter(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTenantLetterPrintResponse(res) || console.error(`TypeGuard for response 'ResponseTenantLetterPrintResponse' caught inconsistency.`, res)));
  }

  override letterGetLetterCorrespondence(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLetterCorrespondenceModel> {
    return super.letterGetLetterCorrespondence(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseLetterCorrespondenceModel(res) || console.error(`TypeGuard for response 'ResponseLetterCorrespondenceModel' caught inconsistency.`, res)));
  }

  override letterUpdateLetterCorrespondence(
    args: {
      model: models.LetterCorrespondenceModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.letterUpdateLetterCorrespondence(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override libraryGetLibrary(
    args: {
      key: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.libraryGetLibrary(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override lienGet(
    args: {
      customerID: number,
      leaseId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLienResponse> {
    return super.lienGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseLienResponse(res) || console.error(`TypeGuard for response 'ResponseLienResponse' caught inconsistency.`, res)));
  }

  override lienGetAuctionLocation(
    args: {
      id: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseAuctionLocation> {
    return super.lienGetAuctionLocation(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseAuctionLocation(res) || console.error(`TypeGuard for response 'ResponseAuctionLocation' caught inconsistency.`, res)));
  }

  override lienAddDefault(
    args: {
      customerID: number,
      leaseId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.lienAddDefault(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override lienAddMiscItems(
    args: {
      request: models.ChargeRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.lienAddMiscItems(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override lienCompleteLien(
    args: {
      request: models.LienPostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCompleteLienResponse> {
    return super.lienCompleteLien(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseCompleteLienResponse(res) || console.error(`TypeGuard for response 'ResponseCompleteLienResponse' caught inconsistency.`, res)));
  }

  override lienPrintLien(
    args: {
      request: models.LienPrintRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLienPrintResponse> {
    return super.lienPrintLien(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseLienPrintResponse(res) || console.error(`TypeGuard for response 'ResponseLienPrintResponse' caught inconsistency.`, res)));
  }

  override lienDisplay(
    args: {
      request: models.DisplayRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.lienDisplay(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override mainOfficeAddOrUpdate(
    args: {
      request: models.MainOfficePostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.mainOfficeAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override mainOfficeDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.mainOfficeDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override mainOfficeGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMainOfficeViewModel> {
    return super.mainOfficeGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListMainOfficeViewModel(res) || console.error(`TypeGuard for response 'ResponseListMainOfficeViewModel' caught inconsistency.`, res)));
  }

  override mainOfficeGetTenantOption(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTenantOptionPostModel> {
    return super.mainOfficeGetTenantOption(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTenantOptionPostModel(res) || console.error(`TypeGuard for response 'ResponseTenantOptionPostModel' caught inconsistency.`, res)));
  }

  override mainOfficeGetFeeOption(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFeeOptionPostModel> {
    return super.mainOfficeGetFeeOption(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFeeOptionPostModel(res) || console.error(`TypeGuard for response 'ResponseFeeOptionPostModel' caught inconsistency.`, res)));
  }

  override mainOfficeGetSystemDate(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSystemDateModel> {
    return super.mainOfficeGetSystemDate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseSystemDateModel(res) || console.error(`TypeGuard for response 'ResponseSystemDateModel' caught inconsistency.`, res)));
  }

  override mainOfficeGetPosVendors(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.mainOfficeGetPosVendors(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override mainOfficeGetAuctionLocation(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAuctionLocationModel> {
    return super.mainOfficeGetAuctionLocation(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListAuctionLocationModel(res) || console.error(`TypeGuard for response 'ResponseListAuctionLocationModel' caught inconsistency.`, res)));
  }

  override mainOfficeAddOrUpdateAuctionLocation(
    args: {
      request: models.AuctionLocationModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.mainOfficeAddOrUpdateAuctionLocation(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override mainOfficeDeleteAuctionLocation(
    args: {
      ids: number[],
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.mainOfficeDeleteAuctionLocation(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override managerDashboardGetYearlyRentReport(
    args: {
      endDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListYearlyRentReportResponse> {
    return super.managerDashboardGetYearlyRentReport(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListYearlyRentReportResponse(res) || console.error(`TypeGuard for response 'ResponseListYearlyRentReportResponse' caught inconsistency.`, res)));
  }

  override managerDashboardGetOccupancyReport(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseOccupancyReportResponse> {
    return super.managerDashboardGetOccupancyReport(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseOccupancyReportResponse(res) || console.error(`TypeGuard for response 'ResponseOccupancyReportResponse' caught inconsistency.`, res)));
  }

  override managerDashboardGetDelinquencyReport(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseDelinquencyReportResponse> {
    return super.managerDashboardGetDelinquencyReport(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseDelinquencyReportResponse(res) || console.error(`TypeGuard for response 'ResponseDelinquencyReportResponse' caught inconsistency.`, res)));
  }

  override managerDashboardGetRevenueReport(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseRevenueReportResponse> {
    return super.managerDashboardGetRevenueReport(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseRevenueReportResponse(res) || console.error(`TypeGuard for response 'ResponseRevenueReportResponse' caught inconsistency.`, res)));
  }

  override managerDashboardGetMarketingReport(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMarketingReportResponse> {
    return super.managerDashboardGetMarketingReport(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListMarketingReportResponse(res) || console.error(`TypeGuard for response 'ResponseListMarketingReportResponse' caught inconsistency.`, res)));
  }

  override managerDashboardGetDashboardBillingInfo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseDashboardBillingInfoResponse> {
    return super.managerDashboardGetDashboardBillingInfo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseDashboardBillingInfoResponse(res) || console.error(`TypeGuard for response 'ResponseDashboardBillingInfoResponse' caught inconsistency.`, res)));
  }

  override merchandiseAddOrUpdateUnitType(
    args: {
      request: models.MerchandiseCommonModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.merchandiseAddOrUpdateUnitType(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override merchandiseNextItemNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.merchandiseNextItemNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override merchandiseNextCategoryNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.merchandiseNextCategoryNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override merchandiseGetMercHandises(
    requestHttpOptions?: HttpOptions
  ): Observable<models.MerchandiseViewModel[]> {
    return super.merchandiseGetMercHandises(requestHttpOptions)
      .pipe(tap((res: any) => guards.isMerchandiseViewModel(res) || console.error(`TypeGuard for response 'MerchandiseViewModel' caught inconsistency.`, res)));
  }

  override merchandiseGetCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.MerchandiseCategoryModel[]> {
    return super.merchandiseGetCategories(requestHttpOptions)
      .pipe(tap((res: any) => guards.isMerchandiseCategoryModel(res) || console.error(`TypeGuard for response 'MerchandiseCategoryModel' caught inconsistency.`, res)));
  }

  override MoveOutUnitsList(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMoveOutLeases> {
    return super.MoveOutUnitsList(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListMoveOutLeases(res) || console.error(`TypeGuard for response 'ResponseListMoveOutLeases' caught inconsistency.`, res)));
  }

  override MoveoutTransDetailsList(
    args: {
      request: models.MoveOutTransDetailsRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMoveOutTransDetails> {
    return super.MoveoutTransDetailsList(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListMoveOutTransDetails(res) || console.error(`TypeGuard for response 'ResponseListMoveOutTransDetails' caught inconsistency.`, res)));
  }

  override moveOutBalanceDue(
    args: {
      request: models.BalanceRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseDecimal> {
    return super.moveOutBalanceDue(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseDecimal(res) || console.error(`TypeGuard for response 'ResponseDecimal' caught inconsistency.`, res)));
  }

  override moveOutCompleteMoveout(
    args: {
      request: models.MoveOutRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.moveOutCompleteMoveout(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override moveOutIsCreditToCustomer(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.moveOutIsCreditToCustomer(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override notesGetNotes(
    args: {
      customerID?: number,
      filter?: string,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListNoteModel> {
    return super.notesGetNotes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListNoteModel(res) || console.error(`TypeGuard for response 'ResponseListNoteModel' caught inconsistency.`, res)));
  }

  override notesCreateOrUpdateNote(
    args: {
      request: models.NoteModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseNoteModel> {
    return super.notesCreateOrUpdateNote(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseNoteModel(res) || console.error(`TypeGuard for response 'ResponseNoteModel' caught inconsistency.`, res)));
  }

  override notesDeleteNote(
    args: {
      noteId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.notesDeleteNote(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override notesPrintNotes(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.notesPrintNotes(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override onlineConfigSaveData(
    args: {
      request: models.OnlineConfigModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.onlineConfigSaveData(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override onlineConfigUpdateWebService(
    args: {
      request: models.OnlineConfigModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.onlineConfigUpdateWebService(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override onlineConfigTestConnection(
    args: {
      request: models.TestConnectionModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.onlineConfigTestConnection(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override onlineConfigGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseOnlineConfigModel> {
    return super.onlineConfigGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseOnlineConfigModel(res) || console.error(`TypeGuard for response 'ResponseOnlineConfigModel' caught inconsistency.`, res)));
  }

  override operationGet(
    args: {
      operationCategories?: number[],
      operationNos?: number[],
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseOperationModel> {
    return super.operationGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseOperationModel(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseOperationModel' caught inconsistency.`, res)));
  }

  override operationGetOperationReportInfos(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListOperationReportInfoModel> {
    return super.operationGetOperationReportInfos(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListOperationReportInfoModel(res) || console.error(`TypeGuard for response 'ResponseListOperationReportInfoModel' caught inconsistency.`, res)));
  }

  override operationCreateOperationReportInfo(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.operationCreateOperationReportInfo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override overLockGet(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableOverLockResponse> {
    return super.overLockGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableOverLockResponse(res) || console.error(`TypeGuard for response 'ResponseIEnumerableOverLockResponse' caught inconsistency.`, res)));
  }

  override overLockSaveChanges(
    args: {
      request: models.OverLockRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.overLockSaveChanges(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override paymentSaveCreditCard(
    args: {
      request: models.SaveCreditCardClearentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSaveCreditCardClearentResponse> {
    return super.paymentSaveCreditCard(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseSaveCreditCardClearentResponse(res) || console.error(`TypeGuard for response 'ResponseSaveCreditCardClearentResponse' caught inconsistency.`, res)));
  }

  override paymentGetCustomerCreditCards(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListCustomerCreditCardResponse> {
    return super.paymentGetCustomerCreditCards(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListCustomerCreditCardResponse(res) || console.error(`TypeGuard for response 'ResponseListCustomerCreditCardResponse' caught inconsistency.`, res)));
  }

  override paymentDeleteCustomerCreditCard(
    args: {
      payerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.paymentDeleteCustomerCreditCard(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override paymentEditClearentConfig(
    args: {
      request: models.ClearentConfigModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.paymentEditClearentConfig(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override paymentGetClearentConfigs(
    args: {
      siteGroupId: number,
      siteConfigId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableClearentConfigModel> {
    return super.paymentGetClearentConfigs(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableClearentConfigModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerableClearentConfigModel' caught inconsistency.`, res)));
  }

  override payMoverAddOrUpdate(
    args: {
      request: models.PayMoverModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.payMoverAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override payMoverGet(
    args: {
      siteGroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePayMoverModel> {
    return super.payMoverGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePayMoverModel(res) || console.error(`TypeGuard for response 'ResponsePayMoverModel' caught inconsistency.`, res)));
  }

  override payMoverSaveAdditionalAccountList(
    args: {
      list: any,
      siteGroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.payMoverSaveAdditionalAccountList(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override payMoverGetAdditionalAccount(
    args: {
      siteGroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerablePayMoverAdditionalAccountModel> {
    return super.payMoverGetAdditionalAccount(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerablePayMoverAdditionalAccountModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerablePayMoverAdditionalAccountModel' caught inconsistency.`, res)));
  }

  override picturesGetCustomerPictures(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerablePicture> {
    return super.picturesGetCustomerPictures(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerablePicture(res) || console.error(`TypeGuard for response 'ResponseIEnumerablePicture' caught inconsistency.`, res)));
  }

  override picturesAddCustomerPicture(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.picturesAddCustomerPicture(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override picturesRemoveCustomerPicture(
    args: {
      pictureId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.picturesRemoveCustomerPicture(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override printerSave(
    args: {
      request: models.PrinterModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.printerSave(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override printerGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePrinterModel> {
    return super.printerGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePrinterModel(res) || console.error(`TypeGuard for response 'ResponsePrinterModel' caught inconsistency.`, res)));
  }

  override printerGetPrinters(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableString> {
    return super.printerGetPrinters(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableString(res) || console.error(`TypeGuard for response 'ResponseIEnumerableString' caught inconsistency.`, res)));
  }

  override promotionsAddOrUpdate(
    args: {
      request: models.PromoModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.promotionsAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override promotionsDelete(
    args: {
      iDs: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.promotionsDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override promotionsGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.PromoModel[]> {
    return super.promotionsGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isPromoModel(res) || console.error(`TypeGuard for response 'PromoModel' caught inconsistency.`, res)));
  }

  override promotionsGetPromoTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ModelItem[]> {
    return super.promotionsGetPromoTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isModelItem(res) || console.error(`TypeGuard for response 'ModelItem' caught inconsistency.`, res)));
  }

  override promotionsGetPromoLocations(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ModelItem[]> {
    return super.promotionsGetPromoLocations(requestHttpOptions)
      .pipe(tap((res: any) => guards.isModelItem(res) || console.error(`TypeGuard for response 'ModelItem' caught inconsistency.`, res)));
  }

  override promotionsGetPromoDiscountTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ModelItem[]> {
    return super.promotionsGetPromoDiscountTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isModelItem(res) || console.error(`TypeGuard for response 'ModelItem' caught inconsistency.`, res)));
  }

  override quickBooksInterfacePreviewExportQuickBooks(
    args: {
      request: models.ExportQuickBooksRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePreviewExportQuickBooksResponse> {
    return super.quickBooksInterfacePreviewExportQuickBooks(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePreviewExportQuickBooksResponse(res) || console.error(`TypeGuard for response 'ResponsePreviewExportQuickBooksResponse' caught inconsistency.`, res)));
  }

  override quickBooksInterfaceExportQuickBooks(
    args: {
      request: models.ExportQuickBooksRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    return super.quickBooksInterfaceExportQuickBooks(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseFileResponse(res) || console.error(`TypeGuard for response 'ResponseFileResponse' caught inconsistency.`, res)));
  }

  override quickBooksInterfaceGetDepositGroups(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListDepositGroupModel> {
    return super.quickBooksInterfaceGetDepositGroups(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListDepositGroupModel(res) || console.error(`TypeGuard for response 'ResponseListDepositGroupModel' caught inconsistency.`, res)));
  }

  override quickBooksInterfaceUpsertDepositGroup(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.quickBooksInterfaceUpsertDepositGroup(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override quickBooksInterfaceDeleteDepositGroup(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.quickBooksInterfaceDeleteDepositGroup(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override quickBooksInterfaceImportIIFFile(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.quickBooksInterfaceImportIIFFile(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override quickBooksInterfaceGetQBAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListQBAccountModel> {
    return super.quickBooksInterfaceGetQBAccounts(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListQBAccountModel(res) || console.error(`TypeGuard for response 'ResponseListQBAccountModel' caught inconsistency.`, res)));
  }

  override rateChangeGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListRateChangeModel> {
    return super.rateChangeGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListRateChangeModel(res) || console.error(`TypeGuard for response 'ResponseListRateChangeModel' caught inconsistency.`, res)));
  }

  override rateChangePost(
    args: {
      upsertRateChangeRequest: models.RateChangeModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.rateChangePost(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override rateChangeDeleteSizes(
    args: {
      rateChangeId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.rateChangeDeleteSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override rateChangeGetRateChangeTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.rateChangeGetRateChangeTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override rateChangeGetNewRateCalcMethods(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.rateChangeGetNewRateCalcMethods(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override rateChangeGetRoundingMethods(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.rateChangeGetRoundingMethods(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override referenceTableAddOrUpdate(
    args: {
      request: models.ReferanceTablePostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.referenceTableAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override referenceTableGetData(
    args: {
      request: models.ReferanceTableRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListBaseReferenceTable> {
    return super.referenceTableGetData(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListBaseReferenceTable(res) || console.error(`TypeGuard for response 'ResponseListBaseReferenceTable' caught inconsistency.`, res)));
  }

  override referenceTableGetTables(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    return super.referenceTableGetTables(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableModelItem(res) || console.error(`TypeGuard for response 'ResponseIEnumerableModelItem' caught inconsistency.`, res)));
  }

  override referenceTableDelete(
    args: {
      ids: number[],
      tabelIndex: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.referenceTableDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override remindersGetReminderTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListReminderTypeModel> {
    return super.remindersGetReminderTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListReminderTypeModel(res) || console.error(`TypeGuard for response 'ResponseListReminderTypeModel' caught inconsistency.`, res)));
  }

  override remindersGetReminders(
    args: {
      customerId: number,
      filter: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListReminderResponseModel> {
    return super.remindersGetReminders(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListReminderResponseModel(res) || console.error(`TypeGuard for response 'ResponseListReminderResponseModel' caught inconsistency.`, res)));
  }

  override remindersGetReminder(
    args: {
      reminderId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReminderResponseModel> {
    return super.remindersGetReminder(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseReminderResponseModel(res) || console.error(`TypeGuard for response 'ResponseReminderResponseModel' caught inconsistency.`, res)));
  }

  override remindersCreateOrUpdateReminder(
    args: {
      request: models.ReminderRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReminderResponseModel> {
    return super.remindersCreateOrUpdateReminder(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseReminderResponseModel(res) || console.error(`TypeGuard for response 'ResponseReminderResponseModel' caught inconsistency.`, res)));
  }

  override remindersDeleteReminder(
    args: {
      reminderId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.remindersDeleteReminder(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override remindersGetRemindersForDashboard(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListReminderEntryResponse> {
    return super.remindersGetRemindersForDashboard(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListReminderEntryResponse(res) || console.error(`TypeGuard for response 'ResponseListReminderEntryResponse' caught inconsistency.`, res)));
  }

  override reportGetReportList(
    args: {
      reportCategory: number,
      name?: string,
      reportNos?: number[],
      reportCategories?: number[],
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseReportListModel> {
    return super.reportGetReportList(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseReportListModel(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseReportListModel' caught inconsistency.`, res)));
  }

  override reportGetSentinelReportList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListSentinelReportListModel> {
    return super.reportGetSentinelReportList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListSentinelReportListModel(res) || console.error(`TypeGuard for response 'ResponseListSentinelReportListModel' caught inconsistency.`, res)));
  }

  override reportGetReportCategoryList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListReportCategoryListItem> {
    return super.reportGetReportCategoryList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListReportCategoryListItem(res) || console.error(`TypeGuard for response 'ResponseListReportCategoryListItem' caught inconsistency.`, res)));
  }

  override reportGetReportEventCategoryList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListEventCategoriesModel> {
    return super.reportGetReportEventCategoryList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListEventCategoriesModel(res) || console.error(`TypeGuard for response 'ResponseListEventCategoriesModel' caught inconsistency.`, res)));
  }

  override reportGenerateReports(
    args: {
      reportRequest: models.GenerateReportRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportResponse> {
    return super.reportGenerateReports(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseReportResponse(res) || console.error(`TypeGuard for response 'ResponseReportResponse' caught inconsistency.`, res)));
  }

  override reportDisplay(
    args: {
      index: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.reportDisplay(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override reportGetSiteGroupUseCashAccount(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.reportGetSiteGroupUseCashAccount(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override reportAddOrUpdate(
    args: {
      request: models.ReportInfoPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.reportAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override ReportNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.ReportNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override reportDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.reportDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override reportGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ReportInfoViewModel[]> {
    return super.reportGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isReportInfoViewModel(res) || console.error(`TypeGuard for response 'ReportInfoViewModel' caught inconsistency.`, res)));
  }

  override reportGetReportNames(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListString> {
    return super.reportGetReportNames(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListString(res) || console.error(`TypeGuard for response 'ResponseListString' caught inconsistency.`, res)));
  }

  override reportGetSummary(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportInfoViewModel> {
    return super.reportGetSummary(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseReportInfoViewModel(res) || console.error(`TypeGuard for response 'ResponseReportInfoViewModel' caught inconsistency.`, res)));
  }

  override reportCategoryAddOrUpdate(
    args: {
      request: models.ReportCategoryModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.reportCategoryAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override ReportCategoryNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.ReportCategoryNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override reportCategoryDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.reportCategoryDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override reportCategoryGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ReportCategoryModel[]> {
    return super.reportCategoryGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isReportCategoryModel(res) || console.error(`TypeGuard for response 'ReportCategoryModel' caught inconsistency.`, res)));
  }

  override retailSalesGetRetailSaleCost(
    args: {
      request: models.RetailSalesCostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseRetailSalesCostResponse> {
    return super.retailSalesGetRetailSaleCost(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseRetailSalesCostResponse(res) || console.error(`TypeGuard for response 'ResponseRetailSalesCostResponse' caught inconsistency.`, res)));
  }

  override retailSalesTransferCost(
    args: {
      request: models.RetailSalesRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseRetailSalesResponse> {
    return super.retailSalesTransferCost(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseRetailSalesResponse(res) || console.error(`TypeGuard for response 'ResponseRetailSalesResponse' caught inconsistency.`, res)));
  }

  override siteListInsuranceConfig(
    args: {
      insuranceConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListInsuranceConfigModel> {
    return super.siteListInsuranceConfig(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListInsuranceConfigModel(res) || console.error(`TypeGuard for response 'ResponseListInsuranceConfigModel' caught inconsistency.`, res)));
  }

  override siteListInsuranceTransactions(
    args: {
      startDate: string,
      endDate: string,
      insuranceConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListInsuranceTransactionModel> {
    return super.siteListInsuranceTransactions(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListInsuranceTransactionModel(res) || console.error(`TypeGuard for response 'ResponseListInsuranceTransactionModel' caught inconsistency.`, res)));
  }

  override siteGetSiteInfo(
    args: {
      siteConfigNo: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListSiteModel> {
    return super.siteGetSiteInfo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListSiteModel(res) || console.error(`TypeGuard for response 'ResponseListSiteModel' caught inconsistency.`, res)));
  }

  override siteGetSiteDetail(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSiteModel> {
    return super.siteGetSiteDetail(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseSiteModel(res) || console.error(`TypeGuard for response 'ResponseSiteModel' caught inconsistency.`, res)));
  }

  override siteGetNextCustomerNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.siteGetNextCustomerNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override siteSendSupportEmail(
    args: {
      request: models.SupportEmailModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.siteSendSupportEmail(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override siteChangeSiteLogo(
    args: {
      logoModel: models.SiteLogoModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.siteChangeSiteLogo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override siteRemoveSiteLogo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.siteRemoveSiteLogo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override siteGetSiteSettings(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSiteSettingModel> {
    return super.siteGetSiteSettings(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseSiteSettingModel(res) || console.error(`TypeGuard for response 'ResponseSiteSettingModel' caught inconsistency.`, res)));
  }

  override siteSaveSiteSettings(
    args: {
      request: models.SiteSettingModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.siteSaveSiteSettings(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override siteSendSupportEmailTenant(
    args: {
      request: models.SupportEmailModel,
      siteConfigId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.siteSendSupportEmailTenant(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override SitesListBySiteId(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableSiteGroupModel> {
    return super.SitesListBySiteId(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableSiteGroupModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerableSiteGroupModel' caught inconsistency.`, res)));
  }

  override siteConfigAddorupdate(
    args: {
      model: models.SiteConfigPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.siteConfigAddorupdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override siteConfigGetAll(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListSiteConfigViewModel> {
    return super.siteConfigGetAll(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListSiteConfigViewModel(res) || console.error(`TypeGuard for response 'ResponseListSiteConfigViewModel' caught inconsistency.`, res)));
  }

  override siteConfigDeleteSiteConfig(
    args: {
      siteConfigIds: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.siteConfigDeleteSiteConfig(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override siteConfigNextSiteConfigNoAndSiteName(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.siteConfigNextSiteConfigNoAndSiteName(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override siteConfigGateConfigurationList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListGateConfigModel> {
    return super.siteConfigGateConfigurationList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListGateConfigModel(res) || console.error(`TypeGuard for response 'ResponseListGateConfigModel' caught inconsistency.`, res)));
  }

  override siteConfigPostGateConfig(
    args: {
      model: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.siteConfigPostGateConfig(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override siteConfigUpLoad(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.siteConfigUpLoad(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override sizesAddOrUpdateSizes(
    args: {
      request: models.SizePostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.sizesAddOrUpdateSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override sizesAddOrUpdateRange(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.sizesAddOrUpdateRange(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override sizesDeleteSizes(
    args: {
      sizeID: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.sizesDeleteSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override sizesGetAll(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.SizeViewModel[]> {
    return super.sizesGetAll(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isSizeViewModel(res) || console.error(`TypeGuard for response 'SizeViewModel' caught inconsistency.`, res)));
  }

  override sizesUploadSizeImage(
    args: {
      sizeGroup: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12',
      darkMode: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    return super.sizesUploadSizeImage(args, requestHttpOptions)
      .pipe(tap((res: any) => typeof res === 'object' || console.error(`TypeGuard for response 'object' caught inconsistency.`, res)));
  }

  override sizesGet(
    args: {
      sizeType?: '0' | '1' | '2' | '3',
      unitTypeId?: number,
      isTenantPortal?: boolean,
      siteConfigId?: number,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseSizeModel> {
    return super.sizesGet(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseSizeModel(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseSizeModel' caught inconsistency.`, res)));
  }

  override sizesFeatureGetBySizeId(
    args: {
      sizeId: number,  // The ID of the size to retrieve features for.
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableSizesFeatureResponse> {
    return super.sizesFeatureGetBySizeId(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableSizesFeatureResponse(res) || console.error(`TypeGuard for response 'ResponseIEnumerableSizesFeatureResponse' caught inconsistency.`, res)));
  }

  override sizesFeatureAddOrUpdateSizes(
    args: {
      request: models.SizesFeatureRequest,  // The size feature data to add or update.
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.sizesFeatureAddOrUpdateSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override sizesFeatureAddOrUpdateRange(
    args: {
      request: any,  // The list of size feature data to add or update.
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.sizesFeatureAddOrUpdateRange(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override sizesFeatureRemoveAndAssignFeaturesToSize(
    args: {
      request: models.SizesFeatureAssignment,  // The size feature assignment data.
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.sizesFeatureRemoveAndAssignFeaturesToSize(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override sizesFeatureGetAll(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableSizesFeatureResponse> {
    return super.sizesFeatureGetAll(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableSizesFeatureResponse(res) || console.error(`TypeGuard for response 'ResponseIEnumerableSizesFeatureResponse' caught inconsistency.`, res)));
  }

  override sizesFeatureDeleteSizesFeature(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.sizesFeatureDeleteSizesFeature(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override systemChargerCheck(
    args: {
      todaysDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCheckSystemChargerResponse> {
    return super.systemChargerCheck(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseCheckSystemChargerResponse(res) || console.error(`TypeGuard for response 'ResponseCheckSystemChargerResponse' caught inconsistency.`, res)));
  }

  override systemChargerLogs(
    args: {
      request: models.GetLogsRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSystemChargerLogResponse> {
    return super.systemChargerLogs(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseSystemChargerLogResponse(res) || console.error(`TypeGuard for response 'ResponseSystemChargerLogResponse' caught inconsistency.`, res)));
  }

  override systemChargerRun(
    args: {
      todaysDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSystemChargerRunResponse> {
    return super.systemChargerRun(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseSystemChargerRunResponse(res) || console.error(`TypeGuard for response 'ResponseSystemChargerRunResponse' caught inconsistency.`, res)));
  }

  override systemChargerSetNextDailyCloseDate(
    args: {
      dailyCloseDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.systemChargerSetNextDailyCloseDate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override systemCharGetAutoBills(
    args: {
      todaysDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableAutoBillResponse> {
    return super.systemCharGetAutoBills(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableAutoBillResponse(res) || console.error(`TypeGuard for response 'ResponseIEnumerableAutoBillResponse' caught inconsistency.`, res)));
  }

  override systemChargerRunAutoBills(
    args: {
      todaysDate: string,
      autoBillItems: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSystemChargerRunResponse> {
    return super.systemChargerRunAutoBills(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseSystemChargerRunResponse(res) || console.error(`TypeGuard for response 'ResponseSystemChargerRunResponse' caught inconsistency.`, res)));
  }

  override taxesAddOrUpdate(
    args: {
      request: models.TaxPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.taxesAddOrUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override taxesTaxAuthorityNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.taxesTaxAuthorityNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override taxesDelete(
    args: {
      taxids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.taxesDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override taxesGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.TaxViewModel[]> {
    return super.taxesGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isTaxViewModel(res) || console.error(`TypeGuard for response 'TaxViewModel' caught inconsistency.`, res)));
  }

  override timeZoneAddOrUpdateSizes(
    args: {
      request: models.TimeZonePostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.timeZoneAddOrUpdateSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override TimeZoneNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.TimeZoneNo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override timeZoneDeleteSizes(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.timeZoneDeleteSizes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override timeZoneGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.TimeZoneModel[]> {
    return super.timeZoneGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isTimeZoneModel(res) || console.error(`TypeGuard for response 'TimeZoneModel' caught inconsistency.`, res)));
  }

  override transactionGetTransactionsOData(
    args: {
      transactionType: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.TransactionResponse[]> {
    return super.transactionGetTransactionsOData(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isTransactionResponse(res) || console.error(`TypeGuard for response 'TransactionResponse' caught inconsistency.`, res)));
  }

  override transactionGetTransactionDetailsOData(
    requestHttpOptions?: HttpOptions
  ): Observable<models.TransactionDetailResponse[]> {
    return super.transactionGetTransactionDetailsOData(requestHttpOptions)
      .pipe(tap((res: any) => guards.isTransactionDetailResponse(res) || console.error(`TypeGuard for response 'TransactionDetailResponse' caught inconsistency.`, res)));
  }

  override transactionGetTransactionDetails(
    args: {
      transactionId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableTransactionDetailResponse> {
    return super.transactionGetTransactionDetails(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableTransactionDetailResponse(res) || console.error(`TypeGuard for response 'ResponseIEnumerableTransactionDetailResponse' caught inconsistency.`, res)));
  }

  override transactionCancelTransactionCost(
    args: {
      transactionId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCancelTransactionResponse> {
    return super.transactionCancelTransactionCost(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseCancelTransactionResponse(res) || console.error(`TypeGuard for response 'ResponseCancelTransactionResponse' caught inconsistency.`, res)));
  }

  override transactionCancelTransaction(
    args: {
      request: models.CancelTransactionRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCancelTransactionResponse> {
    return super.transactionCancelTransaction(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseCancelTransactionResponse(res) || console.error(`TypeGuard for response 'ResponseCancelTransactionResponse' caught inconsistency.`, res)));
  }

  override transFitSaveData(
    args: {
      request: models.TransFitModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.transFitSaveData(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override transFitGetTransFit(
    args: {
      siteGroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransFitModel> {
    return super.transFitGetTransFit(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransFitModel(res) || console.error(`TypeGuard for response 'ResponseTransFitModel' caught inconsistency.`, res)));
  }

  override transFitSaveAdditionalAccount(
    args: {
      request: any,
      sitegroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.transFitSaveAdditionalAccount(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override transFitGetAdditionalAccount(
    args: {
      sitegroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableAdditionalAccountModel> {
    return super.transFitGetAdditionalAccount(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseIEnumerableAdditionalAccountModel(res) || console.error(`TypeGuard for response 'ResponseIEnumerableAdditionalAccountModel' caught inconsistency.`, res)));
  }

  override unitsGetUnitsPaginated(
    args: {
      unitID?: number,
      sizeID?: number,
      sizeType?: '0' | '1' | '2' | '3',
      unitTypeID?: number,
      promoID?: number,
      isTenantPortal?: boolean,
      siteConfigID?: number,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseUnitSummaryModel> {
    return super.unitsGetUnitsPaginated(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseUnitSummaryModel(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseUnitSummaryModel' caught inconsistency.`, res)));
  }

  override unitsGetVacantUnits(
    args: {
      sizeID?: number,
      sizeType?: '0' | '1' | '2' | '3',
      promoID?: number,
      orderBy?: string,
      isTenantPortal?: boolean,
      siteConfigID?: number,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseUnitSummaryModel> {
    return super.unitsGetVacantUnits(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponsePaginatedListResponseUnitSummaryModel(res) || console.error(`TypeGuard for response 'ResponsePaginatedListResponseUnitSummaryModel' caught inconsistency.`, res)));
  }

  override unitsGetUnitTypes(
    args: {
      hasVacantUnit: boolean,
      siteConfigId?: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUnitTypeResponse> {
    return super.unitsGetUnitTypes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListUnitTypeResponse(res) || console.error(`TypeGuard for response 'ResponseListUnitTypeResponse' caught inconsistency.`, res)));
  }

  override unitsGetUnitStatuses(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.unitsGetUnitStatuses(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override unitsGetPromos(
    args: {
      sizeID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListPromoModel> {
    return super.unitsGetPromos(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListPromoModel(res) || console.error(`TypeGuard for response 'ResponseListPromoModel' caught inconsistency.`, res)));
  }

  override unitsMoveinUnit(
    args: {
      payment: models.MoveinRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    return super.unitsMoveinUnit(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransactionResult(res) || console.error(`TypeGuard for response 'ResponseTransactionResult' caught inconsistency.`, res)));
  }

  override unitsMoveinCost(
    args: {
      rentCost: models.CostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    return super.unitsMoveinCost(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransactionResult(res) || console.error(`TypeGuard for response 'ResponseTransactionResult' caught inconsistency.`, res)));
  }

  override unitsReservationCost(
    args: {
      reserveCost: models.CostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    return super.unitsReservationCost(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransactionResult(res) || console.error(`TypeGuard for response 'ResponseTransactionResult' caught inconsistency.`, res)));
  }

  override unitsReserveUnit(
    args: {
      payment: models.MoveinRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    return super.unitsReserveUnit(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransactionResult(res) || console.error(`TypeGuard for response 'ResponseTransactionResult' caught inconsistency.`, res)));
  }

  override unitsGetLateCycleSchedules(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListLateCycleScheduleModel> {
    return super.unitsGetLateCycleSchedules(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListLateCycleScheduleModel(res) || console.error(`TypeGuard for response 'ResponseListLateCycleScheduleModel' caught inconsistency.`, res)));
  }

  override unitsGetUnitAdditionalChargeTypes(
    args: {
      chargeTypes?: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAdditionalChargeTypesResponse> {
    return super.unitsGetUnitAdditionalChargeTypes(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListAdditionalChargeTypesResponse(res) || console.error(`TypeGuard for response 'ResponseListAdditionalChargeTypesResponse' caught inconsistency.`, res)));
  }

  override unitsGetUnitCustomers(
    args: {
      customerID: number,
      sizes?: number[],
      statuses?: number[],
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUnitCustomerResponse> {
    return super.unitsGetUnitCustomers(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListUnitCustomerResponse(res) || console.error(`TypeGuard for response 'ResponseListUnitCustomerResponse' caught inconsistency.`, res)));
  }

  override unitsGetUnitCustomerDetails(
    args: {
      unitID: number,
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUnitCustomerDetailResponse> {
    return super.unitsGetUnitCustomerDetails(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseUnitCustomerDetailResponse(res) || console.error(`TypeGuard for response 'ResponseUnitCustomerDetailResponse' caught inconsistency.`, res)));
  }

  override unitsTransferCost(
    args: {
      request: models.TransferCostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransferCostResponse> {
    return super.unitsTransferCost(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransferCostResponse(res) || console.error(`TypeGuard for response 'ResponseTransferCostResponse' caught inconsistency.`, res)));
  }

  override unitsTransfer(
    args: {
      request: models.TransferRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransferResponse> {
    return super.unitsTransfer(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTransferResponse(res) || console.error(`TypeGuard for response 'ResponseTransferResponse' caught inconsistency.`, res)));
  }

  override unitsGetUnitStatus(
    args: {
      customerId: number,
      unitCustomerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUnitStatusModel> {
    return super.unitsGetUnitStatus(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseUnitStatusModel(res) || console.error(`TypeGuard for response 'ResponseUnitStatusModel' caught inconsistency.`, res)));
  }

  override unitsUnitList(
    args: {
      customerId: number,
      isOldTenant?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.UnitListModel[]> {
    return super.unitsUnitList(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isUnitListModel(res) || console.error(`TypeGuard for response 'UnitListModel' caught inconsistency.`, res)));
  }

  override unitsAddorUpdate(
    args: {
      model: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.unitsAddorUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override unitsNextUnitNo(
    args: {
      siteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseString> {
    return super.unitsNextUnitNo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseString(res) || console.error(`TypeGuard for response 'ResponseString' caught inconsistency.`, res)));
  }

  override unitsDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.unitsDelete(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override unitsGetUnits(
    args: {
      request: models.UnitListRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.PaginatedListResponseUnitViewModel> {
    return super.unitsGetUnits(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isPaginatedListResponseUnitViewModel(res) || console.error(`TypeGuard for response 'PaginatedListResponseUnitViewModel' caught inconsistency.`, res)));
  }

  override unitsGetLeaseStatus(
    args: {
      customerID: number,
      unitId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLeaseStatusModel> {
    return super.unitsGetLeaseStatus(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseLeaseStatusModel(res) || console.error(`TypeGuard for response 'ResponseLeaseStatusModel' caught inconsistency.`, res)));
  }

  override unitsSetLeaseStatus(
    args: {
      request: models.LeaseStatusModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.unitsSetLeaseStatus(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override unitsGetLinkedUnits(
    args: {
      customerID: number,
      unitId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUnitLinkModel> {
    return super.unitsGetLinkedUnits(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseUnitLinkModel(res) || console.error(`TypeGuard for response 'ResponseUnitLinkModel' caught inconsistency.`, res)));
  }

  override unitsLinkAndUnlink(
    args: {
      model: models.LinkUnitPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.unitsLinkAndUnlink(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override unitsSave(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.unitsSave(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override unitTypeAddOrUpdateUnitTypeRange(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.unitTypeAddOrUpdateUnitTypeRange(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override unitTypeAddOrUpdateUnitType(
    args: {
      request: models.UnitTypePostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.unitTypeAddOrUpdateUnitType(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override unitTypeDeleteunitType(
    args: {
      unitTypesId: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.unitTypeDeleteunitType(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override unitTypeNextUnitType(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    return super.unitTypeNextUnitType(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseInt32(res) || console.error(`TypeGuard for response 'ResponseInt32' caught inconsistency.`, res)));
  }

  override unitTypeGetAll(
    requestHttpOptions?: HttpOptions
  ): Observable<models.UnitTypeViewModel[]> {
    return super.unitTypeGetAll(requestHttpOptions)
      .pipe(tap((res: any) => guards.isUnitTypeViewModel(res) || console.error(`TypeGuard for response 'UnitTypeViewModel' caught inconsistency.`, res)));
  }

  override usersGetCurrentUserInfo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUserResponse> {
    return super.usersGetCurrentUserInfo(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseUserResponse(res) || console.error(`TypeGuard for response 'ResponseUserResponse' caught inconsistency.`, res)));
  }

  override usersGetUserList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUserResponse> {
    return super.usersGetUserList(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListUserResponse(res) || console.error(`TypeGuard for response 'ResponseListUserResponse' caught inconsistency.`, res)));
  }

  override usersUpdateUserInfo(
    args: {
      request: models.UpdateUserRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUserResponse> {
    return super.usersUpdateUserInfo(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseUserResponse(res) || console.error(`TypeGuard for response 'ResponseUserResponse' caught inconsistency.`, res)));
  }

  override usersGetUserGroups(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUserGroupModel> {
    return super.usersGetUserGroups(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListUserGroupModel(res) || console.error(`TypeGuard for response 'ResponseListUserGroupModel' caught inconsistency.`, res)));
  }

  override usersGetUsers(
    args: {
      userGroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUserPostModel> {
    return super.usersGetUsers(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListUserPostModel(res) || console.error(`TypeGuard for response 'ResponseListUserPostModel' caught inconsistency.`, res)));
  }

  override usersGetOperationCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    return super.usersGetOperationCategories(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseListModelItem(res) || console.error(`TypeGuard for response 'ResponseListModelItem' caught inconsistency.`, res)));
  }

  override usersGetUserRights(
    args: {
      usergroupId: number,
      opCategory: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.UserRightViewModel[]> {
    return super.usersGetUserRights(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isUserRightViewModel(res) || console.error(`TypeGuard for response 'UserRightViewModel' caught inconsistency.`, res)));
  }

  override usersUpdateUserGroups(
    args: {
      model: models.UserGroupModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.usersUpdateUserGroups(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override usersUpdateUserGroup(
    args: {
      usergroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.usersUpdateUserGroup(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override usersUpdateUser(
    args: {
      model: models.UserPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.usersUpdateUser(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override usersDeleteUsers(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.usersDeleteUsers(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override userSecurityUpdate(
    args: {
      request: models.UserSecurityModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    return super.userSecurityUpdate(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseBoolean(res) || console.error(`TypeGuard for response 'ResponseBoolean' caught inconsistency.`, res)));
  }

  override userSecurityGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUserSecurityModel> {
    return super.userSecurityGet(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseUserSecurityModel(res) || console.error(`TypeGuard for response 'ResponseUserSecurityModel' caught inconsistency.`, res)));
  }

  override userSecurityUpdateSecKey(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTrackingModel> {
    return super.userSecurityUpdateSecKey(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseTrackingModel(res) || console.error(`TypeGuard for response 'ResponseTrackingModel' caught inconsistency.`, res)));
  }

  override userSecurityGetDefaultUserSecurity(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUserSecurityModel> {
    return super.userSecurityGetDefaultUserSecurity(requestHttpOptions)
      .pipe(tap((res: any) => guards.isResponseUserSecurityModel(res) || console.error(`TypeGuard for response 'ResponseUserSecurityModel' caught inconsistency.`, res)));
  }

}
