import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { OpenGateModalComponent } from "src/@sentinel/shared/open-gate-modal/open-gate-modal.component";
import { HelperService } from "src/app/services/helper.service";
import { MatDialog } from "@angular/material/dialog";
import { ThemeSettingsService } from "src/app/services/theme-settings.service";
import { HomeService } from "src/app/services/home.service";
import { DomSanitizer } from "@angular/platform-browser";
import { LocationStrategy } from '@angular/common';
import { BooleanString, DarkOrLight, DarkOrLightMode } from "shared/enums";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  isDarkMode: any;

  @Output() updateSideNavEmitter = new EventEmitter();
  @Output() userProfileAvatarEmitter = new EventEmitter();
  @Output() userInitialsEmitter = new EventEmitter();

  navItems = [
    { name: "Home", path: "/dashboard/home", icon: "home.svg" },
    { name: "History", path: "/dashboard/history", icon: "history.svg" },
    {
      name: "Make Payment",
      path: "/dashboard/payments",
      icon: "payments.svg",
    },
    // TODO:
    // { name: "Open gate", path: "/dashboard/open-gate", icon: "gate.svg" },
    {
      name: "Rent / Reserve",
      path: "/dashboard/rent-unit",
      icon: "rent-unit.svg",
    },
    { name: "Settings", path: "/dashboard/settings", icon: "settings.svg" },
    { name: "Support", path: "/dashboard/support", icon: "support.svg" },
    { name: "Log out", path: "/login", icon: "log-out.svg" },
  ];

  userFullName: string = '';
  userInitials: string = '';
  imageSrc: any;
  expandedSideNav = true;
  isSmallScreen: any;
  siteDetails: any;
  siteConfigId: string = "";

  constructor(
    private helperService: HelperService,
    private homeService: HomeService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private location: LocationStrategy,
    private route: ActivatedRoute,
    private router: Router,
    public themeService: ThemeSettingsService
  ) {}

  ngOnInit(): void {
    this.getDarkModeOption();
    this.getUserProfilePicture();
    this.getUserInitials();

    this.route.queryParams.subscribe((params: {[key: string]: string}) => {
      params?.SiteConfigId && (this.siteConfigId = params.SiteConfigId);
    });

    if (!this.location.path().includes('/home')) {
      this.homeService.getCustomerDetails().subscribe();
    }

    const siteConfigId = this.siteConfigId || localStorage.getItem('SiteConfigId') || '';
    this.homeService.getSiteDetail(false, siteConfigId).subscribe((siteData) => {
      this.siteDetails = siteData.Result;
    });

    this.homeService.picture$.subscribe(picture => {
      this.imageSrc = picture ? `data:image/jpeg;base64,${picture}` : null;     
      this.userProfileAvatarEmitter.emit(this.imageSrc);
    });
  }

  isItemActive(route: string): boolean {
    return this.router.isActive(route, false) || this.router.url.startsWith(route);
  }

  getUserInitials() {
    this.homeService.userName.subscribe(  
      data => {
        this.userFullName = data;
        const words = this.userFullName.split(', ');
        
        this.userFullName = `${words[1]} ${words[0]}`;
        this.userInitials = '';
    
        for (let i = words.length - 1; i >= 0; i--) {
          const word = words[i];
          this.userInitials += word.charAt(0).toUpperCase();
        }
        
        this.userInitialsEmitter.emit(this.userInitials);
      }
    );

  }

  getUserProfilePicture() {
    if (!localStorage.getItem("token")) {
      return;
    }
    this.homeService.getUserProfilePicture().subscribe(
      data => {
        if (data.Result.length) {
          this.imageSrc = this.convertBase64ToImage(data.Result[data.Result.length - 1]?.PictureBase64String);
          
          this.userProfileAvatarEmitter.emit(this.imageSrc);
        }
      }
    );
  }

  convertBase64ToImage(base64String: string) {
    const imageDataUrl = 'data:image/jpeg;base64,' + base64String;
    return this.sanitizer.bypassSecurityTrustUrl(imageDataUrl);
  }

  onUpdateSideNav() {
    this.expandedSideNav = !this.expandedSideNav;

    this.updateSideNavEmitter.emit(this.expandedSideNav);
  }

  onNavigate(item: any) {
    if (item.name == "Log out") {
      this.helperService.clearLocalStorage();
      this.homeService.userName.next('');
      this.helperService.setItemToLocalStorage('darkMode', false);
      this.helperService.setItemToLocalStorage('SiteConfigId', '');
      this.themeService.toggleTheme(DarkOrLight.LIGHT);
    }
  }

  getDarkModeOption() {
    this.isDarkMode = this.helperService.getItemFromLocalStorage(DarkOrLightMode.DARK_MODE);
    if (this.isDarkMode == BooleanString.FALSE) {
      this.isDarkMode = false;
    } else {
      this.isDarkMode = true;
    }
  }

  changeDarkMode() {
    this.isDarkMode = !this.isDarkMode;

    this.themeService.toggleTheme(this.isDarkMode ? DarkOrLight.DARK : DarkOrLight.LIGHT);
  }

  siteLogoImageUrl() {
    if (this.siteDetails && this.siteDetails.LogoImage && !this.isDarkMode) {
      return `${environment.apiUrl}${this.siteDetails.LogoImage}`;
    }
    if (this.siteDetails && this.siteDetails.DarkLogoImage && this.isDarkMode) {
      return `${environment.apiUrl}${this.siteDetails.DarkLogoImage}`;
    }

    return null;
  }

  getIconStyles(icon: any) {
    if (icon === 'support.svg') {
      return {
        'padding-left': '15px',
        'padding-right': '23px'
      }
    } else if (icon === 'log-out.svg') {
      return {
        'padding-left': '20px',
        'padding-right': '25px'
      }
    }
    return null;
  }
}
