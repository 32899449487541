import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";
import { HomeService } from "src/app/services/home.service";

declare var ClearentSDK: any;

@Component({
  selector: "app-payment-form",
  templateUrl: "./payment-form.component.html",
  styleUrls: ["./payment-form.component.scss"],
})
export class PaymentFormComponent {
  mobileJwt: string;
  billingInfoForm: FormGroup;
  isPaymentFormLoaded = false;
  savingCreditCard = false;
  submitAttempt = false;
  isSmallScreen = false;
  isPaymentFormErrorAppeared = false;

  // option list
  countryList: any = [];
  statesList: any = [];

  secondaryTenant: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PaymentFormComponent>,
    private fb: FormBuilder,
    private homeService: HomeService,
    private snackBar: MatSnackBar
  ) {
    this.billingInfoForm = this.fb.group({
      firstName: [{value: this.data?.FirstName || '', disabled: true}],
      lastName: [{value: this.data?.FirstName || '', disabled: true}],
      state: ['', [Validators.required, Validators.min(1)]],
      country: ['', [Validators.required, Validators.min(1)]],
      city: ['', Validators.required],
      address1: ['', Validators.required],
      zipCode: ['', Validators.required],
    });
  }

  ngOnInit() {
    if (this.data.clearentConfig) {
      this.initializeClearentSDK();
    }
    this.getCountries();

    if (this.data?.customerData?.primaryTenant) {
      const primaryTenant = this.data.customerData?.primaryTenant;

      this.getStates(primaryTenant?.CountryID);
      this.billingInfoForm.patchValue({
        firstName: primaryTenant?.FirstName,
        lastName: primaryTenant?.LastName,
        city: primaryTenant?.City,
        address1: primaryTenant?.Address1,
        zipCode: primaryTenant?.ZipCode,
      });
      this.billingInfoForm.controls.firstName.setValue(primaryTenant?.FirstName);
    }

    if (this.data.customerData?.secondaryTenant && !this.data?.customerData?.primaryTenant) {
      this.secondaryTenant = this.data.customerData?.secondaryTenant;
      this.getStates(this.secondaryTenant?.CountryId || this.secondaryTenant?.CountryID);

      this.billingInfoForm.patchValue({
        firstName: this.secondaryTenant?.FirstName,
        lastName: this.secondaryTenant?.LastName,
        city: this.secondaryTenant?.City,
        address1: this.secondaryTenant?.Address1,
        zipCode: this.secondaryTenant?.ZipCode,
      });
      this.billingInfoForm.controls.firstName.setValue(this.secondaryTenant?.FirstName);
    }

    if (this.data.customerData?.secondaryCustomerId && !this.data?.customerData?.secondaryTenant && !this.data.customerData?.primaryTenant) {
      this.getSecondaryTenants();
    }

    this.isSmallScreen = this.breakpointObserver.isMatched("(max-width: 768px)");
  }

  getSecondaryTenants() {
    this.homeService.getSecondaryTenants(this.data?.customerData?.primaryCustomerId).subscribe(
      data => {
        const allSecondaryTenants = data?.Result;
        this.secondaryTenant = allSecondaryTenants.find((item: any) => item?.CustomerID == this.data?.customerData?.secondaryCustomerId);
        this.getStates(this.secondaryTenant?.CountryId || this.secondaryTenant?.CountryID);

        this.billingInfoForm.patchValue({
          firstName: this.secondaryTenant?.FirstName,
          lastName: this.secondaryTenant?.LastName,
          country: this.secondaryTenant?.CountryId || this.secondaryTenant?.CountryID,
          state: this.secondaryTenant?.StateID,
          city: this.secondaryTenant?.City,
          address1: this.secondaryTenant?.Address1,
          zipCode: this.secondaryTenant?.ZipCode,
        });
        this.billingInfoForm.controls.firstName.setValue(this.secondaryTenant?.FirstName);
      }
    );
  }

  initializeClearentSDK() {
    try {
      if (!ClearentSDK.initialized) {
        ClearentSDK?.init({
          baseUrl: this.data.clearentConfig.BaseUrl,
          pk: this.data.clearentConfig.PublicKey,
          styles: "input{font-size:16px!important;}",
        });

        ClearentSDK.initialized = true;
      }

      const paymentFormIFrame = document.getElementById('ClearentIframe') as HTMLIFrameElement;
      paymentFormIFrame.onload = () => {
        this.isPaymentFormLoaded = true;
      };
    } catch (error) {
      this.isPaymentFormLoaded = true;
      this.isPaymentFormErrorAppeared = true;
      console.error(error);
    }
  }

  onSubmit() {
    this.submitAttempt = true;

    if (!this.billingInfoForm.valid) {
      return;
    }
    this.savingCreditCard = true;

    let primaryTenant: any;
    let secondaryTenant: any;
    const formValue = this.billingInfoForm.controls;
    if (this.secondaryTenant) {
      secondaryTenant = {
        ...this.secondaryTenant,
        FirstName: formValue.firstName.value,
        LastName: formValue.lastName.value,
        CountryID: formValue.country.value,
        StateID: formValue.state.value,
        City: formValue.city.value,
        Address1: formValue.address1.value,
        ZipCode: formValue.zipCode.value,
      }
    }

    if (this.data.customerData?.primaryTenant) {
      primaryTenant = {
        ...this.data?.customerData?.primaryTenant,
        FirstName: formValue.firstName.value,
        LastName: formValue.lastName.value,
        CountryID: formValue.country.value,
        StateID: formValue.state.value,
        City: formValue.city.value,
        Address1: formValue.address1.value,
        ZipCode: formValue.zipCode.value,
      }
    }

    ClearentSDK.getPaymentToken().then(
      (result: any) => {
        // this function is called if getting a payment token was successful
        this.mobileJwt = result.payload["mobile-jwt"]["jwt"];

        const creditCardInfo = {
          CardType: result.payload["mobile-jwt"]["card-type"],
          LastFour: result.payload["mobile-jwt"]["last-four"],
          ExpDate: result.payload["mobile-jwt"]["exp-date"],
        };

        if (this.mobileJwt) {
          this.dialogRef.close({
            mobileJwt: this.mobileJwt,
            savedCard: true,
            creditCardInfo: creditCardInfo,
            ...(this.secondaryTenant && { secondaryTenant }),
            ...(primaryTenant && { primaryTenant }),
            billingInfoForm: this.billingInfoForm
          });
          this.savingCreditCard = false;
          ClearentSDK.reset();
        }
      },
      (error: HttpErrorResponse) => {
        // this function is called if getting a payment token failed
        this.savingCreditCard = false;
        this.snackBar.open('Server error', "Close");
      }
    );
  }

  getCountries() {
    this.homeService.getCountries().subscribe(
      data => {
        this.countryList = data.Result;
        const countryId = this.data?.customerData?.primaryTenant?.CountryID || this.secondaryTenant?.CountryId || this.secondaryTenant?.CountryID;
        this.billingInfoForm.patchValue({
          country: countryId || 0,
        });
      }
    );
  }

  getStates(event: any) {
    const countryId = event?.target?.value ? event.target.value : event;

    this.homeService.getStatesByCountry(countryId).subscribe(
      data => {
        this.statesList = data?.Result;
        const stateId = this.data?.customerData?.primaryTenant?.StateID || this.secondaryTenant?.StateID;
        this.billingInfoForm.patchValue({
          state: +stateId || 0,
        });
      }
    );
  }

  onCancel() {
    this.dialogRef.close({ mobileJwt: this.mobileJwt });
    ClearentSDK.reset();
  }
}
