import { LocationStrategy } from "@angular/common";
import { Component, Inject } from "@angular/core";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { SignalRConfigurationModel } from "api/models";
import { BooleanString, DarkOrLight, DarkOrLightMode } from "shared/enums";
import { OpenGateModalComponent } from "src/@sentinel/shared/open-gate-modal/open-gate-modal.component";
import { AuthService } from "src/app/authentication/auth.service";
import { HelperService } from "src/app/services/helper.service";
import { HomeService } from "src/app/services/home.service";
import { SignalRService } from "src/app/services/signalr.service";
import { ThemeSettingsService } from "src/app/services/theme-settings.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-mobile-side-nav",
  templateUrl: "./mobile-side-nav.component.html",
  styleUrls: ["./mobile-side-nav.component.scss"],
})
export class MobileSideNavComponent {
  siteConfigId: SignalRConfigurationModel;
  isDarkMode: any;
  userFullName: string = "";
  isSentinelOnly = false;
  listOfItems: any = [];
  siteConfigIdParam: string = "";
  siteDetails: any;

  navItems = [
    {
      name: "Home",
      path: "/dashboard/home",
      icon: "home.svg",
      iconActive: "home-active.svg",
    },
    {
      name: "History",
      path: "/dashboard/history",
      icon: "history.svg",
      iconActive: "history-active.svg",
    },
    {
      name: "Make a payment",
      path: "/dashboard/payments",
      icon: "payment.svg",
      iconActive: "payment-active.svg",
    },
    {
      name: "Open gate",
      path: "/dashboard/open-gate",
      icon: "gate.svg",
      iconActive: "gate-active.svg",
    },
    {
      name: "Rent/Reserve Unit",
      path: "/dashboard/rent-unit",
      icon: "rent.svg",
      iconActive: "rent-active.svg",
    },
    {
      name: "Settings",
      path: "/dashboard/settings",
      icon: "settings.svg",
      iconActive: "settings-active.svg",
    },
    { name: "Support",
      path: "/dashboard/support",
      icon: "settings.svg" ,
      iconActive: "settings-active.svg",
    },
    {
      name: "Log out",
      path: "/login",
      icon: "logout.svg",
      iconActive: "",
    },
  ];

  navItemsSentinelOnly = [
    {
      name: "Home",
      path: "/dashboard/home",
      icon: "home.svg",
      iconActive: "home-active.svg",
    },
    {
      name: "History",
      path: "/dashboard/history",
      icon: "history.svg",
      iconActive: "history-active.svg",
    },
    {
      name: "Open gate",
      path: "/dashboard/open-gate",
      icon: "gate.svg",
      iconActive: "gate-active.svg",
    },
    {
      name: "Settings",
      path: "/dashboard/settings",
      icon: "settings.svg",
      iconActive: "settings-active.svg",
    },
    {
      name: "Log out",
      path: "/login",
      icon: "logout.svg",
      iconActive: "",
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private dialogRef: MatDialogRef<MobileSideNavComponent>,
    private dialog: MatDialog,
    private homeService: HomeService,
    private helperService: HelperService,
    private signalRService: SignalRService,
    private location: LocationStrategy,
    private route: ActivatedRoute,
    public themeService: ThemeSettingsService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: {[key: string]: string}) => {
      params?.SiteConfigId && (this.siteConfigIdParam = params.SiteConfigId);
    });

    this.authService.isAuthenticated() && this.getSiteConfigId();
    this.getDarkModeOption();

    this.isSentinelOnly =
      this.helperService.getItemFromLocalStorage("isSentinelOnly") == "true";
    if (this.isSentinelOnly) {
      this.listOfItems = this.navItemsSentinelOnly;
    } else {
      this.listOfItems = this.navItems;
    }

    if (!this.location.path().includes("/home")) {
      this.homeService.getCustomerDetails().subscribe();
    }

    const siteConfigId = this.siteConfigIdParam || localStorage.getItem('SiteConfigId') || '';
    this.homeService.getSiteDetail(false, siteConfigId).subscribe((siteData) => {
      this.siteDetails = siteData.Result;
    });

    this.homeService.userName.subscribe((data: any) => {
      this.userFullName = data;
      const words = this.userFullName.split(", ");
      this.userFullName = `${words[1]} ${words[0]}`;
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onNavigate(item: any) {
    if (item.name == "Log out") {
      this.helperService.clearLocalStorage();
      this.homeService.userName.next("");
      this.helperService.setItemToLocalStorage('darkMode', false);
      this.helperService.setItemToLocalStorage('SiteConfigId', '');
      this.themeService.toggleTheme(DarkOrLight.LIGHT);
    }

    if (item.name == "Open gate") {
      this.openGate();
    }
    this.dialogRef.close();
  }

  getDarkModeOption() {
    this.isDarkMode = this.helperService.getItemFromLocalStorage(
      DarkOrLightMode.DARK_MODE
    );
    if (this.isDarkMode == BooleanString.FALSE) {
      this.isDarkMode = false;
    } else {
      this.isDarkMode = true;
    }
  }

  changeDarkMode() {
    this.isDarkMode = !this.isDarkMode;

    this.themeService.toggleTheme(
      this.isDarkMode ? DarkOrLight.DARK : DarkOrLight.LIGHT
    );
  }

  getSiteConfigId() {
    // TODO home service
    this.homeService.getSignalRConfiguration().subscribe((data) => {
      console.log("site config id", data.Result);
      this.siteConfigId = data.Result!;
    });
  }

  openGate() {
    // TODO::
    this.signalRService.initConnection(this.siteConfigId);
    const modal = this.dialog.open(OpenGateModalComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: "forgot-password-modal",
      position: {
        bottom: "0px",
      },
      width: "100%",
    });
  }

  siteLogoImageUrl() {
    if (this.siteDetails && this.siteDetails.LogoImage) {
      return `${environment.apiUrl}${this.siteDetails.LogoImage}`;
    }

    return null;
  }
}
