<app-side-nav *ngIf="isLoggedIn()" (updateSideNavEmitter)="handleSideNavEvent($event)"
  (userProfileAvatarEmitter)="handleUserAvatarEvent($event)"
  (userInitialsEmitter)="handleUserInitialsEvent($event)"></app-side-nav>

<div class="user-avatar" *ngIf="imageSrc || userInitials">
  <div class="avatar">
    <span class="image">
      <img [src]="imageSrc" alt="Image" *ngIf="imageSrc">
      <span *ngIf="!imageSrc">
        {{userInitials}}
      </span>
    </span>
  </div>
</div>
<div class="layout" [ngStyle]="{
    'margin-left': getMarginLeft()
  }">
  <div *ngIf="!isLoggedIn()" class="unauthorised-header">
    <div class="main-info">
      <img *ngIf="!!siteDetails" class="sentinel-icon" 
        src="{{ siteLogoImageUrl() || '../../../assets/icons/sentinel-logo.svg' }}" alt=""
        routerLink="/login" />
      <div class="d-flex flex-column site-details">
        <span class="site-name">{{ siteDetails?.SiteName }}</span>
        <span class="site-address">{{ siteDetails?.Address1 }}</span>
      </div>
    </div>

    <div class="d-flex align-items-center clickable" style="gap: 20px">
      <span an (click)="onHoursModal()">Hours</span>
      <div *ngIf="siteDetails?.Phone" class="contact-info">
        <a href="tel:+1-{{siteDetails?.Phone}}" class="button button-positive">
          <img src="../../../assets/icons/header-phone-icon.svg" alt="" />
        </a>
        <span>{{ siteDetails?.Phone }}</span>
      </div>
    </div>
  </div>

  <app-mobile-footer></app-mobile-footer>

  <router-outlet></router-outlet>
</div>