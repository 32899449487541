import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HomeService } from "src/app/services/home.service";


@Component({
  selector: 'app-edit-billing-dialog',
  templateUrl: './edit-billing-dialog.component.html',
  styleUrls: ['./edit-billing-dialog.component.scss']
})
export class EditBillingDialogComponent {

  billingInfoForm: FormGroup;
  isDefaultPaymentType = false;
  isPaymentFormLoaded = false;
  savingCreditCard = false;
  submitAttempt = false;
  isSmallScreen = false;

  // option list
  countryList: any = [];
  statesList: any = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditBillingDialogComponent>,
    private fb: FormBuilder,
    private homeService: HomeService,
    private snackBar: MatSnackBar
  ) {
    this.billingInfoForm = this.fb.group({
      firstName: [{value: this.data?.FirstName || '', disabled: true}],
      lastName: [{value: this.data?.LastName || '', disabled: true}],
      country: [this.data?.CountryID || '', Validators.required],
      state: [this.data?.StateID || '', Validators.required],
      city: [this.data?.City || '', Validators.required],
      address1: [this.data?.Address1 || '', Validators.required],
      zipCode: [this.data?.ZipCode || '', Validators.required],
    });
  }

  ngOnInit() {
    this.getCountries();
    this.getStates(this.data?.CountryID);
    this.isSmallScreen = this.breakpointObserver.isMatched("(max-width: 768px)");
  }

  onSubmit() {
    this.submitAttempt = true;
    const formValue = this.billingInfoForm.controls;
    const primaryTenant = {
        FirstName: formValue.firstName.value,
        LastName: formValue.lastName.value,
        CountryID: formValue.country.value,
        StateID: formValue.state.value,
        City: formValue.city.value,
        Address1: formValue.address1.value,
        ZipCode: formValue.zipCode.value,
    }

    this.dialogRef.close({result: primaryTenant});
  }

  
  getCountries() {
    this.homeService.getCountries().subscribe(
      data => {
        this.countryList = data.Result;
        const countryId = this.data?.CountryID;
        this.billingInfoForm.patchValue({
          country: countryId || 0,
        });
      }
    );
  }

  getStates(event: any) {
    const countryId = event?.target?.value ? event.target.value : event;

    this.homeService.getStatesByCountry(countryId).subscribe(
      data => {
        this.statesList = data?.Result;
        const stateId = this.data?.StateID;
        
        this.billingInfoForm.patchValue({
          state: +stateId || 0,
        });
      }
    );
  }

  onDefaultPaymentType() {
    this.isDefaultPaymentType = !this.isDefaultPaymentType;
  }

  onCancel() {
    this.dialogRef.close();
  }
}
