/* tslint:disable */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { DefaultHttpOptions, HttpOptions, APIClientInterface } from './';

import * as models from './models';

export const USE_DOMAIN = new InjectionToken<string>('APIClient_USE_DOMAIN');
export const USE_HTTP_OPTIONS = new InjectionToken<HttpOptions>('APIClient_USE_HTTP_OPTIONS');

type APIHttpOptions = HttpOptions & {
  headers: HttpHeaders;
  params: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json';
};

/**
 * Created with https://github.com/flowup/api-client-generator
 */
@Injectable()
export class APIClient implements APIClientInterface {

  readonly options: APIHttpOptions;

  readonly domain: string = `https://devapi.sentinelplatinum.com`;

  constructor(private readonly http: HttpClient,
              @Optional() @Inject(USE_DOMAIN) domain?: string,
              @Optional() @Inject(USE_HTTP_OPTIONS) options?: DefaultHttpOptions) {

    if (domain != null) {
      this.domain = domain;
    }

    this.options = {
      headers: new HttpHeaders(options && options.headers ? options.headers : {}),
      params: new HttpParams(options && options.params ? options.params : {}),
      ...(options && options.reportProgress ? { reportProgress: options.reportProgress } : {}),
      ...(options && options.withCredentials ? { withCredentials: options.withCredentials } : {})
    };
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accessControlGetAlarms(
    args: {
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseAlarmPointResponse> {
    const path = `/accessControl/alarms`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseAlarmPointResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accessControlSetAlarmStatus(
    args: {
      doorID: number,
      status: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/accessControl/setAlarmStatus/${args.doorID}/${args.status}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accLevelAddOrUpdate(
    args: {
      request: models.AccLevelPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/acclevel/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accLevelNextAccLevelNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/acclevel/nextAccLevelNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accLevelDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/acclevel/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accLevelGet(
    args: {
      psSiteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAccLevelViewModel> {
    const path = `/acclevel/${args.psSiteConfigID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListAccLevelViewModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accLevelGetAccessDevices(
    args: {
      psSiteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableAccessDeviceModel> {
    const path = `/acclevel/accessDevices/${args.psSiteConfigID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableAccessDeviceModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accLevelGetRelays(
    args: {
      psSiteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableRelayModel> {
    const path = `/acclevel/relays/${args.psSiteConfigID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableRelayModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accLevelGetLightZones(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/acclevel/lightZones`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accountAddOrUpdate(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/accounts/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accountDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/accounts/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('Ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accountGetAll(
    requestHttpOptions?: HttpOptions
  ): Observable<models.AccountViewModel[]> {
    const path = `/accounts`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.AccountViewModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accountGetUserAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableUserAccount> {
    const path = `/accounts/userAccounts`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableUserAccount>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accountGetDefaultValues(
    args: {
      selectedAccountId: number,
      addingAnyAccount: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseNextDefaultValue> {
    const path = `/accounts/defaultValues/${args.selectedAccountId}/${args.addingAnyAccount}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseNextDefaultValue>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accountGetRevenueTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/accounts/revenueTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accountGetAccountTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/accounts/accountTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  accountGetAccountCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/accounts/accountCategories`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  advertisingAddOrUpdateSizes(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/advertising/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  advertisingDeleteSizes(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/advertising/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  advertisingGet(
    args: {
      leaseID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableAdvertisingConfigModel> {
    const path = `/advertising/${args.leaseID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableAdvertisingConfigModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  auctionGetAuctions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAuctionResponse> {
    const path = `/Auction`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListAuctionResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  authAPIUserLogin(
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/auth/authenticate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  authGetClearentConfig(
    args: {
      siteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseClearentConfigModel> {
    const path = `/auth/clearentConfig/${args.siteConfigID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseClearentConfigModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  authCustomerLogin(
    args: {
      request: models.CustomerLoginRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/auth/customerLogin`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  authSwitchToFacility(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLoginResponse> {
    const path = `/auth/facilitySwitch`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseLoginResponse>('POST', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  authManagerLogin(
    args: {
      request: models.ManagerLoginRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/auth/managerLogin`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  authManagerLogout(
    args: {
      userId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/auth/managerLogout/${args.userId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  authForgotPassword(
    args: {
      request: models.ForgotPasswordRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/auth/forgetPassword`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  authResetPassword(
    args: {
      request: models.ResetPasswordRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/auth/resetPassword`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  blueMoonSettingAddOrUpdate(
    args: {
      request: models.BlueMoonSettingModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/blueMoonSetting/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  blueMoonSettingGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBlueMoonSettingModel> {
    const path = `/blueMoonSetting`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBlueMoonSettingModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  closingGet(
    args: {
      closeTypes?: number[],
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseClosingModel> {
    const path = `/closing`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('closeTypes' in args) {
      if (args.closeTypes && args.closeTypes.length) {
        options.params = args.closeTypes.reduce<HttpParams>((acc, cur) => acc.append('closeTypes', `${cur}`), options.params);
      }
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseClosingModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  closingGetProofEditData(
    args: {
      startDate: string,
      endDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseProofEditInfo> {
    const path = `/closing/proofEditData`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('startDate' in args) {
      options.params = options.params.set('startDate', String(args.startDate));
    }
    if ('endDate' in args) {
      options.params = options.params.set('endDate', String(args.endDate));
    }
    return this.sendRequest<models.ResponseProofEditInfo>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  closingPrintProofEdit(
    args: {
      request: models.PrintProofEditReportRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportResponse> {
    const path = `/closing/printProofEdit`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseReportResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  closingRun(
    args: {
      request: models.RunCloserRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportResponse> {
    const path = `/closing/run`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseReportResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  closingReprintClose(
    args: {
      request: models.ReprintCloseRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportResponse> {
    const path = `/closing/reprint`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseReportResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGet(
    args: {
      customerID: number,
      customerNo?: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListCustomerModel> {
    const path = `/customers/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('customerNo' in args) {
      options.params = options.params.set('customerNo', String(args.customerNo));
    }
    return this.sendRequest<models.ResponseListCustomerModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersPut(
    args: {
      customerModelDto: models.UpsertCustomerRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUpsertCustomerResponse> {
    const path = `/customers`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUpsertCustomerResponse>('PUT', path, options, JSON.stringify(args.customerModelDto));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersPost(
    args: {
      customerModelDto: models.UpsertCustomerRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUpsertCustomerResponse> {
    const path = `/customers`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUpsertCustomerResponse>('POST', path, options, JSON.stringify(args.customerModelDto));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetAcctInfoByPhone(
    args: {
      infoByPhoneRequest: models.AccountInfoByPhoneRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseAccountInfo> {
    const path = `/customers/accountInfoByPhone`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseAccountInfo>('POST', path, options, JSON.stringify(args.infoByPhoneRequest));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetAccountInfo(
    args: {
      accountInfoRequest: models.AccountInfoRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseAccountInfo> {
    const path = `/customers/accountInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseAccountInfo>('POST', path, options, JSON.stringify(args.accountInfoRequest));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetPaymentInfo(
    args: {
      request: models.GetPaymentInfoRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaymentInfoResponse> {
    const path = `/customers/paymentInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponsePaymentInfoResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersMakePayment(
    args: {
      payment: models.MakePaymentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    const path = `/customers/payment`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTransactionResult>('POST', path, options, JSON.stringify(args.payment));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetChangeRentDueDayCost(
    args: {
      request: models.GetPaymentInfoRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaymentInfoResponse> {
    const path = `/customers/changeRentDueDayCost`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponsePaymentInfoResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersChangeRentDueDay(
    args: {
      payment: models.MakePaymentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    const path = `/customers/changeRentDueDay`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTransactionResult>('POST', path, options, JSON.stringify(args.payment));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetCustomerDefaults(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTenantOptionPostModel> {
    const path = `/customers/customerDefaults`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTenantOptionPostModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetDashboardData(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCustomerDashboardResponse> {
    const path = `/customers/customerDashboard/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseCustomerDashboardResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersUpdateCustomerSetting(
    args: {
      customerSummary: models.CustomerSummaryModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/customers/updateCustomerSetting`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.customerSummary));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetCustomerTimeZones(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTimeZoneModel> {
    const path = `/customers/customerTimeZones/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTimeZoneModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersTransHistory(
    args: {
      customerID?: number,
      transID?: number,
      isOldTenant?: boolean,
      fromDate?: string,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionHistoryResponse> {
    const path = `/customers/transactionHistory`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('customerID' in args) {
      options.params = options.params.set('customerID', String(args.customerID));
    }
    if ('transID' in args) {
      options.params = options.params.set('transID', String(args.transID));
    }
    if ('isOldTenant' in args) {
      options.params = options.params.set('isOldTenant', String(args.isOldTenant));
    }
    if ('fromDate' in args) {
      options.params = options.params.set('fromDate', String(args.fromDate));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponseTransactionHistoryResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersActivities(
    args: {
      customerID: number,
      fromDate?: string,
      unitNo?: string,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
      accessOnlyEvents?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCustomerEventHistoryResponse> {
    const path = `/customers/activities/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('fromDate' in args) {
      options.params = options.params.set('fromDate', String(args.fromDate));
    }
    if ('unitNo' in args) {
      options.params = options.params.set('unitNo', String(args.unitNo));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    if ('accessOnlyEvents' in args) {
      options.params = options.params.set('accessOnlyEvents', String(args.accessOnlyEvents));
    }
    return this.sendRequest<models.ResponseCustomerEventHistoryResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGenerateRandomPassCode(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/customers/generateRandomPassCode/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetSecondaryCustomers(
    args: {
      primaryCustomerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUnitCustomerDataClass> {
    const path = `/customers/secondaryCustomer/${args.primaryCustomerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListUnitCustomerDataClass>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetAccessControl(
    args: {
      customerId: number,
      unitCustomerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseAccessControlModel> {
    const path = `/customers/accessControl/${args.customerId}/${args.unitCustomerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseAccessControlModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetCustomerHistory(
    args: {
      customerId: number,
      transTypes?: string[],
      unitNo?: string[],
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListTenantHistoryModel> {
    const path = `/customers/customerHistory/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('transTypes' in args) {
      if (args.transTypes && args.transTypes.length) {
        options.params = args.transTypes.reduce<HttpParams>((acc, cur) => acc.append('transTypes', `${cur}`), options.params);
      }
    }
    if ('unitNo' in args) {
      if (args.unitNo && args.unitNo.length) {
        options.params = args.unitNo.reduce<HttpParams>((acc, cur) => acc.append('unitNo', `${cur}`), options.params);
      }
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponseListTenantHistoryModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetCustomerHistoryReport(
    args: {
      customerId: number,
      reportName: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/customers/customerHistoryReport/${args.customerId}/${args.reportName}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetAccessLevels(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAccessLevelsItem> {
    const path = `/customers/accesslevels`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListAccessLevelsItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetMonthlyCharges(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListRecurringChargesItem> {
    const path = `/customers/getMonthlyCharges/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListRecurringChargesItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetTenants(
    args: {
      tenantName?: string,
      unitNo?: string,
      sizes?: number[],
      unitStatuses?: number[],
      lockStatus?: number,
      isDistinct?: boolean,
      isPastDue?: boolean,
      oldTenants?: boolean,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseTenantModel> {
    const path = `/customers/tenants`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('tenantName' in args) {
      options.params = options.params.set('tenantName', String(args.tenantName));
    }
    if ('unitNo' in args) {
      options.params = options.params.set('unitNo', String(args.unitNo));
    }
    if ('sizes' in args) {
      if (args.sizes && args.sizes.length) {
        options.params = args.sizes.reduce<HttpParams>((acc, cur) => acc.append('sizes', `${cur}`), options.params);
      }
    }
    if ('unitStatuses' in args) {
      if (args.unitStatuses && args.unitStatuses.length) {
        options.params = args.unitStatuses.reduce<HttpParams>((acc, cur) => acc.append('unitStatuses', `${cur}`), options.params);
      }
    }
    if ('lockStatus' in args) {
      options.params = options.params.set('lockStatus', String(args.lockStatus));
    }
    if ('isDistinct' in args) {
      options.params = options.params.set('isDistinct', String(args.isDistinct));
    }
    if ('isPastDue' in args) {
      options.params = options.params.set('isPastDue', String(args.isPastDue));
    }
    if ('oldTenants' in args) {
      options.params = options.params.set('oldTenants', String(args.oldTenants));
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseTenantModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetCustomerInfo(
    args: {
      customerId: number,
      unitCustomerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTenantGeneralInfoResponse> {
    const path = `/customers/customerInfo/${args.customerId}/${args.unitCustomerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTenantGeneralInfoResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersSave(
    args: {
      data: models.TenantPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/customers/tenantSave`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.data));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetODataTenants(
    args: {
      isDistinct: number,
      customerType: string,
      $filter?: string,
      $top?: number,
      $skip?: number,
      $orderby?: string,
      $expand?: string,
      $select?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.CustomerSearchResponse[]> {
    const path = `/customers/search/${args.isDistinct}/${args.customerType}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('$filter' in args) {
      options.params = options.params.set('$filter', String(args.$filter));
    }
    if ('$top' in args) {
      options.params = options.params.set('$top', String(args.$top));
    }
    if ('$skip' in args) {
      options.params = options.params.set('$skip', String(args.$skip));
    }
    if ('$orderby' in args) {
      options.params = options.params.set('$orderby', String(args.$orderby));
    }
    if ('$expand' in args) {
      options.params = options.params.set('$expand', String(args.$expand));
    }
    if ('$select' in args) {
      options.params = options.params.set('$select', String(args.$select));
    }
    return this.sendRequest<models.CustomerSearchResponse[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetAllContacts(
    args: {
      customerName?: string,
      category?: string,
      phone?: string,
      emailAddress?: string,
      siteGroupId?: number,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseAllContactResponse> {
    const path = `/customers/allContact`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('customerName' in args) {
      options.params = options.params.set('customerName', String(args.customerName));
    }
    if ('category' in args) {
      options.params = options.params.set('category', String(args.category));
    }
    if ('phone' in args) {
      options.params = options.params.set('phone', String(args.phone));
    }
    if ('emailAddress' in args) {
      options.params = options.params.set('emailAddress', String(args.emailAddress));
    }
    if ('siteGroupId' in args) {
      options.params = options.params.set('siteGroupId', String(args.siteGroupId));
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseAllContactResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetLatestLeads(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListLatestLeadsResponse> {
    const path = `/customers/latestLeads`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListLatestLeadsResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetProspectCustomers(
    args: {
      fullName?: string,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseProspectCustomerModel> {
    const path = `/customers/prospectCustomers`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('fullName' in args) {
      options.params = options.params.set('fullName', String(args.fullName));
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseProspectCustomerModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersCreateProspectCustomer(
    args: {
      request: models.ProspectCustomerModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseProspectCustomerModel> {
    const path = `/customers/prospectCustomer`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseProspectCustomerModel>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersDeleteProspectCustomer(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/customers/prospectCustomer/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetWaitingListCustomers(
    args: {
      fullName?: string,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseGetWaitingListCustomerResponse> {
    const path = `/customers/waitingListCustomers`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('fullName' in args) {
      options.params = options.params.set('fullName', String(args.fullName));
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseGetWaitingListCustomerResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersCreateWaitingListCustomer(
    args: {
      request: models.WaitingListCustomerModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseWaitingListCustomerModel> {
    const path = `/customers/waitingListCustomer`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseWaitingListCustomerModel>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersDeleteWaitingListCustomer(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/customers/waitingListCustomer/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetCustomersHaveReserved(
    args: {
      fullName?: string,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseGetCustomersHaveReservedResponse> {
    const path = `/customers/reservedCustomers`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('fullName' in args) {
      options.params = options.params.set('fullName', String(args.fullName));
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseGetCustomersHaveReservedResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetCustomerSizesAndUnits(
    args: {
      customerId: number,
      isReservation: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCustomerSizesAndUnitsModel> {
    const path = `/customers/customerSizesAndUnits/${args.customerId}/${args.isReservation}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseCustomerSizesAndUnitsModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  customersGetDefaultCashCustomer(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCustomerModel> {
    const path = `/customers/defaultCashCustomer`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseCustomerModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dashboardOtherOperationsGetTipOfDay(
    requestHttpOptions?: HttpOptions
  ): Observable<models.TipOfDayModel> {
    const path = `/otheroperation/tipofday`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.TipOfDayModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dashboardOtherOperationsGetWhatIsNew(
    requestHttpOptions?: HttpOptions
  ): Observable<models.WhatIsNew> {
    const path = `/otheroperation/whatisnew`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.WhatIsNew>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dashboardOtherOperationsGetSupportVideos(
    requestHttpOptions?: HttpOptions
  ): Observable<models.Video[]> {
    const path = `/otheroperation/playList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Video[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dashboardOtherOperationsGetSystemEvents(
    args: {
      request: models.EventRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.PaginatedListResponseDashboardEventItem> {
    const path = `/otheroperation/systemEvents`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.PaginatedListResponseDashboardEventItem>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  demographicAddOrUpdateDemographic(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/demographic/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  demographicDeleteSizes(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/demographic/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  demographicGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.DemographicGroup[]> {
    const path = `/demographic`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.DemographicGroup[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  demographicGetDemoGraphicItem(
    args: {
      demoId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableDemographicItem> {
    const path = `/demographic/demographiccategoryItems/${args.demoId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableDemographicItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  demographicGetDemoGraphicItemByCustomer(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableCustomerDemoprapichItem> {
    const path = `/demographic/demographicItem/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableCustomerDemoprapichItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetCountries(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListCountryModel> {
    const path = `/dictionary/countries`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListCountryModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetStates(
    args: {
      countryID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListStateModel> {
    const path = `/dictionary/states/${args.countryID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListStateModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetTimeZones(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListTimeZoneModel> {
    const path = `/dictionary/timezones`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListTimeZoneModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetCustomerTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/dictionary/customertype`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetActionMethods(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/dictionary/followActionMethod`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetLetters(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/dictionary/letters/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetInsuranceConfigList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListDictionaryInsuranceConfigModel> {
    const path = `/dictionary/insuranceConfigList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListDictionaryInsuranceConfigModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetMilitaryStatuses(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/militaryStatus`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryBillingTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/billingTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetRefundOptions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/refundOption`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetLedgerOptions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/ledgerOption`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetRoundingMethods(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/roundingMethods`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetRateChargeMethodList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/rateChargesMethods`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryDiscounPlanList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/discounPlanlist`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryLateCycleList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/lateCycleList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryAutoBillPeriodsList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/autoBillPeriodsList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryDaysBeforeDueDateList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/daysBeforeDueDateList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryBankAccountClassesList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/bankAccountClassesList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryBankAccountTypesList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/bankAccountTypesList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionarySmsCarrier(
    args: {
      countryId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/smsCarriers/${args.countryId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryRentAccountList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/rentaccount`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetGateInterfaces(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListGateInterfaceModel> {
    const path = `/dictionary/gateinterfaces`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListGateInterfaceModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetAccLevels(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAccLevelModel> {
    const path = `/dictionary/accessLevels`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListAccLevelModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryPayableorPrepaidAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/payableorprepaidaccounts`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetAuctionLocation(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/auctionLocation`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryRevenueAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/revenueaccounts`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetMiscItems(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/miscItems`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetAccountTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/accountTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetAccountList(
    args: {
      accountType: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/accountList/${args.accountType}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetLienItems(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/lienItems`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetMerchItems(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMerchandiseModel> {
    const path = `/dictionary/merchItems`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListMerchandiseModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryTexCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/taxcategories`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryReceivableaccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/receivableaccounts`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryMerchandiseAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/merchandiseaccount`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetTransTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/dictionary/merchandisetranstypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryGetFollowUpActions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListFollowUpAction> {
    const path = `/dictionary/followUpActions`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListFollowUpAction>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryTestEmail(
    args: {
      testMail: models.EmailData,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/dictionary/testemail`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.testMail));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  dictionaryPathq(
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/dictionary/path`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsRentalAgreement(
    args: {
      document: models.DocumentModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/documents/rentalAgreement`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('POST', path, options, JSON.stringify(args.document));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsPostSignature(
    args: {
      customerSig: models.CustomerSig,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/documents/postSignature`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('POST', path, options, JSON.stringify(args.customerSig));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsWelcome(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/documents/welcome/${args.transId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsMoveInReceipt(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/documents/moveInReceipt/${args.transId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsReservationContract(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/documents/reservationContract/${args.transId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsReceipt(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/documents/receipt/${args.transId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsAddendum(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/documents/addendum`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsAddSignature(
    args: {
      request: models.DocImgSigRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/documents/addSignature`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsGetCustomerContracts(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListGetCustomerContractResponse> {
    const path = `/documents/customerContracts/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListGetCustomerContractResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsWelcomeNew(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/documents/welcomeNew/${args.transId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  documentsReceiptNew(
    args: {
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/documents/receiptNew/${args.transId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  editorGetInfo(
    args: {
      unitCustomerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseEditorResponseModel> {
    const path = `/editor/${args.unitCustomerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseEditorResponseModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  editorAddTransItem(
    args: {
      request: models.EditorTransRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/editor/addTransItem`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  editorSave(
    args: {
      request: models.EditorRequestModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/editor/save`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  emailSettingCommitSetting(
    args: {
      model: models.EmailSettingModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/emailSetting/commitSetting`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  emailSettingGet(
    args: {
      isLoadingDefaultGmailSeeting: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseEmailSettingModel> {
    const path = `/emailSetting/${args.isLoadingDefaultGmailSeeting}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseEmailSettingModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  emailSettingGetSMTPSetting(
    args: {
      isLoadingDefaultGmailSeeting: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSMTPSettingModel> {
    const path = `/emailSetting/smtpSetting/${args.isLoadingDefaultGmailSeeting}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseSMTPSettingModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  emailSettingCommitSMTPSetting(
    args: {
      model: models.SMTPSettingModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/emailSetting/commitSmtpSetting`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  emailSettingGetMailSystems(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/emailSetting/mailSystems`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  emailSettingGetSMTPLoginOptions(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/emailSetting/smtpLoginOption`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  emailSettingSendTestEmail(
    args: {
      model: models.TestEmailModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/emailSetting/testEmail`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  eventDescriptionAddOrUpdate(
    args: {
      request: models.EventDescriptionPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/eventdescription/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  eventDescriptionNextEventNo(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/eventdescription/nextEventNo/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  eventDescriptionDeleteSizes(
    args: {
      ids: number[],
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/eventdescription/delete/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  eventDescriptionGet(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.EventDescriptionViewModel[]> {
    const path = `/eventdescription/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.EventDescriptionViewModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  eventDescriptionGetEventType(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/eventdescription/eventTypes/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeGetBillingInfoOfCustomer(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBillingInfoModel> {
    const path = `/finance/billingInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBillingInfoModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeUpdateBillingInfoOfCustomer(
    args: {
      model: models.BillingInfoPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/finance/billingInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeGetInsurancesInfoOfCustomer(
    args: {
      customerId?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListInsuranceModel> {
    const path = `/finance/insuranceInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('customerId' in args) {
      options.params = options.params.set('customerId', String(args.customerId));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponseListInsuranceModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeGetInsuranceCoverageList(
    args: {
      insuranceConfigId: number,
      rateNo: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListCoverageModel> {
    const path = `/finance/insuranceCoverage/${args.insuranceConfigId}/${args.rateNo}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListCoverageModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeGetTransactions(
    args: {
      customerID?: number,
      transID?: number,
      isOldTenant?: boolean,
      fromDate?: string,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionHistoryResponse> {
    const path = `/finance/transactions`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('customerID' in args) {
      options.params = options.params.set('customerID', String(args.customerID));
    }
    if ('transID' in args) {
      options.params = options.params.set('transID', String(args.transID));
    }
    if ('isOldTenant' in args) {
      options.params = options.params.set('isOldTenant', String(args.isOldTenant));
    }
    if ('fromDate' in args) {
      options.params = options.params.set('fromDate', String(args.fromDate));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponseTransactionHistoryResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeAllocateInsurance(
    args: {
      request: models.InsurancePaymentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInsuranceTransDetailResponse> {
    const path = `/finance/allocateInsurance`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInsuranceTransDetailResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeCompleteInsurance(
    args: {
      request: models.InsurancePaymentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/finance/completeInsurance`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeGetBalances(
    args: {
      request: models.FinanceBalanceRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBalanceResponse> {
    const path = `/finance/balance`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBalanceResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeGetReturnCheck(
    args: {
      customerId: number,
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReturnCheckModel> {
    const path = `/finance/returnCheck/${args.customerId}/${args.transId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseReturnCheckModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeCancelVoidpayment(
    args: {
      customerId: number,
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCancelTransactionVoidRespone> {
    const path = `/finance/cancel/voidpayment/${args.customerId}/${args.transId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseCancelTransactionVoidRespone>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeCancelTransaction(
    args: {
      request: models.CancelTransRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseString> {
    const path = `/finance/cancel`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseString>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeSaveReturnCheck(
    args: {
      model: models.ReturnCheckPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/finance/returnCheck/save`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  financeIsRetCheckAlreadyPosted(
    args: {
      customerId: number,
      transId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/finance/checkAlReadyPosted/${args.customerId}/${args.transId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  followUpActionAddOrUpdate(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/followup/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  followUpActionDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/followup/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  followUpActionGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableFollowUpActionModel> {
    const path = `/followup`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableFollowUpActionModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  gateAccessGetSignalRConfiguration(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSignalRConfigurationModel> {
    const path = `/gateAccess/siteSignalRConfig`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseSignalRConfigurationModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  gateAccessGetClosestGate(
    args: {
      model: models.ClosestGateRequestModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseClosestGateResponseModel> {
    const path = `/gateAccess/closestGateData`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseClosestGateResponseModel>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  gateAccessGetGateList(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListGateInfo> {
    const path = `/gateAccess/gateList/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListGateInfo>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  gateAccessGetGateAccessData(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseGateAccessDataModel> {
    const path = `/gateAccess/gateAccessData`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseGateAccessDataModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  gateAccessSaveGateInfo(
    args: {
      request: models.GateInfoPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/gateAccess/saveGateInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  gateAccessGateMaxDistance(
    args: {
      request: models.GetGateMaxDistanceRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseDouble> {
    const path = `/gateAccess/gateMaxDistance`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseDouble>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  insuranceConfigAddOrUpdate(
    args: {
      request: models.InsuranceConfigRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/insuranceConfig/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  insuranceConfigNextInsuranceConfigNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/insuranceConfig/nextInsuranceConfigNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  insuranceConfigDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/insuranceConfig/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  insuranceConfigGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.InsuranceConfigResponse[]> {
    const path = `/insuranceConfig`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.InsuranceConfigResponse[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  kioskOptionAddOrUpdate(
    args: {
      request: models.KioskOptionModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/kioskOption/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  kioskOptionGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseKioskOptionModel> {
    const path = `/kioskOption`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseKioskOptionModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleAddOrUpdate(
    args: {
      request: models.LateCycleRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/latecycle/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  LateCycleNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/latecycle/nextLateCycleNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleNextLateFeeSchedulesNo(
    args: {
      lateCycleId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/latecycle/nextLateFeeSchedulesNo/${args.lateCycleId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleNextLateNoticeSchedulesNo(
    args: {
      lateCycleId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/latecycle/nextLateNoticeSchedulesNo/${args.lateCycleId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleDelete(
    args: {
      ids: number[],
      siteGroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/latecycle/delete/${args.siteGroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleGet(
    args: {
      sitegroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.LateCycleModel[]> {
    const path = `/latecycle/${args.sitegroupId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.LateCycleModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleGetLateFetypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/latecycle/lateFeeType`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleGetLienCharge(
    args: {
      latecycleID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLienChargeResponse> {
    const path = `/latecycle/liencharge/${args.latecycleID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseLienChargeResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleUpdateLienCharge(
    args: {
      model: models.UpdateLienChargeRequest,
      latecycleID: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/latecycle/updateliencharge/${args.latecycleID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleDeleteLienCharges(
    args: {
      ids: number[],
      lateCycleId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/latecycle/deleteLienCharges/${args.lateCycleId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateCycleGetLateFeeScheduleTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/latecycle/lateFeeScheduleTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateLettersAndMailingsGetLateLettersAndMailings(
    args: {
      noticeDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListNoticeResponse> {
    const path = `/lateLettersAndMailings`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('noticeDate' in args) {
      options.params = options.params.set('noticeDate', String(args.noticeDate));
    }
    return this.sendRequest<models.ResponseListNoticeResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateLettersAndMailingsPrintNotice(
    args: {
      request: models.PrintNoticeRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/lateLettersAndMailings/print`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lateLettersAndMailingsGetSpecialMailings(
    args: {
      request: models.SpecialMailingRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListNoticeResponse> {
    const path = `/lateLettersAndMailings/specialMailings`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListNoticeResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterAddOrUpdate(
    args: {
      request: models.LetterPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/letter/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  LetterNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/letter/nextLetterNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/letter/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  LetterFileNames(
    args: {
      letterID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableLetterFileNameViewModel> {
    const path = `/letter/leterFileNames/${args.letterID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableLetterFileNameViewModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterGetLetterCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/letter/letterCategories`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.LetterViewModel[]> {
    const path = `/letter`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.LetterViewModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterGetLetterNames(
    args: {
      request: models.LetterFilterRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListString> {
    const path = `/letter/letterNames`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListString>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterPrint(
    args: {
      request: models.PrintOrSendLetterRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/letter/print`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterSendLetterEmail(
    args: {
      request: models.PrintOrSendLetterRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/letter/sendLetterEmail`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  LettersbyFilter(
    args: {
      request: models.LetterRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableLetterViewModel> {
    const path = `/letter/letterbyFilter`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableLetterViewModel>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterGetAttachmentFormats(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListString> {
    const path = `/letter/attachmentFormats`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListString>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterGetSmsCarriers(
    args: {
      currentRegionGeoId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ModelItem[]> {
    const path = `/letter/smscarriers/${args.currentRegionGeoId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ModelItem[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterPrintLetter(
    args: {
      request: models.LetterPrintRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTenantLetterPrintResponse> {
    const path = `/letter/printTenantLetter`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTenantLetterPrintResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterGetLetterCorrespondence(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLetterCorrespondenceModel> {
    const path = `/letter/letterCorrespondence`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseLetterCorrespondenceModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  letterUpdateLetterCorrespondence(
    args: {
      model: models.LetterCorrespondenceModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/letter/updateletterCorrespondence`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  libraryGetLibrary(
    args: {
      key: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/library/${args.key}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lienGet(
    args: {
      customerID: number,
      leaseId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLienResponse> {
    const path = `/lien/${args.customerID}/${args.leaseId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseLienResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lienGetAuctionLocation(
    args: {
      id: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseAuctionLocation> {
    const path = `/lien/auctionLocation/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseAuctionLocation>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lienAddDefault(
    args: {
      customerID: number,
      leaseId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/lien/default/${args.customerID}/${args.leaseId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lienAddMiscItems(
    args: {
      request: models.ChargeRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/lien/addmisctitem`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lienCompleteLien(
    args: {
      request: models.LienPostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCompleteLienResponse> {
    const path = `/lien/completeLien`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseCompleteLienResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lienPrintLien(
    args: {
      request: models.LienPrintRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLienPrintResponse> {
    const path = `/lien/printLien`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseLienPrintResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  lienDisplay(
    args: {
      request: models.DisplayRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/lien/displayReport`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeAddOrUpdate(
    args: {
      request: models.MainOfficePostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/mainOffice/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/mainOffice/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMainOfficeViewModel> {
    const path = `/mainOffice`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListMainOfficeViewModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeGetTenantOption(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTenantOptionPostModel> {
    const path = `/mainOffice/tenantOption/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTenantOptionPostModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeGetFeeOption(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFeeOptionPostModel> {
    const path = `/mainOffice/feeOption/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFeeOptionPostModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeGetSystemDate(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSystemDateModel> {
    const path = `/mainOffice/systemDate/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseSystemDateModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeGetPosVendors(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/mainOffice/posVendor/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeGetAuctionLocation(
    args: {
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAuctionLocationModel> {
    const path = `/mainOffice/auctionLocation/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListAuctionLocationModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeAddOrUpdateAuctionLocation(
    args: {
      request: models.AuctionLocationModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/mainOffice/addOrUpdateAuctionLocation`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  mainOfficeDeleteAuctionLocation(
    args: {
      ids: number[],
      sitegroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/mainOffice/deleteAuctionLocation/${args.sitegroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  managerDashboardGetYearlyRentReport(
    args: {
      endDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListYearlyRentReportResponse> {
    const path = `/ManagerDashboard/yearlyRentReport/${args.endDate}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListYearlyRentReportResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  managerDashboardGetOccupancyReport(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseOccupancyReportResponse> {
    const path = `/ManagerDashboard/occupancy`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseOccupancyReportResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  managerDashboardGetDelinquencyReport(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseDelinquencyReportResponse> {
    const path = `/ManagerDashboard/delinquency`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseDelinquencyReportResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  managerDashboardGetRevenueReport(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseRevenueReportResponse> {
    const path = `/ManagerDashboard/revenue`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseRevenueReportResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  managerDashboardGetMarketingReport(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMarketingReportResponse> {
    const path = `/ManagerDashboard/marketing`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListMarketingReportResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  managerDashboardGetDashboardBillingInfo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseDashboardBillingInfoResponse> {
    const path = `/ManagerDashboard/dashboardBillingInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseDashboardBillingInfoResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  merchandiseAddOrUpdateUnitType(
    args: {
      request: models.MerchandiseCommonModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/merchandise/updatemerchandise`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  merchandiseNextItemNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/merchandise/nextItemNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  merchandiseNextCategoryNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/merchandise/nextcategoryNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  merchandiseGetMercHandises(
    requestHttpOptions?: HttpOptions
  ): Observable<models.MerchandiseViewModel[]> {
    const path = `/merchandise`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.MerchandiseViewModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  merchandiseGetCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.MerchandiseCategoryModel[]> {
    const path = `/merchandise/categories`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.MerchandiseCategoryModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  MoveOutUnitsList(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMoveOutLeases> {
    const path = `/moveOut/moveOutUnitsList/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListMoveOutLeases>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  MoveoutTransDetailsList(
    args: {
      request: models.MoveOutTransDetailsRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListMoveOutTransDetails> {
    const path = `/moveOut/moveOutTransDetailsList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListMoveOutTransDetails>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  moveOutBalanceDue(
    args: {
      request: models.BalanceRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseDecimal> {
    const path = `/moveOut/balanceDue`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseDecimal>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  moveOutCompleteMoveout(
    args: {
      request: models.MoveOutRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/moveOut/completeMoveout`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  moveOutIsCreditToCustomer(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/moveOut/isCreditToCustomer`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  notesGetNotes(
    args: {
      customerID?: number,
      filter?: string,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListNoteModel> {
    const path = `/notes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('customerID' in args) {
      options.params = options.params.set('customerID', String(args.customerID));
    }
    if ('filter' in args) {
      options.params = options.params.set('filter', String(args.filter));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponseListNoteModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  notesCreateOrUpdateNote(
    args: {
      request: models.NoteModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseNoteModel> {
    const path = `/notes/createOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseNoteModel>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  notesDeleteNote(
    args: {
      noteId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/notes/${args.noteId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  notesPrintNotes(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/notes/print/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  onlineConfigSaveData(
    args: {
      request: models.OnlineConfigModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/onlineConfig/save`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  onlineConfigUpdateWebService(
    args: {
      request: models.OnlineConfigModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/onlineConfig/updateWebService`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  onlineConfigTestConnection(
    args: {
      request: models.TestConnectionModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/onlineConfig/testConnection`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  onlineConfigGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseOnlineConfigModel> {
    const path = `/onlineConfig/data`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseOnlineConfigModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  operationGet(
    args: {
      operationCategories?: number[],
      operationNos?: number[],
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseOperationModel> {
    const path = `/operation`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('operationCategories' in args) {
      if (args.operationCategories && args.operationCategories.length) {
        options.params = args.operationCategories.reduce<HttpParams>((acc, cur) => acc.append('operationCategories', `${cur}`), options.params);
      }
    }
    if ('operationNos' in args) {
      if (args.operationNos && args.operationNos.length) {
        options.params = args.operationNos.reduce<HttpParams>((acc, cur) => acc.append('operationNos', `${cur}`), options.params);
      }
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseOperationModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  operationGetOperationReportInfos(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListOperationReportInfoModel> {
    const path = `/operation/operationReportInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListOperationReportInfoModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  operationCreateOperationReportInfo(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/operation/operationReportInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  overLockGet(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableOverLockResponse> {
    const path = `/overlock/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableOverLockResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  overLockSaveChanges(
    args: {
      request: models.OverLockRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/overlock/saveChanges`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  paymentSaveCreditCard(
    args: {
      request: models.SaveCreditCardClearentRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSaveCreditCardClearentResponse> {
    const path = `/payment/saveClearentCardToken`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseSaveCreditCardClearentResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  paymentGetCustomerCreditCards(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListCustomerCreditCardResponse> {
    const path = `/payment/creditCards/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListCustomerCreditCardResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  paymentDeleteCustomerCreditCard(
    args: {
      payerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/payment/creditCards`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('payerId' in args) {
      options.params = options.params.set('payerId', String(args.payerId));
    }
    return this.sendRequest<models.ResponseBoolean>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  paymentEditClearentConfig(
    args: {
      request: models.ClearentConfigModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/payment/clearentConfig/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  paymentGetClearentConfigs(
    args: {
      siteGroupId: number,
      siteConfigId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableClearentConfigModel> {
    const path = `/payment/clearentConfig/${args.siteGroupId}/${args.siteConfigId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableClearentConfigModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  payMoverAddOrUpdate(
    args: {
      request: models.PayMoverModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/paymover/saveData`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  payMoverGet(
    args: {
      siteGroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePayMoverModel> {
    const path = `/paymover/data/${args.siteGroupId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponsePayMoverModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  payMoverSaveAdditionalAccountList(
    args: {
      list: any,
      siteGroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/paymover/saveAdditionalAccount/${args.siteGroupId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.list));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  payMoverGetAdditionalAccount(
    args: {
      siteGroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerablePayMoverAdditionalAccountModel> {
    const path = `/paymover/additionalAccount/${args.siteGroupId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerablePayMoverAdditionalAccountModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  picturesGetCustomerPictures(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerablePicture> {
    const path = `/pictures/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerablePicture>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  picturesAddCustomerPicture(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/pictures/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  picturesRemoveCustomerPicture(
    args: {
      pictureId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/pictures/${args.pictureId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  printerSave(
    args: {
      request: models.PrinterModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/printer/save`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  printerGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePrinterModel> {
    const path = `/printer`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponsePrinterModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  printerGetPrinters(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableString> {
    const path = `/printer/installedprinter`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableString>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  promotionsAddOrUpdate(
    args: {
      request: models.PromoModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/promo/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  promotionsDelete(
    args: {
      iDs: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/promo/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('iDs' in args) {
      if (args.iDs && args.iDs.length) {
        options.params = args.iDs.reduce<HttpParams>((acc, cur) => acc.append('IDs', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  promotionsGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.PromoModel[]> {
    const path = `/promo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.PromoModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  promotionsGetPromoTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ModelItem[]> {
    const path = `/promo/promoTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ModelItem[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  promotionsGetPromoLocations(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ModelItem[]> {
    const path = `/promo/promoLocations`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ModelItem[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  promotionsGetPromoDiscountTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ModelItem[]> {
    const path = `/promo/promoDiscountTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ModelItem[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  quickBooksInterfacePreviewExportQuickBooks(
    args: {
      request: models.ExportQuickBooksRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePreviewExportQuickBooksResponse> {
    const path = `/quickBooks/previewExport`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponsePreviewExportQuickBooksResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  quickBooksInterfaceExportQuickBooks(
    args: {
      request: models.ExportQuickBooksRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseFileResponse> {
    const path = `/quickBooks/export`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseFileResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  quickBooksInterfaceGetDepositGroups(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListDepositGroupModel> {
    const path = `/quickBooks/depositGroups`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListDepositGroupModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  quickBooksInterfaceUpsertDepositGroup(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/quickBooks/depositGroups`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  quickBooksInterfaceDeleteDepositGroup(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/quickBooks/depositGroups`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('DELETE', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  quickBooksInterfaceImportIIFFile(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/quickBooks/importIIFFile`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  quickBooksInterfaceGetQBAccounts(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListQBAccountModel> {
    const path = `/quickBooks/QBAccounts`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListQBAccountModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  rateChangeGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListRateChangeModel> {
    const path = `/rateChange`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListRateChangeModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  rateChangePost(
    args: {
      upsertRateChangeRequest: models.RateChangeModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/rateChange`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.upsertRateChangeRequest));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  rateChangeDeleteSizes(
    args: {
      rateChangeId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/rateChange/${args.rateChangeId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  rateChangeGetRateChangeTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/rateChange/rateChangeTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  rateChangeGetNewRateCalcMethods(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/rateChange/newRateCalcMethods`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  rateChangeGetRoundingMethods(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/rateChange/roundingMethods`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  referenceTableAddOrUpdate(
    args: {
      request: models.ReferanceTablePostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/referenceTable/addOrUpdateSmsCarrier`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  referenceTableGetData(
    args: {
      request: models.ReferanceTableRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListBaseReferenceTable> {
    const path = `/referenceTable/data`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListBaseReferenceTable>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  referenceTableGetTables(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableModelItem> {
    const path = `/referenceTable/tablenames`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  referenceTableDelete(
    args: {
      ids: number[],
      tabelIndex: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/referenceTable/delete/${args.tabelIndex}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  remindersGetReminderTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListReminderTypeModel> {
    const path = `/reminders/reminderTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListReminderTypeModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  remindersGetReminders(
    args: {
      customerId: number,
      filter: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListReminderResponseModel> {
    const path = `/reminders/${args.customerId}/${args.filter}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListReminderResponseModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  remindersGetReminder(
    args: {
      reminderId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReminderResponseModel> {
    const path = `/reminders/reminder/${args.reminderId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseReminderResponseModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  remindersCreateOrUpdateReminder(
    args: {
      request: models.ReminderRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReminderResponseModel> {
    const path = `/reminders/createOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseReminderResponseModel>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  remindersDeleteReminder(
    args: {
      reminderId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/reminders/${args.reminderId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  remindersGetRemindersForDashboard(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListReminderEntryResponse> {
    const path = `/reminders/remindersForDashboard`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListReminderEntryResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportGetReportList(
    args: {
      reportCategory: number,
      name?: string,
      reportNos?: number[],
      reportCategories?: number[],
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseReportListModel> {
    const path = `/report/reportList/${args.reportCategory}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('name' in args) {
      options.params = options.params.set('name', String(args.name));
    }
    if ('reportNos' in args) {
      if (args.reportNos && args.reportNos.length) {
        options.params = args.reportNos.reduce<HttpParams>((acc, cur) => acc.append('reportNos', `${cur}`), options.params);
      }
    }
    if ('reportCategories' in args) {
      if (args.reportCategories && args.reportCategories.length) {
        options.params = args.reportCategories.reduce<HttpParams>((acc, cur) => acc.append('reportCategories', `${cur}`), options.params);
      }
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseReportListModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportGetSentinelReportList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListSentinelReportListModel> {
    const path = `/report/sentinelReportList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListSentinelReportListModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportGetReportCategoryList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListReportCategoryListItem> {
    const path = `/report/reportCategoryList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListReportCategoryListItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportGetReportEventCategoryList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListEventCategoriesModel> {
    const path = `/report/reportEventCategoryList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListEventCategoriesModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportGenerateReports(
    args: {
      reportRequest: models.GenerateReportRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportResponse> {
    const path = `/report/generateReports`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseReportResponse>('POST', path, options, JSON.stringify(args.reportRequest));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportDisplay(
    args: {
      index: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/report/displayReports/${args.index}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<object>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportGetSiteGroupUseCashAccount(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/report/siteGroupUseCashAccount`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportAddOrUpdate(
    args: {
      request: models.ReportInfoPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/report/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  ReportNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/report/nextReportNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/report/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ReportInfoViewModel[]> {
    const path = `/report`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ReportInfoViewModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportGetReportNames(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListString> {
    const path = `/report/reportNames`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListString>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportGetSummary(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseReportInfoViewModel> {
    const path = `/report/summary`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseReportInfoViewModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportCategoryAddOrUpdate(
    args: {
      request: models.ReportCategoryModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/reportcategory/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  ReportCategoryNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/reportcategory/nextReportCategoryNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportCategoryDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/reportcategory/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  reportCategoryGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ReportCategoryModel[]> {
    const path = `/reportcategory`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ReportCategoryModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  retailSalesGetRetailSaleCost(
    args: {
      request: models.RetailSalesCostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseRetailSalesCostResponse> {
    const path = `/retailSales/cost`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseRetailSalesCostResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  retailSalesTransferCost(
    args: {
      request: models.RetailSalesRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseRetailSalesResponse> {
    const path = `/retailSales/make-sale`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseRetailSalesResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteListInsuranceConfig(
    args: {
      insuranceConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListInsuranceConfigModel> {
    const path = `/site/insuranceConfig/${args.insuranceConfigID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListInsuranceConfigModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteListInsuranceTransactions(
    args: {
      startDate: string,
      endDate: string,
      insuranceConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListInsuranceTransactionModel> {
    const path = `/site/insuranceTransactions/${args.startDate}/${args.endDate}/${args.insuranceConfigID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListInsuranceTransactionModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteGetSiteInfo(
    args: {
      siteConfigNo: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListSiteModel> {
    const path = `/site/${args.siteConfigNo}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListSiteModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteGetSiteDetail(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSiteModel> {
    const path = `/site/siteDetail`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseSiteModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteGetNextCustomerNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/site/nextCustomerNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteSendSupportEmail(
    args: {
      request: models.SupportEmailModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/site/sendSupportEmail`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteChangeSiteLogo(
    args: {
      logoModel: models.SiteLogoModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/site/changeLogo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.logoModel));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteRemoveSiteLogo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/site/removeLogo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteGetSiteSettings(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSiteSettingModel> {
    const path = `/site/siteSettings`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseSiteSettingModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteSaveSiteSettings(
    args: {
      request: models.SiteSettingModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/site/saveSiteSettings`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteSendSupportEmailTenant(
    args: {
      request: models.SupportEmailModel,
      siteConfigId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/site/sendSupportEmailTenant/${args.siteConfigId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  SitesListBySiteId(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableSiteGroupModel> {
    const path = `/site/list`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableSiteGroupModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteConfigAddorupdate(
    args: {
      model: models.SiteConfigPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/siteConfig/addorupdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteConfigGetAll(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListSiteConfigViewModel> {
    const path = `/siteConfig/all`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListSiteConfigViewModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteConfigDeleteSiteConfig(
    args: {
      siteConfigIds: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/siteConfig/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('siteConfigIds' in args) {
      if (args.siteConfigIds && args.siteConfigIds.length) {
        options.params = args.siteConfigIds.reduce<HttpParams>((acc, cur) => acc.append('siteConfigIds', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteConfigNextSiteConfigNoAndSiteName(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/siteConfig/nextdefaultvalues`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteConfigGateConfigurationList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListGateConfigModel> {
    const path = `/siteConfig/gateConfigurationList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListGateConfigModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteConfigPostGateConfig(
    args: {
      model: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/siteConfig/postGateConfig`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  siteConfigUpLoad(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/siteConfig/upload`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesAddOrUpdateSizes(
    args: {
      request: models.SizePostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/sizes/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesAddOrUpdateRange(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/sizes/addOrUpdateRange`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesDeleteSizes(
    args: {
      sizeID: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/sizes/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('sizeID' in args) {
      if (args.sizeID && args.sizeID.length) {
        options.params = args.sizeID.reduce<HttpParams>((acc, cur) => acc.append('sizeID', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesGetAll(
    args: {
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.SizeViewModel[]> {
    const path = `/sizes/all/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.SizeViewModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesUploadSizeImage(
    args: {
      sizeGroup: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12',
      darkMode: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<object> {
    const path = `/sizes/uploadImage`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('sizeGroup' in args) {
      options.params = options.params.set('sizeGroup', String(args.sizeGroup));
    }
    if ('darkMode' in args) {
      options.params = options.params.set('darkMode', String(args.darkMode));
    }
    return this.sendRequest<object>('POST', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesGet(
    args: {
      sizeType?: '0' | '1' | '2' | '3',
      unitTypeId?: number,
      isTenantPortal?: boolean,
      siteConfigId?: number,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseSizeModel> {
    const path = `/sizes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('sizeType' in args) {
      options.params = options.params.set('sizeType', String(args.sizeType));
    }
    if ('unitTypeId' in args) {
      options.params = options.params.set('unitTypeId', String(args.unitTypeId));
    }
    if ('isTenantPortal' in args) {
      options.params = options.params.set('isTenantPortal', String(args.isTenantPortal));
    }
    if ('siteConfigId' in args) {
      options.params = options.params.set('siteConfigId', String(args.siteConfigId));
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseSizeModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesFeatureGetBySizeId(
    args: {
      sizeId: number,  // The ID of the size to retrieve features for.
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableSizesFeatureResponse> {
    const path = `/sizesFeature/getBySizeId`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('sizeId' in args) {
      options.params = options.params.set('sizeId', String(args.sizeId));
    }
    return this.sendRequest<models.ResponseIEnumerableSizesFeatureResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesFeatureAddOrUpdateSizes(
    args: {
      request: models.SizesFeatureRequest,  // The size feature data to add or update.
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/sizesFeature/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesFeatureAddOrUpdateRange(
    args: {
      request: any,  // The list of size feature data to add or update.
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/sizesFeature/addOrUpdateRange`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesFeatureRemoveAndAssignFeaturesToSize(
    args: {
      request: models.SizesFeatureAssignment,  // The size feature assignment data.
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/sizesFeature/assignFeaturesToSize`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesFeatureGetAll(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableSizesFeatureResponse> {
    const path = `/SizesFeature`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableSizesFeatureResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  sizesFeatureDeleteSizesFeature(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/SizesFeature`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  systemChargerCheck(
    args: {
      todaysDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCheckSystemChargerResponse> {
    const path = `/systemCharger/check/${args.todaysDate}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseCheckSystemChargerResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  systemChargerLogs(
    args: {
      request: models.GetLogsRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSystemChargerLogResponse> {
    const path = `/systemCharger/logs`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseSystemChargerLogResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  systemChargerRun(
    args: {
      todaysDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSystemChargerRunResponse> {
    const path = `/systemCharger/run/${args.todaysDate}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseSystemChargerRunResponse>('POST', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  systemChargerSetNextDailyCloseDate(
    args: {
      dailyCloseDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/systemCharger/setNextDailyCloseDate/${args.dailyCloseDate}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  systemCharGetAutoBills(
    args: {
      todaysDate: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableAutoBillResponse> {
    const path = `/systemCharger/getAutoBills/${args.todaysDate}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableAutoBillResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  systemChargerRunAutoBills(
    args: {
      todaysDate: string,
      autoBillItems: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseSystemChargerRunResponse> {
    const path = `/systemCharger/runAutoBills/${args.todaysDate}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseSystemChargerRunResponse>('POST', path, options, JSON.stringify(args.autoBillItems));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  taxesAddOrUpdate(
    args: {
      request: models.TaxPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/taxes/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  taxesTaxAuthorityNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/taxes/nextTaxAuthorityNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  taxesDelete(
    args: {
      taxids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/taxes/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('taxids' in args) {
      if (args.taxids && args.taxids.length) {
        options.params = args.taxids.reduce<HttpParams>((acc, cur) => acc.append('taxids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  taxesGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.TaxViewModel[]> {
    const path = `/taxes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.TaxViewModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  timeZoneAddOrUpdateSizes(
    args: {
      request: models.TimeZonePostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/timeZones/addOrUpdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  TimeZoneNo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/timeZones/nextTimeZoneNo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  timeZoneDeleteSizes(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/timeZones/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  timeZoneGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.TimeZoneModel[]> {
    const path = `/timeZones`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.TimeZoneModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  transactionGetTransactionsOData(
    args: {
      transactionType: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.TransactionResponse[]> {
    const path = `/transaction/query-transaction/${args.transactionType}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.TransactionResponse[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  transactionGetTransactionDetailsOData(
    requestHttpOptions?: HttpOptions
  ): Observable<models.TransactionDetailResponse[]> {
    const path = `/transaction/query-transaction-details`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.TransactionDetailResponse[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  transactionGetTransactionDetails(
    args: {
      transactionId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableTransactionDetailResponse> {
    const path = `/transaction/transaction-details/${args.transactionId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableTransactionDetailResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  transactionCancelTransactionCost(
    args: {
      transactionId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCancelTransactionResponse> {
    const path = `/transaction/cancel-cost/${args.transactionId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseCancelTransactionResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  transactionCancelTransaction(
    args: {
      request: models.CancelTransactionRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseCancelTransactionResponse> {
    const path = `/transaction/cancel`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseCancelTransactionResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  transFitSaveData(
    args: {
      request: models.TransFitModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/transFit/savedata`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  transFitGetTransFit(
    args: {
      siteGroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransFitModel> {
    const path = `/transFit/transFitData/${args.siteGroupId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTransFitModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  transFitSaveAdditionalAccount(
    args: {
      request: any,
      sitegroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/transFit/saveAdditionalAccount/${args.sitegroupId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  transFitGetAdditionalAccount(
    args: {
      sitegroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseIEnumerableAdditionalAccountModel> {
    const path = `/transFit/additionalAccount/${args.sitegroupId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseIEnumerableAdditionalAccountModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetUnitsPaginated(
    args: {
      unitID?: number,
      sizeID?: number,
      sizeType?: '0' | '1' | '2' | '3',
      unitTypeID?: number,
      promoID?: number,
      isTenantPortal?: boolean,
      siteConfigID?: number,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseUnitSummaryModel> {
    const path = `/units`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('unitID' in args) {
      options.params = options.params.set('unitID', String(args.unitID));
    }
    if ('sizeID' in args) {
      options.params = options.params.set('sizeID', String(args.sizeID));
    }
    if ('sizeType' in args) {
      options.params = options.params.set('sizeType', String(args.sizeType));
    }
    if ('unitTypeID' in args) {
      options.params = options.params.set('unitTypeID', String(args.unitTypeID));
    }
    if ('promoID' in args) {
      options.params = options.params.set('promoID', String(args.promoID));
    }
    if ('isTenantPortal' in args) {
      options.params = options.params.set('isTenantPortal', String(args.isTenantPortal));
    }
    if ('siteConfigID' in args) {
      options.params = options.params.set('siteConfigID', String(args.siteConfigID));
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseUnitSummaryModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetVacantUnits(
    args: {
      sizeID?: number,
      sizeType?: '0' | '1' | '2' | '3',
      promoID?: number,
      orderBy?: string,
      isTenantPortal?: boolean,
      siteConfigID?: number,
      pageNumber?: number,
      pageSize?: number,
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponsePaginatedListResponseUnitSummaryModel> {
    const path = `/units/vacant`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('sizeID' in args) {
      options.params = options.params.set('sizeID', String(args.sizeID));
    }
    if ('sizeType' in args) {
      options.params = options.params.set('sizeType', String(args.sizeType));
    }
    if ('promoID' in args) {
      options.params = options.params.set('promoID', String(args.promoID));
    }
    if ('orderBy' in args) {
      options.params = options.params.set('orderBy', String(args.orderBy));
    }
    if ('isTenantPortal' in args) {
      options.params = options.params.set('isTenantPortal', String(args.isTenantPortal));
    }
    if ('siteConfigID' in args) {
      options.params = options.params.set('siteConfigID', String(args.siteConfigID));
    }
    if ('pageNumber' in args) {
      options.params = options.params.set('pageNumber', String(args.pageNumber));
    }
    if ('pageSize' in args) {
      options.params = options.params.set('pageSize', String(args.pageSize));
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponsePaginatedListResponseUnitSummaryModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetUnitTypes(
    args: {
      hasVacantUnit: boolean,
      siteConfigId?: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUnitTypeResponse> {
    const path = `/units/unitTypes/${args.hasVacantUnit}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('siteConfigId' in args) {
      options.params = options.params.set('siteConfigId', String(args.siteConfigId));
    }
    return this.sendRequest<models.ResponseListUnitTypeResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetUnitStatuses(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/units/unitStatus`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetPromos(
    args: {
      sizeID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListPromoModel> {
    const path = `/units/promos/${args.sizeID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListPromoModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsMoveinUnit(
    args: {
      payment: models.MoveinRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    const path = `/units/moveIn`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTransactionResult>('POST', path, options, JSON.stringify(args.payment));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsMoveinCost(
    args: {
      rentCost: models.CostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    const path = `/units/moveInCost`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTransactionResult>('POST', path, options, JSON.stringify(args.rentCost));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsReservationCost(
    args: {
      reserveCost: models.CostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    const path = `/units/reserveCost`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTransactionResult>('POST', path, options, JSON.stringify(args.reserveCost));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsReserveUnit(
    args: {
      payment: models.MoveinRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransactionResult> {
    const path = `/units/reserve`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTransactionResult>('POST', path, options, JSON.stringify(args.payment));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetLateCycleSchedules(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListLateCycleScheduleModel> {
    const path = `/units/lateCycleSchedule`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListLateCycleScheduleModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetUnitAdditionalChargeTypes(
    args: {
      chargeTypes?: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListAdditionalChargeTypesResponse> {
    const path = `/units/unitAdditionalChargeTypes`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('chargeTypes' in args) {
      if (args.chargeTypes && args.chargeTypes.length) {
        options.params = args.chargeTypes.reduce<HttpParams>((acc, cur) => acc.append('chargeTypes', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseListAdditionalChargeTypesResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetUnitCustomers(
    args: {
      customerID: number,
      sizes?: number[],
      statuses?: number[],
      sorterValue?: string,
      sorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUnitCustomerResponse> {
    const path = `/units/unitCustomers/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('sizes' in args) {
      if (args.sizes && args.sizes.length) {
        options.params = args.sizes.reduce<HttpParams>((acc, cur) => acc.append('sizes', `${cur}`), options.params);
      }
    }
    if ('statuses' in args) {
      if (args.statuses && args.statuses.length) {
        options.params = args.statuses.reduce<HttpParams>((acc, cur) => acc.append('statuses', `${cur}`), options.params);
      }
    }
    if ('sorterValue' in args) {
      options.params = options.params.set('sorter.value', String(args.sorterValue));
    }
    if ('sorterDirection' in args) {
      options.params = options.params.set('sorter.direction', String(args.sorterDirection));
    }
    return this.sendRequest<models.ResponseListUnitCustomerResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetUnitCustomerDetails(
    args: {
      unitID: number,
      customerID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUnitCustomerDetailResponse> {
    const path = `/units/unitCustomerDetails/${args.unitID}/${args.customerID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUnitCustomerDetailResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsTransferCost(
    args: {
      request: models.TransferCostRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransferCostResponse> {
    const path = `/units/transferCost`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTransferCostResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsTransfer(
    args: {
      request: models.TransferRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTransferResponse> {
    const path = `/units/transfer`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTransferResponse>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetUnitStatus(
    args: {
      customerId: number,
      unitCustomerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUnitStatusModel> {
    const path = `/units/unitStatus/${args.customerId}/${args.unitCustomerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUnitStatusModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsUnitList(
    args: {
      customerId: number,
      isOldTenant?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.UnitListModel[]> {
    const path = `/units/unitList/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('isOldTenant' in args) {
      options.params = options.params.set('isOldTenant', String(args.isOldTenant));
    }
    return this.sendRequest<models.UnitListModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsAddorUpdate(
    args: {
      model: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/units/addorupdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsNextUnitNo(
    args: {
      siteConfigID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseString> {
    const path = `/units/nextUnitNo/${args.siteConfigID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseString>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsDelete(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/units/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetUnits(
    args: {
      request: models.UnitListRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.PaginatedListResponseUnitViewModel> {
    const path = `/units/list`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.PaginatedListResponseUnitViewModel>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetLeaseStatus(
    args: {
      customerID: number,
      unitId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseLeaseStatusModel> {
    const path = `/units/leasestatus/${args.customerID}/${args.unitId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseLeaseStatusModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsSetLeaseStatus(
    args: {
      request: models.LeaseStatusModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/units/leasestatus`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsGetLinkedUnits(
    args: {
      customerID: number,
      unitId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUnitLinkModel> {
    const path = `/units/linkedunit/${args.customerID}/${args.unitId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUnitLinkModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsLinkAndUnlink(
    args: {
      model: models.LinkUnitPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/units/linkunit`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitsSave(
    args: {
      customerId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/units/linkunit/${args.customerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitTypeAddOrUpdateUnitTypeRange(
    args: {
      request: any,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/unitTypes/addOrUpdateUnitTyperange`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitTypeAddOrUpdateUnitType(
    args: {
      request: models.UnitTypePostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/unitTypes/addOrUpdateUnitType`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitTypeDeleteunitType(
    args: {
      unitTypesId: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/unitTypes/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('unitTypesId' in args) {
      if (args.unitTypesId && args.unitTypesId.length) {
        options.params = args.unitTypesId.reduce<HttpParams>((acc, cur) => acc.append('unitTypesId', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitTypeNextUnitType(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseInt32> {
    const path = `/unitTypes/nextUnitType`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseInt32>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  unitTypeGetAll(
    requestHttpOptions?: HttpOptions
  ): Observable<models.UnitTypeViewModel[]> {
    const path = `/unitTypes/all`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.UnitTypeViewModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersGetCurrentUserInfo(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUserResponse> {
    const path = `/users/getInfo`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUserResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersGetUserList(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUserResponse> {
    const path = `/users/userList`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListUserResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersUpdateUserInfo(
    args: {
      request: models.UpdateUserRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUserResponse> {
    const path = `/users/update`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUserResponse>('PUT', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersGetUserGroups(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUserGroupModel> {
    const path = `/users/userGroups`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListUserGroupModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersGetUsers(
    args: {
      userGroupID: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListUserPostModel> {
    const path = `/users/${args.userGroupID}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListUserPostModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersGetOperationCategories(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseListModelItem> {
    const path = `/users/operationCategories`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseListModelItem>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersGetUserRights(
    args: {
      usergroupId: number,
      opCategory: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.UserRightViewModel[]> {
    const path = `/users/userRights/${args.usergroupId}/${args.opCategory}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.UserRightViewModel[]>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersUpdateUserGroups(
    args: {
      model: models.UserGroupModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/users/userGroup/addorupdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersUpdateUserGroup(
    args: {
      usergroupId: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/users/userGroup/delete/${args.usergroupId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersUpdateUser(
    args: {
      model: models.UserPostModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/users/addorupdate`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.model));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  usersDeleteUsers(
    args: {
      ids: number[],
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/users/delete`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('ids' in args) {
      if (args.ids && args.ids.length) {
        options.params = args.ids.reduce<HttpParams>((acc, cur) => acc.append('ids', `${cur}`), options.params);
      }
    }
    return this.sendRequest<models.ResponseBoolean>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  userSecurityUpdate(
    args: {
      request: models.UserSecurityModel,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseBoolean> {
    const path = `/userSecurity/update`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseBoolean>('POST', path, options, JSON.stringify(args.request));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  userSecurityGet(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUserSecurityModel> {
    const path = `/userSecurity`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUserSecurityModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  userSecurityUpdateSecKey(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseTrackingModel> {
    const path = `/userSecurity/updateSecKey`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseTrackingModel>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  userSecurityGetDefaultUserSecurity(
    requestHttpOptions?: HttpOptions
  ): Observable<models.ResponseUserSecurityModel> {
    const path = `/userSecurity/getDefaultUserSecurity`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUserSecurityModel>('GET', path, options);
  }

  private sendRequest<T>(method: string, path: string, options: HttpOptions, body?: any): Observable<T> {
    switch (method) {
      case 'DELETE':
        return this.http.delete<T>(`${this.domain}${path}`, options);
      case 'GET':
        return this.http.get<T>(`${this.domain}${path}`, options);
      case 'HEAD':
        return this.http.head<T>(`${this.domain}${path}`, options);
      case 'OPTIONS':
        return this.http.options<T>(`${this.domain}${path}`, options);
      case 'PATCH':
        return this.http.patch<T>(`${this.domain}${path}`, body, options);
      case 'POST':
        return this.http.post<T>(`${this.domain}${path}`, body, options);
      case 'PUT':
        return this.http.put<T>(`${this.domain}${path}`, body, options);
      default:
        console.error(`Unsupported request: ${method}`);
        return throwError(`Unsupported request: ${method}`);
    }
  }
}
