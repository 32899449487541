import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RentalAgreementModalComponent } from "./shared/rental-agreement-modal/rental-agreement-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TenantLoginModalComponent } from "./shared/tenant-login-modal/tenant-login-modal.component";

import { PaginationComponent } from "./shared/pagination/pagination.component";
import { PaymentFormComponent } from "./shared/payment-form/payment-form.component";
import { OutsideDirective } from "./shared/directives/outside.directive";
import { ConfirmationDialogComponent } from "./shared/confirmation-dialog/confirmation-dialog.component";
import { LoginStepperComponent } from "./shared/login-stepper/login-stepper.component";
import { ForgotPasswordModalComponent } from "./shared/forgot-password-modal/forgot-password-modal.component";
import { PreviewItemsComponent } from "./shared/preview-items/preview-items.component";
import { LayoutModule } from "@angular/cdk/layout";
import { UnitDetailsComponent } from "./shared/unit-details/unit-details.component";
import { SecondaryTenantsComponent } from "./shared/unit-details/secondary-tenants/secondary-tenants.component";
import { InsuranceComponent } from "./shared/unit-details/insurance/insurance.component";
import { OpenGateModalComponent } from "./shared/open-gate-modal/open-gate-modal.component";
import { TransactionDetailsComponent } from "./shared/transaction-details/transaction-details.component";
import { MakeAPaymentDetailsComponent } from "./shared/make-a-payment-details/make-a-payment-details.component";
import { HoursModalComponent } from './shared/hours-modal/hours-modal.component';
import { AddSecondaryTenantComponent } from './shared/add-secondary-tenant/add-secondary-tenant.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditBillingDialogComponent } from './shared/edit-billing-dialog/edit-billing-dialog.component';
import { ConfirmationPaymentModalComponent } from './shared/confirmation-payment-modal/confirmation-payment-modal.component';
import { AgreementsComponent } from './shared/unit-details/agreements/agreements.component';
import { AgreementModalComponent } from './shared/unit-details/agreements/agreement-modal/agreement-modal.component';
import { PdfViewerModule } from "ng2-pdf-viewer";

@NgModule({
  declarations: [
    RentalAgreementModalComponent,
    TenantLoginModalComponent,
    PaginationComponent,
    PaymentFormComponent,
    OutsideDirective,
    ConfirmationDialogComponent,
    LoginStepperComponent,
    ForgotPasswordModalComponent,
    PreviewItemsComponent,
    UnitDetailsComponent,
    SecondaryTenantsComponent,
    InsuranceComponent,
    OpenGateModalComponent,
    TransactionDetailsComponent,
    MakeAPaymentDetailsComponent,
    HoursModalComponent,
    AddSecondaryTenantComponent,
    EditBillingDialogComponent,
    ConfirmationPaymentModalComponent,
    AgreementsComponent,
    AgreementModalComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatMenuModule,
    MatSnackBarModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatTooltipModule,
    PdfViewerModule
  ],
  exports: [
    RentalAgreementModalComponent,
    PaginationComponent,
    PaymentFormComponent,
    OutsideDirective,
    LoginStepperComponent,
    MatSnackBarModule,
  ],
})
export class SentinelSharedModule {}