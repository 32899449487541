import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../authentication/auth.service";
import { HelperService } from "../services/helper.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private helperService: HelperService, private router: Router) {}

  canActivate() {
    if (this.helperService.isLocalStorageAvailable()) {
      if (localStorage.getItem("CustomerId")) {
        this.router.navigate(["/dashboard"]);
      }
    }
    return true;
  }
}
