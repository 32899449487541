import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, Inject } from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/authentication/auth.service";
import { RentUnitService } from "src/app/services/rent-unit.service";

@Component({
  selector: "app-forgot-password-modal",
  templateUrl: "./forgot-password-modal.component.html",
  styleUrls: ["./forgot-password-modal.component.scss"],
})
export class ForgotPasswordModalComponent {
  forgotPasswordForm: FormGroup;
  submitEmailSuccess = false;
  errorMessage = "";
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ForgotPasswordModalComponent>,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.forgotPasswordForm = this.fb.group({
      Email: ["", [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.submitEmailSuccess) {
      this.dialogRef.close();
      return;
    }

    if (!this.forgotPasswordForm.valid) {
      this.errorMessage = 'Please enter a valid email address!';
      return;
    }
    this.loading = true;

    const body = {
      EmailAddress: this.forgotPasswordForm.get("Email")?.value,
      RedirectURL: `${window?.location?.protocol}//${window?.location?.hostname}/reset-password`,
    };
    this.authService.forgotPassword(body).subscribe(
      (data: any) => {
        console.log(data);
        this.submitEmailSuccess = true;
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        console.log(error.error);
        this.errorMessage = error.error.ErrorMessage;
        this.loading = false;
      }
    );
  }
}
