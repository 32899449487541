import { Component, OnInit, Inject } from "@angular/core";

import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/authentication/auth.service";
import { ThemeSettingsService } from "src/app/services/theme-settings.service";

@Component({
  selector: "app-preview-items",
  templateUrl: "./preview-items.component.html",
  styleUrls: ["./preview-items.component.scss"],
})
export class PreviewItemsComponent {
  mainTransactionData: any;
  totalAmount: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PreviewItemsComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    public themeService: ThemeSettingsService
  ) {
    this.mainTransactionData = this.data.transactionDetails;

    this.totalAmount = this.mainTransactionData.reduce((curr: any, acc: any) => {
      return curr + acc.Amount
    }, 0);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {}

  onSearchInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchedValue = inputElement.value;

    if (!searchedValue) {
      this.mainTransactionData = this.data.transactionDetails;
    }

    this.mainTransactionData = this.data.transactionDetails.filter(
      (item: any) => {
        return item.Description.toLocaleLowerCase().includes(searchedValue);
      }
    );
  }
}
