import { HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from "@angular/core";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { APIClient } from "api";
import { AuthService } from "src/app/authentication/auth.service";
import { HomeService } from "src/app/services/home.service";

@Component({
  selector: 'app-confirmation-payment-modal',
  templateUrl: './confirmation-payment-modal.component.html',
  styleUrls: ['./confirmation-payment-modal.component.scss']
})
export class ConfirmationPaymentModalComponent {

  mainTransactionData: any;

  accountData: any;
  totalDue: any;

  isConfiramtionScreen = false;
  pdfSrc = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmationPaymentModalComponent>,
    private apiClient: APIClient,
    private snackBar: MatSnackBar,
    private homeService: HomeService,
    private router: Router
  ) {
    this.mainTransactionData = this.data.transactionDetails;
  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    if (this.data?.transactionDetails) {
      this.totalDue = 0;
      this.accountData = this.data?.transactionDetails.reduce((acc: any, cur: any) => {
        let amount;

        const accountNo = cur.AccountNo;
          amount = cur?.Amount;

        
        const existingItem = acc.find(
          (item: any) => (item.AccountNo === accountNo));
        if (existingItem) {
          existingItem.Amount += amount;
        } else {
          acc.push({
            AccountNo: accountNo,
            Description: cur.Description,
            Amount: amount,
          });
        }
        return acc;
      }, []);

      for (let i = 0; i < this.accountData.length; i++) {
        this.totalDue += this.accountData[i].Amount;
      }
    }
    this.isConfiramtionScreen = this.data.isConfiramtionScreen || false;
  }

  onCancel() {
    this.router.navigate(["/dashboard/rent-unit"]);
    this.dialogRef.close();
  }

  onPrintInvoice() {
    this.homeService
    .getTransactionInvoice(this.data.TransactionId)
    .subscribe((data: any) => {
      this.pdfSrc = data.Result.Base64String;
      this.downloadPdf(this.pdfSrc);
    }, (error: HttpErrorResponse) => {
    });
  }
  

  onSearchInput(event: any) {
    const searchedValue = event.target.value;

    if (!searchedValue) {
      this.mainTransactionData = this.data.transactionDetails;
    }

    this.mainTransactionData = this.data.transactionDetails.filter(
      (item: any) => {
        return item.Status.toLocaleLowerCase().includes(searchedValue);
      }
    );
  }

  downloadPdf(pdfData: any) {
    const byteCharacters = atob(pdfData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);

    window.open(url);
  }

  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }
}
