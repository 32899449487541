import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { SentinelSharedModule } from "src/@sentinel/sentinel-shared.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutModule } from "./layout/layout.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthGuard } from "./guards/auth.guard";
import { SignalRService } from "./services/signalr.service";
import { TokenInterceptor } from "./services/token-interceptor.service";
import { DatePipe } from "@angular/common";

// material
import { MatNativeDateModule } from "@angular/material/core";
import { APIClientModule } from "api";
import { environment } from "src/environments/environment";
import {ApiVersionInterceptor} from "./services/api-version.interceptor";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    SentinelSharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    APIClientModule.forRoot({
      domain: environment.apiUrl,
    }),

    // material
    MatNativeDateModule,
  ],
  providers: [
    AuthGuard,
    SignalRService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiVersionInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
