import { Component } from "@angular/core";
import {
  ActivatedRoute,
  NavigationStart,
  Router,
} from "@angular/router";
import { AuthService } from "./authentication/auth.service";
import { ThemeSettingsService } from "./services/theme-settings.service";
import { HelperService } from "./services/helper.service";
import { BooleanString, DarkOrLight, DarkOrLightMode } from "shared/enums";
import { DEFAULT_SITE_NO } from "shared/constants";
import { Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  siteNo: string;

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private router: Router,
    private themeSettingsService: ThemeSettingsService,
    private titleService: Title
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        setTimeout(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }, 200);
      });
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (
          event?.url.includes("dashboard/rent-unit") &&
          !this.authService.isAuthenticated()
        ) {
          this.titleService.setTitle("Rent A Unit");
        } else {
          this.titleService.setTitle("Tenant Portal");
        }
      }
    });

    this.route.queryParams.subscribe((params: any) => {
      if (params.SiteNo) {
        this.siteNo = params.SiteNo;
        localStorage.setItem("SiteNo", this.siteNo);
      } else {
        this.siteNo = localStorage.getItem("SiteNo") || DEFAULT_SITE_NO;
        localStorage.setItem("SiteNo", this.siteNo);
      }
    });

    // Retrieve the preferred theme from local storage, if set
    const preferredTheme = this.helperService.getItemFromLocalStorage(
      DarkOrLightMode.DARK_MODE
    );
    if (preferredTheme) {
      this.themeSettingsService.setTheme(
        preferredTheme === BooleanString.FALSE
          ? DarkOrLight.LIGHT
          : DarkOrLight.DARK
      );
    } else {
      this.helperService.setItemToLocalStorage(
        DarkOrLightMode.DARK_MODE,
        false
      );
      this.themeSettingsService.setTheme(DarkOrLight.LIGHT);
    }
  }
}
