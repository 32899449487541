<div class="forgot-password box">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex align-items-center">
      <span class="title">Forgot password</span>
    </div>

    <img
      src="../../../assets/icons/rent-unit/close-rental-popup.svg"
      alt=""
      (click)="onCancel()"
      class="clickable"
    />
  </div>

  <form class="body-wrapper" [formGroup]="forgotPasswordForm">
    <span class="subtitle" *ngIf="!submitEmailSuccess"
      >In order to reset your password please enter your registered email
      address below.</span
    >

    <span class="subtitle" *ngIf="submitEmailSuccess">
      Instructions have been sent to your email address with a link to reset
      your password. If you don’t see the email, check your spam or junk folder
      before submitting a new request.
    </span>

    <div *ngIf="!submitEmailSuccess" class="d-flex flex-column mrb-24">
      <label for="firstName">Email</label>
      <input
        type="text"
        formControlName="Email"
        placeholder="Enter your email"
      />
    </div>
    <div class="login-btns">
      <button class="sentinel-btn sentinel-btn-primary d-flex" (click)="onSave()" [disabled]="loading">
        {{ submitEmailSuccess ? "Thank you" : "Reset password" }}
        <img class="loading-wrapper m-1" style="width: 10px; height: 10px;" 
          src="../../../assets/loaders/loader.gif" alt="loading" *ngIf="loading"/>
      </button>

      <span *ngIf="errorMessage && !submitEmailSuccess" class="error">{{
        errorMessage
      }}</span>
    </div>
  </form>
</div>
