<div class="row" *ngIf="insuranceDetails">


  <div class="col-xs-12 col-lg-4">
    <div class="insurance-box">
      <img src="../../../../assets/icons/home/insecured-status.svg" alt="" *ngIf="!(themeService.isDarkModeSubject | async)" />
      <img src="../../../../assets/icons/home/insecured-status-dark.svg" alt="" *ngIf="themeService.isDarkModeSubject | async" />
      <div class="d-flex flex-column">
        <span>Status</span>
        <span class="bold status" [ngClass]="insuranceDetails?.InsuranceStatus ==='Insured'?'insured':'not-isured' ">{{ insuranceDetails?.InsuranceStatus || '-'  }}</span>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-lg-4">
    <div class="insurance-box">
      <img src="../../../../assets/icons/home/carrier.svg" alt="" />
      <div class="d-flex flex-column">
        <span>Carrier</span>
        <span class="bold">{{ insuranceDetails?.CarrierName || '-' }}</span>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-lg-4">
    <div class="insurance-box">
      <img src="../../../../assets/icons/home/hashtag.svg" alt="" />
      <div class="d-flex flex-column">
        <span>Policy no</span>
        <span class="bold">{{ insuranceDetails?.InsPolicyNo || '-'  }}</span>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-lg-4">
    <div class="insurance-box">
      <img src="../../../../assets/icons/home/paid-to-date.svg" alt="" />
      <div class="d-flex flex-column">
        <span>Start date</span>
        <span class="bold">{{ (insuranceDetails?.InsStartDate | date) || '-'  }}</span>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-lg-4">
    <div class="insurance-box">
      <img src="../../../../assets/icons/home/paid-to-date.svg" alt="" />
      <div class="d-flex flex-column">
        <span>Paid to date</span>
        <span class="bold">{{ (insuranceDetails?.InsPaidtoDate | date) || '-'  }}</span>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-lg-4">
    <div class="insurance-box">
      <img src="../../../../assets/icons/home/percentage.svg" alt="" />
      <div class="d-flex flex-column">
        <span>Theft coverage</span>
        <span class="bold">{{ insuranceDetails?.InsTheftPercent || '0'  }}%</span>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-lg-4">
    <div class="insurance-box">
      <img src="../../../../assets/icons/home/unit-rate.svg" alt="" />
      <div class="d-flex flex-column">
        <span>Amount of coverage</span>
        <span class="bold"
          >{{ (insuranceDetails?.InsCoverage | currency) || '0'  }}</span
        >
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-lg-4">
    <div class="insurance-box">
      <img src="../../../../assets/icons/home/unit-rate.svg" alt="" />
      <div class="d-flex flex-column">
        <span>Monthly premium</span>
        <span class="bold"
          >{{ (insuranceDetails?.InsPremium | currency) || '0'  }}</span
        >
      </div>
    </div>
  </div>

</div>

<div class="col-12" *ngIf="!insuranceDetails">
  <div class="insurance-box d-flex flex-column align-items-center justify-content-center" style="padding: 80px 0;">
    <img width="30%" height="auto" src="../../../assets/icons/home/no-items-icon.svg" alt="">
    <h3>No insurance plan at this time</h3>
  </div>
</div>
