import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { HelperService } from "./helper.service";
import {BehaviorSubject, Observable, of} from "rxjs";
import { SiteNoService } from "./siteno.service";
import { APIClient, HttpOptions } from "api";
import { AuthService } from "../authentication/auth.service";
import {ResponseBalanceResponse, ResponseCustomerDashboardResponse} from "../../../api/models";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class HomeService {
  private cache: any = {};

  siteNo: string;
  userName: BehaviorSubject<string> = new BehaviorSubject<string>("");

  private pictureSubject = new BehaviorSubject<string | null>(null);
  picture$ = this.pictureSubject.asObservable();


  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router,
    private helperService: HelperService,
    private siteNoService: SiteNoService,
    private apiClient: APIClient
  ) {
    this.siteNoService.siteNo$.subscribe((siteNo: any) => {
      this.siteNo = siteNo;
    });
  }

  updatePicture(pictureBase64: string) {
    this.pictureSubject.next(pictureBase64);
  }

  getTenantList(filters: any, pageNumber?: number, pageSize?: number) {
    let body: any = {
      PageNumber: pageNumber || 1,
      PageSize: pageSize || 200,
    };

    let params = new HttpParams();
    let url = `${apiUrl}/customers/tenants`;

    if (filters?.TenantName) {
      params = params.append("TenantName", filters?.TenantName);
    }

    if (filters?.Sizes) {
      params = params.append("Sizes", filters.Sizes);
    }

    if (filters?.UnitStatuses) {
      params = params.append("UnitStatuses", filters.UnitStatuses);
    }

    if (filters?.LockStatus === 0 || filters?.LockStatus === 1) {
      params = params.append("LockStatus", filters.LockStatus);
    }

    params = params.append("PageNumber", body.PageNumber);
    params = params.append("PageSize", body.PageSize);

    params = params.append("SiteNo", this.siteNo);

    return this.http
      .get(url, {
        params,
        headers: this.getHttpHeaders(),
        withCredentials: true,
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getUnitList() {
    let url = `${apiUrl}//units/unitList?SiteNo=${this.siteNo}`;

    return this.http
      .get(url, { headers: this.getHttpHeaders(), withCredentials: true })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getCustomerDetails(): Observable<ResponseCustomerDashboardResponse | undefined> {
    const url = `${apiUrl}/customers/customerdashboard?SiteNo=${this.siteNo}`;

    return this.http
      .get<ResponseCustomerDashboardResponse>(url, { headers: this.getHttpHeaders(), withCredentials: true })
      .pipe(
        map((response) => {
          this.userName.next(response.Result!.Fullname!);

          if (response.ErrorMessage == "Unauthorized User") {
            this.authService.redirectToLogin();
            return;
          }

          return response!;
        })
      );
  }

  getCustomerDocuments(leaseId?: number) {
    let url = `${apiUrl}/documents/customerContracts/${leaseId}?SiteNo=${this.siteNo}`;

    return this.http
      .get(url, { headers: this.getHttpHeaders(), withCredentials: true })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getCustomerUnits(sortType?: string, sortDirection?: number) {
    const sortByType =
      sortType !== undefined
        ? `&Sorter.Value=${sortType}&Sorter.Direction=${sortDirection}`
        : "";

    const url = `${apiUrl}/units/unitcustomers?SiteNo=${this.siteNo}${sortByType}`;

    return this.http
      .get(url, { headers: this.getHttpHeaders(), withCredentials: true })
      .pipe(
        map((response: any) => {
          if (response.ErrorMessage == "Unauthorized User") {
            this.authService.redirectToLogin();
            return;
          }

          return response;
        })
      );
  }

  getUserProfilePicture() {
    const url = `${apiUrl}/customers/customerPictures?SiteNo=${this.siteNo}`;

    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getSizes() {
    const url = `${apiUrl}/sizes/?SiteNo=${this.siteNo}&IsTenantPortal=true`;

    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        if (response.ErrorMessage == "Unauthorized User") {
          this.authService.redirectToLogin();
          return;
        }
        return response;
      })
    );
  }

  getUnitDetails(id: number) {
    const url = `${apiUrl}/units/unitcustomerdetails/${id}?SiteNo=${this.siteNo}`;

    return this.http
      .get(url, { headers: this.getHttpHeaders(), withCredentials: true })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPaymentInfo(body: any) {
    const url = `${apiUrl}/customers/paymentInfo?SiteNo=${this.siteNo}`;

    return this.http.post(url, body, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  makePayment(body: any) {
    const url = `${apiUrl}/customers/payment?SiteNo=${this.siteNo}`;

    return this.http.post(url, body, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCreditCards(isGuestUser: boolean = false) {
    let url = `${apiUrl}/payment/creditCards?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getClearentConfigData(isGuestUser: boolean = false) {
    let url = `${apiUrl}/auth/clearentConfig/${1}?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveClearentCardToken(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/payment/saveClearentCardToken?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.post(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteCreditCard(payerId: string, isGuestUser: boolean = false) {
    let url = `${apiUrl}/payment/creditCards?SiteNo=${this.siteNo}&payerId=${payerId}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }
    return this.http.delete(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateUserSettings(body: any) {
    const url = `${apiUrl}/customers/updateCustomerSetting?SiteNo=${this.siteNo}`;

    return this.http.post(url, body, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCustomerSettings(customerNo: any) {
    const url = `${apiUrl}/customers?CustomerNo=${customerNo}&SiteNo=${this.siteNo}`;
    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCustomerPicture() {
    const url = `${apiUrl}/customers/customerPictures?SiteNo=${this.siteNo}`;
    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCountries(isGuestUser: boolean = false) {
    let url = `${apiUrl}/dictionary/countries?SiteNo=${this.siteNo}`;


    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getStatesByCountry(countryId: number, isGuestUser: boolean = false) {
    let url = `${apiUrl}/dictionary/states/${countryId}?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getHistoryTransactions(
    sortType?: string,
    sortDirection?: any
  ) {
    const sortByType =
      sortType !== undefined
        ? `&Sorter.Value=${sortType}&Sorter.Direction=${sortDirection}`
        : "";

    const url = `${apiUrl}/customers/transactionHistory?SiteNo=${this.siteNo}${sortByType}`;
    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getHistoryActivity(
    sortType?: string,
    sortDirection?: any,
    accessOnly?: boolean
  ) {
    const accessOnlyEvents = accessOnly
      ? `&AccessOnlyEvents=${accessOnly}`
      : "";
    const sortByType =
      sortType !== undefined
        ? `&Sorter.Value=${sortType}&Sorter.Direction=${sortDirection}`
        : "";

    const url = `${apiUrl}/customers/activities?SiteNo=${this.siteNo}${sortByType}${accessOnlyEvents}`;
    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  customersActivities(
    args: {
      apiVersion: string,  // The requested API version
      requestFromDate?: string,
      requestUnitNo?: string,
      requestAccessOnlyEvents?: boolean,
      requestPageNumber?: number,
      requestPageSize?: number,
      requestSorterValue?: string,
      requestSorterDirection?: '0' | '1',
    },
    requestHttpOptions?: HttpOptions
  ): Observable<any> {
  
    const path = `${apiUrl}/customers/activities`;
  
    let params = new HttpParams().set('apiVersion', String(args.apiVersion));

    if (args.requestFromDate) {
      params = params.set('request.fromDate', String(args.requestFromDate));
    }
  
    if (args.requestUnitNo) {
      params = params.set('request.unitNo', String(args.requestUnitNo));
    }
  
    if (args.requestAccessOnlyEvents !== undefined) {
      params = params.set('request.accessOnlyEvents', args.requestAccessOnlyEvents ? 'true' : 'false');
    }
  
    if (args.requestPageNumber) {
      params = params.set('request.pageNumber', String(args.requestPageNumber));
    }
  
    if (args.requestPageSize) {
      params = params.set('request.pageSize', String(args.requestPageSize));
    }
  
    if (args.requestSorterValue) {
      params = params.set('request.sorter.value', String(args.requestSorterValue));
    }
  
    if (args.requestSorterDirection) {
      params = params.set('request.sorter.direction', String(args.requestSorterDirection));
    }
  
    const options: any = {
      ...requestHttpOptions,
      params
    };
  
    return this.http.get<any>(path, options).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getTransactionInvoice(transId: number) {
    const url = `${apiUrl}/documents/receipt/${transId}?SiteNo=${this.siteNo}`;

    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getSignalRConfiguration() {
    return this.apiClient
      .gateAccessGetSignalRConfiguration(this.getRequestHttpOptions())
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  loadClosestGateData(
    lat: number,
    lon: number,
    keypadIds: string[]
  ) {
    return this.apiClient
      .gateAccessGetClosestGate(
        {
          model: {
            Latitude: lat?.toString(),
            Longtitude: lon?.toString(),
            ActiveKeypadIds: keypadIds,
          },
        },
        this.getRequestHttpOptions()
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getGateAccessData() {
    return this.apiClient
      .gateAccessGetGateAccessData(this.getRequestHttpOptions())
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getSiteDetail(isGuestUser: boolean = false, siteConfigId: string = '') {
    let url = `${apiUrl}/site/siteDetail/?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    if (siteConfigId) {
      url += `&siteConfigId=${siteConfigId}`;
    }

    if (this.cache[url]) {
      return of(this.cache[url]);
    }

    return this.http
      .get(url, {
        headers: isGuestUser
          ? this.getHttpHeadersNoBearer()
          : this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => {
          this.cache[url] = data;
        })
      );
  }

  getSmsCarriers(countryId: number) {
    const url = `${apiUrl}/dictionary/smsCarriers/${countryId}?SiteNo=${this.siteNo}`;
    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateTenant(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/customers?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.put(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  secondaryTenantSave(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/customers?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.post(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateSecondaryTenant(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/customers?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.put(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getSecondaryTenants(isGuestUser: boolean = false) {
    let url = `${apiUrl}/customers/secondaryCustomer?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http
      .get(url, {
        headers: isGuestUser
          ? this.getHttpHeadersNoBearer()
          : this.getHttpHeaders()
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getHttpHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }).set("Content-Type", "application/json");
  }

  getHttpHeadersNoBearer() {
    return new HttpHeaders().set("Content-Type", "application/json");
  }

  getRequestHttpOptions(): HttpOptions {
    return {
      headers: this.getHttpHeaders(),
      params: new HttpParams().append("SiteNo", this.siteNo)
    };
  }

  getGuestRequestHttpOptions() {
    return {
      params: new HttpParams()
        .append("SiteNo", this.siteNo)
        .append("IsGuestUser", true)
    };
  }
}
