<div class="preview-items box">
    <div class="head-wrapper border-btm justify-content-between">
      <div class="d-flex align-items-center">
        <img src="../../../assets/icons/rent-unit/transaction-details.svg" alt="" />
        <span class="title">Transaction Completed</span>
      </div>
  
      <img *ngIf="!isConfiramtionScreen" src="../../../assets/icons/rent-unit/close-rental-popup.svg" alt="" (click)="onCancel()" class="clickable" />
    </div>
  
    <div class="body-wrapper" *ngIf="!data?.isFinanceSection">
      <div class="search-input">
        <div class="input-container">
          <input (input)="onSearchInput($event)" type="text" placeholder="Search..." />
        </div>
  
        <mat-menu #menuItem="matMenu" yPosition="below" xPosition="before" class="popup-menu-panel">
        </mat-menu>
      </div>
  
      <div class="unit-details">
        <div class="unit-details__head col-12">
          <span class="col-2" style="padding-left: 0">DESCRIPTION </span>
          <span class="col-2">UNIT NO </span>
          <span class="col-2">RATE </span>
          <span class="col-2">BALANCE DUE </span>
          <span class="col-2">MONTH TO PAY </span>
          <span class="col-2">TOTAL </span>
        </div>
  
        <div class="unit-details__body col-12" *ngFor="let item of mainTransactionData">
          <span style="padding-left: 10px" class="col-2">{{ item?.Status }}</span>
          <span class="col-2">{{ item?.UnitNo }}</span>
          <span class="col-2">{{ (item?.UnitRate | currency) }}</span>
          <span class="col-2">{{ (item?.BalanceDue | currency) }}</span>
          <span class="col-2">{{ item?.monthsToPay }}</span>
          <span class="col-2">{{ (item?.UnitRate | currency) }}</span>
        </div>
      </div>
  
      <div class="unit-details-mobile">
        <div class="unit-details__head col-12">
          <span style="padding-left: 10px" class="col-3">DESCRIPTION </span>
          <span class="col-2">UNIT NO </span>
          <span class="col-2">RATE </span>
          <span class="col-3">BALANCE DUE </span>
          <span class="col-2">TOTAL </span>
        </div>
  
        <div class="unit-details__body col-12" *ngFor="let item of mainTransactionData">
          <span style="padding-left: 10px" class="col-3">{{ item?.Status }}</span>
          <span class="col-2">{{ item?.UnitNo }}</span>
          <span class="col-2">{{ (item?.UnitRate | currency) }}</span>
          <span class="col-3">{{ (item?.BalanceDue | currency) }}</span>
          <span class="col-2">{{ (item?.UnitRate | currency) }}</span>
        </div>
      </div>
    </div>
  
    <div class="body-wrapper" *ngIf="data?.isFinanceSection" [ngClass]="isConfiramtionScreen?'body-wrapper body-wrapper-with-footer':'body-wrapper'">
      <div class="d-flex justify-content-between finance-transaction-details">
        <div class="billing-info">
          <div class="d-flex flex-column">
            <span class="title">Billing info</span>
            <span class="info">{{
              this.data?.transactionCustomer?.FirstName +
              " " +
              this.data?.transactionCustomer?.LastName
              }}</span>
  
            <span class="info">Phone: {{ this.data?.transactionCustomer?.HomePhone }}</span>
            <span class="info">{{ this.data?.transactionCustomer?.Address1 }}{{ this.data?.transactionCustomer?.City ? ', ':
              '' }} {{ this.data?.transactionCustomer?.City }}{{ this.data?.transactionCustomer?.State ? ', ': '' }} {{
                this.data?.transactionCustomer?.State }} {{ this.data?.transactionCustomer?.ZipCode }}</span>
          </div>
        </div>
    </div>
  
    <div class="d-flex justify-content-between finance-transaction-details mt-4">
      <div class="transaction-summary">
        <div class="d-flex flex-column">
          <span class="title">Transaction summary</span>
  
          <div class="body-wrapper">
            <div class="row mrb-16" *ngFor="let item of accountData">
              <div class="col-8 description">{{ item?.Description || '-' }}</div>
              <div class="col-4 amount">{{ (item?.Amount | currency) || '0$'}}</div>
            </div>
  
            <div class="row">
              <div class="col-6 bold-title">Total:</div>
              <div class="col-6 bold-title amount">{{ (totalDue | currency) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="d-flex justify-content-between finance-transaction-details mt-4">
      <div class="" style="height: 80px;">
        <div class="d-flex flex-column">
          <span class="title">Payment</span>
  
          <div class="">
  
            <div class="row">
              <div class="card-box" style=" height: fit-content; flex-direction: column;">
                <div class="d-flex">
                  <div class="card-box__info" *ngIf="this.data.requestedData"
                    style=" margin-left: 0;">
                    <span class="description">Paid by 
                    </span>
                    <span  class="description" *ngIf="this.data.requestedData?.CashPaymentAmount">Cash - {{(this.data.requestedData.CashPaymentAmount | currency) || '$0.00'}}</span>
                    <span  class="description" *ngIf="this.data.requestedData?.CheckPaymentAmount">Check #{{this.data.requestedData.CheckNo}} - {{(this.data.requestedData.CheckPaymentAmount | currency) || '$0.00'}}</span>
                    <span  class="description" *ngIf="this.data.requestedData?.AchPaymentAmount">Ach,  Bank Account No {{this.data.requestedData.BankAccountNo || ''}}, Bank Routing No {{this.data.requestedData.BankRoutingNo || ''}} - {{(this.data.requestedData.AchPaymentAmount | currency) || '$0.00'}}</span>
                    <span  class="description" *ngIf="this.data.requestedData?.CreditCardPaymentAmount">Card,  {{ this.data?.transactionCustomer?.CreditCardType || 'Visa'}} -
                      {{ this.data?.transactionCustomer?.CreditCardNo }} -  {{(this.data.requestedData.CreditCardPaymentAmount | currency) || '$0.00' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end p-3 modal-footer" *ngIf="isConfiramtionScreen">
    <button class="sentinel-btn sentinel-btn-transparent me-2" (click)="onCancel()">Back to
      dashboard</button>
    <button class="sentinel-btn sentinel-btn-primary" (click)="onPrintInvoice()" [disabled]="!this.data.TransactionId">View Receipt
    </button>
  </div>