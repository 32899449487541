<div class="preview-items box">
    <div class="body-wrapper" [ngClass]="{'disable-section': loading}">
        <div class="row" *ngIf="loading" class="loading-wrapper">
            <img src="../../../assets/loaders/loader.gif" alt="" />
        </div>

        <div class="box box-list">
            <pdf-viewer *ngIf="dataUri" [src]="dataUri" [render-text]="true" [original-size]="false">
            </pdf-viewer>

            <div *ngIf="!dataUri && !loading" class="no-document-message">
                Document could not be generated.
            </div>
            <div class="d-flex justify-content-end">
                <button *ngIf="dataUri && !signedAgreement && !selectedDocumentToSign.IsSigned"
                    class="sentinel-btn sentinel-btn-primary mx-0" style="width: fit-content;" (click)="eSignAgreement()">
                    E-sign agreement
                </button>
            </div>
        </div>
    </div>
</div>