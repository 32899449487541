import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { HelperService } from "../services/helper.service";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { SiteNoService } from "../services/siteno.service";
import { SITE_NO, TOKEN } from "shared/constants";
import { Router } from "@angular/router";

const helper = new JwtHelperService();
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  siteNo: string;

  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private siteNoService: SiteNoService,
    private router: Router
  ) {
    this.siteNoService.siteNo$.subscribe((siteNo: string) => {
      this.siteNo = siteNo;
    });
  }

  isAuthenticated(): boolean {
    const token: string | any = this.getToken();

    if (helper.isTokenExpired(token)) {
      this.helperService.clearLocalStorage();
    }

    return !helper.isTokenExpired(token);
  }

  getToken() {
    if (this.helperService.isLocalStorageAvailable()) {
      return localStorage.getItem(TOKEN);
    }
    return;
  }

  getSiteNo() {
    if (this.helperService.isLocalStorageAvailable()) {
      return localStorage.getItem(SITE_NO);
    }
    return;
  }

  signIn(body: any) {
    const url = `${apiUrl}/auth/customerlogin?SiteNo=${this.siteNo}`;

    return this.http
      .post(url, body, {
        headers: this.getHttpHeaders(),
        withCredentials: true,
      })
      .pipe(
        map((response: any) => {
          this.helperService.setItemToLocalStorage(
            TOKEN,
            response?.Result?.Token
          );
          return response;
        })
      );
  }

  forgotPassword(body: any) {
    const url = `${apiUrl}/auth/forgetpassword?SiteNo=${this.siteNo}`;

    return this.http.post(url, body, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  resetPassword(body: any) {
    const url = `${apiUrl}/auth/resetpassword?SiteNo=${this.siteNo}`;

    return this.http.post(url, body, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  redirectToLogin() {
    const currentUrl = window.location.href;
    const queryString = currentUrl.split("?")[1];

    const queryParams: any = {};
    if (queryString) {
      const paramPairs = queryString.split("&");
      paramPairs.forEach((paramPair) => {
        const [key, value] = paramPair.split("=");
        if (key && value) {
          queryParams[key] = decodeURIComponent(value);
        }
      });
    }

    this.router.navigate(["/login"], { queryParams });
  }

  getHttpHeaders() {
    return new HttpHeaders().set("Content-Type", "application/json");
  }
}
