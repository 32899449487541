import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Weekends, WorkingDays } from "shared/enums";
import { HoursModalType } from "shared/models/hours-modal.model";

@Component({
  selector: 'app-hours-modal',
  templateUrl: './hours-modal.component.html',
  styleUrls: ['./hours-modal.component.scss']
})
export class HoursModalComponent implements OnInit {
  workingDays = [WorkingDays.MONDAY, WorkingDays.TUESDAY, WorkingDays.WEDNESDAY, WorkingDays.THURSDAY, WorkingDays.FRIDAY];
  Weekends = Weekends;
  siteDetials: any;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: HoursModalType,
    private dialogRef: MatDialogRef<HoursModalComponent>,
  ) { }

  ngOnInit(): void {
    this.siteDetials = this.data.siteDetails;
  }

  onCancel() {
    this.dialogRef.close();
  }
}
