<div class="confirmation-dialog">
  <div class="header">
    <img src="../../../assets/icons/popup-{{ data?.icon }}.svg" alt="" *ngIf="!(themeService.isDarkModeSubject | async)" />
    <img src="../../../assets/icons/popup-{{ data?.icon }}-dark.svg" alt="" *ngIf="(themeService.isDarkModeSubject | async)"/>
    <img
      (click)="close(null)"
      src="../../../assets/icons/close-icon.svg"
      alt=""
      class="close-icon"
    />
  </div>

  <div class="body">
    <h5 class="title-text">{{ data?.title }}</h5>

    <span class="text-center" *ngIf="data?.description">{{ data?.description }}</span>
    <span class="text-center" *ngIf="data?.formatDescription" [innerHTML]="data?.formatDescription"></span>

    <button
      class="sentinel-btn {{ data?.button?.className }}"
      (click)="close(data)"
    >
      {{ data?.button?.text }}
    </button>

    <button
      (click)="close(null)"
      class="sentinel-btn sentinel-btn-cancel"
      [ngStyle]="{'border-color' : !(themeService.isDarkModeSubject | async) ? 'none' : '#8e8e93'}"
      *ngIf="data.hasCancelBtn"
    >
    {{ data?.cancelButton?.text || 'Cancel' }}
    </button>
  </div>
</div>
