import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { HelperService } from "src/app/services/helper.service";
import { MobileSideNavComponent } from "src/app/layout/mobile-side-nav/mobile-side-nav.component";
import { OpenGateModalComponent } from "src/@sentinel/shared/open-gate-modal/open-gate-modal.component";
import { RentUnitService } from "src/app/services/rent-unit.service";
import { SignalRService } from "src/app/services/signalr.service";
import { HomeService } from "src/app/services/home.service";
import { SignalRConfigurationModel } from "api/models";
import { AuthService } from "src/app/authentication/auth.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-mobile-footer",
  templateUrl: "./mobile-footer.component.html",
  styleUrls: ["./mobile-footer.component.scss"],
})
export class MobileFooterComponent {
  siteConfigId: SignalRConfigurationModel;
  isSentinelOnly = false;
  signalRConnected = false;
  siteConfigIdParam: string = "";

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private helperService: HelperService,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private signalRService: SignalRService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: {[key: string]: string}) => {
      params?.SiteConfigId && (this.siteConfigIdParam = params.SiteConfigId);
    });

    const siteConfigId = this.siteConfigIdParam || localStorage.getItem('SiteConfigId') || '';
    this.homeService.getSiteDetail(true, siteConfigId).subscribe((siteData) => {
      this.isSentinelOnly = siteData.Result.SentinelOnly;
    });
    this.authService.isAuthenticated() && this.getSiteConfigId();
  }

  getSiteConfigId() {
    // TODO home service
    this.homeService.getSignalRConfiguration().subscribe((data) => {
      this.siteConfigId = data.Result!;
    });
  }

  isLoggedIn() {
    if (this.helperService.isLocalStorageAvailable()) {
      return localStorage.getItem("CustomerId");
    }
    return;
  }

  openLeftSideNav() {
    const modal = this.dialog.open(MobileSideNavComponent, {
      autoFocus: false,
      // position: {
      //   bottom: "0px",
      //   left: "0px",
      // },
      // width: "100%",
      height: "100%",
    });
  }

  openGate() {
    if (!this.signalRConnected) {
      this.signalRService.initConnection(this.siteConfigId);
      this.signalRConnected = true;
    }
    const modal = this.dialog.open(OpenGateModalComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: "forgot-password-modal",
      position: {
        bottom: "0px",
      },
      width: "100%",
    });

    modal.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      modal.componentInstance.onModalClosed();
    });
  }
}
