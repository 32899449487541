import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { LeaseStatuses, UnitDetailsTabs } from "shared/enums";
import { HelperService } from "src/app/services/helper.service";
import { HomeService } from "src/app/services/home.service";
import { ThemeSettingsService } from "src/app/services/theme-settings.service";
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: "app-unit-details",
  templateUrl: "./unit-details.component.html",
  styleUrls: ["./unit-details.component.scss"],
})
export class UnitDetailsComponent {
  UnitDetailsTabs = UnitDetailsTabs;
  tabs: UnitDetailsTabs[] = [];
  selectedTab = UnitDetailsTabs.UNIT_DETAILS;
  leaseStatuses = LeaseStatuses;
  unitDetails: any;
  insuranceDetails: any;
  customers: any;
  customerId: number;
  loading = false;
  invalidDate = "0001-01-01T00:00:00";
  isMobileScreen = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<UnitDetailsComponent>,
    private homeService: HomeService,
    private helperService: HelperService,
    private router: Router,
    public themeService: ThemeSettingsService,
    private snackBar: MatSnackBar,
  ) {
    const id = data.id;
    this.customerId = this.helperService.getItemFromLocalStorage("CustomerId");

    this.getUnitDetails(id);

    this.isMobileScreen = breakpointObserver.isMatched('(max-width: 1023px)');

    if (breakpointObserver.isMatched('(max-width: 768px)')) {
      this.tabs = [UnitDetailsTabs.UNIT_DETAILS, UnitDetailsTabs.SECONDARY_TENANTS_SHORT, UnitDetailsTabs.INSURANCE];
    } else {
      this.tabs = [UnitDetailsTabs.UNIT_DETAILS, UnitDetailsTabs.SECONDARY_TENANTS, UnitDetailsTabs.INSURANCE];
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSelectTab(tab: UnitDetailsTabs) {
    this.selectedTab = tab;
  }

  getUnitDetails(id: number) {
    this.loading = true;
    this.homeService.getUnitDetails(id).subscribe((data: any) => {
      this.loading = false;
      this.customers = data.Result.SecondaryTenants;
      this.insuranceDetails = data.Result.InsuranceModel;
      this.unitDetails = data.Result.UnitCustomerDetail;

      if (this.data?.leaseStatus == this.leaseStatuses.RENT || this.data?.leaseStatus == this.leaseStatuses.COMPLIMENTARY || 
        this.data?.leaseStatus == this.leaseStatuses.DELINQUENT || this.data?.leaseStatus == this.leaseStatuses.IN_LIEN_PROCESS || this.data?.leaseStatus == this.leaseStatuses.LIEN_COMPLETED) {
        this.tabs = [...this.tabs, UnitDetailsTabs.AGREEMENT];
      }
    }, error => {
      this.loading = false;
      this.snackBar.open(`Error: ${error?.error?.ErrorMessage}`, 'Close', { duration: 3000 });
      console.error('Error fetching unit details:', error);
    });
  }

  navigateToPayments() {
    this.router.navigate(["/dashboard/payments"]);
    this.onCancel();
  }
}
