<div class="pagination-container">
  <div *ngIf="!isSmallScreen">
    <span>Showing</span>
    <select [(ngModel)]="pageSize" (change)="pageSizeChange()">
      <option *ngFor="let size of pageSizes">{{ size }}</option>
    </select>
    of <strong style="margin-left: 5px">{{ totalCount }}</strong>
  </div>

  <div class="d-flex">
    <a *ngIf="hasPreviousPage" (click)="previousPage()">&laquo;</a>
    <a *ngIf="pageNumber > 5" (click)="goToPage(1)">1</a>
    <a *ngIf="pageNumber > 5" (click)="goToPage(pageNumber - 4)"> ... </a>
    <span *ngFor="let page of pages">
      <a
        [ngClass]="{ active: pageNumber == page }"
        *ngIf="pageNumber !== page"
        (click)="goToPage(page)"
      >
        {{ page }}
      </a>
      <span
        [ngClass]="{ active: pageNumber == page }"
        *ngIf="pageNumber === page"
        >{{ page }}</span
      >
    </span>
    <a *ngIf="pageNumber < totalPages - 4" (click)="goToPage(pageNumber + 4)">
      ...
    </a>
    <a *ngIf="pageNumber < totalPages - 4" (click)="goToPage(totalPages)">
      {{ totalPages }}
    </a>
    <a *ngIf="hasNextPage" (click)="nextPage()">&raquo;</a>
  </div>
</div>
