<div class="payment-form-container box">
  <div class="head-wrapper border-btm justify-content-between">
    <div class="d-flex align-items-center">
      <img class="card-icon" src="../../../assets/icons/rent-unit/card-info.svg" alt="" />
      <span class="title">Enter card information below</span>
    </div>

    <img src="../../../assets/icons/rent-unit/close-rental-popup.svg" alt="" (click)="onCancel()" class="clickable" />
  </div>

  <div class="body-wrapper">
    <div class="card-types">
      <img src="../../../assets/images/credit-cards/cc-0.svg" alt="" />
      <img src="../../../assets/images/credit-cards/cc-1.svg" alt="" />
      <img src="../../../assets/images/credit-cards/cc-2.svg" alt="" />
      <img src="../../../assets/images/credit-cards/cc-3.svg" alt="" />
      <img src="../../../assets/images/credit-cards/cc-4.svg" alt="" />
      <img src="../../../assets/images/credit-cards/cc-5.svg" alt="" />
    </div>

    <div id="payment-form" style="position: relative;">
      <img class="loading-wrapper m-1" style="width: 100px; height: 100px" src="../../../assets/loaders/loader.gif"
        alt="loading" *ngIf="!isPaymentFormLoaded" />
    </div>
    <div *ngIf="isPaymentFormErrorAppeared" class="error-message">*Error in loading the payment form.</div>

    <form [formGroup]="billingInfoForm" class="billing-address">
      <h6 class="col-12">Billing address</h6>
      <div class="input-wrapper col-12">
        <div [ngStyle]="{'padding-right' : isSmallScreen ? '0' : '5px'}"
          [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}">
          <input formControlName="firstName" type="text" placeholder="First name on card" class="mrb-16 w-100 disabled-input"/>
        </div>
        <div [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}"
          [ngStyle]="{'padding-left' : isSmallScreen ? '0' : '5px'}">
          <input formControlName="lastName" type="text" placeholder="Last name on card" class="mrb-16 w-100 disabled-input"/>
        </div>
      </div>
      <div class="col-12">
        <input formControlName="address1" class="mrb-16 w-100" type="text" placeholder="Address" [ngClass]="{'error-message': !billingInfoForm.controls.address1.valid && 
          billingInfoForm.controls.address1.touched || (!billingInfoForm.controls.address1.valid && submitAttempt)}" />
      </div>

      <div class="input-wrapper col-12">
        <div [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}"
          [ngStyle]="{'padding-right' : isSmallScreen ? '0' : '5px'}">
          <input formControlName="city" class="mrb-16 w-100" type="text" placeholder="City"
            [ngClass]="{'error-message': !billingInfoForm.controls.city.valid && 
                            billingInfoForm.controls.city.touched || (!billingInfoForm.controls.city.valid && submitAttempt)}" />
        </div>

        <div [ngStyle]="{'padding-left' : isSmallScreen ? '0' : '5px'}"
          [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}" (change)="getStates($event)">
          <select class="items-select" formControlName="country" style="margin-bottom: 10px;"
            [ngClass]="{'error-message': !billingInfoForm.controls.country.valid && submitAttempt}">
            <option [value]="0" [disabled]="true">Select country</option>
            <option *ngFor="let item of countryList" [value]="item?.CounrtyID">
              {{ item?.Name }}
            </option>
          </select>
        </div>
      </div>

      <div class="input-wrapper col-12" style="row-gap: 10px;">
        <div [ngStyle]="{'padding-right' : isSmallScreen ? '0' : '5px'}"
          [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}">
          <select class="items-select" formControlName="state" [ngClass]="{'error-message': !billingInfoForm.controls.state.valid && 
                  billingInfoForm.controls.state.touched || (!billingInfoForm.controls.state.valid && submitAttempt)}">
            <option value="" [disabled]="true">Select state</option>
            <option *ngFor="let item of statesList" [value]="item?.StateID">
              {{ item?.Name }}
            </option>
          </select>
        </div>

        <div [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}"
          [ngStyle]="{'padding-left' : isSmallScreen ? '0' : '5px'}">
          <input class="mrb-16 w-100" style="height: 42px;" formControlName="zipCode" type="text" placeholder="Zip Code"
            [ngClass]="{'error-message': !billingInfoForm.controls.zipCode.valid && 
                  billingInfoForm.controls.zipCode.touched || (!billingInfoForm.controls.zipCode.valid && submitAttempt)}" />
        </div>
      </div>
    </form>

    <div class="action-btns">
      <button class="sentinel-btn sentinel-btn-transparent mrr-16" (click)="onCancel()" [disabled]="savingCreditCard">
        Cancel
      </button>
      <button class="sentinel-btn sentinel-btn-primary" (click)="onSubmit()"
        [disabled]="savingCreditCard || !isPaymentFormLoaded">
        Save Card
        <img class="save-loading-wrapper" style="width: 10px; height: 10px" src="../../../assets/loaders/loader.gif"
          alt="loading" *ngIf="savingCreditCard" />
      </button>
    </div>
  </div>
</div>