import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { GetCustomerContractResponse, FileResponse } from 'api/models';
import { HomeService } from 'src/app/services/home.service';
import { AgreementModalComponent } from './agreement-modal/agreement-modal.component';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent {
  agreementDocuments: GetCustomerContractResponse[] | undefined;
  customerId: string;
  customerFullName: string;
  dataUri: string;
  rentalAgreementPdf: FileResponse;
  signedAgreement = false;

  // loaders
  loading = false;

  constructor(
    private dialog: MatDialog,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {

  }

  ngOnInit(): void {
    this.getCustomerDocuments();

    this.homeService.userName.subscribe(
      data => this.customerFullName = data
    );
  }

  getCustomerDocuments() {
    this.loading = true;

    this.homeService.getCustomerDocuments(0).subscribe(
      (data) => {
        this.loading = false;
        this.agreementDocuments = data?.Result;
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.snackBar.open(`Error: ${error?.error?.ErrorMessage}`, 'Close', { duration: 4000 })
      }
    );
  }

  getTheAgreement(item: GetCustomerContractResponse) {
    const modal = this.dialog.open(AgreementModalComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: "common-modal",
      data: {
        selectedDocument: item,
        customerId: this.customerId
      },
      position: {
        bottom: "0px",
        right: "0px",
      },
      height: "100vh",
      width: "50%",
    });

    modal.afterClosed().subscribe((data: any) => {
      this.getCustomerDocuments();
    });
  }
}
