import { Component, Inject } from "@angular/core";

import { FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/authentication/auth.service";
import { ThemeSettingsService } from "src/app/services/theme-settings.service";

@Component({
  selector: "app-make-a-payment-details",
  templateUrl: "./make-a-payment-details.component.html",
  styleUrls: ["./make-a-payment-details.component.scss"],
})
export class MakeAPaymentDetailsComponent {
  mainTransactionData: any;

  totalAmount: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MakeAPaymentDetailsComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    public themeService: ThemeSettingsService
  ) {
    this.mainTransactionData = this.data?.transactionDetails.filter((item: any) => item?.isChecked);
    this.mainTransactionData = this.mainTransactionData.reduce((acc: any, cur: any) => {
      const details = cur.Details;
      details.forEach((detail: any) => {
        const accountNo = detail.AccountNo;
        const amount = detail.Amount;

        acc.push({
          AccountNo: accountNo,
          Description: detail.Description,
          Amount: amount,
          UnitNo: cur?.UnitNo,
          monthsToPay: cur?.monthsToPay,
          BalanceDue: cur?.BalanceDue,
          UnitRate: cur?.UnitRate
        });
      });
      return acc;
    }, []);

    this.totalAmount = this.mainTransactionData?.reduce((curr: any, acc: any) => {
      return curr + acc.Amount
    }, 0);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSearchInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchedValue = inputElement.value;

    if (!searchedValue) {
      this.mainTransactionData = this.data.transactionDetails;
    }

    this.mainTransactionData = this.data.transactionDetails.filter(
      (item: any) => {
        return item.Status.toLocaleLowerCase().includes(searchedValue);
      }
    );
  }
}
