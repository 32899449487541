import { Injectable } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SiteNoService {
  private siteNoSubject: BehaviorSubject<any> = new BehaviorSubject<any>("");
  siteNo$ = this.siteNoSubject.asObservable();

  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const queryParams = this.route.snapshot.queryParams;
        const siteNo = queryParams["SiteNo"] || localStorage.getItem("SiteNo");

        if (siteNo) {
          this.siteNoSubject.next(siteNo);
        }
      });
  }

  getSiteNo(): string {
    return this.siteNoSubject.value;
  }
}
