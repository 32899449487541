import { Component, Input } from "@angular/core";
import { ThemeSettingsService } from "src/app/services/theme-settings.service";

@Component({
  selector: "app-insurance",
  templateUrl: "./insurance.component.html",
  styleUrls: ["./insurance.component.scss"],
})
export class InsuranceComponent {
  @Input() insuranceDetails: any;

  constructor(public themeService: ThemeSettingsService) {}
}
