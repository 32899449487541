import {inject} from "@angular/core";
import {
    CanActivateChildFn,
    CanActivateFn
} from "@angular/router";
import {AuthService} from "../authentication/auth.service";

export const canActivate: CanActivateFn = () => {
    const authService = inject(AuthService);
    if (!authService.isAuthenticated()) {
        authService.redirectToLogin();
    }
    return true;
}

export const canActivateChild: CanActivateChildFn = () => {
    const authService = inject(AuthService);
    if (!authService.isAuthenticated()) {
        authService.redirectToLogin();
    }
    return true;
}