import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { SiteNoService } from "./siteno.service";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class RentUnitService {
  siteNo: string;

  constructor(
    private http: HttpClient,
    private siteNoService: SiteNoService
  ) {
    this.siteNoService.siteNo$.subscribe((siteNo: any) => {
      this.siteNo = siteNo;
    });
  }

  getRentUnits(
    sizeType?: any,
    pageNumber?: number,
    pageSize?: number,
    promoId?: number,
    sortBy?: string,
    siteConfigId?: string
  ) {
    let body: any = {
      PageNumber: pageNumber || 1,
      PageSize: pageSize || 5,
      sizeType: sizeType || 0,
    };

    let params = new HttpParams();
    const promoFilter =
      promoId !== undefined && promoId !== null ? `&PromoID=${promoId}` : "";

    const sortByFilter = sortBy !== undefined ? sortBy : "";

    const url = `${apiUrl}/units/vacant?SiteNo=${this.siteNo}&PageNumber=${body.PageNumber}&PageSize=${body.PageSize}&SizeType=${body.sizeType}${promoFilter}${sortByFilter}`;

    if (siteConfigId) {
      params = params.append('SiteConfigId', siteConfigId);
    }

    return this.http.get(url, { params, headers: this.getHttpHeaders() });
  }

  getGuestRentUnits(
    sizeType?: any,
    pageNumber?: number,
    pageSize?: number,
    promoId?: number,
    sortBy?: string,
    siteConfigId?: string
  ) {
    let body: any = {
      PageNumber: pageNumber || 1,
      PageSize: pageSize || 5,
      sizeType: sizeType || 0,
    };

    let params = new HttpParams();
    const promoFilter =
      promoId !== undefined && promoId !== null ? `&PromoID=${promoId}` : "";

    const sortByFilter = sortBy !== undefined ? sortBy : "";

    const url = `${apiUrl}/units/vacant?SiteNo=${this.siteNo}&PageNumber=${body.PageNumber}&PageSize=${body.PageSize}&SizeType=${body.sizeType}${promoFilter}${sortByFilter}&isGuestUser=true`;

    if (siteConfigId) {
      params = params.append('SiteConfigId', siteConfigId);
    }
    return this.http.get(url, { params });
  }

  getRentUnitDetails(sizeId?: any, siteConfigId?: string) {
    let params = new HttpParams();

    const url = `${apiUrl}/units/vacant?SiteNo=${
      this.siteNo
    }&PageNumber=1&PageSize=1&SizeID=${sizeId}`;

    if (siteConfigId) {
      params = params.append('SiteConfigId', siteConfigId);
    }
    return this.http.get(url, { params, headers: this.getHttpHeaders() });
  }

  getGuestRentUnitDetails(sizeId?: any, siteConfigId?: string) {
    let params = new HttpParams();

    const url = `${apiUrl}/units/vacant?isGuestUser=true&SiteNo=${
      this.siteNo
    }&PageNumber=1&PageSize=1&SizeID=${sizeId}`;

    if (siteConfigId) {
      params = params.append('SiteConfigId', siteConfigId);
    }
    return this.http.get(url, { params });
  }

  getInsuranceConfig() {
    const id = 1;

    const url = `${apiUrl}/site/insuranceConfig/${id}?SiteNo=${this.siteNo}`;

    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getGuesstInsuranceConfig() {
    const id = 1;

    const url = `${apiUrl}/site/insuranceConfig/${id}?isGuestUser=true&SiteNo=${this.siteNo}`;

    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }


  getPromos() {
    const url = `${apiUrl}/units/promos?SiteNo=${this.siteNo}`;

    return this.http.get(url, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getUnitTypes(siteConfigId?: string) {
    let params = new HttpParams();
    const url = `${apiUrl}/units/unitTypes/true?SiteNo=${this.siteNo}`;

    if (siteConfigId) {
      params = params.append('SiteConfigId', siteConfigId);
    }

    return this.http.get(url, { params, headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getGuestUnitTypes(siteConfigId?: string) {
    let params = new HttpParams();
    const url = `${apiUrl}/units/unitTypes/true?isGuestUser=true&SiteNo=${this.siteNo}`;

    if (siteConfigId) {
      params = params.append('SiteConfigId', siteConfigId);
    }

    return this.http.get(url, { params }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getRentalAgreement(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/documents/rentalAgreement?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.post(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  postSignature(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/documents/postsignature?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.post(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  moveInCost(body: any) {
    const url = `${apiUrl}/units/moveInCost?SiteNo=${this.siteNo}`;
    return this.http.post(url, body, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  moveInGuestCost(body: any, unitId?: string) {
    let url = `${apiUrl}/units/moveInCost?isGuestUser=true&SiteNo=${this.siteNo}`;
    if(unitId){
      url+= `&UnitID=${unitId}`;
    }
    return this.http.post(url, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  moveIn(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/units/moveIn?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.post(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  reservecost(body: any) {
    const url = `${apiUrl}/units/reserveCost?SiteNo=${this.siteNo}`;

    return this.http.post(url, body, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  reserveGuestCost(body: any) {
    const url = `${apiUrl}/units/reserveCost?isGuestUser=true&SiteNo=${this.siteNo}`;

    return this.http.post(url, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  reserve(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/units/reserve?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.post(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  reserveGuest(body: any) {
    const url = `${apiUrl}/units/reserve?isGuestUser=true&SiteNo=${this.siteNo}`;

    return this.http.post(url, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  reservationContract(transId: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/documents/reservationContract/${transId}?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  tenantLogin(body: any) {
    const url = `${apiUrl}/customers/accountInfo?SiteNo=${this.siteNo}`;

    return this.http.post(url, body, { headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCustomerDetails(CustomerID: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/customers/${CustomerID}?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getGuestCustomerDetails(CustomerID: any) {
    const url = `${apiUrl}/customers/${CustomerID}?isGuestUser=true&SiteNo=${this.siteNo}`;

    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  addCustomer(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/customers?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.post(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  addGuestCustomer(body: any) {
    const url = `${apiUrl}/customers?isGuestUser=true&SiteNo=${this.siteNo}`;

    return this.http.post(url, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getWelcomeLetter(transId: number, isGuestUser: boolean = false) {
    let url = `${apiUrl}/documents/welcome/${transId}?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getMoveInReceiptPdf(transId: number, isGuestUser: boolean = false) {
    let url = `${apiUrl}/documents/moveInReceipt/${transId}?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getSizes(sizeType?: number, unitTypeId?: number, sortBy?: any, siteConfigId?: string) {
    let params = new HttpParams();
    const sortByFilter = sortBy !== undefined ? sortBy : "";

    const url = `${apiUrl}/sizes?SiteNo=${this.siteNo
      }&PageSize=${100}&SizeType=${sizeType}&UnitTypeId=${unitTypeId}${sortByFilter}&IsTenantPortal=true`;

    if (siteConfigId) {
      params = params.append('SiteConfigId', siteConfigId);
    }

    return this.http.get(url, { params, headers: this.getHttpHeaders() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getGuestSizes(sizeType?: number, unitTypeId?: number, sortBy?: any, siteConfigId?: string) {
    let params = new HttpParams();
    const sortByFilter = sortBy !== undefined ? sortBy : "";

    const url = `${apiUrl}/sizes?isGuestUser=true&SiteNo=${this.siteNo
      }&PageSize=${100}&SizeType=${sizeType}&UnitTypeId=${unitTypeId}${sortByFilter}&IsTenantPortal=true`;

    if (siteConfigId) {
      params = params.append('SiteConfigId', siteConfigId);
    }

    return this.http.get(url, { params }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getClearentConfigData(isGuestUser: boolean = false) {
    let url = `${apiUrl}/auth/clearentConfig/${1}?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveClearentCardToken(body: any, isGuestUser: boolean = false) {
    let url = `${apiUrl}/payment/saveClearentCardToken?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.post(url, body, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCreditCards(isGuestUser: boolean = false) {
    let url = `${apiUrl}/payment/creditCards?SiteNo=${this.siteNo}`;

    if (isGuestUser) {
      url += "&isGuestUser=true";
    }

    return this.http.get(url, {
      headers: isGuestUser
        ? this.getHttpHeadersNoBearer()
        : this.getHttpHeaders()
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getHttpHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }).set("Content-Type", "application/json");
  }

  getHttpHeadersNoBearer() {
    return new HttpHeaders().set("Content-Type", "application/json");
  }
}
