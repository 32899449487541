import { Injectable } from "@angular/core";
import { HomeService } from "./home.service";
import { HelperService } from "./helper.service";
import { BehaviorSubject, interval, startWith, Subscription } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { ComDevice } from "./signalr.service.models";
import { ClosestGateResponseModel, SignalRConfigurationModel } from "api/models";

declare let $: any;

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  private proxy: any;
  private connection: any;
  private siteConfigs: SignalRConfigurationModel;

  public latitude: number;
  public longitude: number;

  public deviceNo: number;
  public customerId: number;
  public keypadId: number;

  public comDevices: ComDevice[] = [];
  public closestGate: ClosestGateResponseModel;

  public isRequesting = true;
  public isGeolocationGranted = false;
  private sscClientIdentifier?: string;

  public geoLocationIntervalSubscription: Subscription;
  private signalRLocalStorageKey: string = "signalr-tenant-connection-id";
  private clientIdentifierLocalStorageKey: string = "signalr-tenant-client-identifier-id";

  geo_options = {
    enableHighAccuracy: true,
    maximumAge: 1000,
  };
  gateOpened$: BehaviorSubject<null> = new BehaviorSubject<null>(null);

  constructor(
    private homeService: HomeService,
    private helperService: HelperService
  ) { }

  public initConnection(configs: SignalRConfigurationModel): void {
    this.customerId = +this.helperService.getItemFromLocalStorage("CustomerId");
    this.siteConfigs = configs;
    this.connection = $.hubConnection(this.siteConfigs.SignalRURI, {
      useDefaultPath: false,
    });

    let sscClientIdentifier = this.helperService.generateGUID();
    this.connection.logging = true;
    this.connection.qs = {
      SscClient: `Sentinel-Tenant-${sscClientIdentifier}`,
      SscCustNo: this.siteConfigs.SignalRSiteNumber,
      SSCsiteConfigID: this.siteConfigs.SiteConfigID,
    };

    this.proxy = this.connection.createHubProxy("myHub");

    if (this.proxy.hubName) {
      this.tryRemoveOldClientConnection();
      this.initListeners();
      this.startConnection();
      this.initOnDisconnectBehaviour();
    }
  }

  private initOnDisconnectBehaviour() {
    this.connection.disconnected(() => {
      setTimeout(() => {
        this.startConnection();
      }, 5000);
    });
  }

  public startConnection() {
    this.connection
      .start()
      .done(() => {
        this.proxy
          .invoke(
            "GetComDevices",
            this.siteConfigs.SignalRSiteNumber,
            this.siteConfigs.SiteConfigID,
            this.connection.id
          )
          .catch((error: HttpErrorResponse) => {
            console.log("SIGNALR => GetComDevices error -> " + error);
          });
      })
      .catch((error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  private initListeners() {
    this.proxy.on("SetComDevices", (data: ComDevice[]) => {
      console.log("SIGNALR => SetComDevices", data);
      this.comDevices = data;
      this.startPositionTracking();
    });

    this.proxy.on("SetValue", (value: string, message: string) => {
      console.log("SIGNALR => SetValue", value, message);
      switch (value) {
        case "Gate_Status":
          this.gateOpened$?.next(null);
          this.gateOpened$?.complete();
          break;
      }
    });
  }

  private tryRemoveOldClientConnection() {
    this.proxy.on('SetConnectionId', (connectionId: string, clientIdentifier: string) => {
      let previousConnectionId = localStorage.getItem(this.signalRLocalStorageKey);
      if (previousConnectionId) {
        let previosClientIdentifier = localStorage.getItem(this.clientIdentifierLocalStorageKey);
        this.proxy
          .invoke('RemoveOldClient', previosClientIdentifier)
          .then(() => { });
      }
      console.log("SIGNALR acknowledged connection id = " + connectionId);
      localStorage.setItem(this.signalRLocalStorageKey, connectionId);
      localStorage.setItem(this.clientIdentifierLocalStorageKey, clientIdentifier);
    });
  }

  startPositionTracking() {
    this.requestGeoLocationPermission();
    this.geoLocationIntervalSubscription = interval(10000)
      .pipe(startWith(0))
      .subscribe(() => {
        if (this.isGeolocationGranted) {
          this.refreshGeoLocation();
        }
      });
  }

  refreshGeoLocation() {
    if (!this.isGeolocationGranted) {
      return;
    }

    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      this.onGeoLocationSuccess,
      this.onGeoLocationError,
      this.geo_options
    );
  }

  onGeoLocationSuccess = (position: GeolocationPosition) => {
    this.latitude = position.coords.latitude;
    this.longitude = position.coords.longitude;
    console.log(`Latitude: ${this.latitude} Longitude: ${this.longitude}`);

    this.getClosestGate();
  };

  onGeoLocationError = (err: any) => {
    alert(`ERROR(${err.code}): ${err.message}`);
  };

  requestGeoLocationPermission() {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          this.isGeolocationGranted = true;
          this.refreshGeoLocation();
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            this.onGeoLocationSuccess,
            this.onGeoLocationError,
            this.geo_options
          );
        } else if (result.state === "denied") {
          this.isGeolocationGranted = false;
          alert(
            "Geolocation permission denied. Please enable it in your browser settings."
          );
          return;
        }
        result.onchange = () => {
          if (result.state === "granted") {
            this.isGeolocationGranted = true;
            this.refreshGeoLocation();
          } else if (result.state === "denied") {
            this.isGeolocationGranted = false;
            alert(
              "Geolocation permission denied. Please enable it in your browser settings."
            );
            return;
          }
        };
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        this.onGeoLocationSuccess,
        this.onGeoLocationError,
        this.geo_options
      );
    }
  }

  onOpenGate(onError?: () => void) {
    this.gateOpened$ = new BehaviorSubject<null>(null);
    this.homeService.getGateAccessData().subscribe((data) => {
      console.log("open gate called");
      console.log("siteConfigs", this.siteConfigs);
      console.log("deviceNo", this.closestGate.KeyPadID);
      console.log("connectionId", this.connection.id);
      console.log("getGateAccessData", data.Result);
      this.proxy
        .invoke(
          "TenantOpenGate",
          this.siteConfigs.SignalRSiteNumber,
          this.siteConfigs.SiteConfigID,
          this.closestGate.KeyPadID,
          this.connection.id,
          data.Result?.AINumber,
          data.Result?.PassCode
        )
        .then(() => {
        })
        .catch((error: HttpErrorResponse) => {
          console.log("tenantOpenGate error => ", error);

          if (onError) {
            onError();
          }
        });
    });
  }

  getClosestGate() {
    this.homeService
      .loadClosestGateData(
        this.latitude,
        this.longitude,
        this.comDevices.map((d) => d.CommDeviceNo.toLocaleString())
      )
      .subscribe((data) => {
        console.log("closestGate Result", data.Result);
        this.closestGate = data.Result!;

        this.isRequesting = false;
      });
  }

  getNameUI() {
    return this.comDevices.find(
      (x) => x.CommDeviceNo === this.closestGate?.KeyPadID
    )?.Description;
  }

  getDescriptionUI() {
    return this.closestGate?.GateDesc;
  }

  getLocationUI() {
    return `${this.latitude || '-'} / ${this.longitude || '-'}`;
  }

  ngOnDestroy(): void {
    if (this.geoLocationIntervalSubscription) {
      this.geoLocationIntervalSubscription.unsubscribe();
    }
  }
}
