import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },

  {
    path: "dashboard",
    loadChildren: () =>
      import("./layout/layout.module").then((m) => m.LayoutModule),
  },

  {
    path: "login",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./authentication/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./authentication/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },

  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollOffset: [0, 0], 
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
